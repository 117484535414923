import { Component, EventEmitter, Input, Output } from "@angular/core";

export interface ToggleSwitchEvent {
  identifier: string;
  isEnabled: boolean;
}

@Component({
  selector: 'mibp-toggle-switch',
  templateUrl: './toggle-switch.component.html',
  styleUrls: ['./toggle-switch.component.scss']
})

export class ToggleSwitchComponent {
  @Input() isDisabled = false;
  @Input() identifier : string;
  @Output() valueToggle = new EventEmitter<ToggleSwitchEvent>();

  onToggle():void{
    this.valueToggle.emit({identifier:this.identifier,isEnabled:!this.isDisabled});
  }
}
