import { LogService } from './../../services/logservice/log.service';
import { MibpFilterBase, MibpTextFilter, MibpDateFilter, MibpDropdownFilter } from './filter.component.types';
import { Injectable } from '@angular/core';
import { MibpLogger } from 'root/services/logservice/mibplogger.class';
import { UrlHelperService } from 'root/services';
import { DropdownInput } from '../dropdown';

@Injectable()
export class MibpFilterComponentService {

  private filters: { [name: string]: MibpFilterBase} = {};
  private log: MibpLogger;

  constructor(logger: LogService, private urlHelper: UrlHelperService) {
    this.log = logger.withPrefix('filter-service');
  }

  public addTextFilter(name: string, resourceStringKey: string): MibpFilterComponentService {
    this.filters[name] = new MibpTextFilter(name, resourceStringKey, null);
    return this;
  }

  public clear(): MibpFilterComponentService {
    this.filters = {};
    return this;
  }

  public addDropdownFilter(
    name: string,
    resourceStringKey: string,
    items: DropdownInput[],
    showMoreAfter: number = null,
    scrollableOverflowHeight = 0, // Currently only implemented for Checkbox filters
    initialSelectedValues: DropdownInput[] = null
    ): MibpFilterComponentService {
    this.filters[name] = new MibpDropdownFilter(name, resourceStringKey, items, showMoreAfter, initialSelectedValues, scrollableOverflowHeight);
    return this;
  }

  public hideFilter(...names: string[]): void {

    names.forEach(name => {
      const filter = this.filters[name];
      if (filter) {
        filter.hidden = true;
      }
    });

  }

  public showFilter(...names: string[]): void {

    names.forEach(name => {
      const filter = this.filters[name];
      if (filter) {
        filter.hidden = false;
      }
    });

  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public getQuerystringFilter(): { [key: string]: any } {
    const p = this.urlHelper.parseUrl();
    if (p.query['filter']) {
      return JSON.parse(p.query['filter']);
    }
    return {};
  }

  /**
   *
   *
   * @param {string} name
   * @param {string} resourceStringKey
   * @param {Date} [value]
   * @param {boolean} asEndOfDay Set to TRUE to set end of the selected day an not the start
   * @param {*}
   * @returns {MibpFilterComponentService}
   * @memberof MibpFilterComponentService
   */
  public addDateFilter(name: string, resourceStringKey: string, asEndOfDay = false): MibpFilterComponentService {
    this.filters[name] = new MibpDateFilter(name, resourceStringKey, null, asEndOfDay);
    return this;
  }

  public getTextFilter(name: string): MibpTextFilter {
    if (!this.filters[name]) {
      this.log.error(`Filter with name ${name} is not defined`);
      return null;
    }
    if (!(this.filters[name] instanceof MibpTextFilter)) {
      this.log.error(`Filter with name ${name} is not a Text filter`);
      return null;
    }
    return this.filters[name] as MibpTextFilter;
  }

  public getDropdownFilter(name: string): MibpDropdownFilter {
    if (!this.filters[name]) {
      this.log.error(`Filter with name ${name} is not defined`);
      return null;
    }
    if (!(this.filters[name] instanceof MibpDropdownFilter)) {
      this.log.error(`Filter with name ${name} is not a Dropdown filter`);
      return null;
    }
    return this.filters[name] as MibpDropdownFilter;
  }

  public getDateFilter(name: string): MibpDateFilter {
    if (!this.filters[name]) {
      this.log.error(`Filter with name ${name} is not defined`);
      return null;
    }
    if (!(this.filters[name] instanceof MibpDateFilter)) {
      this.log.error(`Filter with name ${name} is not a Date filter`);
      return null;
    }
    return this.filters[name] as MibpDateFilter;
  }


  public toArray():  MibpFilterBase[] {
    return Object.keys(this.filters).map(key => this.filters[key] ).filter(val => typeof val !== 'undefined');
  }

}
