<div class="nothing-selected" *ngIf="!currentValue || currentValue.length == 0">
  No features selected
</div>
<table *ngIf="currentValue?.length > 0" class="selected-items">
  <colgroup>
    <col>
    <col width="20">
  </colgroup>
  <tr *ngFor="let featureIdentifier of currentValue; let i = index;">
    <td><mibp-resource-string [key]="'feature_' + featureIdentifier + '_name'"></mibp-resource-string></td>
    <td><button (click)="removeSelectedIndex(i)" class="my-button my-button--small"><span translate="no" class="material-icon">delete</span></button></td>
  </tr>
</table>

<div>
  <button type="button" class="my-button my-button--blue" (click)="openDialog()">Select Features</button>
</div>

<mibp-dialog [buttons]="dialogButtons" titleResourceStringKey="FeaturePicker_DialogTitle">

  <table class="my-table my-table--compact my-table--hover">


    <tbody *ngFor="let groupForm of formGroups.controls; let groupI = index;" [formGroup]="groupForm">
      <tr>
        <th colspan="3">{{ groupForm.value.name }}</th>
      </tr>
      <tr *ngFor="let featureForm of groupForm.controls.features.controls; let i = index;" [formGroup]="featureForm">
        <td>
          <div class="checkbox"><input id="change{{groupI}}_{{i}}" formControlName="selected" type="checkbox"><label for="change{{groupI}}_{{i}}">{{ featureForm.value.name }}</label></div>
        </td>
        <td>
          <mibp-resource-string [isHtml]="true" [key]="'feature_' + featureForm.value.identifier + '_description'"></mibp-resource-string>
        </td>
      </tr>

    </tbody>

  </table>

</mibp-dialog>
