import { MySandvikNavigationItem } from "../navigation-types";
import { allPermissionPolicies } from "root/all-permission-policies";
export const NAVITEMS_SERVICE: MySandvikNavigationItem = {
  resourceKey: 'Global_Service',
  sandvikIconName: 'instant_mix',
  showIfAnyChildren: true,
  uniqueId: 'services',
  route: {
    exactMatch: true,
    path: ['services']
  },
  children: [
    {
      resourceKey: 'RequestSupport_Title',
      permissionPolicy: allPermissionPolicies.canCreateRemoteExpertise,
      uniqueId: 'remoteexpertise',
      route: {
        path: ['remoteexpertise']
      },
    }
  ]
};
