import { Directive, ElementRef, HostListener } from "@angular/core";
import { LogService, MibpLogger } from "root/services";

@Directive({
  selector: 'input[mibpIntegerOnlyValue]',
})

export class InputIntegerOnlyDirective {
  // Allow key codes for special events // Backspace, tab, end, home
  private specialKeyCodes: Array<string> = [
    'Backspace',
    'Tab',
    'End',
    'Home',
    'ArrowLeft',
    'ArrowRight',
    'Del',
    'Delete'
  ];
  
  log: MibpLogger;

  constructor(private el: ElementRef, logger: LogService) {
    this.log = logger.withPrefix('mibpIntegerOnlyValue');
  }
  @HostListener('keydown', ['$event'])

  keydown(event: KeyboardEvent) {
    return event.key.match(/^[0-9]+(\\.?[0-9]+)?$/) != null || this.specialKeyCodes.indexOf(event.key) != -1;
  }
}
