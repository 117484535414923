import { Component, OnDestroy, OnInit} from "@angular/core";
import { ActivatedRoute, NavigationEnd, Router, RouterEvent } from "@angular/router";
import { ChangeTrackingApiController } from "root/mibp-openapi-gen/controllers";
import { ChangeTrackingHistoryDto } from "root/mibp-openapi-gen/models";
import { LogService, MibpLogger, UrlHelperService } from "root/services";
import { Subscription, firstValueFrom } from "rxjs";

@Component({
  selector: 'mibp-change-tracking-change',
  templateUrl: './change-tracking-change.component.html',
  styleUrls: ['./change-tracking-change.component.scss']
})
export class  MibpChangeTrackingChangesComponent implements OnInit, OnDestroy {

  changeTrackingList: ChangeTrackingHistoryDto[];
  log: MibpLogger;
  routerSubscription: Subscription;
  isHistoryPage = true;
  isLoading = true;
  hasError = false;
  constructor(private changeTrackingApiController: ChangeTrackingApiController,
    private route: ActivatedRoute,
    private router: Router,
    logger: LogService,
    private urlHelper: UrlHelperService){
    if(!this.router.url.toString().endsWith('history')){
      this.isHistoryPage = false;
    }
    this.log = logger.withPrefix('change-tracking-change.component');
    this.routerSubscription = this.router.events.subscribe(
    {
      next: event => {
        if (event instanceof NavigationEnd) {
          if(event.url.endsWith('history')){
            this.isHistoryPage = true;
            this.getChangeTrackings();
          }
          else{
            this.isHistoryPage = false;
          }
        }
      }
    });
  }
  ngOnDestroy(): void {
    this.routerSubscription?.unsubscribe();
  }
  async ngOnInit() {
    await this.getChangeTrackings();
  }

  async getChangeTrackings(){
    const changeTrackinId = await this.getChangeTrackingId();
    if(changeTrackinId){
      await this.getChangeTrackingChanges(changeTrackinId);
    }
    this.isLoading = false;
  }

  private async getChangeTrackingChanges(changeTrackingId: number){
    try {
      this.isLoading = true;
      this.changeTrackingList = await firstValueFrom(this.changeTrackingApiController.listChangesByChangeTrackingId({changeTrackingId: changeTrackingId}));
    }
    catch(error){
      this.isLoading = false;
      this.hasError = true;
      this.log.error('error fetching change tracking changes',error);
    }
  }

  async getChangeTrackingId(){
    const p = this.urlHelper.parseUrl();
    const listPage = p.path.split('/');
    const id = listPage[listPage.length - 2] ? listPage[listPage.length - 2] : null;
    const entity = listPage[listPage.length - 3];
    try{
      return await firstValueFrom(this.changeTrackingApiController.getChangeTrackingId({entityType: entity, entityId: id}));
    }
    catch(error){
      this.hasError = true;
      this.isLoading = false;
      this.log.error('error fetching changetrackingid',error);
    }
  }

  navigateToDetailsPage(id: number){
    this.router.navigate(
      [id],
      {
        relativeTo: this.route
      });

  }
}

