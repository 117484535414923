import { catchError } from 'rxjs/operators';

import { DeliverySequenceApiController } from './../../mibp-openapi-gen/services/delivery-sequence-api-controller';
import { Injectable } from '@angular/core';
import { firstValueFrom, from, Observable, of } from 'rxjs';
import { DropdownData, DropdownInput} from '../../components/dropdown/dropdown.interface';
import { ResponsibilityPickerScope, SimpleScope } from '../../components/responsibility-picker/responsibility-picker.types';
import { map } from 'rxjs/operators';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { ApiService } from 'root/services/mibp-api-services/_mibp-api-generated.service';
import { Guid } from 'guid-typescript';
import { MibpLogger, LogService } from '../logservice';
import { BusinessRelation, DeliverySequence, ResponsibilityPickerKeyValuePair, Scope } from 'root/mibp-openapi-gen/models';
import { UsersApiController,BusinessRelationsApiController, CompaniesApiController, AccessGroupsApiController, OperationSitesApiController } from 'root/mibp-openapi-gen/controllers';
import { PagedSearchOptions, ResolvedScope, EquipmentSearchViewModel } from 'root/mibp-openapi-gen/models';
import { EquipmentsApiController } from 'root/mibp-openapi-gen/controllers';
import { GlobalConfigService } from '../global-config/global-config.service';


export interface ScopeValidationError {
  itemIndex: number;
  conflictsWithIndex: number;
  itemString: string;
  conflictsWithString: string;
  areEqual: boolean;
  isWithinScope: boolean;
}

export interface ExtendedDropdownInput extends DropdownInput {
  altValue?: string;
}  


export class ScopeInformation {

  log: MibpLogger;
  constructor(public scopes: Scope[], logger: LogService) {
    this.log = logger.withPrefix('responsibility-picker');
  }

  /**
   * true if any of the user Scopes has companyId NULL (Any)
   */
  get hasAnyCompany(): boolean {
    return this.scopes.filter(scope => scope.company === null).length > 0;
  }

  /**
   * True if any of the user Scopes with the specified company have null customer (ANY)
   */
  hasAnyCustomer(companyCode: string): boolean {
    if (this.hasAnyCompany) {
      // If UserScope contains ANY Company, then ignore any specific rows with customers...
      return true;
    }
    const hasAny = this.scopes.filter(scope => !!scope.company && scope.company.code === companyCode && scope.businessRelation === null);
    this.log.debug("hasAnycustomer", hasAny);
    return hasAny.length > 0;
  }

  hasAnyDeliverysequence(companyCode: string, customerNumber: string): boolean {
    if (this.hasAnyCustomer(companyCode)) {
      // If UserScope contains ANY Company, then ignore any specific rows with customers...
      return true;
    }
    return this.scopes
      .filter(scope =>
        !!scope.company && scope.company.code === companyCode &&
        // !!scope.businessRelation && scope.customer.number === customerNumber
        !!scope.businessRelation && scope.businessRelation.erpCustomerID === customerNumber
        && scope.deliverySequence === null
      ).length > 0;
  }



  getFirstCompanyCustomerFromScope(companyCode: string): BusinessRelation {
    const scope = this.scopes.find(s => s.company.code === companyCode && s.businessRelation !== null);
    return scope ? scope.businessRelation : null;
  }

  getFirstDeliverySequenceFromScope(companyCode: string, customerNumber: string): DeliverySequence {
    const scope = this.scopes.find(s =>
      !!s.company && s.company.code === companyCode
      // &&  !!s.customer  && s.customer.number === customerNumber
      &&  !!s.businessRelation  && s.businessRelation.erpCustomerID === customerNumber
      && s.deliverySequence !== null);
    return scope ? scope.deliverySequence : null;
  }

}


@Injectable({
  providedIn: 'root'
})
export class ResponsibilityPickerService {

    constructor(
      private accessGroupApi: AccessGroupsApiController,
      private sanitizer: DomSanitizer,
      private usersApi: UsersApiController,
      private logger: LogService,
      private companiesController: CompaniesApiController,
      private deliverySequenceApi: DeliverySequenceApiController,
      private businessRelationController: BusinessRelationsApiController,
      private equipmentsApiController : EquipmentsApiController,
      private operationSiteApiController : OperationSitesApiController,
      private globalConfig: GlobalConfigService) { 
        this.enableEquipmentScopingBasedOnCustomers = this.globalConfig.enableEquipmentScopingBasedOnCustomers;
      }

  private enableEquipmentScopingBasedOnCustomers = false;
  userScope: Scope[];
  public readonly pageSize = 10;
  log: MibpLogger;

  getAccessGroupScopes(accessGroupId: Guid): Observable<ResolvedScope[]> {
    return from(
      firstValueFrom(this.accessGroupApi.getResolvedScopes({ accessGroupId: accessGroupId.toString() })
      ).catch(error => {
        this.log.error('error fetching resolved scopes', error);
        throw new Error(error);
      }));
  }

  getUserScopes(): Observable<ScopeInformation> {

    if (this.userScope) {
      return of(new ScopeInformation(this.userScope, this.logger));
    }

    return this.usersApi.getOrganizationScopes().pipe(map(scopeList => {
      this.userScope = scopeList;
      return new ScopeInformation(scopeList, this.logger);
    }));
  }

  getCompanies(query: string, startIndex = 0, take = this.pageSize): Observable<DropdownData> {
    return this.companiesController.search({body: {query: query ? query + '*' : '', skip: startIndex, take: take} as PagedSearchOptions}).pipe(
      map(searchResult => <DropdownData>{
        hasMoreResults: searchResult.count > startIndex + searchResult.items.length,
        items: searchResult.items.map(company => <ExtendedDropdownInput>{
          text: company.code,
          value: company.code,
          altValue: company.id,
          disabled: !(company.isActive && company.hasActiveBusinessRelations)
        }),
        totalCount: searchResult.count,
      })
    );
  }

  getCustomers(companyCode: string, query: string, startIndex = 0, take = this.pageSize): Observable<DropdownData> {
    return this.businessRelationController.search({body:{companyCode: companyCode, query: query, skip: startIndex, take: take, searchErpCustomerIdOnly: true, fetchActiveOnly: false}}).pipe(
      map(searchResult => <DropdownData>{
        hasMoreResults: searchResult.count > startIndex + searchResult.items.length,
        items: searchResult.items.map(businessRelation => <ExtendedDropdownInput>{
          text: businessRelation.erpCustomerId,
          value: businessRelation.erpCustomerId,
          altValue: businessRelation.id,
          disabled: !(businessRelation.isActive && businessRelation.hasActiveDeliverySequences),
        }),
        totalCount: searchResult.count
      }),
      catchError(error => {
        this.log.error('error fetching business relations for dropdown', error);
        throw new Error(error);
      })
    );
  }

  private fixQuery(query: string): string {
    // Add wildcard to query
    return query ? query + '*' : '';
  }

  getDeliverySequences(companyCode: string, customerNumber: string,  query: string, startIndex: number, take: number): Observable<DropdownData> {
    return this.deliverySequenceApi.search(
      {
        companyCode:companyCode,
        customerNumber:customerNumber,
        query: this.fixQuery(query),
        index:startIndex,
        take:take
      }).pipe(
      map(searchResult => <DropdownData>{
        hasMoreResults: searchResult.count > startIndex + searchResult.items.length,
        items: searchResult.items.map(delierySequence => <DropdownInput>{
          text: delierySequence.number,
          value: delierySequence.number,
          disabled: !delierySequence.isActive
        }),
        totalCount: searchResult.count
      })
    );
  }

  getOperationSites(companyId: string, businessRelationId: string, query: string, startIndex = 0, take = this.pageSize): Observable<DropdownData> {
    return from(firstValueFrom(this.operationSiteApiController.searchForResponsibilityPicker(
      {
        companyId: companyId,         
        businessRelationId: businessRelationId,         
        query: query,
        skip: startIndex, 
        take: take
      }))).pipe(
      map(searchResult => <DropdownData>{
        hasMoreResults: searchResult.count > startIndex + searchResult.items.length,
        items: searchResult.items.map(operationSite => <DropdownInput>{
          text: `${operationSite.name}, ${operationSite.stateProvince}`,
          value: operationSite.id.toString()          
        }),
        totalCount: searchResult.count
      }),
      catchError(error => {
        this.log.error('error fetching operation sites for dropdown', error);
        throw new Error(error);
      })
    );
  }

  getProductAreas(searchFilter: EquipmentSearchViewModel): Observable<DropdownData> {
    return this.equipmentsApiController.listEquipmentProductAreas({body:searchFilter}).pipe(
      map(searchResult => <DropdownData>{
        hasMoreResults: searchResult.count > searchFilter.skip + searchResult.items.length,
        items: searchResult.items.map(productArea => <DropdownInput>{
          text: productArea.description,
          value: productArea.code
        }),
        totalCount: searchResult.count
      })
    );
  }

  getProductGroups(searchFilter: EquipmentSearchViewModel): Observable<DropdownData> {
    return this.equipmentsApiController.listEquipmentProductGroups({body:searchFilter}).pipe(
      map(searchResult => <DropdownData>{
        hasMoreResults: searchResult.count > searchFilter.skip + searchResult.items.length,
        items: searchResult.items.map(productGroup => <DropdownInput>{
          text: productGroup.description,
          value: productGroup.code
        }),
        totalCount: searchResult.count
      })
    );
  }

  getProductSubGroups(searchFilter: EquipmentSearchViewModel): Observable<DropdownData> {
    return this.equipmentsApiController.listEquipmentProductSubGroups({body : searchFilter}).pipe(
      map(searchResult => <DropdownData>{
        hasMoreResults: searchResult.count > searchFilter.skip + searchResult.items.length,
        items: searchResult.items.map(subgroup => <DropdownInput>{
          text: subgroup.description,
          value: subgroup.code
        }),
        totalCount: searchResult.count
      })
    );
  }

  getProductModels(searchFilter: EquipmentSearchViewModel): Observable<DropdownData> {
    return this.equipmentsApiController.listEquipmentProductModels({body : searchFilter}).pipe(
      map(searchResult => <DropdownData>{
        hasMoreResults: searchResult.count > searchFilter.skip + searchResult.items.length,
        items: searchResult.items.map(model => <DropdownInput>{
          text: model.name,
          value: model.name
        }),
        totalCount: searchResult.count
      })
    );
  }

  getSerialNumbers(searchFilter: EquipmentSearchViewModel): Observable<DropdownData> {
    return this.equipmentsApiController.listEquipment({body:searchFilter}).pipe(
      map(searchResult => <DropdownData>{
        hasMoreResults: searchResult.count > searchFilter.skip + searchResult.items.length,
        items: searchResult.items.map(modelName => <DropdownInput>{
          text: !modelName.alias ? modelName.name : ( modelName.name.concat(" (",modelName.alias , ")")),
          value: modelName.name
        }),
        totalCount: searchResult.count
      })
    );
  }

  getEqipmentSerialNumbers(searchFilter: EquipmentSearchViewModel): Observable<DropdownData> {
    return this.equipmentsApiController.listEquipment({body:searchFilter}).pipe(
      map(searchResult => <DropdownData>{
        hasMoreResults: searchResult.count > searchFilter.skip + searchResult.items.length,
        items: searchResult.items.map(modelName => <DropdownInput>{
          text: !modelName.alias ? modelName.name : ( modelName.name.concat(" (",modelName.alias , ")")),
          value: modelName.id.toString()
        }),
        totalCount: searchResult.count
      })
    );
  }

  convertToScopes(selection: ResponsibilityPickerScope): SimpleScope[] {
    const result: SimpleScope[] = [];

    selection = this.normalizeScope(selection);

    if (selection.companies.length === 1) {
      // One company
    }

    result.push(<SimpleScope> {
      companyId: 41,
      customerId: null,
      deliverySequenceid: null,
      operationSiteId: null,
      productAreaId: null,
      productGroupId: null,
      productModelId: null,
      productSubgroupId: null
    });

    return result;

  }

  selectionToScopes(selection: ResponsibilityPickerScope): Observable<ResolvedScope[]> {
    return from(firstValueFrom(this.accessGroupApi.resolveScopes({
      body: {
        companyCodes: selection.companies ? selection.companies.map(i => i.value) : null,
        customerNumbers: selection.customers ? selection.customers.map(i => i.value) : null,
        deliverySequenceNumbers: selection.deliverySequences ? selection.deliverySequences.map(i => i.value) : null,
        operationSites: selection.operationSites ? selection.operationSites.map(i => <ResponsibilityPickerKeyValuePair>{ key: i.text, value: i.value}) : null,
        productAreaCodes: selection.productAreas ? selection.productAreas.map(i => i.value) : null,
        productGroupCodes: selection.productGroups ? selection.productGroups.map(i => i.value) : null,
        productSubGroupCodes: selection.productSubgroups ? selection.productSubgroups.map(i => i.value) : null,
        productModelNames: selection.models ? selection.models.map(i => i.value) : null,
        equipmentNames: selection.serials ? selection.serials.map(i => i.value) : null,
      }
    })).catch(error => {
      this.log.error('error fetching resolved scopes', error);
      throw new Error(error);
    }));
  }




  // Will make sure responsibility picker scope has all properties and that they all are arrays
  private normalizeScope(scope: ResponsibilityPickerScope) {
    const keys = ['companies', 'customers', 'operationSites' , 'deliverySequences', 'productGroups', 'productSubgroups', 'models', 'serials'];
    keys.forEach(key => scope[key] = this.normalizeArray(scope[key]));    
    return scope;
  }

  private normalizeArray(value: any): any[] {
    if (value === undefined || value === null) {
      return [];
    }
    return value;
  }

  scopeToString(scope: ResponsibilityPickerScope): SafeHtml {

    scope = this.normalizeScope(scope);
    const lines: string[] = [];

    const hasCompanies = scope.companies.length > 0;
    const hasCustomers = scope.customers.length > 0;
    const hasOperationSites = scope.operationSites.length > 0;
    const hasDeliverySequences = scope.deliverySequences.length > 0;
    const hasModelGroups = scope.productGroups.length > 0;
    const hasModels = scope.models.length > 0;
    const hasSerials = scope.serials.length > 0;


    if (!hasCompanies && !hasCustomers && !hasOperationSites && !hasDeliverySequences && !hasModelGroups && !hasModels && !hasSerials) {
      return this.sanitizer.bypassSecurityTrustHtml('Everything');
    }

    if (hasModels) {
      lines.push(`Model${scope.models.length === 1 ? '' : 's'} ${this.joinWith(scope.models.map(m => m.value), 'and')}`);
    }

    if (hasCompanies) {
      if (lines.length > 0) {
        lines.push('in');
      }      
      lines.push(`Compan${scope.companies.length === 1 ? 'y' : 'ies'} ${this.joinWith(scope.companies.map(m => m.value), 'and')}`);
    }

    return this.sanitizer.bypassSecurityTrustHtml(lines.join(' '));
  }


  private joinWith(collection: string[], withWhat = 'or') {
    collection = collection.map(val => `<span class="scope">${val}</span>`);
    if (collection.length === 1) {
      return collection[0];
    } else {
      const first = collection.slice(0, collection.length - 1);
      const last = collection.slice(collection.length - 1);
      return `${first.join(', ')} ${withWhat} ${last.join(', ')}`;
    }
  }

  private readableScope(collection: string[], singular: string, plural: string, skipText = false) {
    collection = collection.map(val => `<span class="scope scope-${singular.toLowerCase()}">${val}</span>`);
    if (collection.length === 1) {
      return `${skipText ? '' : singular} ${collection[0]}`;
    } else {
      const first = collection.slice(0, collection.length - 1);
      const last = collection.slice(collection.length - 1);
      return `${skipText ? '' : plural} ${first.join(', ')} or ${last.join(', ')}`;
    }
  }



  validateScope(selectedResponsibilities: ResolvedScope[]): ScopeValidationError[] {
    if (!selectedResponsibilities) {
      return [];
    }
    const errors: ScopeValidationError[] = [];
    selectedResponsibilities.forEach((row, ix) => {
      selectedResponsibilities.forEach((r2, ix2) => {
        if (ix !== ix2) {
          const isWithin = this.isWithin(row, r2);
          const areEqual = this.areEqual(row, r2);
          if (isWithin || areEqual) {
            if (errors.findIndex(d => d.itemIndex === ix) === -1 && errors.findIndex(d => d.itemIndex === ix2) === -1) {
              errors.push({
                itemIndex: ix,
                itemString: this.getScopeName(row),
                conflictsWithIndex: ix2,
                conflictsWithString: this.getScopeName(r2),
                areEqual: areEqual,
                isWithinScope: isWithin
              });
            }
          }
        }
      });
    });


    for (let i = 0; i < errors.length; i++) {
      const item = errors[i];
      item.conflictsWithIndex = this.findLastReference(item, errors);
    }

    return errors;
  }

  private findLastReference(err: ScopeValidationError, list: ScopeValidationError[]): number {

    const referenceIsAlsoInError = list.find(f => f.itemIndex === err.conflictsWithIndex);
    if (referenceIsAlsoInError) {
      return this.findLastReference(referenceIsAlsoInError, list);
    }

    return err.conflictsWithIndex;
  }

  private areEqual(a: ResolvedScope, b: ResolvedScope) {
    return this.getScopeName(a) === this.getScopeName(b);
  }

  private getScopeName(a: ResolvedScope) {
    const name: string[] = [];

    name.push(a.company ? a.company.title : 'ANY');
    name.push(a.businessRelation ? a.businessRelation.erpCustomerId : 'ANY');
    name.push(a.operationSite ? a.operationSite.title : 'ANY');
    name.push(a.deliverySequence ? a.deliverySequence.title : 'ANY');

    name.push(a.productArea ? a.productArea.title : 'ANY');
    name.push(a.productGroup ? a.productGroup.title : 'ANY');
    name.push(a.productSubGroup ? a.productSubGroup.title : 'ANY');
    name.push(a.productModel ? a.productModel.title : 'ANY');
    name.push(a.equipment ? a.equipment.title : 'ANY');    

    return name.join('/');
  }


  testSingleOne(a: any, b: any, property: string, trueIfEqual = false): boolean {

    if (a[property] && !b[property]) {
      return true;
    }

    if (!a[property] && b[property]) {
      return false;
    }

    if (!a[property] && !b[property]) {
      return null;
    }

    if (a[property] && b[property] && a[property].id !== b[property].id  ) {
      return false;
    }
    else if (a[property] && b[property] && a[property].id === b[property].id  ) {
      return true;
    } else {
      if (trueIfEqual) {
        return true;
      }
    }

    return null;
  }


  isWithin(a: ResolvedScope, b: ResolvedScope) {
    const aHasAnyProductValues = a.productArea || a.productGroup || a.productSubGroup || a.productModel || a.equipment;
    const bHasAnyProductValues = b.productArea || b.productGroup || b.productSubGroup || b.productModel || b.equipment;
    const aHasCompanyValues = a.company || a.businessRelation || a.deliverySequence || a.operationSite;
    const bHasCompanyValues = b.company || b.businessRelation || b.deliverySequence || a.operationSite;

    if (!bHasAnyProductValues && !bHasCompanyValues && (aHasCompanyValues || aHasAnyProductValues)) {
      return true;
    }

    if (!aHasAnyProductValues && !bHasAnyProductValues) {
      //No product values. Just compare company values

      const cv = this.testCompanyValues(a, b);
      return cv;

    } else if (!aHasCompanyValues && !bHasCompanyValues) {
      //No product values. Just compare company values
      const cv = this.testProductValues(a, b);
      return cv;
    } else if (bHasAnyProductValues && !bHasCompanyValues && aHasCompanyValues && !aHasAnyProductValues)  {
      return false;
    } else if (aHasAnyProductValues && bHasAnyProductValues && aHasCompanyValues && !bHasCompanyValues) {
      const coTest = this.testProductValues(a, b, true);
      return coTest;
    } else if (aHasAnyProductValues && aHasCompanyValues && bHasCompanyValues && !bHasAnyProductValues) {
      if (this.testCompanyValues(a, b, true) === false) {
        return false;
      }
      return true;
    }
    return false;
  }

  private testCompanyValues(a: any, b: any, returnTrueIfEqual = false) {
    // Customer only in both A and B
    const co = this.testSingleOne(a, b, 'company', returnTrueIfEqual);
    if(co === null || co === false){return co;}

    const aHasBusinessRelationValues = a.businessRelation;
    const bHasBusinessRelationValues =  b.businessRelation;
    const aHasOperationSiteValues = a.operationSite;
    const bHasOperationSiteValues = b.operationSite;

    if((!aHasBusinessRelationValues && !aHasOperationSiteValues) 
    || (!bHasBusinessRelationValues && !bHasOperationSiteValues)) {
      return co;
    }
    
    const cu1 = this.testSingleOne(a, b, 'businessRelation', returnTrueIfEqual);
    if(cu1 === null || cu1 === false){return cu1;}

    if (this.enableEquipmentScopingBasedOnCustomers) {
      const os1 = this.testSingleOne(a, b, 'operationSite', returnTrueIfEqual);
      if(os1 === null || os1 === false){return os1;}
    }    

    const aHasDSValues =  a.deliverySequence;
    const bHasDSValues =  b.deliverySequence;
    if(!aHasDSValues || !bHasDSValues) {return cu1;}

    const ds1 = this.testSingleOne(a, b, 'deliverySequence', returnTrueIfEqual);
    if (ds1 !== null) { return ds1; }

    // Customer only in both A and B
    const cu = this.testSingleOne(a, b, 'businessRelation', returnTrueIfEqual);
    if (cu !== null) {

      if(cu === true)
      {
        const ds2 = this.testSingleOne(a, b, 'deliverySequence', returnTrueIfEqual);
        if (ds2 !== null) { return ds2; }
      }
      else{
        return cu;
      }
    }

    // Customer only in both A and B
    const ds = this.testSingleOne(a, b, 'deliverySequence', returnTrueIfEqual);
    if (ds !== null) { return ds; }

    return false;
  }

  private testProductValues(a: any, b: any, returnTrueIfEqual = false) {

    // Customer only in both A and B
    const pa = this.testSingleOne(a, b, 'productArea', returnTrueIfEqual);
    if (pa !== null) { return pa; }

    // Customer only in both A and B
    const pg = this.testSingleOne(a, b, 'productGroup', returnTrueIfEqual);
    if (pg !== null) { return pg; }

    // Customer only in both A and B
    const psg = this.testSingleOne(a, b, 'productSubgroup', returnTrueIfEqual);
    if (psg !== null) { return psg; }

    // Customer only in both A and B
    const pm = this.testSingleOne(a, b, 'productModel', returnTrueIfEqual);
    if (pm !== null) { return pm; }

    // Customer only in both A and B
    const eq = this.testSingleOne(a, b, 'equipment', returnTrueIfEqual);
    if (eq !== null) { return eq; }

    return false;
  }

  async importFile(file: File): Promise<ResolvedScope[]> {
    const formData = new FormData();
    formData.append('file', file, file.name);
    return firstValueFrom(this.accessGroupApi.resolveScopesFromFile({body:{filereq: file as Blob} }));
  }
}
