
import { Subscription } from 'rxjs/internal/Subscription';

import { Component, Input, OnInit, ViewChild, Output, EventEmitter, OnDestroy } from '@angular/core';
import { DropdownArgs, DropdownComponent, DropdownData, DropdownInput } from '../dropdown';
import { Observable } from 'rxjs';
import { MachineFilterRequest, MachineFilterResponseValue, MachineFilterType } from 'root/mibp-openapi-gen/models';
import { MachineFilterService } from 'root/services/machine-filter/machine-filter.service';
import { Params, Router } from '@angular/router';
import { FrontendContextService, GlobalConfigService, ScrollToService, SignalR_DeliverySequence } from 'root/services';
import { MibpSessionService } from 'root/services/mibp-session/mibp-session.service';

export interface MachineFilterSelection {
  machine: MachineFilterResponseValue[];
  model:MachineFilterResponseValue[];
  subgroup:MachineFilterResponseValue[];
  group:MachineFilterResponseValue[];
  company:MachineFilterResponseValue[];
  customer:MachineFilterResponseValue[];
  operationSite:MachineFilterResponseValue[];
  
}

@Component({
  selector: 'mibp-machine-filter',
  templateUrl: './machine-filter.component.html',
  styleUrls: ['./machine-filter.component.scss']
})
export class MachineFilterComponent implements OnInit, OnDestroy {

  @Input() currentPage: 'machines' | 'kits' | 'locations' | 'bulletins' |'partsmanuals' | 'promotions'| 'electronicmanuals' | 'maintenancekitcalendar' | 'claims' | 'warrantyclaims' |'fleetdocuments' | 'downloadedmanuals';
  @Input() extraQueryParams: Params;
  @Input() isHorizontal = false;
  @Input() promotionId: number;
  @Input() doNotScrollToTop = false;
  @Input() productCode: string;
  @Input() displayMode: 'default' | 'inline'  = 'default';
  @Input() useDynamicWidth = false;
  @Input() disabled = false;

  companyFilterEnabled = false;
  customerFilterEnabled = false;
  operationSiteFilterEnabled = false;
  machineFilterEnabled = true;
  machineGroupFilterEnabled = true;
  machineSubGroupFilterEnabled = true;
  machineModelFilterEnabled = true;
  dropdownPageSize = 10;

  @ViewChild(DropdownComponent) machineDropdown: DropdownComponent;
  @ViewChild(DropdownComponent) machineSubGroupDropdown: DropdownComponent;
  @ViewChild(DropdownComponent) machineGroupDropdown: DropdownComponent;
  @ViewChild(DropdownComponent) machineModelDropdown: DropdownComponent;
  @ViewChild(DropdownComponent) companyDropdown: DropdownComponent;
  @ViewChild(DropdownComponent) customerlDropdown: DropdownComponent;
  @ViewChild(DropdownComponent) operationSiteDropdown: DropdownComponent;

  data$: { [key: number]: Observable<DropdownData> } = {};

  initialMachineSelection: DropdownInput[];
  initialModelSelection: DropdownInput[];
  initialGroupSelection: DropdownInput[];
  initialSubgroupSelection: DropdownInput[];
  initialCompanySelection: DropdownInput[];
  initialCustomerSelection: DropdownInput[];
  initialOperationSiteSelection: DropdownInput[];

  deliverySequenceSubscription: Subscription;
  selectedFilters: MachineFilterSelection = {
    machine : [],
    model : [],
    group : [],
    subgroup : [],
    company : [],
    customer : [],
    operationSite : [],
  };
  machineFilterType = MachineFilterType.Machine;
  modelFilterType = MachineFilterType.Model;
  subgroupFilterType = MachineFilterType.SubGroup;
  groupFilterType = MachineFilterType.Group;
  companyFilterType = MachineFilterType.Company;
  customerFilterType = MachineFilterType.Customer;
  operationSiteFilterType = MachineFilterType.OperationSite;

  @Output() filterChange = new EventEmitter<MachineFilterSelection>();
  enableEquipmentScopingBasedOnCustomers: boolean;
  
  constructor(
    private machineservice: MachineFilterService,
    private router: Router,
    private sessionService: MibpSessionService,
    private scrollService: ScrollToService,
    private globalConfig: GlobalConfigService) {
  }

  ngOnInit(): void {
    this.enableEquipmentScopingBasedOnCustomers = this.globalConfig.enableEquipmentScopingBasedOnCustomers;
    this.setIntitialValues();
    this.intitializedropdowns();
    this.deliverySequenceSubscription = this.sessionService.activeDeliverySequence$.subscribe(() => {
      if(this.deliverySequenceSubscription){
        this.resetFilters();
        this.resetDropdowns();
      }
    });
  }

  ngOnDestroy(): void {
    this.deliverySequenceSubscription?.unsubscribe();
  }

  public clearFilters(doNotNavigate = false): void {
    this.resetFilters();
    this.resetDropdowns(doNotNavigate);
    this.selectedFilters = {
      machine : [],
      model : [],
      group : [],
      subgroup : [],
      company:[],
      customer:[],
      operationSite:[]
    };
  }

  resetFilters(){    
    for(const key of Object.keys(this.data$)) {
      this.data$[key] = null;
  }
  }

  resetDropdowns(doNotNavigate = false) {
    this.selectedFilters.machine = [];
    this.selectedFilters.model = [];
    this.selectedFilters.subgroup = [];
    this.selectedFilters.group = [];
    this.selectedFilters.company = [];
    this.selectedFilters.customer = [];
    this.selectedFilters.operationSite = [];
    this.setDropdownValues();
    if (!doNotNavigate) {
      this.routeUpdate();
    }
  }

  setIntitialValues(){
    const intitialFilter = this.machineservice.getInitialMachineFiltersFromRoute();
    if(intitialFilter){
      this.selectedFilters = intitialFilter;
      this.setDropdownValues();
    }
  }

  setDropdownValues () {
    this.initialMachineSelection = this.selectedFilters.machine;
    this.initialModelSelection = this.selectedFilters.model;
    this.initialSubgroupSelection = this.selectedFilters.subgroup;
    this.initialGroupSelection = this.selectedFilters.group;

    this.initialCompanySelection = this.selectedFilters.company;
    this.initialCustomerSelection = this.selectedFilters.customer;
    this.initialOperationSiteSelection = this.selectedFilters.operationSite;
  }

  filtermachines(a: DropdownArgs): void {
    const filterRequest = <MachineFilterRequest>{ take: a.take, skip: a.index,
      filterType: MachineFilterType.Machine, query: a.query,
      productModels: this.selectedFilters.model.map(s=> Number(s.value)),
      productGroups: this.selectedFilters.group.map(s=> Number(s.value)),
      productSubGroups: this.selectedFilters.subgroup.map(s=> Number(s.value)),

      companies: this.selectedFilters.company.map(s=> Number(s.value)),
      customers: this.selectedFilters.customer.map(s=> Number(s.value)),
      operationSites: this.selectedFilters.operationSite.map(s=> Number(s.value)),

      machines:[],
      productCode: this.productCode};
    this.filterForCurrentPage(this.currentPage, filterRequest);

  }

  filterModels(a: DropdownArgs): void {
    const filterRequest = <MachineFilterRequest>{ take: a.take, skip: a.index,
      filterType: MachineFilterType.Model, query: a.query,
      productModels: [], productGroups: this.selectedFilters.group.map(s=> Number(s.value)),
      productSubGroups: this.selectedFilters.subgroup.map(s=> Number(s.value)),
      companies: this.selectedFilters.company.map(s=> Number(s.value)),
      customers: this.selectedFilters.customer.map(s=> Number(s.value)),
      operationSites: this.selectedFilters.operationSite.map(s=> Number(s.value)),
      machines: this.selectedFilters.machine.map(s=> Number(s.value)),
      productCode: this.productCode

    };

    this.filterForCurrentPage(this.currentPage, filterRequest);
  }

  filterSubGroups(a: DropdownArgs): void {
    const filterRequest = <MachineFilterRequest>{ take: a.take, skip: a.index,
      filterType: MachineFilterType.SubGroup, query: a.query,
      productModels: this.selectedFilters.model.map(s=> Number(s.value)),
      productGroups: this.selectedFilters.group.map(s=> Number(s.value)),
      productSubGroups: [],
      companies: this.selectedFilters.company.map(s=> Number(s.value)),
      customers: this.selectedFilters.customer.map(s=> Number(s.value)),
      operationSites: this.selectedFilters.operationSite.map(s=> Number(s.value)),
      machines: this.selectedFilters.machine.map(s=> Number(s.value)),
      productCode: this.productCode};
    this.filterForCurrentPage(this.currentPage, filterRequest);
  }

  filterGroups(a: DropdownArgs): void {
    const filterRequest = <MachineFilterRequest>{ take: a.take, skip: a.index,
      filterType: MachineFilterType.Group, query: a.query,
      productModels: this.selectedFilters.model.map(s=> Number(s.value)),
      productGroups: [],
      productSubGroups: this.selectedFilters.subgroup.map(s=> Number(s.value)),
      companies: this.selectedFilters.company.map(s=> Number(s.value)),
      customers: this.selectedFilters.customer.map(s=> Number(s.value)),
      operationSites: this.selectedFilters.operationSite.map(s=> Number(s.value)),
      machines: this.selectedFilters.machine.map(s=> Number(s.value)),
      productCode: this.productCode};
    this.filterForCurrentPage(this.currentPage, filterRequest);
  }

  filterCompanies(a: DropdownArgs): void {
    const filterRequest = <MachineFilterRequest>{ take: a.take, skip: a.index,
      filterType: MachineFilterType.Company, query: a.query,
      productModels: this.selectedFilters.model.map(s=> Number(s.value)),
       productGroups: this.selectedFilters.group.map(s=> Number(s.value)),
      productSubGroups: this.selectedFilters.subgroup.map(s=> Number(s.value)),
      companies: [],
      customers: this.selectedFilters.customer.map(s=> Number(s.value)),
      operationSites: this.selectedFilters.operationSite.map(s=> Number(s.value)),
      machines: this.selectedFilters.machine.map(s=> Number(s.value)),
      productCode: this.productCode};
    this.filterForCurrentPage(this.currentPage, filterRequest);
  }

  filterCustomers(a: DropdownArgs): void {
    const filterRequest = <MachineFilterRequest>{ take: a.take, skip: a.index,
      filterType: MachineFilterType.Customer, query: a.query,
      productModels: this.selectedFilters.model.map(s=> Number(s.value)),
       productGroups: this.selectedFilters.group.map(s=> Number(s.value)),
      productSubGroups: this.selectedFilters.subgroup.map(s=> Number(s.value)),
      companies: this.selectedFilters.company.map(s=> Number(s.value)),
      customers: [],
      operationSites: this.selectedFilters.operationSite.map(s=> Number(s.value)),
      machines: this.selectedFilters.machine.map(s=> Number(s.value)),
      productCode: this.productCode};
    this.filterForCurrentPage(this.currentPage, filterRequest);
  }

  filterOperationSites(a: DropdownArgs): void {
    const filterRequest = <MachineFilterRequest>{ take: a.take, skip: a.index,
      filterType: MachineFilterType.OperationSite, query: a.query,
      productModels: this.selectedFilters.model.map(s=> Number(s.value)),
      productGroups: this.selectedFilters.group.map(s=> Number(s.value)),
      productSubGroups: this.selectedFilters.subgroup.map(s=> Number(s.value)),
      companies: this.selectedFilters.company.map(s=> Number(s.value)),
      customers: this.selectedFilters.customer.map(s=> Number(s.value)),
      operationSites: [],
      machines: this.selectedFilters.machine.map(s=> Number(s.value)),
      productCode: this.productCode};
    this.filterForCurrentPage(this.currentPage, filterRequest);
  }


  onDropdownValueChange(e: DropdownInput[], type: MachineFilterType){
    switch (type) {
    case MachineFilterType.Machine: {
      this.handelMachineDropdownChange(e);
      break;
    }
    case MachineFilterType.Model: {
      this.handelModelDropdownChange(e);
      break;
    }
    case MachineFilterType.SubGroup: {
      this.handelSubGroupDropdownChange(e);
      break;
    }
    case MachineFilterType.Group: {
      this.handelGroupDropdownChange(e);
      break;
    }
    case MachineFilterType.Company: {
      this.handelCompanyDropdownChange(e);
      break;
    }
    case MachineFilterType.Customer: {
      this.handelCustomerDropdownChange(e);
      break;
    }
    case MachineFilterType.OperationSite: {
      this.handelOperationSiteDropdownChange(e);
      break;
    }
    }
  }

  handelMachineDropdownChange(e: DropdownInput[]){
    this.resetFilters();

    this.selectedFilters.machine = e.map(ddlValue => {
      return <MachineFilterResponseValue>{
        text: ddlValue.text,
        value: ddlValue.value
      };
    });
    this.filterChange.emit(this.selectedFilters);
    this.routeUpdate();
  }

  routeUpdate(){
    const filterQueryParams = this.createFilterQueryString();
    let params = { machinefilter: filterQueryParams};
    setTimeout(() => {
      if (this.extraQueryParams) {
        params = Object.assign({}, params, this.extraQueryParams);
      }

      if (this.doNotScrollToTop) {
        this.scrollService.stopNextScrollToTop();
      }
      this.router.navigate([], { queryParams: params, queryParamsHandling: 'merge' });
    });
  }

  createFilterQueryString(){
    const querystringFilter:{[key: string]: any } = {};
    if(this.selectedFilters.machine && this.selectedFilters.machine.length > 0){
      querystringFilter['machine'] = this.selectedFilters.machine;
    }
    if(this.selectedFilters.group && this.selectedFilters.group.length > 0){
      querystringFilter['group'] = this.selectedFilters.group;
    }
    if(this.selectedFilters.model && this.selectedFilters.model.length > 0){
      querystringFilter['model'] = this.selectedFilters.model;
    }
    if(this.selectedFilters.subgroup && this.selectedFilters.subgroup.length > 0){
      querystringFilter['subgroup'] = this.selectedFilters.subgroup;
    }
    if(this.selectedFilters.company && this.selectedFilters.company.length > 0){
      querystringFilter['company'] = this.selectedFilters.company;
    }
    if(this.selectedFilters.customer && this.selectedFilters.customer.length > 0){
      querystringFilter['customer'] = this.selectedFilters.customer;
    }
    if(this.selectedFilters.operationSite && this.selectedFilters.operationSite.length > 0){
      querystringFilter['operationSite'] = this.selectedFilters.operationSite;
    }
    return Object.keys(querystringFilter).length > 0 ? JSON.stringify(querystringFilter) : null;
  }

  handelModelDropdownChange(e: DropdownInput[]){
    this.selectedFilters.model = e.map(ddlValue => {
      return <MachineFilterResponseValue>{
        text: ddlValue.text,
        value: ddlValue.value
      };
    });
    this.filterChange.emit(this.selectedFilters);
    this.routeUpdate();
  }

  handelSubGroupDropdownChange(e: DropdownInput[]){
    this.resetFilters();

    this.selectedFilters.subgroup =  e.map(ddlValue => {
      return <MachineFilterResponseValue>{
        text: ddlValue.text,
        value: ddlValue.value
      };
    });
    this.filterChange.emit(this.selectedFilters);
    this.routeUpdate();
  }

  handelGroupDropdownChange(e: DropdownInput[]){
    this.resetFilters();

    this.selectedFilters.group = e.map(ddlValue => {
      return <MachineFilterResponseValue>{
        text: ddlValue.text,
        value: ddlValue.value
      };
    });
    this.filterChange.emit(this.selectedFilters);
    this.routeUpdate();
  }

  handelCompanyDropdownChange(e: DropdownInput[]){
    this.resetFilters();

    this.selectedFilters.company =  e.map(ddlValue => {
      return <MachineFilterResponseValue>{
        text: ddlValue.text,
        value: ddlValue.value
      };
    });
    this.filterChange.emit(this.selectedFilters);
    this.routeUpdate();
  }

  handelCustomerDropdownChange(e: DropdownInput[]){
    this.resetFilters();

    this.selectedFilters.customer =  e.map(ddlValue => {
      return <MachineFilterResponseValue>{
        text: ddlValue.text,
        value: ddlValue.value
      };
    });
    this.filterChange.emit(this.selectedFilters);
    this.routeUpdate();
  }

  handelOperationSiteDropdownChange(e: DropdownInput[]){
    this.resetFilters();

    this.selectedFilters.operationSite =  e.map(ddlValue => {
      return <MachineFilterResponseValue>{
        text: ddlValue.text,
        value: ddlValue.value
      };
    });

    this.filterChange.emit(this.selectedFilters);
    this.routeUpdate();
  }

  intitializedropdowns(){

    if(this.enableEquipmentScopingBasedOnCustomers) {      

      this.companyFilterEnabled = this.sessionService.current?.user?.shouldSeeSalesEntityFilter;
      this.customerFilterEnabled = this.sessionService.current?.user?.shouldSeeCustomerFilter;
      this.operationSiteFilterEnabled = true;
    }

    switch (this.currentPage) {
    case 'machines': {
      this.machineFilterEnabled = false;
      break;
    }
    case 'kits': {
      this.machineSubGroupFilterEnabled = false;
      this.machineGroupFilterEnabled = false;
      break;
    }
    case 'electronicmanuals': {
      this.machineSubGroupFilterEnabled = false;
      break;
    }
    case 'locations': {
      this.operationSiteFilterEnabled = false;
      break;
    }
    case 'bulletins': {
      //statements;
      break;
    }
    case 'partsmanuals': {
      //statements;
      break;
    }
    case 'promotions': {
      this.operationSiteFilterEnabled = false;
      this.machineFilterEnabled = false;
      this.machineGroupFilterEnabled = false;
      this.machineSubGroupFilterEnabled = false;
      this.machineModelFilterEnabled = false;
      break;
    }
    case 'maintenancekitcalendar': {
      this.machineSubGroupFilterEnabled = false;
      this.machineGroupFilterEnabled = false;
      break;
    }
    case 'claims': {
      this.machineFilterEnabled = false;
      this.machineModelFilterEnabled = false;
      this.machineSubGroupFilterEnabled = false;
      this.machineGroupFilterEnabled = false;
      break;
    }
    case 'warrantyclaims': {
      this.machineFilterEnabled = false;
      this.machineModelFilterEnabled = false;
      this.machineSubGroupFilterEnabled = false;
      this.machineGroupFilterEnabled = false;
      break;
    }
    case 'fleetdocuments': {
      this.machineFilterEnabled = false;
      this.machineModelFilterEnabled = false;
      this.machineSubGroupFilterEnabled = false;
      this.machineGroupFilterEnabled = false;
      break;
    }
    case 'downloadedmanuals': {
      this.machineFilterEnabled = false;
      this.machineModelFilterEnabled = false;
      this.machineSubGroupFilterEnabled = false;
      this.machineGroupFilterEnabled = false;
      break;
    }
    }
  }

  filterForCurrentPage(currentPage: string, filterRequest: MachineFilterRequest) {
    switch (this.currentPage) {
      case 'machines': {
        this.data$[filterRequest.filterType] = this.machineservice.getFiltersForEquipment(filterRequest);
        break;
      }
      case 'kits': {
        this.data$[filterRequest.filterType] = this.machineservice.getFiltersForKits(filterRequest);
        break;
      }
      case 'locations': {
        this.data$[filterRequest.filterType] = this.machineservice.getFiltersForLocations(filterRequest);
        break;
      }
      case 'bulletins': {
        this.data$[filterRequest.filterType] = this.machineservice.getFiltersForBulletins(filterRequest);
        break;
      }
      case 'partsmanuals': {
        this.data$[filterRequest.filterType] = this.machineservice.getFiltersForPartsManuals(filterRequest);
        break;
      }
      case 'promotions': {
        this.data$[filterRequest.filterType] = this.machineservice.getFiltersForPromotions(filterRequest, this.promotionId);
        break;
      }
      case 'electronicmanuals': {
        this.data$[filterRequest.filterType] = this.machineservice.getFiltersForElectronicManuals(filterRequest);
        break;
      }
      case 'maintenancekitcalendar': {
        this.data$[filterRequest.filterType] = this.machineservice.getFiltersForMaintenanceKitCalendar(filterRequest);
        break;
      }
      case 'claims': {
        this.data$[filterRequest.filterType] = this.machineservice.getFiltersForClaims(filterRequest);
        break;
      }
      case 'warrantyclaims': {
        this.data$[filterRequest.filterType] = this.machineservice.getFiltersForWarrantyApplications(filterRequest);
        break;
      }
      case 'fleetdocuments': {
        this.data$[filterRequest.filterType] = this.machineservice.getFiltersForFleetDocuments(filterRequest);
        break;
      }
      case 'downloadedmanuals': {
        this.data$[filterRequest.filterType] = this.machineservice.getFiltersForDownloadedManuals(filterRequest);
        break;
      }
    }
  }
}
