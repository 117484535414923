import { ClientIdService } from './../clientid-service/clientid.service';
import { BroadcastService, DialogService, LocalizationService, LogService, MibpLogger } from 'root/services';
/* eslint-disable @typescript-eslint/no-explicit-any */
import { AuthService } from 'root/services';
import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpEventType, HttpErrorResponse, HttpClient } from '@angular/common/http';
import { EMPTY, firstValueFrom, from, NEVER, Observable, of, throwError } from 'rxjs';
import { catchError, switchMap} from 'rxjs/operators';
import { Router } from '@angular/router';
import { differenceInMinutes } from 'date-fns';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  private log: MibpLogger;

  constructor(
    private auth: AuthService,
    private http: HttpClient,
    private clientIdService: ClientIdService,
    private localizationService: LocalizationService,
    private broadcastService: BroadcastService,
    private router: Router,
    log: LogService,
    private dialogService: DialogService) {
    this.log = log.withPrefix('auth-interceptor');
  }

  /**
   * When this is true we have detected the "error 0 - Unknown Error" from backend calls,
   * and the release.json suggests we have a recent deploy.
   *
   * So then a message is shown and all requests to the backend will be blocked and user would have to reload page
   */
  private blockAllRequestsDuringMaintenance = false;

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<HttpEventType.Response>> {
    return from(this.handle(req, next));
  }

  async handle(req: HttpRequest<any>, next: HttpHandler): Promise<HttpEvent<any>> {

    // TODO: Here we call enusure token every time. Could this be done better - by trying to refresh when we get 401 errors perhaps?
    const accessToken = await this.auth.ensureToken(req.url.endsWith('/session'));

    const headers = {};
    const clientId = this.clientIdService.getClientId();

    // "Allow anoymous" for globalConfig. Refactor to some sort of config if we need more of these
    if (!req.url.includes('globalConfig')) {
      if (accessToken) {
        headers['Authorization'] = `Bearer ${accessToken}`;
      }
    }

    if (clientId) {
      headers['X-Client-Id'] = clientId;
    }

    headers['x-lang-code'] = this.localizationService.getLang();

    const authReq = req.clone({
      setHeaders: headers
    });

    if (this.blockAllRequestsDuringMaintenance || this.broadcastService.snapshot.sessionRevoked) {
      if (this.broadcastService.snapshot.sessionRevoked) {
        this.log.warn(`[Block] Session is revoked: ${req.url}`);
      } else {
        this.log.warn(`[Block] During maintenance: ${req.url}`);
      }
      return firstValueFrom(NEVER);
    }

    return next.handle(authReq)

      .pipe(catchError((response: HttpErrorResponse) => {
        // This is what we catch when we get CORS error when backend application is turned off (during deploy)
        if (response.status == 0 && response.statusText == 'Unknown Error') {
        //if (response.status == 403) { // Useful for  testing on LDE
          this.log.info(`Deteted error that may occur during deploy`);
          return this.handleBackendErrorDuringDeploy(req, next, response);
        }

        if (response.status == 403 && response.error?.errors[0]?.exceptionType == "MibpForbiddenException") {
          this.router.navigateByUrl('en/unauthorized',{skipLocationChange: true});
        }
        const errors = response.error?.errors;

        if (errors && response.status == 500 && errors[errors.length - 1]?.exceptionType == "MibpSessionRevokedException") {
          this.blockAllRequestsDuringMaintenance = true; // To stop all the following potential requests
          this.dialogService.prompt("Users_Session_LogOutMessage", "Global_Ok").finally(() => {
            this.auth.signout(null, 'revoked');
          });
          return NEVER;
        }
        return throwError(() => response);
      })).toPromise();

  }


  private handleBackendErrorDuringDeploy(request: HttpRequest<any>, next: HttpHandler, originalError: HttpErrorResponse): Observable<HttpEvent<any>> {
    this.log.info(`Checking for a release.json file`);

    return this.tryGetReleaseFile().pipe(
      switchMap((releaseJsonResponse) => {
        if (releaseJsonResponse && Object.keys(releaseJsonResponse).length > 0) {

          // deployTime in this file is set in Release pipeline before code is deployed to azure
          if (releaseJsonResponse.deployTime) {
            const deployTime = new Date(releaseJsonResponse.deployTime);
            const minutesSinceDeploy = differenceInMinutes(new Date(), deployTime);
            this.log.info(`release.json found. Deploy was done ${minutesSinceDeploy} minutes ago. (${deployTime})`);
            if (differenceInMinutes(new Date(), deployTime) < 60) {
              this.log.info(`Showing maintenance message`);

              // If we get this error and we're close to a deploy, then we show the small maintenance message
              this.showMaintenanceMessage();
              return NEVER;
            }
          }
          return throwError(() => originalError);
        } else {
          return throwError(() => originalError);
        }
      }),
      catchError(err => {
        this.log.warn('Could not load release.json', err);
        return throwError(() => originalError);
      })
    );
  }

  private tryGetReleaseFile(): Observable<any> {
    return from(this.http.get(`/assets/release.json?c=${new Date().getTime()}`)
      .pipe(
        switchMap(releaseFileResponse => {
          return of(releaseFileResponse);
        }),
        catchError(() => {
          return of({});
        })
      ));
  }

  /**
   * Show a maintenance message covering the site.
   * This will only be shown if a deploy has been done recently and we get strange
   * errors from backend (error code = 0 and statusText == "Unknown Error")
   */
  private showMaintenanceMessage(): void {

    this.auth.clearCacheDueToError();

    if (document.querySelector('.error-maintenance-window')) {
      return;
    }

    this.blockAllRequestsDuringMaintenance = true;

    const div = document.createElement(`div`);
    div.classList.add('error-maintenance-window');
    div.classList.add('error-maintenance-window--hidden');
    div.innerHTML = `<div>
      <div class="icon"><span class="material-icon">handyman</span></div>
      <div><img src="/assets/images/logo_black.svg" alt="Sandvik Logotype"></div>
      <div><h1></h1></div>
      <p class="first">Sorry, we're down for scheduled maintenance right now.</p>
      <p class="second">Please wait a while and try again.</p>
    </div>`;
    div.querySelector('h1').textContent = this.localizationService.get('ScheduledMaintenanceError_Title', 'Scheduled maintenance');
    div.querySelector('p.first').textContent = this.localizationService.get('ScheduledMaintenanceError_Message1', `Sorry, we're down for scheduled maintenance right now.`);
    div.querySelector('p.second').textContent = this.localizationService.get('ScheduledMaintenanceError_Message2', `Please wait a while and try again`);

    document.body.append(div);
    document.querySelector('.error-maintenance-window').classList.add('error-maintenance-window--visible');

  }
}
