/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { EquipmentIndexDtoSearchResponse } from '../models/equipment-index-dto-search-response';
import { getEquipmentsForFilters } from '../fn/operation-sites/get-equipments-for-filters';
import { GetEquipmentsForFilters$Params } from '../fn/operation-sites/get-equipments-for-filters';
import { getEquipmentsForFilters$Plain } from '../fn/operation-sites/get-equipments-for-filters-plain';
import { GetEquipmentsForFilters$Plain$Params } from '../fn/operation-sites/get-equipments-for-filters-plain';
import { getEquipmentsForOperationSite } from '../fn/operation-sites/get-equipments-for-operation-site';
import { GetEquipmentsForOperationSite$Params } from '../fn/operation-sites/get-equipments-for-operation-site';
import { getEquipmentsForOperationSite$Plain } from '../fn/operation-sites/get-equipments-for-operation-site-plain';
import { GetEquipmentsForOperationSite$Plain$Params } from '../fn/operation-sites/get-equipments-for-operation-site-plain';
import { getTargetForPicker } from '../fn/operation-sites/get-target-for-picker';
import { GetTargetForPicker$Params } from '../fn/operation-sites/get-target-for-picker';
import { getTargetForPicker$Plain } from '../fn/operation-sites/get-target-for-picker-plain';
import { GetTargetForPicker$Plain$Params } from '../fn/operation-sites/get-target-for-picker-plain';
import { hasAnyOperationSites } from '../fn/operation-sites/has-any-operation-sites';
import { HasAnyOperationSites$Params } from '../fn/operation-sites/has-any-operation-sites';
import { hasAnyOperationSites$Plain } from '../fn/operation-sites/has-any-operation-sites-plain';
import { HasAnyOperationSites$Plain$Params } from '../fn/operation-sites/has-any-operation-sites-plain';
import { MapDtoSearchResponse } from '../models/map-dto-search-response';
import { OperationSiteIndexSearchResponse } from '../models/operation-site-index-search-response';
import { OperationSitePickerTargetDto } from '../models/operation-site-picker-target-dto';
import { OperationSiteViewModelSearchResponse } from '../models/operation-site-view-model-search-response';
import { searchForResponsibilityPicker } from '../fn/operation-sites/search-for-responsibility-picker';
import { SearchForResponsibilityPicker$Params } from '../fn/operation-sites/search-for-responsibility-picker';
import { searchForResponsibilityPicker$Plain } from '../fn/operation-sites/search-for-responsibility-picker-plain';
import { SearchForResponsibilityPicker$Plain$Params } from '../fn/operation-sites/search-for-responsibility-picker-plain';
import { searchOperationSites } from '../fn/operation-sites/search-operation-sites';
import { SearchOperationSites$Params } from '../fn/operation-sites/search-operation-sites';
import { searchOperationSites$Plain } from '../fn/operation-sites/search-operation-sites-plain';
import { SearchOperationSites$Plain$Params } from '../fn/operation-sites/search-operation-sites-plain';
import { searchOperationSitesForPicker } from '../fn/operation-sites/search-operation-sites-for-picker';
import { SearchOperationSitesForPicker$Params } from '../fn/operation-sites/search-operation-sites-for-picker';
import { searchOperationSitesForPicker$Plain } from '../fn/operation-sites/search-operation-sites-for-picker-plain';
import { SearchOperationSitesForPicker$Plain$Params } from '../fn/operation-sites/search-operation-sites-for-picker-plain';

@Injectable({ providedIn: 'root' })
export class OperationSitesApiController extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `operationSitesControllerSearchForResponsibilityPickercompanyIdBusinessRelationIdQuerySkipTake()` */
  static readonly OperationSitesControllerSearchForResponsibilityPickercompanyIdBusinessRelationIdQuerySkipTakePath = '/operationsites/searchforresponsibilitypicker';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `searchForResponsibilityPicker$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  searchForResponsibilityPicker$Plain$Response(params?: SearchForResponsibilityPicker$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<OperationSiteViewModelSearchResponse>> {
    return searchForResponsibilityPicker$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `searchForResponsibilityPicker$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  searchForResponsibilityPicker$Plain(params?: SearchForResponsibilityPicker$Plain$Params, context?: HttpContext): Observable<OperationSiteViewModelSearchResponse> {
    return this.searchForResponsibilityPicker$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<OperationSiteViewModelSearchResponse>): OperationSiteViewModelSearchResponse => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `searchForResponsibilityPicker()` instead.
   *
   * This method doesn't expect any request body.
   */
  searchForResponsibilityPicker$Response(params?: SearchForResponsibilityPicker$Params, context?: HttpContext): Observable<StrictHttpResponse<OperationSiteViewModelSearchResponse>> {
    return searchForResponsibilityPicker(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `searchForResponsibilityPicker$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  searchForResponsibilityPicker(params?: SearchForResponsibilityPicker$Params, context?: HttpContext): Observable<OperationSiteViewModelSearchResponse> {
    return this.searchForResponsibilityPicker$Response(params, context).pipe(
      map((r: StrictHttpResponse<OperationSiteViewModelSearchResponse>): OperationSiteViewModelSearchResponse => r.body)
    );
  }

  /** Path part for operation `operationSitesControllerSearchOperationSitesrefinementOptions()` */
  static readonly OperationSitesControllerSearchOperationSitesrefinementOptionsPath = '/operationsites/sites';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `searchOperationSites$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  searchOperationSites$Plain$Response(params?: SearchOperationSites$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<MapDtoSearchResponse>> {
    return searchOperationSites$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `searchOperationSites$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  searchOperationSites$Plain(params?: SearchOperationSites$Plain$Params, context?: HttpContext): Observable<MapDtoSearchResponse> {
    return this.searchOperationSites$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<MapDtoSearchResponse>): MapDtoSearchResponse => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `searchOperationSites()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  searchOperationSites$Response(params?: SearchOperationSites$Params, context?: HttpContext): Observable<StrictHttpResponse<MapDtoSearchResponse>> {
    return searchOperationSites(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `searchOperationSites$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  searchOperationSites(params?: SearchOperationSites$Params, context?: HttpContext): Observable<MapDtoSearchResponse> {
    return this.searchOperationSites$Response(params, context).pipe(
      map((r: StrictHttpResponse<MapDtoSearchResponse>): MapDtoSearchResponse => r.body)
    );
  }

  /** Path part for operation `operationSitesControllerSearchOperationSitesForPickercompanyIdBusinessRelationIdQuerySkipTake()` */
  static readonly OperationSitesControllerSearchOperationSitesForPickercompanyIdBusinessRelationIdQuerySkipTakePath = '/operationsites/sitesforpicker';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `searchOperationSitesForPicker$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  searchOperationSitesForPicker$Plain$Response(params?: SearchOperationSitesForPicker$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<OperationSiteIndexSearchResponse>> {
    return searchOperationSitesForPicker$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `searchOperationSitesForPicker$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  searchOperationSitesForPicker$Plain(params?: SearchOperationSitesForPicker$Plain$Params, context?: HttpContext): Observable<OperationSiteIndexSearchResponse> {
    return this.searchOperationSitesForPicker$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<OperationSiteIndexSearchResponse>): OperationSiteIndexSearchResponse => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `searchOperationSitesForPicker()` instead.
   *
   * This method doesn't expect any request body.
   */
  searchOperationSitesForPicker$Response(params?: SearchOperationSitesForPicker$Params, context?: HttpContext): Observable<StrictHttpResponse<OperationSiteIndexSearchResponse>> {
    return searchOperationSitesForPicker(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `searchOperationSitesForPicker$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  searchOperationSitesForPicker(params?: SearchOperationSitesForPicker$Params, context?: HttpContext): Observable<OperationSiteIndexSearchResponse> {
    return this.searchOperationSitesForPicker$Response(params, context).pipe(
      map((r: StrictHttpResponse<OperationSiteIndexSearchResponse>): OperationSiteIndexSearchResponse => r.body)
    );
  }

  /** Path part for operation `operationSitesControllerGetEquipmentsForOperationSiteoperationSiteIdPagedOptionsRefinementOptions()` */
  static readonly OperationSitesControllerGetEquipmentsForOperationSiteoperationSiteIdPagedOptionsRefinementOptionsPath = '/operationsites/sites/{operationSiteId}/equipments';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getEquipmentsForOperationSite$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getEquipmentsForOperationSite$Plain$Response(params: GetEquipmentsForOperationSite$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<EquipmentIndexDtoSearchResponse>> {
    return getEquipmentsForOperationSite$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getEquipmentsForOperationSite$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getEquipmentsForOperationSite$Plain(params: GetEquipmentsForOperationSite$Plain$Params, context?: HttpContext): Observable<EquipmentIndexDtoSearchResponse> {
    return this.getEquipmentsForOperationSite$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<EquipmentIndexDtoSearchResponse>): EquipmentIndexDtoSearchResponse => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getEquipmentsForOperationSite()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getEquipmentsForOperationSite$Response(params: GetEquipmentsForOperationSite$Params, context?: HttpContext): Observable<StrictHttpResponse<EquipmentIndexDtoSearchResponse>> {
    return getEquipmentsForOperationSite(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getEquipmentsForOperationSite$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getEquipmentsForOperationSite(params: GetEquipmentsForOperationSite$Params, context?: HttpContext): Observable<EquipmentIndexDtoSearchResponse> {
    return this.getEquipmentsForOperationSite$Response(params, context).pipe(
      map((r: StrictHttpResponse<EquipmentIndexDtoSearchResponse>): EquipmentIndexDtoSearchResponse => r.body)
    );
  }

  /** Path part for operation `operationSitesControllerGetEquipmentsForFiltersrefinementOptions()` */
  static readonly OperationSitesControllerGetEquipmentsForFiltersrefinementOptionsPath = '/operationsites/sites/equipments';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getEquipmentsForFilters$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getEquipmentsForFilters$Plain$Response(params?: GetEquipmentsForFilters$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<EquipmentIndexDtoSearchResponse>> {
    return getEquipmentsForFilters$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getEquipmentsForFilters$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getEquipmentsForFilters$Plain(params?: GetEquipmentsForFilters$Plain$Params, context?: HttpContext): Observable<EquipmentIndexDtoSearchResponse> {
    return this.getEquipmentsForFilters$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<EquipmentIndexDtoSearchResponse>): EquipmentIndexDtoSearchResponse => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getEquipmentsForFilters()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getEquipmentsForFilters$Response(params?: GetEquipmentsForFilters$Params, context?: HttpContext): Observable<StrictHttpResponse<EquipmentIndexDtoSearchResponse>> {
    return getEquipmentsForFilters(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getEquipmentsForFilters$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getEquipmentsForFilters(params?: GetEquipmentsForFilters$Params, context?: HttpContext): Observable<EquipmentIndexDtoSearchResponse> {
    return this.getEquipmentsForFilters$Response(params, context).pipe(
      map((r: StrictHttpResponse<EquipmentIndexDtoSearchResponse>): EquipmentIndexDtoSearchResponse => r.body)
    );
  }

  /** Path part for operation `operationSitesControllerHasAnyOperationSites()` */
  static readonly OperationSitesControllerHasAnyOperationSitesPath = '/operationsites/sites/any';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `hasAnyOperationSites$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  hasAnyOperationSites$Plain$Response(params?: HasAnyOperationSites$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<boolean>> {
    return hasAnyOperationSites$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `hasAnyOperationSites$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  hasAnyOperationSites$Plain(params?: HasAnyOperationSites$Plain$Params, context?: HttpContext): Observable<boolean> {
    return this.hasAnyOperationSites$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `hasAnyOperationSites()` instead.
   *
   * This method doesn't expect any request body.
   */
  hasAnyOperationSites$Response(params?: HasAnyOperationSites$Params, context?: HttpContext): Observable<StrictHttpResponse<boolean>> {
    return hasAnyOperationSites(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `hasAnyOperationSites$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  hasAnyOperationSites(params?: HasAnyOperationSites$Params, context?: HttpContext): Observable<boolean> {
    return this.hasAnyOperationSites$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /** Path part for operation `operationSitesControllerGetTargetForPickeroperationSiteIdBusinessrelationId()` */
  static readonly OperationSitesControllerGetTargetForPickeroperationSiteIdBusinessrelationIdPath = '/operationsites/gettargets';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getTargetForPicker$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getTargetForPicker$Plain$Response(params?: GetTargetForPicker$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<OperationSitePickerTargetDto>> {
    return getTargetForPicker$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getTargetForPicker$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getTargetForPicker$Plain(params?: GetTargetForPicker$Plain$Params, context?: HttpContext): Observable<OperationSitePickerTargetDto> {
    return this.getTargetForPicker$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<OperationSitePickerTargetDto>): OperationSitePickerTargetDto => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getTargetForPicker()` instead.
   *
   * This method doesn't expect any request body.
   */
  getTargetForPicker$Response(params?: GetTargetForPicker$Params, context?: HttpContext): Observable<StrictHttpResponse<OperationSitePickerTargetDto>> {
    return getTargetForPicker(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getTargetForPicker$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getTargetForPicker(params?: GetTargetForPicker$Params, context?: HttpContext): Observable<OperationSitePickerTargetDto> {
    return this.getTargetForPicker$Response(params, context).pipe(
      map((r: StrictHttpResponse<OperationSitePickerTargetDto>): OperationSitePickerTargetDto => r.body)
    );
  }

}
