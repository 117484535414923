
<article class=" equipment-list-section" id="js-equipment-list" #equipmentListElement>
  <div class="machine-detail-header">
    <h2 class="my-header"><mibp-resource-string key="SearchProduct_Machines"></mibp-resource-string></h2>
  </div>

  <table class="my-table my-table--nowrap"  style="width: 100%;" *ngIf="!isLoading">
    <thead>
      <tr>
        <th> <mibp-resource-string key="MyFleet_Machine" titleAttr="true"></mibp-resource-string></th>
        <th class="cell_status"> <mibp-resource-string key="MyFleet_Status" titleAttr="true"></mibp-resource-string></th>
        <th class="cell_installationdate"> <mibp-resource-string key="MyFleet_InstallationDateShort" titleAttr="true"></mibp-resource-string></th>
        <th class="cell_actions"></th>
      </tr>
    </thead>
    <!-- Table design for laptop view-->
    <tbody >

        <tr *ngFor="let equipment of equipments; let i = index" data-aut="myfleet-machine-row" class="row_machine"  >

          <td class="cell_machine">
              <a data-aut="myfleet-machine-details-link" class="serialnumber" (click)="viewDetails(equipment.id)">{{equipment.name}}</a>
              <table class="properties">
                <tbody>
                  <tr *ngIf="equipment.alias">
                    <th><mibp-resource-string key="MyFleet_Alias"></mibp-resource-string>:</th>
                    <td>{{equipment.alias}}</td>
                  </tr>
                  <tr>
                    <th><mibp-resource-string key="MyFleet_Model"></mibp-resource-string>:</th>
                    <td>{{equipment.productModel}}</td>
                  </tr>
                  <tr>
                    <th><mibp-resource-string key="MyFleet_Customer"></mibp-resource-string>:</th>
                    <td>{{equipment.customerName}}</td>
                  </tr>
                  <tr *ngIf="!enableEquipmentScopingBasedOnCustomers">
                    <th><mibp-resource-string key="FleetMachine_DelSequence"></mibp-resource-string>:</th>
                    <td>{{equipment.deliverySequence}}
                      <mibp-interim-deliverysequence-message *ngIf="equipment.hasInterimDeliverySequence"></mibp-interim-deliverysequence-message>
                    </td>
                  </tr>
                  <tr *ngIf="enableEquipmentScopingBasedOnCustomers">
                    <th><mibp-resource-string key="Global_OperationSite"></mibp-resource-string>:</th>
                    <td>{{equipment.operationSite}}</td>
                  </tr>
                  <tr>
                    <th><mibp-resource-string key="FleetMachine_ModelGroup"></mibp-resource-string>:</th>
                    <td>{{equipment.productGroup}}</td>
                  </tr>
                  <tr>
                    <th><mibp-resource-string key="FleetMachine_ModelSubGroup"></mibp-resource-string>:</th>
                    <td>{{equipment.productSubGroup}}</td>
                  </tr>
                  <tr class="property__installationdate">
                    <th><mibp-resource-string key="MyFleet_InstallationDateShort"></mibp-resource-string>:</th>
                    <td><mibp-datetime [date]="equipment.installationDate"></mibp-datetime></td>
                  </tr>
                  <tr class="property__status">
                    <th><mibp-resource-string key="MyFleet_Status"></mibp-resource-string>:</th>
                    <td>
                      <mibp-equipment-status-desc [canEdit]="true" [status]="equipment.status"></mibp-equipment-status-desc>
                    </td>
                  </tr>
                </tbody>
              </table>

              <div class="property__actions">
                <button data-aut="myfleet-machine-details-button" class="my-button my-button--fill" (click)="viewDetails(equipment.id)">
                  <mibp-resource-string key="MyFleet_ViewDetails"></mibp-resource-string>
                </button>
              </div>
           </td>
          <td class="cell_status">
            <mibp-equipment-status-desc [canEdit]="true" [status]="equipment.status"></mibp-equipment-status-desc>
          </td>
          <td class="cell_installationdate"><mibp-datetime [date]="equipment.installationDate"></mibp-datetime></td>
          <td class="cell_actions">
            <button data-aut="myfleet-machine-details-button" class="my-button my-button--fill" (click)="viewDetails(equipment.id)">
              <mibp-resource-string key="MyFleet_ViewDetails"></mibp-resource-string>
            </button>
          </td>
        </tr>
    </tbody>
  </table>
  <div class="hide-print" style="text-align: center;" *ngIf="!isLoading && totalItems > defaultPageSize">
    <mibp-paginator (changePage)="onPageChange($event)" [totalItems]="totalItems" [pageSize]="take"
    [startIndex]="skip">
    </mibp-paginator>
</div>
</article>


