import { Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ButtonColors } from 'root/components/button/button.enum';
import { DropdownInput } from 'root/components/dropdown';
import { MachineUpgradesApiController, SupportCaseApiController } from 'root/mibp-openapi-gen/controllers';
import { CaseType, ContactUsSupportCaseRequest, EquipmentUpgradeOpportunity } from 'root/mibp-openapi-gen/models';
import { FormValidationService, FormattingService, LoaderService, LocalizationService } from 'root/services';
import { MibpSessionService } from 'root/services/mibp-session/mibp-session.service';
import { MibpEmailValidator } from 'root/validators/email.validator';
import { requireCheckboxesToBeCheckedValidator } from 'root/validators/requireCheckboxesToBeChecked';
import { Subscription, firstValueFrom } from 'rxjs';
import { MibpAllowedCharactersValidator } from 'root/validators/allowed-characters.validator';
import { Guid } from 'guid-typescript';



export interface ProductUpgradeRequestQuoteData{
  topic: string;
  equipmentId: number,
  upgradeName: string;
  productArea: string;
  shortDescription: string;
  longDescription: string;
}

@Component({
  selector: 'mibp-contact-us-request-quote-form',
  templateUrl: './contact-us-request-quote-form.component.html',
  styleUrls: ['./contact-us-request-quote-form.component.scss']
})
export class ContactUsRequestQuoteFormComponent implements OnInit, OnDestroy {
  protected contactUsRequestQuoteForm: UntypedFormGroup;
  buttonColors = ButtonColors;
  protected formSubmitted: boolean;
  protected isEmailSelected: boolean;
  protected isPhoneNumberSelected: boolean;
  private formValueChangeSub: Subscription;
  private description: string;

  @Input() showHeader = true;
  @Input() requestQuoteData : ProductUpgradeRequestQuoteData = null;
  @Output() successfullySubmitted = new EventEmitter<boolean>();

  private stopUsingResources: () => void;

  constructor(private fb: UntypedFormBuilder,
    private validation: FormValidationService,
    private element: ElementRef,
    private mibpSession: MibpSessionService,
    private formattingService: FormattingService,
    private loader: LoaderService,
    private machineUpgradeApi: MachineUpgradesApiController
  ){

  }
  ngOnDestroy(): void {
    if(this.stopUsingResources){
      this.stopUsingResources();
    }
    this.formValueChangeSub?.unsubscribe();
  }

  ngOnInit(): void {

    this.contactUsRequestQuoteForm = this.fb.group({
      topicName: [{value : this.requestQuoteData?.topic, disabled: true }, [Validators.required, MibpAllowedCharactersValidator()]],
      name: [{value : this.mibpSession.current.user.firstName + ' ' + this.mibpSession.current.user.lastName, disabled: false }, [Validators.required, MibpAllowedCharactersValidator()]],
      email: [{value : this.mibpSession.current.user.email, disabled: false }, MibpEmailValidator(this.formattingService)],
      phonenumber: [{value : this.mibpSession.current.user.phone, disabled: false }, MibpAllowedCharactersValidator()],
      contactPreferenceGroup: new UntypedFormGroup({
        byPhone: new UntypedFormControl(false),
        byEmail: new UntypedFormControl(false)
      }, { validators: requireCheckboxesToBeCheckedValidator(1)}),
        comments: [null, [MibpAllowedCharactersValidator()]]
    });

    this.initForm();


  }

  initForm(){
    this.formValueChangeSub = this.contactUsRequestQuoteForm.controls.contactPreferenceGroup.valueChanges.subscribe(() => {
      this.isEmailSelected = this.contactUsRequestQuoteForm.controls.contactPreferenceGroup.get('byEmail').value;
      this.isPhoneNumberSelected = this.contactUsRequestQuoteForm.controls.contactPreferenceGroup.get('byPhone').value;
      this.isEmailSelected ? this.addRequiredValidators('email') : this.removeRequiredValidators('email');
      this.isPhoneNumberSelected ? this.addRequiredValidators('phonenumber') : this.removeRequiredValidators('phonenumber');
    });

  }

  private addRequiredValidators(field: string){
    this.contactUsRequestQuoteForm.get(field).addValidators([Validators.required]);
    this.contactUsRequestQuoteForm.get(field).updateValueAndValidity();
  }

  private removeRequiredValidators(field: string){
    this.contactUsRequestQuoteForm.get(field).removeValidators(Validators.required);
    this.contactUsRequestQuoteForm.get(field).updateValueAndValidity();
  }

  async submit(){
    this.formSubmitted = true;
    if (this.contactUsRequestQuoteForm.invalid) {
      this.contactUsRequestQuoteForm.markAllAsTouched();
      this.validation.scrollToFirstError(this.contactUsRequestQuoteForm, this.element);
      return;
    }
    const opportunity = <EquipmentUpgradeOpportunity>{
      equipmentId: this.requestQuoteData.equipmentId,
      fullName: this.contactUsRequestQuoteForm.get('name').value,
      email: this.contactUsRequestQuoteForm.get('email').value,
      phone: this.contactUsRequestQuoteForm.get('phonenumber').value,
      prefferedEmailContact: this.contactUsRequestQuoteForm.controls.contactPreferenceGroup.get('byEmail').value,
      prefferedPhoneContact: this.contactUsRequestQuoteForm.controls.contactPreferenceGroup.get('byPhone').value,
      userComments: this.contactUsRequestQuoteForm.get('comments').value,
      upgradeName: this.requestQuoteData.upgradeName,
      productArea: this.requestQuoteData.productArea,
      shortDescription: this.requestQuoteData.shortDescription


    };

    try {
      this.loader.showFullScreenLoader();
      await firstValueFrom(this.machineUpgradeApi.createOpportunity({body: opportunity}));
      this.loader.hideFullScreenLoader();
      this.successfullySubmitted.emit();
    }
    catch(error){
      this.loader.hideFullScreenLoader();
      this.successfullySubmitted.emit(false);

    }
  }
}
