<div *ngIf="isHistoryPage">
  <mibp-page-load-error *ngIf="hasError"></mibp-page-load-error>
  <table *ngIf="!hasError" class="my-table my-table--nowrap">
    <thead>
      <tr>
        <th><mibp-resource-string key="Global_Date"></mibp-resource-string></th>
        <th><mibp-resource-string key="Global_User"></mibp-resource-string></th>
        <th><mibp-resource-string key="Global_Comment"></mibp-resource-string></th>
      </tr>
    </thead>
    <tbody *ngIf="changeTrackingList">
      <mibp-loader *ngIf="isLoading"></mibp-loader>
      <tr *ngFor="let change of changeTrackingList">
        <td><a (click)="navigateToDetailsPage(change.id)"><mibp-datetime [date]="change.createdDate" [format]="'datetime'" [disableTooltip]="true"></mibp-datetime></a></td>
        <td>{{ change.userName }}</td>
        <td class="comment" title="{{ change.comment }}">{{ change.comment }}</td>
      </tr>
    </tbody>
  </table>
</div>
<router-outlet *ngIf="!isHistoryPage"></router-outlet>
