import { cloneDeep } from 'lodash';

import { Component, OnInit, Input, OnDestroy, ViewChild, ElementRef, Output, EventEmitter, AfterViewInit } from '@angular/core';
import { skip, Subscription } from 'rxjs';
import { ButtonColors } from 'root/components/button/button.enum';

import {
  MibpGridDataSource,
  MibpGridSortOptions,
  MibpGridActionArgs,
  BeforeShowActionArgs,
  GridSnapshot,
  MibpGridFrontendFilter,
  MibpGridRefreshOptions,
  MibpGridFrontendColumnConfig,
  MibpGridFieldFormatOptions,
  MibpGridErrorInternal
} from './mibp-grid.types';
import { UserAction } from '../context-menu/context-menu.types';
import { PagingArgs } from './components/mibp-grid-paging/mibp-grid-paging.component';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { ContextMenuComponent } from '../context-menu';
import { MessageType } from '../system-message/system-message.enum';
import { MibpGridColumnResizeArgs } from './components/mibp-grid-resize-handle/mibp-grid-resize-handle.component';
import { MibpGridToolbarComponent } from './components/mibp-grid-toolbar/mibp-grid-toolbar.component';
import { MibpGridService } from './../mibp-grid/services/mibp-grid.service';

import { ButtonIcons } from '../button/button.enum';
import { NoticeType } from '../noticebar/noticebar.enum';
import { ArrayHelperService } from 'root/services/array-helper/array-helper.service';
import { LocalizationService } from 'root/services/localization/localization.service';
import { FormattingService } from 'root/services/formatting/formatting.service';
import { LogService, MibpLogger } from 'root/services/logservice';
import { CacheScope, ClientSideCacheService } from 'root/services/client-side-cache/client-side-cache.service';
import { UrlHelperService } from 'root/services/url-helper/url-helper.service';
import { ScrollToService } from 'root/services/scroll-to/scroll-to.service';
import { MibpHttpApi } from 'root/services/mibp-http-api/mibp-http-api.service';
import { NoticebarService } from 'root/services/noticebar-service/noticebar.service';
import { DropdownComponent, DropdownInput } from '../dropdown';
import { BroadcastService, FrontendContextService } from 'root/services';
import { MibpGridColumn, MibpGridColumnDataType, MibpGridColumnVisibility, MibpGridFilter, MibpGridOptions, MibpGridResponse } from 'root/mibp-openapi-gen/models';
@Component({
  selector: 'mibp-grid',
  templateUrl: './mibp-grid.component.html',
  styleUrls: ['./mibp-grid.component.scss']
})
export class MibpGridComponent implements OnInit, OnDestroy, AfterViewInit {

  buttonColors = ButtonColors;
  calculatedMaxColumnWidth = 0;
  currentGridResponse: MibpGridResponse;
  filterGridResponse?: MibpGridResponse;
  messageType = MessageType;
  sortByIndexes: number[] = [];
  sortByAsc: boolean[] = [];
  subscription: Subscription;
  visibleColumns: MyGridColumnFrontend[];
  currentIndex = 0;
  loading = false;
  pagerVisibleCount = 3;
  columnTypes = MibpGridColumnDataType;
  currentSearchQuery: string;
  filterList: MibpGridFrontendFilter[];
  searchPlaceholder: string;
  readonly messageTypes = MessageType;
  hasError = false;
  isColumnsEdited = false;
  private resolveFilterLoad: () => void;
  systemMessage: {
    titleResourceString: string;
    textResourceString?: string;
    messageType?: MessageType;
    isTextHtml?: boolean;
  };
  colVisibility = MibpGridColumnVisibility;
  editColumnsOpen = false;
  filterLoadStatus: 'not-loaded' | 'loaded' | 'loading' = 'not-loaded';
  deliverySequenceSubscription: Subscription;

  @ViewChild('mygrid') mygrid: ElementRef;
  @ViewChild(MibpGridToolbarComponent) gridToolbar: MibpGridToolbarComponent;
  @ViewChild('selectPageSize') selectPageSizeDropdownComponent: DropdownComponent;

  rows: MibpGridFieldFormatOptions[][];

  private dataSource: MibpGridDataSource;
  private currentOptions: MibpGridOptions;
  private currentTotalCount = 0;
  private currentSortOptions: MibpGridSortOptions[] = [];
  private defaultPageSize: number;
  private defaultSortBy: string[];
  private localeSubscription: Subscription;
  private log: MibpLogger;
  loadFiltersSubscription: Subscription;
  responsiveSub: Subscription;

  public get totalCount(): number {
    return this.currentTotalCount;
  }

  @Input() actions: UserAction[] = [];

  /***
   * Specify a unique grid id to enable caching of stuff
   */
  @Input() disabled = false;
  @Input() striped = true;
  @Input() filters = true;
  @Input() pageSize = 10;
  @Input() defaultActionName: string;
  @Input() search = true;
  @Input() pagingAbove = false;
  @Input() pagingBelow = true;
  @Input() hideTopBorder = false;
  @Input() columnSizes: { [columnName: string]: number | string };
  @Input() name: string;      // Give the grid a name to store options in querystring
  @Input() statusbar = true;
  @Input() beforeActionsFunction: (args: BeforeShowActionArgs) => void;
  @Input() isExportGridToExcelEnabled = false;
  @Input() refresOnDSchanged = false;
  @Input() showHelpIcon = false;
  @Input() searchPlaceHolderKey: string;
  //Custom function that is passed when we want to call a custom endpoint for exporting grid to excel
  @Input() exportGridToExcelCustomFunction: (currentOptions: MibpGridOptions) => void;
  @Input() showCustomFilters: boolean;
  @Output() action = new EventEmitter<MibpGridActionArgs>();
  @Output() showUserFlow = new EventEmitter();
  icons = {
    eye: ButtonIcons.Eye,
    eyeSlash: ButtonIcons.EyeSlash
  };
  pageSizes: DropdownInput[] = [
    {text: '10', value: '10'},
    {text: '50', value: '50'},
    {text: '100', value: '100'},
  ];
  constructor(
    private arrayHelper: ArrayHelperService,
    private formatting: FormattingService,
    private localizationService: LocalizationService,
    logger: LogService,
    private route: ActivatedRoute,
    private router: Router,
    private cache: ClientSideCacheService,
    private gridService: MibpGridService,
    private urlHelper: UrlHelperService,
    private scrollToService: ScrollToService,
    private httpApi: MibpHttpApi,
    private noticebarService: NoticebarService,
    private frontendContext: FrontendContextService,
    private broadcast: BroadcastService,
  ) {
    this.log = logger.withPrefix('mibp-grid');
  }

  ngAfterViewInit(): void {
    const take = this.route.snapshot.queryParams[this.name + '.take'];
    if (take && this.pageSize != take) {
      this.selectPageSizeDropdownComponent.selectItem({text: take ,value: take});
    }
  }

  /// Important!
  /// You must use this definition pattern or "this" will not be the component
  /// name = () => {}
  beforeShowRowActions(actions: UserAction[], rowIndex?: number): UserAction[] {

    if (this.beforeActionsFunction) {
      if (this.beforeActionsFunction instanceof Function) {
        const callbackArg = {
          actions: actions,
          grid: this.createGridSnapshot(rowIndex)
        } as BeforeShowActionArgs;

        this.beforeActionsFunction(callbackArg);

        return callbackArg.actions;
      } else {
        throw new Error("beforeActionsFunction is not a function");
      }
    }
    return actions;
  }

  isDisabled(): boolean {
    return this.disabled || this.loading;
  }

  /**
   * User has chosen an action. Emit the event
   */
  triggerRowActionEvent(action: UserAction, rowIndex: number): void {
    // A tiny timeout so the menu will close at once
    setTimeout(() => {
      this.action.emit({
        grid: this.createGridSnapshot(rowIndex),
        action: action
      } as MibpGridActionArgs);
    });
  }

  /**
   * Get Cache key - by page url and grid name
   */
  private getUserConfigKey() {
    if (this.name) {
      let url = this.router.routerState.snapshot.url.toString();
      url = url.replace(/^\/[a-z]{2}\//, '');
      url = url.split('#')[0].split('?')[0];
      return 'grid.' + url.replace(/\/+$/, '') + '.' + this.name;
    }
    return null;
  }

  private getColumnConfig(): MibpGridFrontendColumnConfig {
    const key = this.getUserConfigKey();
    if (key) {
      return this.cache.get(key);
    }
    return null;
  }

  private saveColumnConfig(config: MibpGridFrontendColumnConfig)  {
    const key = this.getUserConfigKey();
    if (key) {
      this.cache.add(key, config, null, CacheScope.GlobalStorage);
    }
  }

  onColumnResize(col: MibpGridColumn, args: MibpGridColumnResizeArgs): void {

    if (args.newWidth > 0) {
      // Set with of column
      const resizedColumn = this.visibleColumns.find(c => col.id === c.id);
      resizedColumn.width = args.newWidth;
      resizedColumn.maxWidth = args.newWidth;

      // Store resized columns in cache
      const sizedColumns = this.visibleColumns.filter(f => f.width);
      const gridWidth = (this.mygrid.nativeElement as HTMLDivElement).getBoundingClientRect().width;
      if (this.visibleColumns.filter(f => f.width)) {

        const sizes = {};

        sizedColumns.forEach(s => {
          sizes[s.id] = Math.floor((s.width / gridWidth) * 100) + '%';
        });

        this.saveColumnConfig({'sizes': sizes});
      }
    }
  }

  /**
   * Create a snapshot of grid data to be used
   * in events and callback functions
   */
  private createGridSnapshot(rowIndex?: number ): GridSnapshot {
    let rowData: { [key: string]: string} = null;
    if (!isNaN(rowIndex)) {
      if (rowIndex < this.rows.length) {
        rowData = {};
        this.currentGridResponse.columns.forEach((col, columnIndex) => {
          rowData[col.id] = this.rows[rowIndex][columnIndex].text;
        });
      }
    }

    return {
      options: Object.assign({}, this.currentOptions),
      name: this.name,
      row: rowData,
      rowIndex: rowIndex,
      reload: () => {
        this.triggerReload();
      }
    };
  }

  /**
   * Open the row Action Menu
   */
  openRowActionMenu(contextMenu: ContextMenuComponent, e: MouseEvent, rowIndex: number): void {
    if (!this.isDisabled()) {
      contextMenu.open(e, this, this.beforeShowRowActions, rowIndex);
    } else {
      e.preventDefault();
    }
  }

  /**
   * When the user clicks the link that triggers the default action
   */
  onRowDefaultAction(e: Event, rowIndex: number): void {
    e.preventDefault();
    if (this.isDisabled()) {
      return;
    }
    const action = this.actions.find(a => a.name === this.defaultActionName);
    if (action) {
      this.triggerRowActionEvent(action, rowIndex);
    } else {
      alert("Action with name '" + this.defaultActionName + "' does not exist");
    }
  }


  private updateFilterFacetCount(): void {
    this.filterGridResponse.columns
      .filter(col => this.filterGridResponse.checkboxFacets[col.id]) // Only check columns that have the checkbox filters
      .map(col => {
        this.filterGridResponse.checkboxFacets[col.id].forEach(facet => {
          this.gridToolbar.updateCheckboxFilterCount(col.id, facet.text, this.currentGridResponse.checkboxFacets[col.id]?.find(c => c.text === facet.text)?.value || 0);
        });
      });
  }

  private refreshFilterList(gridResponse: MibpGridResponse) {
    //Only filter on visible columns
    const filters = gridResponse.columns
      .filter(col => (col.visibility === MibpGridColumnVisibility.Visible || MibpGridColumnVisibility.FacetOnly) && col.visibility !== MibpGridColumnVisibility.AlwaysHidden)
      .map(col => {

        let currentValue = null;
        const currentFilterValue = this.currentOptions.filters.find(f => f.colId === col.id);
        if (currentFilterValue) {
          currentValue = currentFilterValue.value;
        }

        return {
          column: col,
          facets: gridResponse.facets && gridResponse.facets[col.id] ? gridResponse.facets[col.id]
            .map(o => {
              return {
                text: col.valueReourceStringPrefix ? this.localizationService.get(col.valueReourceStringPrefix + o.text + (col.valueResourceStringSuffix ? col.valueResourceStringSuffix : '')) : o.text,
                value: o.value
              };
            })  : [],
          checkboxFacets : gridResponse.checkboxFacets ? (gridResponse.checkboxFacets[col.id] ? gridResponse.checkboxFacets[col.id] : []) : [],
          value: currentValue
        } as MibpGridFrontendFilter;
      });
    this.filterList = filters;

  }

  updateToolbarSearchPlaceholderText(): void {
    const searchableColumnNames = this.currentGridResponse.columns.filter(x => x.searchable).map(f => this.localizationService.get(f.resourceStringKey));
    const searchinKey = this.localizationService.get('Global_Search_Grid');
    const searchPlaceHolderKeys = this.localizationService.get(this.searchPlaceHolderKey);
    this.searchPlaceholder = (this.searchPlaceHolderKey)? (searchinKey + ' ' + searchPlaceHolderKeys) : (searchinKey + ' ' + searchableColumnNames.map(s => s.trim()).join(', '));

  }



  async loadFilterDataSeparatly(): Promise<void> {
    this.filterLoadStatus = 'loading';
    return new Promise(resolve => {

      // The filterLoadStatus is set to loading.
      // Trigger a second refresh to load the filters
      this.resolveFilterLoad = resolve;
      this.dataSource.refresh({
        query: '',
        skip: 0,
        take: 0,
        filters: [],
        orderBy: [],
        selectedColumns: [],
        typeName: this.currentOptions?.typeName
      });
    });
  }


  // This should be cleaned up and split into normal sized pieces...
  @Input()
  set data(val: MibpGridDataSource) {
    let triggerRefresh = false;
    if (this.subscription) {
      this.hasError = false;
      this.systemMessage = null;
      this.currentGridResponse = null;
      this.currentTotalCount = 0;
      this.currentIndex = 0;
      if (this.gridToolbar) {
        this.gridToolbar.resetFilters();
      }
      this.preserveGridState({
        take: this.pageSize,
        skip: 0,
        filters: undefined,
        orderBy: undefined,
        query: null,
        typeName: null,
      });
      this.subscription.unsubscribe();

      triggerRefresh = true;
    }
    if (val && val.data$ && val.refresh) {
      this.dataSource = val;
      this.subscription = val.data$.subscribe(async upd => {
        const gridResponse = Object.assign({}, upd) as MibpGridResponse;
        const gridErrorResponse = upd as MibpGridErrorInternal;

        let populateGridFilters = false;
        if (!gridErrorResponse.isGridError){
          if (this.filterLoadStatus === 'loading') {
            this.filterLoadStatus = 'loaded';
            this.filterGridResponse = Object.assign({}, gridResponse);
            this.refreshFilterList(this.filterGridResponse);
            this.resolveFilterLoad();
            this.resolveFilterLoad = null;
            return;
          }


          if (this.filterLoadStatus === 'not-loaded') {
            if (this.currentOptions?.filters?.length > 0 || this.currentOptions?.query) {
              this.filterLoadStatus = 'loading';
              await this.loadFilterDataSeparatly();
            } else {
              populateGridFilters = true;
              this.filterGridResponse = Object.assign({}, gridResponse);
            }
          }
        }

        this.loading = false;
        if (!gridResponse || (gridResponse && !gridResponse.columns)) {
          this.hasError = true;
          if ( gridErrorResponse.isGridError ) {

            let messageType: MessageType = MessageType.Error;
            if (gridErrorResponse.type === 'warning') {
              messageType = MessageType.Warning;
            } else if (gridErrorResponse.type === 'information') {
              messageType = MessageType.Neutral;
            }

            this.systemMessage = {
              textResourceString: gridErrorResponse.messageResourceString,
              titleResourceString: gridErrorResponse.titleResourceStringKey,
              isTextHtml: gridErrorResponse.messageIsHtml,
              messageType: messageType
            };
          }
          return;
        }

        if (!gridResponse.facets) { gridResponse.facets = {}; }
        if (!gridResponse.checkboxFacets) { gridResponse.checkboxFacets = {}; }
        this.hasError = false;
        this.systemMessage = null;

        // SO! We will only update columns the first time.
        if (this.currentGridResponse && this.currentGridResponse.columns && !this.isColumnsEdited) {

          this.currentGridResponse.data.rows = gridResponse.data.rows;
          this.currentGridResponse.sortBy = gridResponse.sortBy ? gridResponse.sortBy.slice(0) : [];
          this.currentGridResponse.facets = gridResponse.facets;
          this.currentGridResponse.checkboxFacets = gridResponse.checkboxFacets;


          this.updateFilterFacetCount();

          // this.defaultSortBy = gridResponse.sortBy ? gridResponse.sortBy.slice(0) : [];

          this.currentSortOptions = this.updateSortByFromGridResponse(this.currentGridResponse.sortBy);
          this.updateVisibleColumns();
          // this.refreshFilterList();


        } else {
          const gridWidth = (this.mygrid.nativeElement as HTMLDivElement).getBoundingClientRect().width;
          this.currentGridResponse = JSON.parse(JSON.stringify(gridResponse));

          // We store these values so we can detect if they have changes for the querystring parameters
          this.defaultSortBy = gridResponse.sortBy ? gridResponse.sortBy.slice(0) : [];

          this.currentSortOptions = this.updateSortByFromGridResponse(this.currentGridResponse.sortBy);
          this.currentOptions.typeName = this.currentGridResponse.typeName;
          this.setupColumns();
          if (populateGridFilters) {
            this.refreshFilterList(this.currentGridResponse);
          }
          this.updateFilterFacetCount();

          this.updateToolbarSearchPlaceholderText();

          let preferredSizes: { [key: string]: number | string } = this.columnSizes ? Object.assign({}, this.columnSizes) : {};

          const userConfig = this.getColumnConfig();
          if (userConfig && userConfig.sizes) {
            preferredSizes = Object.assign({}, preferredSizes, userConfig.sizes);
          }

          let usedWidth = (this.actions && this.actions.length > 0 ? 40 : 0);
          if (Object.keys(preferredSizes).length > 0) {
            // Calculate preferred sizes of the columns, if specified
            Object.keys(preferredSizes).forEach(colName => {

              if (this.visibleColumns.find(vc => vc.id === colName)) {

                let size: number;

                if (typeof preferredSizes[colName] === 'string') {
                  if (preferredSizes[colName].toString().match(/^\d+%$/)) {
                    size = parseInt(preferredSizes[colName].toString().substr(0, preferredSizes[colName].toString().length - 1), 10);
                    size = (size / 100) * gridWidth;
                    preferredSizes[colName] = size;
                  }
                } else if (typeof preferredSizes[colName] === 'number') {
                  size = preferredSizes[colName] as number;
                }
                usedWidth += size;
              } else {
                this.log.warn(`columnSizes: Column '${colName}' does not exist`);
              }
            });
          }

          // Calculate a base max column with based on column count and grid width
          const visibleColumnCount = this.visibleColumns.length;
          const remainingColumnCount = visibleColumnCount - this.visibleColumns.filter(vc => preferredSizes[vc.id]).length;

          this.visibleColumns.forEach(col => {
            if (preferredSizes[col.id]) {
              col.width = preferredSizes[col.id] as number;
              col.maxWidth = preferredSizes[col.id] as number;
            } else {
              col.maxWidth = Math.floor((gridWidth - usedWidth) / remainingColumnCount);
            }
          });
        }

        this.refreshRowsFromGridResponse();
        this.currentTotalCount = gridResponse.data.totalCount;
        this.isColumnsEdited = false;
      });

      if (triggerRefresh) {
        this.invokeFirstRefresh();
      }
    }
  }
  /**
   * Will update the currentSortOptions based on currentGridResponse
   */
  private updateSortByFromGridResponse(sortBy: string[]) {
    if (!sortBy) {
      return [];
    }
    const result: MibpGridSortOptions[] = [];
    sortBy.forEach(sortby => {
      const [name, order] = sortby.split(' ');
      result.push({columnName: name, isAscending: order === 'asc'});
    });
    return result;

  }

  private setupColumns() {
    this.visibleColumns = this.currentGridResponse.columns
      .map((col, ix) => {
        if (col.visibility !== MibpGridColumnVisibility.Visible) {
          return null;
        }

        return  <MyGridColumnFrontend>Object.assign({}, col, {
          text: this.localizationService.get(col.resourceStringKey),
          colIndex: ix,
          isSortingBy: this.getCurrentSortOrderForColumn(col.id)
        });

      })
      .filter(f => f);
  }

  get pageLastIndex(): number {
    if (this.currentIndex + this.pageSize > this.totalCount || this.pageSize === 0) {
      return this.totalCount;
    }
    return this.currentIndex + this.pageSize;
  }

  get firstIndexOnPage(): number {
    return this.currentIndex + 1;
  }

  get lastIndexOnPage(): number {
    return this.currentIndex + (this.currentGridResponse?.data.rows?.length || 0);
  }

  private getQuerystringPrefix() {
    if (this.name) {
      return `${this.name}.`;
    }
    return null;
  }

  private updateVisibleColumns() {
    if (this.visibleColumns) {
      this.visibleColumns.forEach(col => {
        col.isSortingBy = this.getCurrentSortOrderForColumn(col.id);
      });
    }
  }

  private getCurrentSortOrderForColumn(name: string): 'asc' | 'desc' {
    const s = this.currentSortOptions.find(f => f.columnName === name);
    if (s) {
      return s.isAscending ? 'asc' : 'desc';
    }
    return null;
  }

  private mapRowToKeyValue(rowValues: string[]): { [key: string]: string } {
    const rowData = {};
    this.currentGridResponse.columns.forEach((col, columnIndex) => {
      rowData[col.id] = rowValues[columnIndex];
    });
    return rowData;
  }

  // TODO: Give the user of the grid the ability to format the values
  refreshRowsFromGridResponse(): void {
    this.rows = [];
    if (this.currentGridResponse.data.rows) {
      this.currentGridResponse.data.rows.forEach((row, rowIndex) => {
        const newRow: MibpGridFieldFormatOptions[] = [];

        this.currentGridResponse.columns.forEach((col, ix) => {
          if (this.dataSource._cfg?.customFormatters[col.id]) {
            if (row[ix]) {
              const rowSnapshot = this.mapRowToKeyValue(row);
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              const customFormat: any = this.dataSource._cfg.customFormatters[col.id](row[ix], {row: rowSnapshot, rowIndex: rowIndex});
              if (typeof customFormat === 'string') {
                newRow.push( {text: customFormat, isVisible : col.toggleVisibility ? false : true });
              } else {
                newRow.push(customFormat);
              }
              return;
            }
          } else if (col.type === MibpGridColumnDataType.DateTime) {
            if (row[ix]) {
              newRow.push({text: this.formatting.formatDateTime(row[ix]), tooltipText: this.formatting.getTooltipString(row[ix]), isVisible : col.toggleVisibility ? false : true});
              return;
            }
          }
          newRow.push( {text: row[ix], isVisible : col.toggleVisibility ? false : true} );
        });

        this.rows.push( newRow );
      });
    }
  }

  sortBy(column: MyGridColumnFrontend): void {
    if (this.isDisabled() || !column.sortable) {
      return;
    }

    let sortOrder = 'asc';
    if (column.isSortingBy === 'asc') {
      sortOrder = 'desc';
    }

    this.triggerDataSourceUpdate(Object.assign({}, this.currentOptions, {
      orderBy: [`${column.id} ${sortOrder}`],
      skip: 0
    }), true);

  }

  pageGoto(args: PagingArgs): void {
    if (this.isDisabled()) {
      return;
    }
    this.triggerDataSourceUpdate({ skip: args.skip }, true);
  }

  ngOnInit(): void {

    this.defaultPageSize = this.pageSize;
    if (this.dataSource) {
      this.invokeFirstRefresh();
    }

    this.responsiveSub = this.broadcast.responsiveBreakpoint.subscribe(br => {
      if(br.lteq('xxs')){
        this.pagerVisibleCount = 3;
      }
      else{
        this.pagerVisibleCount = 10;
      }
    });

    this.localeSubscription = this.localizationService.Locale$.subscribe(() => {
      if (this.dataSource && this.currentGridResponse) {
        this.updateToolbarSearchPlaceholderText();
      }
    });
    if(this.refresOnDSchanged===true){
      this.deliverySequenceSubscription = this.broadcast.deliverySequence.pipe(skip(1)).subscribe(async () => {
        await this.loadFilterDataSeparatly();
        this.triggerReload();
      });
    }

    this.loadFiltersSubscription = this.gridService.loadFilters.subscribe(async x=>{
      if(x===true){
        await this.loadFilterDataSeparatly();
      }
    });
  }

  private invokeFirstRefresh(): void {

    // Default options
    this.currentOptions = {
      query: null,
      skip: 0,
      take: this.pageSize,
      typeName: null
    };

    // If persistant we'll need to keep an eye out for changes in the query params as well
    if (this.name) {
      this.route.queryParams.subscribe(params => {
        const updatedOptions = this.getOptionsFromQueryParameters(params);
        if (updatedOptions) {
          this.triggerDataSourceUpdate(updatedOptions, false);
        }
      });
    } else {
      this.triggerDataSourceUpdate({
        query: null,
        skip: 0,
        take: this.pageSize,
        filters: [],
        orderBy: undefined
      }, false);
    }

  }

  triggerSearch(query: string): void {
    if (this.isDisabled()) {
      return;
    }
    this.triggerDataSourceUpdate( Object.assign({}, this.currentOptions, {
      query: query,
      skip: 0
    }), true);
  }

  triggerFilter(filter: MibpGridFrontendFilter): void {
    // console.warn("triggerFilter", filter);
    this.setFilter(filter.column.id, filter.value);
  }


  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types, @typescript-eslint/no-explicit-any
  setFilter(colName: string, newValue: any): void {
    const currentFilters = this.currentOptions.filters ? cloneDeep(this.currentOptions.filters) : [];
    const existing = currentFilters.find(f => f.colId === colName);
    if (existing) {
      existing.value = newValue;
    } else {
      currentFilters.push({colId: colName, value: newValue});
    }


    this.triggerDataSourceUpdate({
      filters: currentFilters,
      skip: 0
    }, true);

  }

  /**
   * This function will parse the current querystring parametrs and compare
   * with the current options and return a new MibpGridOptions object
   * reflecting the new exciting reality
   */
  private getOptionsFromQueryParameters(params: Params) {

    // Take the current options and apply any changes
    const theOptions: MibpGridOptions = {
      query: this.currentOptions.query,
      skip: this.currentOptions.skip,
      take: this.currentOptions.take,
      filters: this.currentOptions.filters ? this.currentOptions.filters.map(f => Object.assign({}, f)) : [],
      orderBy: this.currentOptions.orderBy ? this.currentOptions.orderBy.slice(0) : [],
      selectedColumns: this.currentOptions.selectedColumns ? this.currentOptions.selectedColumns : [],
      typeName: this.currentOptions.typeName
    };

    if (this.currentOptions.filters) {
      theOptions.filters = this.currentOptions.filters.slice(0);
    }

    let requiresUpdate = false;

    const query = this.getQueryParam(params, 'q');
    if (query !== theOptions.query) {
      theOptions.query = query;
      requiresUpdate = true;
    }

    let skip = parseInt(this.getQueryParam(params, 'skip'), 10);
    skip = isNaN(skip) ? 0 : skip;
    if (skip !== theOptions.skip) {
      theOptions.skip = skip;
      requiresUpdate = true;
    }

    let take = parseInt(this.getQueryParam(params, 'take'), this.pageSize);
    take = isNaN(take) ? this.pageSize : take;
    if (take !== theOptions.take) {
      theOptions.take = take;
      requiresUpdate = true;
    }

    const sortBy = this.getQueryParam(params, 'sortBy');
    const sortByArray = sortBy ? sortBy.toString().split(',').filter(f => f).map(f => f.toString().trim()) : this.defaultSortBy;

    if (!this.arrayHelper.areEqual(sortByArray, theOptions.orderBy)) {
      theOptions.orderBy = sortByArray;
      requiresUpdate = true;
    }

    const selectedColumns = this.getQueryParam(params, 'selectedColumns');
    const selectedColumnsArray = selectedColumns ? selectedColumns.toString().split(',').filter(f => f).map(f => f.toString().trim()) : [];

    if (!this.arrayHelper.areEqual(selectedColumnsArray, theOptions.selectedColumns)) {
      theOptions.selectedColumns = selectedColumnsArray;
      requiresUpdate = true;
    }

    const filterParameters = Object.keys(params).filter(name => name.indexOf(`${this.getQuerystringPrefix()}$`) === 0)
      .map(parameterName => {
        const colName = parameterName.substring(this.getQuerystringPrefix().length + 1);
        return {
          colId: colName,
          value: this.getQueryParam(params, `$${colName}`)
        } as MibpGridFilter;
      });

    if (filterParameters.length > 0) {
      if (!this.areFiltersEqual(filterParameters, this.currentOptions.filters)) {
        theOptions.filters =  filterParameters.map(filter => {
          try {
            filter.value = JSON.parse(filter.value);
            return filter;
          } catch (e) {
            return null;
          }
        }).filter(notFalsy => notFalsy);
        requiresUpdate = true;
      }
    }

    (this.currentOptions.filters ? this.currentOptions.filters.slice(0) : []).forEach((f) => {
      if (!filterParameters.find(fn => fn.colId === f.colId)) {
        const eix = theOptions.filters.findIndex(e => e.colId === f.colId);
        if (eix !== -1) {
          theOptions.filters.splice(eix, 1);
        }
        requiresUpdate = true;
      }
    });

    if (requiresUpdate) {
      return theOptions;
    }

    return null;
  }

  public GetCurrentOptions(): MibpGridOptions {
    return this.currentOptions;
  }

  private getQueryParam(params: Params, name: string): string {
    const key = `${this.getQuerystringPrefix()}${name}`;
    if (params[key]) {
      return params[key];
    }
    return null;
  }

  updateSelectedColumns(selectedColumns: string[], orderBy: string){
    this.isColumnsEdited = true;
    this.currentOptions.selectedColumns = selectedColumns;
    this.triggerReload();

  }

  triggerReload(): void {
    this.loading = true;
    const options = this.prepareFiltersForRequest();
    this.dataSource.refresh(options);
    this.updateVisibleColumns();
  }

  private areFiltersEqual(a: MibpGridFilter[], b: MibpGridFilter[] ) {

    if (!a && !b) {
      return true;
    }

    if ((!a && b) || (!b && a)) {
      return false;
    }

    if (a.length !== b.length) {
      return false;
    }

    for (let i = 0, l = a.length; i < l; i++) {
      const aItem = a[i];
      const bItem = b.find(x => x.colId === aItem.colId);
      if (!bItem) {
        return false;
      }
      if (Array.isArray(aItem.value) && !Array.isArray(bItem.value) ) {
        return false;
      } else if ( !Array.isArray(aItem.value) && Array.isArray(bItem.value) ) {
        return false;
      } else if ( Array.isArray(aItem.value) && Array.isArray(bItem.value) ) {
        if (!this.arrayHelper.areEqual(aItem.value, bItem.value)) {
          return false;
        }
      } else if (aItem.value !== bItem.value) {
        return false;
      }
    }

    return true;

  }




  /**
   * When any options have change, this method must be invoked
   * it will handle and store the changes and trigger a data refresh
   */
  private triggerDataSourceUpdate(newOptions: MibpGridRefreshOptions, saveState: boolean) {

    // detect what has changed
    const skipHasChanged = newOptions.skip !== undefined && newOptions.skip !== this.currentOptions.skip;
    const takeHasChanged = newOptions.take !== undefined && newOptions.take !== this.currentOptions.take;
    const orderByHasChanged = newOptions.orderBy !== undefined && !this.arrayHelper.areEqual(newOptions.orderBy, this.currentOptions.orderBy);
    const queryHasChanged = newOptions.query !== undefined && newOptions.query !== this.currentOptions.query;
    const selectedColumnsHasChanged = newOptions.selectedColumns !== undefined && !this.arrayHelper.areEqual(newOptions.selectedColumns, this.currentOptions.selectedColumns);
    // TODO: Filters
    const hasFiltersChanged =  newOptions.filters !== undefined && !this.areFiltersEqual(this.currentOptions.filters, newOptions.filters);

    const anyChanges = skipHasChanged || takeHasChanged || orderByHasChanged || queryHasChanged || hasFiltersChanged || selectedColumnsHasChanged;


    if (skipHasChanged) { this.log.debug('triggerUpdate skipHasChanged'); }
    if (takeHasChanged) { this.log.debug('triggerUpdate takeHasChanged'); }
    if (orderByHasChanged) { this.log.debug('triggerUpdate orderByHasChanged'); }
    if (queryHasChanged) { this.log.debug('triggerUpdate queryHasChanged'); }
    if (hasFiltersChanged) { this.log.debug('triggerUpdate hasFiltersChanged'); }
    if (selectedColumnsHasChanged) { this.log.debug('triggerUpdate selectedColumnsHasChanged'); }

    if (anyChanges) {
      this.loading = true;
      if (saveState && this.name) {
        // We want to trigger navigation with the updated parameters
        // and handle the changes after the new route is loaded
        const updatedOptions: MibpGridOptions = {
          query: queryHasChanged ? newOptions.query : this.currentOptions.query,
          skip: skipHasChanged ? newOptions.skip : this.currentOptions.skip,
          take: takeHasChanged ? newOptions.take : this.currentOptions.take,
          orderBy: orderByHasChanged ? newOptions.orderBy : this.currentOptions.orderBy,
          filters: hasFiltersChanged ? newOptions.filters : this.currentOptions.filters,
          selectedColumns: selectedColumnsHasChanged ? newOptions.selectedColumns : this.currentOptions.selectedColumns,
          typeName: this.currentOptions.typeName
        };

        this.preserveGridState(updatedOptions);
      } else {
        // No persistance. Update the variables at once
        if (skipHasChanged) {
          this.currentOptions.skip = newOptions.skip;
          this.currentIndex = newOptions.skip;
        }
        if (takeHasChanged) {
          this.currentOptions.take = newOptions.take;
        }
        if (orderByHasChanged) {
          this.currentOptions.orderBy = newOptions.orderBy;
        }
        if (queryHasChanged) {
          this.currentOptions.query = newOptions.query;
          this.currentSearchQuery = newOptions.query;
        }
        if (hasFiltersChanged) {
          this.currentOptions.filters = newOptions.filters;
        }
        if (selectedColumnsHasChanged) {
          this.currentOptions.selectedColumns = newOptions.selectedColumns;
        }

        this.triggerReload();
      }
    } else if (!saveState) {
      this.triggerReload();
    }

  }
  triggerUserFlow(): void {

    this.showUserFlow.emit();

  }
  /**
   * Will detect any non-default settings as querystring parameters
   * and navigate to the new url
   */
  private preserveGridState(options: MibpGridOptions): void {
    const prefix = this.getQuerystringPrefix();
    const params = {};

    // Make sure to set values to null if needed - or they will not be removed from querystring
    params[`${prefix}skip`] = options.skip !== 0 ? options.skip : null;
    // params[`${prefix}take`] = options.take !== this.defaultPageSize ? options.take : null;
    params[`${prefix}take`] = options.take !== this.defaultPageSize ? options.take : this.defaultPageSize;
    params[`${prefix}q`] = options.query ? options.query : null;

    params[`${prefix}sortBy`] = options.orderBy ? options.orderBy.join(', ') : null;
    params[`${prefix}selectedColumns`] = options.selectedColumns?.length > 0 ? options.selectedColumns.join(', ') : null;
    if (!params[`${prefix}sortBy`] && !this.defaultSortBy) {
      params[`${prefix}sortBy`] = null;
    } else if (this.defaultSortBy && options && options.orderBy) {
      if (this.arrayHelper.areEqual(this.defaultSortBy, options.orderBy)) {
        params[`${prefix}sortBy`] = null;
      }
    }

    if (options.filters) {
      options.filters.forEach(filter => {
        let isEmpty = false;

        if (typeof filter.value === 'string') {
          isEmpty = !filter.value;
        } else if (Array.isArray(filter.value)) {
          isEmpty = filter.value.length === 0;
        }
        if (!isEmpty) {
          params[`${prefix}$${filter.colId}`] = JSON.stringify(filter.value);
        }
      });
    }


    // Clear current filters if they are not in the new one
    if (this.route.snapshot.queryParams) {
      const filterPrefix = `${this.getQuerystringPrefix()}$`;
      Object.keys(this.route.snapshot.queryParams)
        .filter(f => f.indexOf(filterPrefix) === 0)
        .filter(f => !params[f])
        .forEach(existingParam => {
          const colId = existingParam.substring(filterPrefix.length);
          params[`${prefix}$${colId}`] = null;
        });
    }

    const queryParams = this.urlHelper.mergeQuerystringParameters(this.router.url, params);
    this.scrollToService.stopNextScrollToTop();


    const queryParametersWillChange = !this.urlHelper.areQuerystringsEqual(this.urlHelper.parseUrl(window.location.href), this.urlHelper.create("http://dummy",  queryParams));

    if (queryParametersWillChange) {
      this.router.navigate([], {queryParams: queryParams });
    } else {
      this.loading = false;
    }
  }

  calcMinHeight(): string {
    return 'auto';
    // TODO: Refine this when filters and stuff is in place
    // if (this.pageSize) {
    //   let h = 0; // header
    //   h += 44;
    //   h += this.filters ? 44 : 0;
    //   h += this.pageSize * 60;
    //   return h + 'px';
    // }
    // return null;
  }

  ngOnDestroy(): void {
    if (this.dataSource) {
      this.dataSource.destroy();
    }
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
    if (this.localeSubscription) {
      this.localeSubscription.unsubscribe();
    }
    if(this.responsiveSub){
      this.responsiveSub.unsubscribe();
    }
  }

  trackColumn(index: number): number {
    return index;
  }

  pageSizeChanged (event: DropdownInput) {

    this.log.info('event', event);

    this.pageSize = parseInt(event.value, 10);
    this.triggerDataSourceUpdate({ skip: 0, take: this.pageSize }, true);

    // this.currentOptions.skip = 0;
    // this.currentIndex = 0;

    // this.currentOptions.take = this.pageSize;
    // this.triggerReload();
    return;

    if (this.gridToolbar) {
      //this.gridToolbar.resetFilters();
    }


    this.log.info('currentOptions', this.currentOptions);
    this.triggerReload();
    const newOptions: MibpGridOptions = {
      take: this.pageSize,
      skip: 0,
      // orderBy: this.currentOptions.orderBy,
      query: null,
      filters: [],
      typeName: null
    };


    this.triggerDataSourceUpdate(newOptions, true);
  }
  toggleVisibilityofField(colIndex:number,rowIndex:number){
    const diff = this.currentGridResponse.columns.length - this.visibleColumns.length;
    this.rows[rowIndex][colIndex+diff].isVisible = (!this.rows[rowIndex][colIndex+diff].isVisible);
  }
  onToggleEditColumns(isOpen: boolean) {
    this.editColumnsOpen = isOpen;
  }

  private prepareFiltersForRequest(): MibpGridOptions {
    const filters: MibpGridFilter[] = [];

    this.currentOptions.filters.forEach((filter) => {
      const newFilter = Object.assign({}, filter);
      if (Array.isArray(newFilter.value)) {
        const newValues = newFilter.value.map(val => {
          if (typeof val === 'string') {
            const parsedFacet = this.gridService.mapStringToFacet(val);
            if (parsedFacet && parsedFacet.value !== val) {
              return parsedFacet.value;
            }
          }
          return val;
        });
        newFilter.value = newValues;
      }
      filters.push(newFilter);
    });

    return Object.assign({}, this.currentOptions, {
      filters: filters
    });
  }

  onDownloadExcel(): void {
    const options = this.prepareFiltersForRequest();
    if (this.exportGridToExcelCustomFunction) {
      this.exportGridToExcelCustomFunction(options);

    } else {
      const url = this.httpApi.resolveUriWithJwtToken(`download/excel-grid`);
      this.onDownloadExcelCore(url);
    }
  }

  private onDownloadExcelCore(url: string): void {
    this.httpApi.Download.GetExcelBasedOnGridWithOptions(this.currentOptions, {responseType: 'blob'})
      .then((data: BlobPart) => {
        const blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        const a: HTMLAnchorElement = document.createElement("a");
        a.style.display = "none";
        document.body.appendChild(a);
        const url = window.URL.createObjectURL(blob);
        a.href = url;
        a.download = `${this.name}.xlsx`;
        a.click();
        window.URL.revokeObjectURL(url);
      })
      .catch(error => {
        this.noticebarService.showText("Excel file could not be created.", NoticeType.Error, false);
      });
  }
}

// eslint-disable-next-line @typescript-eslint/naming-convention
export interface MyGridColumnFrontend extends MibpGridColumn {
  colIndex: number;
  text: string;
  isSortingBy?: 'asc' | 'desc';
  width?: number;
  maxWidth?: number;
}
