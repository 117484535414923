/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { actAsAll } from '../fn/session/act-as-all';
import { ActAsAll$Params } from '../fn/session/act-as-all';
import { actAsAll$Plain } from '../fn/session/act-as-all-plain';
import { ActAsAll$Plain$Params } from '../fn/session/act-as-all-plain';
import { actAsDeliverySequence } from '../fn/session/act-as-delivery-sequence';
import { ActAsDeliverySequence$Params } from '../fn/session/act-as-delivery-sequence';
import { actAsDeliverySequence$Plain } from '../fn/session/act-as-delivery-sequence-plain';
import { ActAsDeliverySequence$Plain$Params } from '../fn/session/act-as-delivery-sequence-plain';
import { actAsEncodedDeliverySequence } from '../fn/session/act-as-encoded-delivery-sequence';
import { ActAsEncodedDeliverySequence$Params } from '../fn/session/act-as-encoded-delivery-sequence';
import { actAsEncodedDeliverySequence$Plain } from '../fn/session/act-as-encoded-delivery-sequence-plain';
import { ActAsEncodedDeliverySequence$Plain$Params } from '../fn/session/act-as-encoded-delivery-sequence-plain';
import { ChangeAccountBusinessRelationVmPagedResponse } from '../models/change-account-business-relation-vm-paged-response';
import { ChangeAccountCompanyVmPagedResponse } from '../models/change-account-company-vm-paged-response';
import { changeConnectionAndUserLanguage } from '../fn/session/change-connection-and-user-language';
import { ChangeConnectionAndUserLanguage$Params } from '../fn/session/change-connection-and-user-language';
import { getAvailableAccountInformation } from '../fn/session/get-available-account-information';
import { GetAvailableAccountInformation$Params } from '../fn/session/get-available-account-information';
import { getAvailableAccountInformation$Plain } from '../fn/session/get-available-account-information-plain';
import { GetAvailableAccountInformation$Plain$Params } from '../fn/session/get-available-account-information-plain';
import { getCurrentSession } from '../fn/session/get-current-session';
import { GetCurrentSession$Params } from '../fn/session/get-current-session';
import { getCurrentSession$Plain } from '../fn/session/get-current-session-plain';
import { GetCurrentSession$Plain$Params } from '../fn/session/get-current-session-plain';
import { getPunchoutProfiles } from '../fn/session/get-punchout-profiles';
import { GetPunchoutProfiles$Params } from '../fn/session/get-punchout-profiles';
import { getPunchoutProfiles$Plain } from '../fn/session/get-punchout-profiles-plain';
import { GetPunchoutProfiles$Plain$Params } from '../fn/session/get-punchout-profiles-plain';
import { listAvailableBusinessRelations } from '../fn/session/list-available-business-relations';
import { ListAvailableBusinessRelations$Params } from '../fn/session/list-available-business-relations';
import { listAvailableBusinessRelations$Plain } from '../fn/session/list-available-business-relations-plain';
import { ListAvailableBusinessRelations$Plain$Params } from '../fn/session/list-available-business-relations-plain';
import { listAvailableCompanies } from '../fn/session/list-available-companies';
import { ListAvailableCompanies$Params } from '../fn/session/list-available-companies';
import { listAvailableCompanies$Plain } from '../fn/session/list-available-companies-plain';
import { ListAvailableCompanies$Plain$Params } from '../fn/session/list-available-companies-plain';
import { listAvailableDeliverySequences } from '../fn/session/list-available-delivery-sequences';
import { ListAvailableDeliverySequences$Params } from '../fn/session/list-available-delivery-sequences';
import { listAvailableDeliverySequences$Plain } from '../fn/session/list-available-delivery-sequences-plain';
import { ListAvailableDeliverySequences$Plain$Params } from '../fn/session/list-available-delivery-sequences-plain';
import { MibpGetUserSessionResponseViewModel } from '../models/mibp-get-user-session-response-view-model';
import { ping } from '../fn/session/ping';
import { Ping$Params } from '../fn/session/ping';
import { PunchoutProfileViewModel } from '../models/punchout-profile-view-model';
import { refreshSessionToken } from '../fn/session/refresh-session-token';
import { RefreshSessionToken$Params } from '../fn/session/refresh-session-token';
import { SearchDeliverySequenceForChangeAccountResponseVm } from '../models/search-delivery-sequence-for-change-account-response-vm';
import { UserAvailableAccountsVm } from '../models/user-available-accounts-vm';

@Injectable({ providedIn: 'root' })
export class SessionApiController extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `sessionControllerGetCurrentSession()` */
  static readonly SessionControllerGetCurrentSessionPath = '/session';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCurrentSession$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCurrentSession$Plain$Response(params?: GetCurrentSession$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<MibpGetUserSessionResponseViewModel>> {
    return getCurrentSession$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getCurrentSession$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCurrentSession$Plain(params?: GetCurrentSession$Plain$Params, context?: HttpContext): Observable<MibpGetUserSessionResponseViewModel> {
    return this.getCurrentSession$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<MibpGetUserSessionResponseViewModel>): MibpGetUserSessionResponseViewModel => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCurrentSession()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCurrentSession$Response(params?: GetCurrentSession$Params, context?: HttpContext): Observable<StrictHttpResponse<MibpGetUserSessionResponseViewModel>> {
    return getCurrentSession(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getCurrentSession$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCurrentSession(params?: GetCurrentSession$Params, context?: HttpContext): Observable<MibpGetUserSessionResponseViewModel> {
    return this.getCurrentSession$Response(params, context).pipe(
      map((r: StrictHttpResponse<MibpGetUserSessionResponseViewModel>): MibpGetUserSessionResponseViewModel => r.body)
    );
  }

  /** Path part for operation `sessionControllerGetPunchoutProfiles()` */
  static readonly SessionControllerGetPunchoutProfilesPath = '/session/punchoutprofiles';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getPunchoutProfiles$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPunchoutProfiles$Plain$Response(params?: GetPunchoutProfiles$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<PunchoutProfileViewModel>>> {
    return getPunchoutProfiles$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getPunchoutProfiles$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPunchoutProfiles$Plain(params?: GetPunchoutProfiles$Plain$Params, context?: HttpContext): Observable<Array<PunchoutProfileViewModel>> {
    return this.getPunchoutProfiles$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<PunchoutProfileViewModel>>): Array<PunchoutProfileViewModel> => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getPunchoutProfiles()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPunchoutProfiles$Response(params?: GetPunchoutProfiles$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<PunchoutProfileViewModel>>> {
    return getPunchoutProfiles(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getPunchoutProfiles$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPunchoutProfiles(params?: GetPunchoutProfiles$Params, context?: HttpContext): Observable<Array<PunchoutProfileViewModel>> {
    return this.getPunchoutProfiles$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<PunchoutProfileViewModel>>): Array<PunchoutProfileViewModel> => r.body)
    );
  }

  /** Path part for operation `sessionControllerRefreshSessionToken()` */
  static readonly SessionControllerRefreshSessionTokenPath = '/session/refresh';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `refreshSessionToken()` instead.
   *
   * This method doesn't expect any request body.
   */
  refreshSessionToken$Response(params?: RefreshSessionToken$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return refreshSessionToken(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `refreshSessionToken$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  refreshSessionToken(params?: RefreshSessionToken$Params, context?: HttpContext): Observable<void> {
    return this.refreshSessionToken$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `sessionControllerPing()` */
  static readonly SessionControllerPingPath = '/session/ping';

  /**
   * Will update LastSeen value in the connection so we'll have a second way to see if a user is still on the site.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `ping()` instead.
   *
   * This method doesn't expect any request body.
   */
  ping$Response(params?: Ping$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return ping(this.http, this.rootUrl, params, context);
  }

  /**
   * Will update LastSeen value in the connection so we'll have a second way to see if a user is still on the site.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `ping$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  ping(params?: Ping$Params, context?: HttpContext): Observable<void> {
    return this.ping$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `sessionControllerChangeConnectionAndUserLanguagelanguageCode()` */
  static readonly SessionControllerChangeConnectionAndUserLanguagelanguageCodePath = '/session/language/{languageCode}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `changeConnectionAndUserLanguage()` instead.
   *
   * This method doesn't expect any request body.
   */
  changeConnectionAndUserLanguage$Response(params: ChangeConnectionAndUserLanguage$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return changeConnectionAndUserLanguage(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `changeConnectionAndUserLanguage$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  changeConnectionAndUserLanguage(params: ChangeConnectionAndUserLanguage$Params, context?: HttpContext): Observable<void> {
    return this.changeConnectionAndUserLanguage$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `sessionControllerGetAvailableAccountInformation()` */
  static readonly SessionControllerGetAvailableAccountInformationPath = '/session/accounts';

  /**
   * Get some basic information about number of available accounts that user can change to.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAvailableAccountInformation$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAvailableAccountInformation$Plain$Response(params?: GetAvailableAccountInformation$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<UserAvailableAccountsVm>> {
    return getAvailableAccountInformation$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * Get some basic information about number of available accounts that user can change to.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getAvailableAccountInformation$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAvailableAccountInformation$Plain(params?: GetAvailableAccountInformation$Plain$Params, context?: HttpContext): Observable<UserAvailableAccountsVm> {
    return this.getAvailableAccountInformation$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<UserAvailableAccountsVm>): UserAvailableAccountsVm => r.body)
    );
  }

  /**
   * Get some basic information about number of available accounts that user can change to.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAvailableAccountInformation()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAvailableAccountInformation$Response(params?: GetAvailableAccountInformation$Params, context?: HttpContext): Observable<StrictHttpResponse<UserAvailableAccountsVm>> {
    return getAvailableAccountInformation(this.http, this.rootUrl, params, context);
  }

  /**
   * Get some basic information about number of available accounts that user can change to.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getAvailableAccountInformation$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAvailableAccountInformation(params?: GetAvailableAccountInformation$Params, context?: HttpContext): Observable<UserAvailableAccountsVm> {
    return this.getAvailableAccountInformation$Response(params, context).pipe(
      map((r: StrictHttpResponse<UserAvailableAccountsVm>): UserAvailableAccountsVm => r.body)
    );
  }

  /** Path part for operation `sessionControllerListAvailableBusinessRelationsskipTakeQueryCompanyId()` */
  static readonly SessionControllerListAvailableBusinessRelationsskipTakeQueryCompanyIdPath = '/session/accounts/businessrelations';

  /**
   * List Business relations that user can change to.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `listAvailableBusinessRelations$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  listAvailableBusinessRelations$Plain$Response(params?: ListAvailableBusinessRelations$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<ChangeAccountBusinessRelationVmPagedResponse>> {
    return listAvailableBusinessRelations$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * List Business relations that user can change to.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `listAvailableBusinessRelations$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  listAvailableBusinessRelations$Plain(params?: ListAvailableBusinessRelations$Plain$Params, context?: HttpContext): Observable<ChangeAccountBusinessRelationVmPagedResponse> {
    return this.listAvailableBusinessRelations$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<ChangeAccountBusinessRelationVmPagedResponse>): ChangeAccountBusinessRelationVmPagedResponse => r.body)
    );
  }

  /**
   * List Business relations that user can change to.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `listAvailableBusinessRelations()` instead.
   *
   * This method doesn't expect any request body.
   */
  listAvailableBusinessRelations$Response(params?: ListAvailableBusinessRelations$Params, context?: HttpContext): Observable<StrictHttpResponse<ChangeAccountBusinessRelationVmPagedResponse>> {
    return listAvailableBusinessRelations(this.http, this.rootUrl, params, context);
  }

  /**
   * List Business relations that user can change to.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `listAvailableBusinessRelations$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  listAvailableBusinessRelations(params?: ListAvailableBusinessRelations$Params, context?: HttpContext): Observable<ChangeAccountBusinessRelationVmPagedResponse> {
    return this.listAvailableBusinessRelations$Response(params, context).pipe(
      map((r: StrictHttpResponse<ChangeAccountBusinessRelationVmPagedResponse>): ChangeAccountBusinessRelationVmPagedResponse => r.body)
    );
  }

  /** Path part for operation `sessionControllerListAvailableDeliverySequencesskipTakeQueryCompanyIdBusinessRelationId()` */
  static readonly SessionControllerListAvailableDeliverySequencesskipTakeQueryCompanyIdBusinessRelationIdPath = '/session/accounts/deliverysequences';

  /**
   * List Delivery sequences that user can change to.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `listAvailableDeliverySequences$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  listAvailableDeliverySequences$Plain$Response(params?: ListAvailableDeliverySequences$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<SearchDeliverySequenceForChangeAccountResponseVm>> {
    return listAvailableDeliverySequences$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * List Delivery sequences that user can change to.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `listAvailableDeliverySequences$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  listAvailableDeliverySequences$Plain(params?: ListAvailableDeliverySequences$Plain$Params, context?: HttpContext): Observable<SearchDeliverySequenceForChangeAccountResponseVm> {
    return this.listAvailableDeliverySequences$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<SearchDeliverySequenceForChangeAccountResponseVm>): SearchDeliverySequenceForChangeAccountResponseVm => r.body)
    );
  }

  /**
   * List Delivery sequences that user can change to.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `listAvailableDeliverySequences()` instead.
   *
   * This method doesn't expect any request body.
   */
  listAvailableDeliverySequences$Response(params?: ListAvailableDeliverySequences$Params, context?: HttpContext): Observable<StrictHttpResponse<SearchDeliverySequenceForChangeAccountResponseVm>> {
    return listAvailableDeliverySequences(this.http, this.rootUrl, params, context);
  }

  /**
   * List Delivery sequences that user can change to.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `listAvailableDeliverySequences$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  listAvailableDeliverySequences(params?: ListAvailableDeliverySequences$Params, context?: HttpContext): Observable<SearchDeliverySequenceForChangeAccountResponseVm> {
    return this.listAvailableDeliverySequences$Response(params, context).pipe(
      map((r: StrictHttpResponse<SearchDeliverySequenceForChangeAccountResponseVm>): SearchDeliverySequenceForChangeAccountResponseVm => r.body)
    );
  }

  /** Path part for operation `sessionControllerListAvailableCompaniesskipTakeQuery()` */
  static readonly SessionControllerListAvailableCompaniesskipTakeQueryPath = '/session/accounts/companies';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `listAvailableCompanies$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  listAvailableCompanies$Plain$Response(params?: ListAvailableCompanies$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<ChangeAccountCompanyVmPagedResponse>> {
    return listAvailableCompanies$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `listAvailableCompanies$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  listAvailableCompanies$Plain(params?: ListAvailableCompanies$Plain$Params, context?: HttpContext): Observable<ChangeAccountCompanyVmPagedResponse> {
    return this.listAvailableCompanies$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<ChangeAccountCompanyVmPagedResponse>): ChangeAccountCompanyVmPagedResponse => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `listAvailableCompanies()` instead.
   *
   * This method doesn't expect any request body.
   */
  listAvailableCompanies$Response(params?: ListAvailableCompanies$Params, context?: HttpContext): Observable<StrictHttpResponse<ChangeAccountCompanyVmPagedResponse>> {
    return listAvailableCompanies(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `listAvailableCompanies$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  listAvailableCompanies(params?: ListAvailableCompanies$Params, context?: HttpContext): Observable<ChangeAccountCompanyVmPagedResponse> {
    return this.listAvailableCompanies$Response(params, context).pipe(
      map((r: StrictHttpResponse<ChangeAccountCompanyVmPagedResponse>): ChangeAccountCompanyVmPagedResponse => r.body)
    );
  }

  /** Path part for operation `sessionControllerActAsDeliverySequencedeliverySequenceId()` */
  static readonly SessionControllerActAsDeliverySequencedeliverySequenceIdPath = '/session/active-deliverysequence/{deliverySequenceId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `actAsDeliverySequence$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  actAsDeliverySequence$Plain$Response(params: ActAsDeliverySequence$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<MibpGetUserSessionResponseViewModel>> {
    return actAsDeliverySequence$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `actAsDeliverySequence$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  actAsDeliverySequence$Plain(params: ActAsDeliverySequence$Plain$Params, context?: HttpContext): Observable<MibpGetUserSessionResponseViewModel> {
    return this.actAsDeliverySequence$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<MibpGetUserSessionResponseViewModel>): MibpGetUserSessionResponseViewModel => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `actAsDeliverySequence()` instead.
   *
   * This method doesn't expect any request body.
   */
  actAsDeliverySequence$Response(params: ActAsDeliverySequence$Params, context?: HttpContext): Observable<StrictHttpResponse<MibpGetUserSessionResponseViewModel>> {
    return actAsDeliverySequence(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `actAsDeliverySequence$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  actAsDeliverySequence(params: ActAsDeliverySequence$Params, context?: HttpContext): Observable<MibpGetUserSessionResponseViewModel> {
    return this.actAsDeliverySequence$Response(params, context).pipe(
      map((r: StrictHttpResponse<MibpGetUserSessionResponseViewModel>): MibpGetUserSessionResponseViewModel => r.body)
    );
  }

  /** Path part for operation `sessionControllerActAsEncodedDeliverySequenceencodeddeliverySequence()` */
  static readonly SessionControllerActAsEncodedDeliverySequenceencodeddeliverySequencePath = '/session/encoded-deliverysequence';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `actAsEncodedDeliverySequence$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  actAsEncodedDeliverySequence$Plain$Response(params?: ActAsEncodedDeliverySequence$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<MibpGetUserSessionResponseViewModel>> {
    return actAsEncodedDeliverySequence$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `actAsEncodedDeliverySequence$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  actAsEncodedDeliverySequence$Plain(params?: ActAsEncodedDeliverySequence$Plain$Params, context?: HttpContext): Observable<MibpGetUserSessionResponseViewModel> {
    return this.actAsEncodedDeliverySequence$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<MibpGetUserSessionResponseViewModel>): MibpGetUserSessionResponseViewModel => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `actAsEncodedDeliverySequence()` instead.
   *
   * This method doesn't expect any request body.
   */
  actAsEncodedDeliverySequence$Response(params?: ActAsEncodedDeliverySequence$Params, context?: HttpContext): Observable<StrictHttpResponse<MibpGetUserSessionResponseViewModel>> {
    return actAsEncodedDeliverySequence(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `actAsEncodedDeliverySequence$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  actAsEncodedDeliverySequence(params?: ActAsEncodedDeliverySequence$Params, context?: HttpContext): Observable<MibpGetUserSessionResponseViewModel> {
    return this.actAsEncodedDeliverySequence$Response(params, context).pipe(
      map((r: StrictHttpResponse<MibpGetUserSessionResponseViewModel>): MibpGetUserSessionResponseViewModel => r.body)
    );
  }

  /** Path part for operation `sessionControllerActAsAll()` */
  static readonly SessionControllerActAsAllPath = '/session/active-deliverysequence';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `actAsAll$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  actAsAll$Plain$Response(params?: ActAsAll$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<MibpGetUserSessionResponseViewModel>> {
    return actAsAll$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `actAsAll$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  actAsAll$Plain(params?: ActAsAll$Plain$Params, context?: HttpContext): Observable<MibpGetUserSessionResponseViewModel> {
    return this.actAsAll$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<MibpGetUserSessionResponseViewModel>): MibpGetUserSessionResponseViewModel => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `actAsAll()` instead.
   *
   * This method doesn't expect any request body.
   */
  actAsAll$Response(params?: ActAsAll$Params, context?: HttpContext): Observable<StrictHttpResponse<MibpGetUserSessionResponseViewModel>> {
    return actAsAll(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `actAsAll$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  actAsAll(params?: ActAsAll$Params, context?: HttpContext): Observable<MibpGetUserSessionResponseViewModel> {
    return this.actAsAll$Response(params, context).pipe(
      map((r: StrictHttpResponse<MibpGetUserSessionResponseViewModel>): MibpGetUserSessionResponseViewModel => r.body)
    );
  }

}
