<div *ngIf="!useNewStyle" class="countdown" (click)="toggle()">
  <div class="countdown-number">
    <span *ngIf="inProgress" translate="no" class="material-icon material-icon--orange material-icon--xxlarge">stop_circle</span>
    <span *ngIf="!inProgress" translate="no" class="material-icon material-icon--blue material-icon--xxlarge">play_circle</span>
    <!-- <span *ngIf="inProgress">{{ remainingSeconds }}</span>
    <span *ngIf="!inProgress">...</span> -->
  </div>
  <svg>
    <circle [class.animate]="inProgress" [style.animationDuration]="seconds + 's'" r="18" cx="20" cy="20"></circle>
  </svg>
</div>


<span *ngIf="useNewStyle">
  <div style="display: flex;">
    <div class="reload-button">

      <span class="icon" (click)="startStop($event)" title="Start/Stop automatic reload" >
        <span *ngIf="!inProgress" translate="no" class="material-icon material-icon--xxlarge">play_circle</span>
        <span *ngIf="inProgress" translate="no" class="material-icon material-icon--blue material-icon--xxlarge">stop_circle</span>
        
        <span class="counter" *ngIf="inProgress">{{remainingSeconds}}</span>
      </span>
      <span class="button" (click)="triggerManualReload()">Reload</span>
    </div>
  </div>

</span>

<mibp-context-menu #ctxMenu (select)="onContextMenuSelect($event)" [items]="contextMenuItems"></mibp-context-menu>
