import { Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ButtonColors } from 'root/components/button/button.enum';
import { DropdownInput } from 'root/components/dropdown';
import { NoticeType } from 'root/components/noticebar/noticebar.enum';
import { SupportCaseApiController } from 'root/mibp-openapi-gen/controllers';
import { CaseType, ContactUsSupportCaseRequest } from 'root/mibp-openapi-gen/models';
import { FormValidationService, FormattingService, LoaderService, LocalizationService, NoticebarService } from 'root/services';
import { MibpSessionService } from 'root/services/mibp-session/mibp-session.service';
import { MibpEmailValidator } from 'root/validators/email.validator';
import { requireCheckboxesToBeCheckedValidator } from 'root/validators/requireCheckboxesToBeChecked';
import { Subscription, firstValueFrom } from 'rxjs';
import { SupportCaseShipppingTrackingType, SupportCaseShipppingTrackingTypeValue } from './contact-us-shipping-tracking-form.interface';
import { MibpAllowedCharactersValidator } from 'root/validators/allowed-characters.validator';

export interface SupportCaseShipingTrackingData {
  deliverysequenceId: number,
  orderNumber: string,
  description?: string,
  enquiryType?: SupportCaseShipppingTrackingType
}
@Component({
  selector: 'mibp-contact-us-shipping-tracking-form',
  templateUrl: './contact-us-shipping-tracking-form.component.html',
  styleUrls: ['./contact-us-shipping-tracking-form.component.scss']
})

export class ContactUsShippingAndTrackingFormComponent implements OnInit, OnDestroy {
  protected contactUsShippingTrackingForm: UntypedFormGroup;
  protected enquiryTypes: DropdownInput[] = [];
  protected buttonColors = ButtonColors;
  protected formSubmitted: boolean;
  protected showEnquiryDropdown = false;
  protected isQuantityRequired: boolean;
  protected isPartNumberRequired = true;
  protected isEmailSelected: boolean;
  protected isPhoneNumberSelected: boolean;

  @Input() showHeader = true;
  @Input() shipingTrackingData : SupportCaseShipingTrackingData = null;
  @Output() successfullySubmitted = new EventEmitter<boolean>();

  private stopUsingResources: () => void;
  private formValueSubscription: Subscription;


  constructor(private fb: UntypedFormBuilder,
    private validation: FormValidationService,
    private element: ElementRef,
    private mibpSession: MibpSessionService,
    private formattingService: FormattingService,
    private supportCaseController: SupportCaseApiController,
    private loader: LoaderService,
    private noticeService: NoticebarService,
    private localizationService: LocalizationService){

  }
  ngOnDestroy(): void {
    if(this.stopUsingResources){
      this.stopUsingResources();
    }
    this.formValueSubscription?.unsubscribe();
  }

  ngOnInit(): void {
    this.contactUsShippingTrackingForm = this.fb.group({
      name: [this.mibpSession.current.user.firstName + ' ' + this.mibpSession.current.user.lastName, [Validators.required, MibpAllowedCharactersValidator()]],
      email: [this.mibpSession.current.user.email, MibpEmailValidator(this.formattingService)],
      phonenumber: [this.mibpSession.current.user.phone, MibpAllowedCharactersValidator()],
      contactPreferenceGroup: new UntypedFormGroup({
        byPhone: new UntypedFormControl(false),
        byEmail: new UntypedFormControl(false)
      }, { validators: requireCheckboxesToBeCheckedValidator(1)}),
      enquiryType: [null, Validators.required],
      orderNumber:[this.shipingTrackingData?.orderNumber, [Validators.required, MibpAllowedCharactersValidator()]],
      description: [this.shipingTrackingData?.description, MibpAllowedCharactersValidator()],
    });

    this.stopUsingResources = this.localizationService.using(
      [SupportCaseShipppingTrackingType.ExpectedDate,
        SupportCaseShipppingTrackingType.General,
        SupportCaseShipppingTrackingType.ModifyOrder,
        SupportCaseShipppingTrackingType.NoTrackingInfo,
        SupportCaseShipppingTrackingType.NoWaybillInformation,
        SupportCaseShipppingTrackingType.NoCarrierInformation],
          resourceStrings => {
      this.enquiryTypes.push(<DropdownInput>{text: resourceStrings[0], value: SupportCaseShipppingTrackingTypeValue.ExpectedDate});
      this.enquiryTypes.push(<DropdownInput>{text: resourceStrings[1], value: SupportCaseShipppingTrackingTypeValue.General});
      this.enquiryTypes.push(<DropdownInput>{text: resourceStrings[2], value: SupportCaseShipppingTrackingTypeValue.ModifyOrder});
      this.enquiryTypes.push(<DropdownInput>{text: resourceStrings[3], value: SupportCaseShipppingTrackingTypeValue.NoTrackingInfo});
      this.enquiryTypes.push(<DropdownInput>{text: resourceStrings[4], value: SupportCaseShipppingTrackingTypeValue.NoWaybillInformation});
      this.enquiryTypes.push(<DropdownInput>{text: resourceStrings[5], value: SupportCaseShipppingTrackingTypeValue.NoCarrierInformation});
      this.showEnquiryDropdown = true;
    });

    this.initForm();

    this.formValueSubscription =  this.contactUsShippingTrackingForm.controls.contactPreferenceGroup.valueChanges.subscribe(() => {
      this.isEmailSelected = this.contactUsShippingTrackingForm.controls.contactPreferenceGroup.get('byEmail').value;
      this.isPhoneNumberSelected = this.contactUsShippingTrackingForm.controls.contactPreferenceGroup.get('byPhone').value;
      this.isEmailSelected ? this.addRequiredValidators('email') : this.removeRequiredValidators('email');
      this.isPhoneNumberSelected ? this.addRequiredValidators('phonenumber') : this.removeRequiredValidators('phonenumber');
    });
  }

  initForm(){
    if(this.shipingTrackingData){
      if(this.shipingTrackingData.enquiryType){
        const enquiryTypeText = this.localizationService.get(this.shipingTrackingData.enquiryType);
        this.contactUsShippingTrackingForm.get('enquiryType').patchValue(<DropdownInput>{text: enquiryTypeText, value: enquiryTypeText});
        this.contactUsShippingTrackingForm.get('enquiryType').disable();
        this.enquiryTypeChangedHandleValidators(this.shipingTrackingData.enquiryType);
      }
    }
  }

  enquiryTypeChanged(value: DropdownInput){
    if(value){
      this.enquiryTypeChangedHandleValidators(value.value);
    }
  }

  enquiryTypeChangedHandleValidators(enquiryType: SupportCaseShipppingTrackingType | string){
    if(enquiryType === SupportCaseShipppingTrackingTypeValue.ModifyOrder){
      this.addRequiredValidators('email');
    }
  }

  private addRequiredValidators(field: string){
    this.contactUsShippingTrackingForm.get(field).addValidators([Validators.required]);
    this.contactUsShippingTrackingForm.get(field).updateValueAndValidity();
  }

  private removeRequiredValidators(field: string){
    this.contactUsShippingTrackingForm.get(field).removeValidators(Validators.required);
    this.contactUsShippingTrackingForm.get(field).updateValueAndValidity();
  }

  async submit(){
    this.formSubmitted = true;
    if (this.contactUsShippingTrackingForm.invalid) {
      this.contactUsShippingTrackingForm.markAllAsTouched();
      this.validation.scrollToFirstError(this.contactUsShippingTrackingForm, this.element);
      return;
    }
    const contactUsSupportCaseRequest = <ContactUsSupportCaseRequest>{
      deliverySequenceId: this.shipingTrackingData?.deliverysequenceId,
      caseType: CaseType.ShippingAndTracking,
      categoryShippingAndTracking: this.contactUsShippingTrackingForm.get('enquiryType').value.text,
      suppliedFullName: this.contactUsShippingTrackingForm.get('name').value,
      suppliedEmail: this.contactUsShippingTrackingForm.get('email').value,
      suppliedPhone: this.contactUsShippingTrackingForm.get('phonenumber').value,
      prefferedEmailContact: this.contactUsShippingTrackingForm.controls.contactPreferenceGroup.get('byEmail').value,
      prefferedPhoneContact: this.contactUsShippingTrackingForm.controls.contactPreferenceGroup.get('byPhone').value,
      description: this.contactUsShippingTrackingForm.get('description').value,
      orderNumber: this.contactUsShippingTrackingForm.get('orderNumber').value
    };

    try {
      this.loader.showFullScreenLoader();
      await firstValueFrom(this.supportCaseController.submitContactUsCase({body: contactUsSupportCaseRequest}));
      this.loader.hideFullScreenLoader();
      this.successfullySubmitted.emit(true);
    }
    catch(error){
      this.loader.hideFullScreenLoader();
      this.successfullySubmitted.emit(false);
    }
  }
}
