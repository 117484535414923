
import { MibpHttpApi } from './mibp-http-api.service';
import { CreateUserFileViewModel } from './mibp-http-api.types';
import { Guid } from 'guid-typescript';
import { SignalR_Rendition } from '../mibp-api-services';

/**
 * Provides an API And utilities for saving UserFiles using HTTP Post
 * and resolving URL:s to user file resources
 */
export class UserFileHttpService {

  constructor(private httpApi: MibpHttpApi) {}

  public async Save(file: CreateUserFileViewModel): Promise<string> {
    return this.httpApi.post<string>('userfile', file).toPromise();
  }

  public getFileUrl(id: Guid): string {
    return this.httpApi.resolveUrl(`userfile/${id.toString()}`);
  }

  public getFileRenditionUrl(id: Guid, rendition: SignalR_Rendition): string {
    return this.httpApi.resolveUriWithJwtToken(`userfile/${id.toString()}/${rendition.toString()}`);
  }

  public getFileDownloadUrl(id: Guid): string {
    return this.httpApi.resolveUriWithJwtToken(`userfile/${id.toString()}/download`);
  }

  public triggerDownload(id: Guid): void {
    if (id) {     
      window.location.href = this.getFileDownloadUrl(id);
    }
  }


}
