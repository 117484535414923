<div class="blob-log-view">

  <div class="load-placeholder" [class.load-placeholder--loading]="isLoading" (click)="loadLog()" *ngIf="blobLogFile && (!showLog || (isLoading && !logRows?.length))">
    @if (isLoading) {
      <div class="load-placeholder__icon">
        <span class="material-icon material-icon--spin" style="font-size: 2rem">progress_activity</span>
      </div>
      <div class="load-placeholder__details">
        <strong>Please wait...</strong>
        <span>{{blobLogFile}}</span>
      </div>
    } @else {
      <div class="load-placeholder__icon">
        <span class="material-icon" style="font-size: 2rem">developer_board</span>
      </div>
      <div class="load-placeholder__details">
        <strong>Click to load log file</strong>
        <span>{{blobLogFile}}</span>
      </div>
    }
  </div>

  <div class="load-placeholder boad-placeholder--no-file" *ngIf="!blobLogFile">
      <div class="load-placeholder__icon">
        <span class="material-icon" style="font-size: 2rem">developer_board_off</span>
      </div>
      <div class="load-placeholder__details">
        <strong>No log file detected</strong>
      </div>
  </div>

  <div  *ngIf="showLog && logRows?.length > 0">
    <div class="header">
      <div class="filename">
        {{blobLogFile}} <span *ngIf="isLoading" class="material-icon material-icon--small material-icon--white material-icon--spin">progress_activity</span>
      </div>
      <div class="plain-toolbar">
        <button title="Reload log" [disabled]="isLoading" (click)="loadLog()"><span class="material-icon">sync</span></button>
        <button title="Copy to clipboard" [disabled]="isLoading" (click)="copyToClipboard()"><span class="material-icon">content_copy</span></button>
        <button title="Download file" [disabled]="isLoading" (click)="downloadLog()"><span class="material-icon">download</span></button>
      </div>
    </div>

    <pre><code><span class="log-row"
      *ngFor="let logRow of logRows"
      [ngClass]="logRow.classNames"><span class="date" *ngIf="logRow.timestampString">{{logRow.timestampString}}</span><span class="value">{{logRow.value}}</span></span></code></pre>


  </div>

</div>
