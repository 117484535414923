import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from "@angular/core";
import { debounceTime, Subject, Subscription } from "rxjs";

@Component({
  selector: 'mibp-filter-tag',
  templateUrl: './filter-tags.component.html',
  styleUrl: './filter-tags.component.scss'
})
export class MibpFilterTagComponent implements OnInit, OnDestroy {
  @Input() tags: MibpFilterTag[];
  @Input() multiselect = true;
  protected selectedValues: string[] = [];
  @Input() debounceTime = 1000;
  @Input() disabled = false;
  @Output() changed = new EventEmitter<string[]>();

  protected selectedValueDictionary: { [key: string]: boolean} = {};
  protected valuesSubject = new Subject<string[]>();
  private valueChangeSub?: Subscription;

  ngOnInit(): void {
    this.valueChangeSub = this.valuesSubject
      .pipe(debounceTime(this.debounceTime))
      .subscribe(() => this.emitChangeEvent());
  }

  private emitChangeEvent(): void {
    this.changed.emit(this.selectedValues);
  }

  ngOnDestroy(): void {
    this.valueChangeSub?.unsubscribe();
  }

  public setValues(values: string[]): void {
    let dict: { [key: string]: boolean} = {};
    let selectedValues: string[] = [];
    values.forEach(val => {
      dict[val] = true;
      selectedValues.push(val);
    });
    this.selectedValues = selectedValues;
    this.selectedValueDictionary = dict;
  }


  protected onTagClick(e: MouseEvent, tag: MibpFilterTag): void {
    if (this.disabled) {
      return;
    }

    let values = this.selectedValues || [];
    let dict: { [key: string]: boolean} = this.selectedValueDictionary || {};

    if (tag == null) {
      values = [];
      dict = {};
    } else {
      if (e.ctrlKey) {
        if (!this.selectedValues.includes(tag.value)) {
          values.push(tag.value);
        }
        dict[tag.value] = true;
      } else {
        values = [tag.value];
        dict = {};
        dict[tag.value] = true;
      }
    }

    this.selectedValues = values;
    this.selectedValueDictionary = dict;
    this.valuesSubject.next(this.selectedValues.slice(0));
  }

}

export type MibpFilterTag = { text?: string, resourceString?: string; value: string; };
