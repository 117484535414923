<div [class.light-scroll]="useScrolledList">
  <div class="list-empty" *ngIf="resolvedScopeList?.length === 0"><mibp-resource-string key="Resolved_Scopes_NoResponsibilitiesAdded"></mibp-resource-string></div>

  <table class="table" *ngIf="resolvedScopeList?.length > 0">
    <tr>
      <th [attr.colspan]="anyValidationErrors || (hasInactiveResposibility && !this.addResposibilityDialog) ? 2 : null"><mibp-resource-string key="Global_Company"></mibp-resource-string></th>
      <th *ngIf="!hideBusinessRelation"><mibp-resource-string key="Global_Customer"></mibp-resource-string></th>
      <th *ngIf="!hideDeliverySequence"><mibp-resource-string key="Global_DeliverySequence"></mibp-resource-string></th>
      <th *ngIf="!hideProductColumn"><mibp-resource-string key="Global_Product_Area"></mibp-resource-string></th>
      <th *ngIf="!hideProductColumn"><mibp-resource-string key="Global_Product_Group"></mibp-resource-string></th>
      <th *ngIf="!hideProductColumn"><mibp-resource-string key="Global_Product_Sub_Group"></mibp-resource-string></th>
      <th *ngIf="!hideModelColumn"><mibp-resource-string key="Global_Model"></mibp-resource-string></th>
      <th *ngIf="!hideEquipmentColumn"><mibp-resource-string key="WarrantyPortal_Field_MachineSerialNumber"></mibp-resource-string></th>
      <th *ngIf="!hideOperationSite"><mibp-resource-string key="Global_OperationSite"></mibp-resource-string></th>
      <th></th>

    </tr>

    <ng-template ngFor let-scope [ngForOf]="resolvedScopeList" let-ix="index">
      <tr [class.children-grayedout]="hasInactiveResposibility && (!scope.isActive && scope.company != null)" [style.color]="scope.validationError ? 'red' : null">
        <td class="error-icon-red" *ngIf="hasInactiveResposibility  && (!scope.isActive && scope.company != null)"><span translate="no" class="material-icon material-icon--red material-icon--fill">warning</span></td>
        <td [class.has-value]="!!scope?.company" [attr.colspan]="(scope.isActive || scope.company == null) && (anyValidationErrors || hasInactiveResposibility) ? 2 : 1">{{ scope?.company?.title || anyText }}</td>
        <td [class.has-value]="!!scope?.businessRelation" *ngIf="!hideBusinessRelation">{{ scope?.businessRelation?.erpCustomerId || anyText }}</td>
        <td [class.has-value]="!!scope?.deliverySequence" *ngIf="!hideDeliverySequence">{{ scope?.deliverySequence?.title || anyText }}</td>
        <td [class.has-value]="!!scope?.productArea" *ngIf="!hideProductColumn">{{ scope?.productArea?.title || anyText }}</td>
        <td [class.has-value]="!!scope?.productGroup" *ngIf="!hideProductColumn">{{ scope?.productGroup?.title || anyText }}</td>
        <td [class.has-value]="!!scope?.productSubGroup" *ngIf="!hideProductColumn">{{ scope?.productSubGroup?.title || anyText }}</td>
        <td [class.has-value]="!!scope?.productModel" *ngIf="!hideModelColumn">{{ scope?.productModel?.title || anyText }}</td>
        <td [class.has-value]="!!scope?.equipment" *ngIf="!hideEquipmentColumn">{{ scope?.equipment?.title || anyText }} </td>
        <td [class.has-value]="!!scope?.operationSite" *ngIf="!hideOperationSite">{{ scope?.operationSite?.title || anyText }} </td>
        <td><mibp-button #btn [icon]="btn.Enum.Icons.Trash" [color]="btn.Enum.Colors.Orange" (click)="triggerDelete(getScopeIndex(scope, ix))"></mibp-button></td>
      </tr>
      <!-- <tr *ngIf="scope?.children">
        <td colspan="10" style="background-color: #f90;">
          <strong>The responsibilities below will be overridden by its more general parent</strong>
        </td>
      </tr> -->
      <tr class="children" *ngFor="let child of scope?.children; let cix = index;"  [style.color]="child.validationError ? 'red' : null">
        <td class="error-icon"><span translate="no" class="material-icon material-icon--red material-icon--fill">warning</span></td>
          <td [class.has-value]="!!child?.company">{{ child?.company?.title || anyText }}</td>
          <td [class.has-value]="!!child?.businessRelation" *ngIf="!hideBusinessRelation">{{ child?.businessRelation?.erpCustomerId || anyText }}</td>
          <td [class.has-value]="!!child?.deliverySequence" *ngIf="!hideDeliverySequence">{{ child?.deliverySequence?.title || anyText }}</td>
          <td [class.has-value]="!!child?.productArea" *ngIf="!hideProductColumn">{{ child?.productArea?.title || anyText }}</td>
          <td [class.has-value]="!!child?.productGroup" *ngIf="!hideProductColumn">{{ child?.productGroup?.title || anyText }}</td>
          <td [class.has-value]="!!child?.productSubgroup" *ngIf="!hideProductColumn">{{ child?.productSubgroup?.title || anyText }}</td>
          <td [class.has-value]="!!child?.productModel" *ngIf="!hideModelColumn">{{ child?.productModel?.title || anyText }}</td>
          <td [class.has-value]="!!child?.equipment" *ngIf="!hideEquipmentColumn">{{ child?.equipment?.title || anyText }}</td>
          <td [class.has-value]="!!child?.operationSite" *ngIf="!hideOperationSite">{{ child?.operationSite?.title || anyText }}</td>
          <td><mibp-button [isSmall]="true" #btn [icon]="btn.Enum.Icons.Trash" [color]="btn.Enum.Colors.Orange" (click)="triggerDelete(getScopeIndex(child, cix))"></mibp-button></td>
      </tr>
    </ng-template>

  </table>
</div>
<!-- <mibp-system-message [messageType]="messageType.Warning" *ngIf="anyValidationErrors">

    <p>
       The <strong style="background-color: #fbfdc7;padding: 8px;">yellow</strong> rows are responsibility rows that are already covered by a different row.
    </p>
    <ul>
      <li>You must remove the conflicting responsibilities to continue</li>
    </ul>

</mibp-system-message> -->
