<div>
  <ul *ngIf="pager?.totalItems > pager.pageSize" class="my-pagination" [class.my-pagination--no-bottom-margin]="noBottomMargin">
    <li [ngClass]="{hidden:pager.currentPage == 1}" class="my-pagination__link">
      <a (click)="setPage(pager.currentPage - 1)">
        <mibp-resource-string key="Global_Dialog_Previous"></mibp-resource-string>
      </a>
    </li>
    <li [ngClass]="{'my-pagination__link--removed':pager.startPage == 1}" class="my-pagination__link">
      <a (click)="setPage(1)">1</a>
    </li>
    <li [ngClass]="{'my-pagination__link--removed':pager.startPage &lt; 3}" class="my-pagination__link">
      <a (click)="setPage(pager.startPage - 1)" [ngClass]="disabled">...</a>
    </li>
    <li *ngFor="let page of pager.pages" [ngClass]="{'my-pagination__link--active':pager.currentPage == page}" class="my-pagination__link">
      <a (click)="setPage(page)">{{page}}</a>
    </li>
    <li [ngClass]="{'my-pagination__link--removed':pager.totalPages - pager.endPage &lt; 2}" class="my-pagination__link">
      <a (click)="setPage(pager.endPage + 1)" [ngClass]="removed">...</a>
    </li>
    <li [ngClass]="{'my-pagination__link--removed':pager.totalPages == pager.endPage}" class="my-pagination__link">
      <a (click)="setPage(pager.totalPages)">{{pager.totalPages}}</a>
    </li>
    <li [ngClass]="{hidden:pager.currentPage == pager.totalPages}" class="my-pagination__link">
      <a (click)="setPage(pager.currentPage + 1)">
        <mibp-resource-string key="Global_Next"></mibp-resource-string>
      </a>
    </li>
  </ul>
</div>
