<div [ngClass]="isHorizontal == true ? 'machine-filter-horizontal' : 'machine-filter-vertical'">
  <div class="machine-filter-dropdown" *ngIf="companyFilterEnabled" 
  [class.machine-filter-dropdown--inline]="displayMode == 'inline'" 
  [class.machine-filter-dropdown--dynamic-width]="useDynamicWidth">
    <label><mibp-resource-string [key]="'MyFleet_SalesEntity'"></mibp-resource-string></label>
    <mibp-dropdown
    #companyDropdown
    [data]="data$[companyFilterType]"
    (filter)="filterCompanies($event)"
    [multiselectApply]="true"
    [newMultiSelectDesign]="true"
    [multiselect]="true"
    (valueChange)="onDropdownValueChange($event,companyFilterType)"
    [take]="dropdownPageSize"
    [selectedOption]="initialCompanySelection"
    [disabled]="disabled"
    ></mibp-dropdown>
  </div>
  <div class="machine-filter-dropdown" *ngIf="customerFilterEnabled" 
  [class.machine-filter-dropdown--inline]="displayMode == 'inline'" 
  [class.machine-filter-dropdown--dynamic-width]="useDynamicWidth">
    <label><mibp-resource-string [key]="'Global_Customer'"></mibp-resource-string></label>
    <mibp-dropdown
    #customerDropdown
    [data]="data$[customerFilterType]"
    (filter)="filterCustomers($event)"
    [multiselectApply]="true"
    [newMultiSelectDesign]="true"
    [multiselect]="true"    
    (valueChange)="onDropdownValueChange($event,customerFilterType)"
    [take]="dropdownPageSize"
    [selectedOption]="initialCustomerSelection"
    [disabled]="disabled"
    ></mibp-dropdown>
  </div>
  <div class="machine-filter-dropdown" *ngIf="operationSiteFilterEnabled" 
  [class.machine-filter-dropdown--inline]="displayMode == 'inline'"
  [class.machine-filter-dropdown--dynamic-width]="useDynamicWidth">
    <label><mibp-resource-string [key]="'Global_OperationSite'"></mibp-resource-string></label>
    <mibp-dropdown
    #operationSiteDropdown
    [data]="data$[operationSiteFilterType]"
    (filter)="filterOperationSites($event)"
    [multiselectApply]="true"
    [newMultiSelectDesign]="true"
    [multiselect]="true"
    (valueChange)="onDropdownValueChange($event,operationSiteFilterType)"
    [take]="dropdownPageSize"
    [selectedOption]="initialOperationSiteSelection"
    [disabled]="disabled"
    ></mibp-dropdown>
  </div>
  <div class="machine-filter-dropdown" *ngIf="machineFilterEnabled" 
  [class.machine-filter-dropdown--inline]="displayMode == 'inline'"
  [class.machine-filter-dropdown--dynamic-width]="useDynamicWidth">
    <label><mibp-resource-string [key]="'Global_MachineSerialNumber'"></mibp-resource-string></label>
    <mibp-dropdown
    #machineDropdown
    [data]="data$[machineFilterType]"
    (filter)="filtermachines($event)"
    [multiselectApply]="true"
    [newMultiSelectDesign]="true"
    [multiselect]="true"
    (valueChange)="onDropdownValueChange($event,machineFilterType)"
    [take]="dropdownPageSize"
    [selectedOption]="initialMachineSelection"
    [disabled]="disabled"
    ></mibp-dropdown>
  </div>
  <div class="machine-filter-dropdown" *ngIf="machineModelFilterEnabled" 
  [class.machine-filter-dropdown--inline]="displayMode == 'inline'"
  [class.machine-filter-dropdown--dynamic-width]="useDynamicWidth">
    <label><mibp-resource-string [key]="'Global_Machine_Model'"></mibp-resource-string></label>
    <mibp-dropdown
    #machineModelDropdown
    [data]="data$[modelFilterType]"
    (filter)="filterModels($event)"
    [multiselectApply]="true"
    [newMultiSelectDesign]="true"
    [multiselect]="true"
    (valueChange)="onDropdownValueChange($event,modelFilterType)"
    [selectedOption]="initialModelSelection"
    [take]="dropdownPageSize"
    [disabled]="disabled"></mibp-dropdown>
  </div>
  <div class="machine-filter-dropdown" *ngIf="machineSubGroupFilterEnabled" 
  [class.machine-filter-dropdown--inline]="displayMode == 'inline'"
  [class.machine-filter-dropdown--dynamic-width]="useDynamicWidth">
    <label><mibp-resource-string [key]="'Global_Machine_ModelSubGroup'"></mibp-resource-string></label>
    <mibp-dropdown
    #machineSubGroupDropdown
    [data]="data$[subgroupFilterType]"
    (filter)="filterSubGroups($event)"
    [multiselectApply]="true"
    [newMultiSelectDesign]="true"
    [multiselect]="true"
    (valueChange)="onDropdownValueChange($event,subgroupFilterType)"
    [selectedOption]="initialSubgroupSelection"
    [take]="dropdownPageSize"
    [disabled]="disabled"></mibp-dropdown>
  </div>
  <div class="machine-filter-dropdown" *ngIf="machineGroupFilterEnabled" 
  [class.machine-filter-dropdown--inline]="displayMode == 'inline'"
  [class.machine-filter-dropdown--dynamic-width]="useDynamicWidth">
    <label><mibp-resource-string [key]="'Global_Machine_ModelGroup'"></mibp-resource-string></label>
    <mibp-dropdown
    #machineGroupDropdown
    [data]="data$[groupFilterType]"
    (filter)="filterGroups($event)"
    [multiselectApply]="true"
    [newMultiSelectDesign]="true"
    [multiselect]="true"
    [selectedOption]="initialGroupSelection"
    (valueChange)="onDropdownValueChange($event,groupFilterType)"
    [take]="dropdownPageSize"
    [disabled]="disabled"></mibp-dropdown>
  </div>
</div>
