<div class="my-address-view" *ngIf="addressVm">
  <div class="my-address-view__value my-address-view__value--name">{{addressVm.name}}</div>
  <div class="my-address-view__value my-address-view__value--addressline1" *ngIf="addressVm.addressLine1">{{addressVm.addressLine1}}</div>
  <div class="my-address-view__value my-address-view__value--addressline2" *ngIf="addressVm.addressLine2">{{addressVm.addressLine2}}</div>
  <div class="my-address-view__value my-address-view__value--addressline3" *ngIf="addressVm.addressLine3">{{addressVm.addressLine3}}</div>
  <div class="my-address-view__value my-address-view__value--postcode" *ngIf="addressVm.postalCode">{{addressVm.postalCode}}</div>
  <div class="my-address-view__value my-address-view__value--city" *ngIf="addressVm.city">{{addressVm.city}}</div>
  <div class="my-address-view__value my-address-view__value--country" *ngIf="addressVm.country">{{addressVm.country}}</div>
  <div class="my-address-view__value my-address-view__value--phonenumber" *ngFor="let phoneNumber of addressVm.phoneNumbers">{{phoneNumber}}</div>
</div>
