/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { Company } from '../models/company';
import { CompanyIndexSearchResponse } from '../models/company-index-search-response';
import { get } from '../fn/companies/get';
import { Get$Params } from '../fn/companies/get';
import { get$Plain } from '../fn/companies/get-plain';
import { Get$Plain$Params } from '../fn/companies/get-plain';
import { gridSearch } from '../fn/companies/grid-search';
import { GridSearch$Params } from '../fn/companies/grid-search';
import { gridSearch$Plain } from '../fn/companies/grid-search-plain';
import { GridSearch$Plain$Params } from '../fn/companies/grid-search-plain';
import { gridSearchForOrganization } from '../fn/companies/grid-search-for-organization';
import { GridSearchForOrganization$Params } from '../fn/companies/grid-search-for-organization';
import { gridSearchForOrganization$Plain } from '../fn/companies/grid-search-for-organization-plain';
import { GridSearchForOrganization$Plain$Params } from '../fn/companies/grid-search-for-organization-plain';
import { MibpGridResponse } from '../models/mibp-grid-response';
import { rescope } from '../fn/companies/rescope';
import { Rescope$Params } from '../fn/companies/rescope';
import { rescope$Plain } from '../fn/companies/rescope-plain';
import { Rescope$Plain$Params } from '../fn/companies/rescope-plain';
import { search } from '../fn/companies/search';
import { Search$Params } from '../fn/companies/search';
import { search$Plain } from '../fn/companies/search-plain';
import { Search$Plain$Params } from '../fn/companies/search-plain';
import { searchActive } from '../fn/companies/search-active';
import { SearchActive$Params } from '../fn/companies/search-active';
import { searchActive$Plain } from '../fn/companies/search-active-plain';
import { SearchActive$Plain$Params } from '../fn/companies/search-active-plain';

@Injectable({ providedIn: 'root' })
export class CompaniesApiController extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `companiesControllerSearchoptions()` */
  static readonly CompaniesControllerSearchoptionsPath = '/companies/search';

  /**
   * Search companies based on search options.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `search$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  search$Plain$Response(params?: Search$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<CompanyIndexSearchResponse>> {
    return search$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * Search companies based on search options.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `search$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  search$Plain(params?: Search$Plain$Params, context?: HttpContext): Observable<CompanyIndexSearchResponse> {
    return this.search$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<CompanyIndexSearchResponse>): CompanyIndexSearchResponse => r.body)
    );
  }

  /**
   * Search companies based on search options.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `search()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  search$Response(params?: Search$Params, context?: HttpContext): Observable<StrictHttpResponse<CompanyIndexSearchResponse>> {
    return search(this.http, this.rootUrl, params, context);
  }

  /**
   * Search companies based on search options.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `search$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  search(params?: Search$Params, context?: HttpContext): Observable<CompanyIndexSearchResponse> {
    return this.search$Response(params, context).pipe(
      map((r: StrictHttpResponse<CompanyIndexSearchResponse>): CompanyIndexSearchResponse => r.body)
    );
  }

  /** Path part for operation `companiesControllerSearchActiveoptions()` */
  static readonly CompaniesControllerSearchActiveoptionsPath = '/companies/searchactive';

  /**
   * Search companies based on search options.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `searchActive$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  searchActive$Plain$Response(params?: SearchActive$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<CompanyIndexSearchResponse>> {
    return searchActive$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * Search companies based on search options.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `searchActive$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  searchActive$Plain(params?: SearchActive$Plain$Params, context?: HttpContext): Observable<CompanyIndexSearchResponse> {
    return this.searchActive$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<CompanyIndexSearchResponse>): CompanyIndexSearchResponse => r.body)
    );
  }

  /**
   * Search companies based on search options.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `searchActive()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  searchActive$Response(params?: SearchActive$Params, context?: HttpContext): Observable<StrictHttpResponse<CompanyIndexSearchResponse>> {
    return searchActive(this.http, this.rootUrl, params, context);
  }

  /**
   * Search companies based on search options.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `searchActive$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  searchActive(params?: SearchActive$Params, context?: HttpContext): Observable<CompanyIndexSearchResponse> {
    return this.searchActive$Response(params, context).pipe(
      map((r: StrictHttpResponse<CompanyIndexSearchResponse>): CompanyIndexSearchResponse => r.body)
    );
  }

  /** Path part for operation `companiesControllerRescopecompanyCode()` */
  static readonly CompaniesControllerRescopecompanyCodePath = '/companies/rescope';

  /**
   * Rescope all companies.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `rescope$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  rescope$Plain$Response(params?: Rescope$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<number>> {
    return rescope$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * Rescope all companies.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `rescope$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  rescope$Plain(params?: Rescope$Plain$Params, context?: HttpContext): Observable<number> {
    return this.rescope$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<number>): number => r.body)
    );
  }

  /**
   * Rescope all companies.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `rescope()` instead.
   *
   * This method doesn't expect any request body.
   */
  rescope$Response(params?: Rescope$Params, context?: HttpContext): Observable<StrictHttpResponse<number>> {
    return rescope(this.http, this.rootUrl, params, context);
  }

  /**
   * Rescope all companies.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `rescope$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  rescope(params?: Rescope$Params, context?: HttpContext): Observable<number> {
    return this.rescope$Response(params, context).pipe(
      map((r: StrictHttpResponse<number>): number => r.body)
    );
  }

  /** Path part for operation `companiesControllerGridSearchForOrganizationoptionsOrganizationId()` */
  static readonly CompaniesControllerGridSearchForOrganizationoptionsOrganizationIdPath = '/companies/searchgrid/organization';

  /**
   * Get company grid data in organization page.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `gridSearchForOrganization$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  gridSearchForOrganization$Plain$Response(params?: GridSearchForOrganization$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<MibpGridResponse>> {
    return gridSearchForOrganization$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * Get company grid data in organization page.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `gridSearchForOrganization$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  gridSearchForOrganization$Plain(params?: GridSearchForOrganization$Plain$Params, context?: HttpContext): Observable<MibpGridResponse> {
    return this.gridSearchForOrganization$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<MibpGridResponse>): MibpGridResponse => r.body)
    );
  }

  /**
   * Get company grid data in organization page.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `gridSearchForOrganization()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  gridSearchForOrganization$Response(params?: GridSearchForOrganization$Params, context?: HttpContext): Observable<StrictHttpResponse<MibpGridResponse>> {
    return gridSearchForOrganization(this.http, this.rootUrl, params, context);
  }

  /**
   * Get company grid data in organization page.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `gridSearchForOrganization$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  gridSearchForOrganization(params?: GridSearchForOrganization$Params, context?: HttpContext): Observable<MibpGridResponse> {
    return this.gridSearchForOrganization$Response(params, context).pipe(
      map((r: StrictHttpResponse<MibpGridResponse>): MibpGridResponse => r.body)
    );
  }

  /** Path part for operation `companiesControllerGridSearchoptions()` */
  static readonly CompaniesControllerGridSearchoptionsPath = '/companies/searchgrid';

  /**
   * Get grid data based on grid options.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `gridSearch$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  gridSearch$Plain$Response(params?: GridSearch$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<MibpGridResponse>> {
    return gridSearch$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * Get grid data based on grid options.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `gridSearch$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  gridSearch$Plain(params?: GridSearch$Plain$Params, context?: HttpContext): Observable<MibpGridResponse> {
    return this.gridSearch$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<MibpGridResponse>): MibpGridResponse => r.body)
    );
  }

  /**
   * Get grid data based on grid options.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `gridSearch()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  gridSearch$Response(params?: GridSearch$Params, context?: HttpContext): Observable<StrictHttpResponse<MibpGridResponse>> {
    return gridSearch(this.http, this.rootUrl, params, context);
  }

  /**
   * Get grid data based on grid options.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `gridSearch$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  gridSearch(params?: GridSearch$Params, context?: HttpContext): Observable<MibpGridResponse> {
    return this.gridSearch$Response(params, context).pipe(
      map((r: StrictHttpResponse<MibpGridResponse>): MibpGridResponse => r.body)
    );
  }

  /** Path part for operation `companiesControllerGetid()` */
  static readonly CompaniesControllerGetidPath = '/companies/{id}';

  /**
   * Get company based on id.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `get$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  get$Plain$Response(params: Get$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<Company>> {
    return get$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * Get company based on id.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `get$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  get$Plain(params: Get$Plain$Params, context?: HttpContext): Observable<Company> {
    return this.get$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<Company>): Company => r.body)
    );
  }

  /**
   * Get company based on id.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `get()` instead.
   *
   * This method doesn't expect any request body.
   */
  get$Response(params: Get$Params, context?: HttpContext): Observable<StrictHttpResponse<Company>> {
    return get(this.http, this.rootUrl, params, context);
  }

  /**
   * Get company based on id.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `get$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  get(params: Get$Params, context?: HttpContext): Observable<Company> {
    return this.get$Response(params, context).pipe(
      map((r: StrictHttpResponse<Company>): Company => r.body)
    );
  }

}
