import { Component, Input, OnDestroy, OnInit, QueryList, ViewChildren } from "@angular/core";
import { MibpFilterBase, MibpFilterComponentService } from "root/components/filter";
import { BroadcastService, GlobalConfigService, LocalizationService, LogService, MibpLogger, PartsCatalogueService, ResponsiveEvent } from "root/services";
import { Subscription, firstValueFrom, skip } from "rxjs";
import { DropdownInput } from "root/components";
import { ActivatedRoute, Params } from "@angular/router";
import { MediaApiController } from "root/mibp-openapi-gen/controllers";
import { SearchMediaResultItemVm, SearchMediaResultItemVmSearchResponse, SearchMediaSortBy } from "root/mibp-openapi-gen/models";
import { Page } from "root/components/paginator/page";
import { FormBuilder } from "@angular/forms";

import { MachineFilterComponent, MachineFilterSelection } from "root/components/machine-filter/machine-filter.component";
import { MachineFilterService } from "root/services/machine-filter/machine-filter.service";


@Component({
  selector: 'mibp-electronic-manuals-where-used-item',
  templateUrl: './electronic-manuals-where-used-item.component.html',
  styleUrl: './electronic-manuals-where-used-item.component.scss',
  providers: [MibpFilterComponentService]
})
export class MibpElectronicManualsWhereUsedItemComponent implements OnInit, OnDestroy {

  @Input() productcode: string;
  @Input() showFilters = true;

  private log: MibpLogger;
  protected pageTitle: string;
  protected isLoading = false;
  protected hasLoadingError = false;
  protected filters?: MibpFilterBase[];
  protected filterResetQueryParams = { skip: null };
  protected actAsSub?: Subscription;
  protected machineFilters?: MachineFilterSelection;
  protected pageSizeOptions: DropdownInput[] = [
    {text: '10', value: '10'},
    {text: '50', value: '50'},
    {text: '100', value: '100'},
  ];
  @ViewChildren(MachineFilterComponent) machineFilterComponents: QueryList<MachineFilterComponent>;

  protected currentPageSizeOption: DropdownInput;
  protected sortByOptions: DropdownInput[];
  protected currentSortByOption: DropdownInput;
  protected categoryOptions: DropdownInput[];
  protected languageOptions: DropdownInput[];
  protected machineSerialOptions: DropdownInput[];
  protected modelNameOptions: DropdownInput[];
  protected productGroupOptions: DropdownInput[];
  protected selectedCategies: string[];
  protected selectedLanguages: string[];
  protected selectedMachineSerials: string[];
  protected selectedMachineModels: string[];
  protected selectedtProductGroups: string[];
  protected mobileFiltersCollapsed = true;
  protected hasAnyFiltersActive = false;
  protected clearFilterWasClicked = false;
  private hasAnyNoneMachineFiltersActive = false;
  private hasAnyMachineFiltersActive = false;

  private filterNames = {
    type: 'type',
    category: 'category',
    language: 'language',
    assemblyNumber: 'assemblyNumber',
    machine: 'machine',
    model: 'model',
    group: 'modelGroup',
    subGroup: 'modelSubGup',
    equipmentName: 'machine'
  };

  protected detailsQueryParams: Params;
  protected currentSearchResult?: SearchMediaResultItemVmSearchResponse;
  protected pageItems: SearchMediaResultItemVm[];
  protected totalItems = 8;

  protected resultCountMacros: {
    firstItemIndex: number;
    lastItemIndex: number;
    totalCount: number;
  } = { firstItemIndex: 0, lastItemIndex: 0, totalCount: 0 };

  private currentFilterEquipmentNames: string[];
  private currentFilterGroupNames: string[];
  private currentFilterModels: string[];
  private currentFilterLanguageCodes: string[];
  private currentFilterCategories: string[];
  protected currentTakeOption = 10;
  protected currentSkipOption = 0;

  protected searchFormWhereUsedItem = this.fb.group({
    queryWhereusedItems: []
  });

  private routerParamSub: Subscription;
  private navigationSkipSub?: Subscription;
  private responsiveSub?: Subscription;

  protected sOrSmaller = false;
  constructor(
    protected globalConfig: GlobalConfigService,
    private mediaApi: MediaApiController,
    private broadcast: BroadcastService,
    private machineFilterService: MachineFilterService,
    private fb: FormBuilder,
    log: LogService,
    protected partsCatalogueService: PartsCatalogueService,
    private filterService: MibpFilterComponentService,
    private route: ActivatedRoute,
    private localizationService: LocalizationService
    ) {
    this.log = log.withPrefix('electronic-manuals-where-used-item');
  }



  private onResponsiveBreakpoint(br: ResponsiveEvent): void {
    this.sOrSmaller = br.lteq('s');
  }

  async ngOnInit(): Promise<void> {

    this.productcode =  this.route.parent.snapshot.params.code ?? this.productcode;
    this.responsiveSub = this.broadcast.responsiveBreakpoint.subscribe(br => this.onResponsiveBreakpoint(br));
    this.machineFilters = this.machineFilterService.getInitialMachineFiltersFromRoute();


    this.sortByOptions = [
      {text: this.localizationService.get('ProductSearch_Relevance'), value: 'relevance'},
      {text: this.localizationService.get('Search_SortBy_NewestFirst'), value: 'newest'},
      {text: this.localizationService.get('Search_SortBy_OldestFirst'), value: 'oldest'}
    ];

    this.currentSortByOption = this.sortByOptions[0];

    this.resetPageSizeDropDown(this.currentTakeOption.toString());

    this.actAsSub = this.broadcast.deliverySequence.pipe(skip(1)).subscribe(() => {
      this.currentSkipOption = 0;

      this.clearFilters();
      this.executeSearch('acting as');

    });

    this.filters = this.filterService
      .addDropdownFilter(this.filterNames.category, 'Global_ManualCategory', [])
      .addDropdownFilter(this.filterNames.language, 'UserReg_Language', [])
      .toArray();

    this.pageTitle = this.localizationService.get('ElectronicManuals_RelatedManualsForItem')+ ' ' + this.productcode;

    if (!this.route.snapshot.queryParams.filter) {
      // If we have a filter in query string then the filter component should trigger a change...
      this.executeSearch('initial');
    }
  }

  private resetPageSizeDropDown(pageSize:string): void {
    let supportedPageSize = this.pageSizeOptions.find(item => item.value == pageSize);
    //If selected page size does NOT exist in pageSizeOptions we set a default value.
    if (!supportedPageSize) {
      supportedPageSize = this.pageSizeOptions[0];
    }

    this.currentPageSizeOption = supportedPageSize;
  }


  protected onChangePage(page: Page): void {
    if (page.currentPage >= 0) {
      if (this.currentSkipOption != page.startIndex) {
        this.currentSkipOption = page.startIndex;

        this.executeSearch('changepage');
      }
    }
  }

  protected clearFilters(skipNavigation = false): void {


    this.clearFilterWasClicked = true;

    this.machineFilters = {
      machine: [],
      model: [],
      group: [],
      subgroup: [],
      company:[],
      customer:[],
      operationSite:[] };

    this.machineFilterComponents.toArray().forEach(cmp => {
      cmp.clearFilters(skipNavigation);
    });


    if (this.hasAnyMachineFiltersActive && !this.hasAnyNoneMachineFiltersActive) {
      this.executeSearch('clear filters with only machine filters active');
    }

    this.currentSkipOption = 0;
  }

  private mapFacetValuesToDropdownItems(filterName: string, facetKey: string, textResourcePattern: string = null): DropdownInput[] {
    const ddInputs = this.currentSearchResult.facets.find(fc => fc.key == facetKey).facetValues.map(val => {{
      let dropdownText = val.value;

      if (textResourcePattern) {
        dropdownText = this.localizationService.get(textResourcePattern.replace('{value}', dropdownText));
      }

      return {
        text: dropdownText,
        value: val.value,
        count: val.count
      } as DropdownInput;
    }});

    if (ddInputs && ddInputs.length > 0) {
      return ddInputs;
    }
  }

  protected submitSearchForm(): void {
    this.currentSkipOption = 0;

    this.executeSearch('submitsearch');
  }

  protected async executeSearch(caller: string): Promise<void> {
    this.isLoading = true;
    this.hasLoadingError = false;

    this.log.info('executeSearch', caller);
    this.log.info('machinefilters', this.machineFilters);

    let sortBy: SearchMediaSortBy = SearchMediaSortBy.Relevance;

    if (this.currentSortByOption.value == 'newest') {
      sortBy = SearchMediaSortBy.NewestFirst;
    } else if (this.currentSortByOption.value == 'oldest') {
      sortBy = SearchMediaSortBy.OldestFirst;
    }

    this.hasAnyMachineFiltersActive = this.machineFilters?.group?.length > 0
      || this.machineFilters?.machine?.length > 0
      || this.machineFilters?.model?.length > 0;

    this.hasAnyNoneMachineFiltersActive = (this.currentFilterLanguageCodes?.length || 0) > 0 ||
      (this.currentFilterModels?.length || 0) > 0 ||
      (this.currentFilterCategories?.length || 0) > 0;

    this.hasAnyFiltersActive = this.searchFormWhereUsedItem.value.queryWhereusedItems?.trim() ||
    this.hasAnyMachineFiltersActive || this.hasAnyNoneMachineFiltersActive;

    const request = {
      skip: this.currentSkipOption,
      take: this.currentTakeOption,
      query: this.searchFormWhereUsedItem.value.queryWhereusedItems?.trim(),
      equipmentNames: this.selectedMachineSerials,
      modelNames: this.selectedMachineModels,
      groupNames: this.selectedtProductGroups,
      languageCodes: this.selectedLanguages,
      categories: this.selectedCategies,
      sortBy: sortBy,
      productCode: this.productcode
    };

    try {

      await firstValueFrom(this.mediaApi.search({
        body: request
      })).then((resultData) => {

        this.currentSearchResult = resultData;

        this.resultCountMacros = {
          totalCount: this.currentSearchResult.count,
          firstItemIndex: this.currentSkipOption + 1,
          lastItemIndex: this.currentSkipOption + this.currentTakeOption > this.currentSearchResult.count ? this.currentSearchResult.count : this.currentSkipOption + this.currentTakeOption
        };

        this.pageItems = this.currentSearchResult.items.map<SearchMediaResultItemVm>(media => {
          return {
            ...media,
            equipmentRoute: media.equipmentId ? ['/', this.localizationService.getLang(), 'myfleet', 'machines', media.equipmentId.toString()] : null,
            mediaRoute: media ? ['/', this.localizationService.getLang(), 'myfleet', 'electronic-manuals', media.equipmentId.toString(), media.identifier,  ] : null,

          };
        });

        if(this.pageItems.length == 0){
          this.selectedCategies = this.selectedLanguages = this.selectedMachineModels = this.selectedMachineSerials = this.selectedtProductGroups = [];
        }

        this.categoryOptions = this.mapFacetValuesToDropdownItems(this.filterNames.category, 'Categories');
        this.languageOptions = this.mapFacetValuesToDropdownItems(this.filterNames.language, 'LanguageCode', 'Global_LanguageName_{value}');
        this.machineSerialOptions = this.mapFacetValuesToDropdownItems(this.filterNames.machine, 'EquipmentNameAndAlias');
        this.productGroupOptions = this.mapFacetValuesToDropdownItems(this.filterNames.group, 'ProductGroupName');
        this.modelNameOptions = this.mapFacetValuesToDropdownItems(this.filterNames.model, 'ProductModelName');
      });
    } catch (e) {
      this.hasLoadingError = true;
      this.isLoading = false;
      this.log.error(`Error calling search endpoint`, e);
    } finally {
      this.isLoading = false;
    }
  }

  private hashMachineFilters(filters: MachineFilterSelection): string {
    return filters ? JSON.stringify(filters) : '';
  }

  protected onSortByChange(e: DropdownInput): void {
    this.changeSortBy(e);
  }

  private changeSortBy(ddlInput: DropdownInput) {
    this.currentSkipOption = 0;
    this.currentSortByOption = ddlInput;
    this.currentSortByOption = this.sortByOptions.find(o => o.value == ddlInput.value);

    this.executeSearch('sortbychanged');
  }

  protected onPageSizeChange(e: DropdownInput): void {
    this.currentTakeOption = parseInt(e.value, 10);
    this.currentSkipOption = 0;


    this.executeSearch('pagesizechanged');
  }

  protected onCategoryChange(e: DropdownInput[]): void {
    this.selectedCategies = e.map(e => e.value);
    this.currentSkipOption = 0;
    this.executeSearch('onCategoryChange');
  }
  protected onLanguageChange(e: DropdownInput[]): void {
    this.selectedLanguages = e.map(e => e.value);
    this.currentSkipOption = 0;
    this.executeSearch('onLanguageChange');
  }
  protected onMachineSerialChange(e: DropdownInput[]): void {
    this.selectedMachineSerials = e.map(e => e.value);
    this.currentSkipOption = 0;
    this.executeSearch('onMachineSerialChange');
  }
  protected onMachineModelChange(e: DropdownInput[]): void {
    this.selectedMachineModels = e.map(e => e.value);
    this.currentSkipOption = 0;
    this.executeSearch('onMachineModelChange');
  }
  protected onProductGroupChange(e: DropdownInput[]): void {
    this.selectedtProductGroups = e.map(e => e.value);
    this.currentSkipOption = 0;
    this.executeSearch('onProductGroupChange');
  }

  ngOnDestroy(): void {
    this.actAsSub?.unsubscribe();
    this.routerParamSub?.unsubscribe();
    this.navigationSkipSub?.unsubscribe();
    this.responsiveSub?.unsubscribe();
  }
}
