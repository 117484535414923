import { AfterViewInit, Component, ElementRef, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewChild } from "@angular/core";
import { BroadcastService, MibpHttpApi, ScrollToService } from 'root/services';
import { PartsManualIllustrationImage } from "../parts-manual.types";
import { Subscription } from 'rxjs/internal/Subscription';
import { BomAttachmentVm } from './../../../mibp-openapi-gen/models/bom-attachment-vm';
import { Hotpoint, Hotpointlink, MediaFolderFileVersion } from 'root/mibp-openapi-gen/models';
import { ActivatedRoute, Params, Router } from "@angular/router";


export interface PartManualsIllustration {
  name: string;
  images: BomAttachmentVmExtended[];
  hotPoints?: HotPointExtended[];
  hotPointsLink?: Hotpointlink[];
}

export interface HotPointExtended extends Hotpoint {
  isSelected: boolean;
}

export interface BomAttachmentVmExtended extends BomAttachmentVm {
  htmlImage?: HTMLImageElement;
}

@Component({
  selector: 'mibp-parts-manual-illustration',
  styleUrls: ['./parts-manual-illustration.component.scss'],
  templateUrl: './parts-manual-illustration.component.html'
})
export class MibpPartsManualIllustrationComponent implements OnChanges, OnInit, OnDestroy, AfterViewInit {

  @Input() image?: PartsManualIllustrationImage;
  @Input() imageIsMissing = false;
  @Input() illustrations: PartManualsIllustration[];
  @ViewChild('imageContainer') imageContainerRef: ElementRef<HTMLDivElement>;
  @ViewChild('canvasHotpoints') canvasRef: ElementRef<HTMLCanvasElement>;
  
  readonly zoomStep = 100;
  readonly maxZoom: number = 2000; // Maximum zoom level
  readonly minZoom: number = -200; // Minimum zoom level

  private currentAttachment: BomAttachmentVmExtended;
  private ctx: CanvasRenderingContext2D;
  private canvasHeight: number;
  private canvasWidth: number;
  private screenSizeSubscription?: Subscription;
  private zoom = 0;
  private isMouseDown: boolean = false; // Track if the mouse is pressed
  private lastMouseX: number = 0;      // Store the last mouse X position
  private lastMouseY: number = 0;      // Store the last mouse Y position
  private imageOffsetX: number = 0;    // X offset for the image panning
  private imageOffsetY: number = 0;    // Y offset for the image panning  
  private imageAreaInitialized = false;
  private queryParamSub: Subscription;  
  private heightFactor: number;
  private selectedNode: any;
  
  private get htmlCanvas() {
    return this.canvasRef?.nativeElement;
  }

  private get htmlImageContainer() {
    return this.imageContainerRef?.nativeElement;
  }

  protected showImage = false;  
  protected currentIllustrationIndex = 0;
  protected isLoadingImage = false;
  protected isPanning = false;

  constructor(
    private httpApi: MibpHttpApi,
    private broadcast: BroadcastService,
    private route: ActivatedRoute,
    private scrollToService: ScrollToService,
    private router: Router) { }

  ngOnInit(): void {
    this.screenSizeSubscription = this.broadcast.screenSize.subscribe(x => this.calculateImageContainerMaxWidth());
    this.queryParamSub = this.route.queryParams.subscribe((params) => this.highlightHotPoint(params));
  }

  ngOnDestroy(): void {
    this.screenSizeSubscription?.unsubscribe();
    this.queryParamSub?.unsubscribe();

    this.htmlCanvas.removeEventListener('click', this.handleClick.bind(this));
    this.htmlCanvas.removeEventListener('mousemove', this.handleMouseMove.bind(this));
    this.htmlCanvas.removeEventListener('mousedown', this.onMouseDown.bind(this));
    this.htmlCanvas.removeEventListener('mouseup', this.onMouseUp.bind(this));
    this.htmlImageContainer.removeEventListener('wheel', this.handleWheel.bind(this));
    this.htmlImageContainer.removeEventListener('mouseleave', this.onMouseLeave.bind(this));
  }

  highlightHotPoint(params: Params) {
    const nodeId = params['node'];
    if (!nodeId) {
      this.selectedNode = 0;
      return;
    }

    const matchingNodes = this.illustrations[this.currentIllustrationIndex].hotPoints?.filter(hp => hp.item == nodeId);   

    if (matchingNodes && matchingNodes.length > 0) {      
        this.selectedNode = nodeId;
               
        if (this.ctx !== undefined) {
          this.clearAllSelectedNodes();
          
          // Set the found nodes as selected
          matchingNodes.forEach(n => n.isSelected = true);          
          this.drawHotPoints(this.selectedNode);
        }
      } 
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.illustrations && changes.illustrations.currentValue &&
      !changes.illustrations.firstChange && this.illustrations?.length > 0) {
      this.loadImage(0);
      this.calculateImageContainerMaxWidth();
    }

    if (this.route.snapshot.queryParams.node) {
      const initiallySelectedHotpointItem = this.route.snapshot.queryParams.node;
      this.illustrations?.filter(f => f.hotPoints?.filter(f => f.item == initiallySelectedHotpointItem).forEach(h => h.isSelected = true));
    }

    if (!this.illustrations) {
      this.showImage = false;
    }
  }

  ngAfterViewInit() {
    this.loadImage(0);
    this.calculateImageContainerMaxWidth();
    this.attachEventListeners();
  }

  nextImage(): void {
    if (this.currentIllustrationIndex < this.illustrations.length - 1) {
      this.clearAllSelectedNodes();
      this.router.navigate([], { queryParams: { node: null }, queryParamsHandling: 'merge', });
      this.loadImage(this.currentIllustrationIndex + 1);
    }
  }

  prevImage(): void {
    if (this.currentIllustrationIndex > 0) {
      this.clearAllSelectedNodes();
      this.router.navigate([], { queryParams: { node: null }, queryParamsHandling: 'merge', });
      this.loadImage(this.currentIllustrationIndex - 1);
    }
  }

  private clearAllSelectedNodes(): void {
    this.illustrations.forEach(ill => ill.hotPoints?.forEach(hp => hp.isSelected = false));
  }

  private calculateImageContainerMaxWidth(): void {
    if (this.htmlImageContainer) {
      const rect = this.htmlImageContainer.getBoundingClientRect();
      const maxWidth = window.innerWidth - rect.left - 104;
      this.htmlImageContainer.style.maxWidth = `${maxWidth}px`;
    }
  }

  protected zoomIn(): void {
    if (this.zoom >= this.maxZoom) {
      return;
    }

    this.zoom += this.zoomStep;
    this.loadImage(this.currentIllustrationIndex, MediaFolderFileVersion.OriginalFile);    
    this.drawHotPoints(null);
  }

  protected zoomOut(): void {
    if (this.zoom <= this.minZoom) {
      return;
    }
    
    this.zoom -= this.zoomStep;
    this.loadImage(this.currentIllustrationIndex, MediaFolderFileVersion.OriginalFile);
    this.drawHotPoints(null);
  }

  protected resetZoom(): void {
    this.router.navigate([], { queryParams: { node: null }, queryParamsHandling: 'merge' });
    this.loadImage(this.currentIllustrationIndex, MediaFolderFileVersion.WebImage, null, true);
  }

  private resetIllustration(): void {
    this.isMouseDown = false;
    this.lastMouseX = 0;
    this.lastMouseY = 0;
    this.imageOffsetX = 0;
    this.imageOffsetY = 0;    
    this.zoom = 0;
    this.selectedNode = 0;

    this.clearAllSelectedNodes();
    
    const canvas = this.canvasRef.nativeElement;
    canvas.style.transform = '';
  }

  private loadImage(index: number, version = MediaFolderFileVersion.WebImage, selectedNode: any = null, reset = false): void {    
    if (this.currentIllustrationIndex != index || reset) {
      this.resetIllustration();
    }

    this.imageAreaInitialized = this.currentIllustrationIndex == index && this.imageAreaInitialized;
    this.currentIllustrationIndex = index;
    this.isLoadingImage = true;    
    this.showImage = false;    

    const originalAttachment = this.illustrations[index].images.find(i => i.version === MediaFolderFileVersion.OriginalFile);

    // If we have the original -> lets use it directly
    this.currentAttachment = originalAttachment.htmlImage ? originalAttachment : this.illustrations[index].images.find(i => i.version === version);

    if (!this.currentAttachment.htmlImage) {
      this.currentAttachment.htmlImage = new Image();
      this.currentAttachment.htmlImage.onload = () => {
        this.setupCanvas(originalAttachment.height, originalAttachment.width, selectedNode);
        this.isLoadingImage = false;
        this.showImage = true;
      };

      //We didn´t have the image for this attachment -> fetch it
      this.currentAttachment.htmlImage.src = this.httpApi.resolveUriWithJwtToken(`/billofmaterial/images/${this.currentAttachment.id}/raw`);
    } else {
      this.setupCanvas(originalAttachment.height, originalAttachment.width, selectedNode);
      this.isLoadingImage = false;
      this.showImage = true;
    }
    
    if (!this.imageAreaInitialized || reset) {
      this.imageAreaInitialized = true;

      setTimeout(() => {
        this.calculateImageContainerMaxWidth();        
      }, 150);
    }    
  }

  private setupCanvas(originalAttachmentHeight: number, originalAttachmentWidth: number, selectedNode: any = null): void {    
    this.ctx = this.htmlCanvas.getContext('2d');

    // Set canvas dimensions and scaling factors
    this.htmlCanvas.height = this.htmlImageContainer?.getBoundingClientRect().height + this.zoom;
    this.htmlCanvas.width = (this.htmlCanvas.height * originalAttachmentWidth) / originalAttachmentHeight;
    this.canvasHeight = this.htmlCanvas.height;
    this.canvasWidth = this.htmlCanvas.width;
    this.heightFactor = this.canvasHeight / originalAttachmentHeight;

    // Clear canvas before redrawing
    this.ctx.clearRect(0, 0, this.canvasWidth, this.canvasHeight);

    // Draw the base image
    this.ctx.imageSmoothingQuality = "high";
    this.ctx.drawImage(this.currentAttachment.htmlImage, 0, 0, this.htmlCanvas.width, this.htmlCanvas.height);
    this.drawHotPoints(selectedNode);
  }

  private attachEventListeners(): void {
    this.htmlCanvas.addEventListener('click', this.handleClick.bind(this));
    this.htmlCanvas.addEventListener('mousemove', this.handleMouseMove.bind(this));
    this.htmlCanvas.addEventListener('mousedown', this.onMouseDown.bind(this));
    this.htmlCanvas.addEventListener('mouseup', this.onMouseUp.bind(this));
    this.htmlImageContainer.addEventListener('wheel', this.handleWheel.bind(this));
    this.htmlImageContainer.addEventListener('mouseleave', this.onMouseLeave.bind(this));
  }

  private drawCircle(hotPoint: HotPointExtended, item: string): void {
    this.ctx.beginPath();
    this.ctx.arc(
      hotPoint.x * this.heightFactor,
      hotPoint.y * this.heightFactor,
      hotPoint.radius * this.heightFactor, 0, 2 * Math.PI
      );
    this.ctx.fillStyle = (item !== null && hotPoint.item == item) || hotPoint.isSelected ? '#1441F5' : '#101010';
    this.ctx.fill();
    this.ctx.strokeStyle = '#000000';
    this.ctx.stroke();
    this.ctx.fillStyle = '#FFFFFF';
    this.ctx.font = `${hotPoint.radius * this.heightFactor}px Arial`;
    this.ctx.textAlign = 'center';
    this.ctx.textBaseline = 'middle';
    this.ctx.fillText(hotPoint.item.toString(), hotPoint.x * this.heightFactor, hotPoint.y * this.heightFactor);
    this.ctx.closePath();
  }

  private drawSquare(hotPoint: HotPointExtended, item: string): void {
    const size = hotPoint.radius * 2 * this.heightFactor; // Square side length
    const topLeftX = (hotPoint.x - hotPoint.radius) * this.heightFactor; // Top-left X coordinate
    const topLeftY = (hotPoint.y - hotPoint.radius) * this.heightFactor; // Top-left Y coordinate
    
    this.ctx.beginPath();
    this.ctx.rect(topLeftX, topLeftY, size, size);
    this.ctx.fillStyle = (item !== null && hotPoint.item == item) || hotPoint.isSelected ? '#1441F5' : '#101010';
    this.ctx.fill();
    this.ctx.strokeStyle = '#000000';
    this.ctx.stroke();
    // Draw the hotPoint.item inside the square
    this.ctx.fillStyle = '#FFFFFF';
    this.ctx.font = `${hotPoint.radius * this.heightFactor}px Arial`;
    this.ctx.textAlign = 'center';
    this.ctx.textBaseline = 'middle';
    this.ctx.fillText(hotPoint.item.toString(), hotPoint.x * this.heightFactor, hotPoint.y * this.heightFactor);
    this.ctx.closePath();
  }

  private handleClick(event: MouseEvent) {    
    const rect = this.htmlCanvas.getBoundingClientRect();
    const x = event.clientX - rect.left;
    const y = event.clientY - rect.top;
    this.scrollToService.stopNextScrollToTop();

    // Find the item for the clicked hotpoint
    const clickedHotpoint = this.illustrations[this.currentIllustrationIndex]?.hotPoints?.find(
      (circle) =>
        Math.pow(x - circle.x * this.heightFactor, 2) +
        Math.pow(y - circle.y * this.heightFactor, 2) <=
        Math.pow(circle.radius * this.heightFactor, 2)
    );

    // If no hotpoint is clicked -> return
    if (!clickedHotpoint?.item) {      
      return;
    }

    const selectedHotpointId = clickedHotpoint?.item;

    // Check if the item is currently selected
    const isCurrentlySelected = this.illustrations[this.currentIllustrationIndex]?.hotPoints?.filter(circle => circle.item == selectedHotpointId && circle.isSelected).length > 0;
    this.illustrations[this.currentIllustrationIndex].hotPoints.filter(hp => hp.item == selectedHotpointId).forEach(hp => hp.isSelected = true);

    if (!isCurrentlySelected) {
      this.drawHotPoints(selectedHotpointId);
      this.router.navigate([], { queryParams: { node: selectedHotpointId }, queryParamsHandling: 'merge' });
    } else {
      this.router.navigate([], { queryParams: { node: selectedHotpointId }, queryParamsHandling: 'merge' });
      this.drawHotPoints(null);
    }
  }

  private handleMouseMove(event: MouseEvent) {
    if (this.isMouseDown) {
      const deltaX = event.clientX - this.lastMouseX;
      const deltaY = event.clientY - this.lastMouseY;

      this.imageOffsetX += deltaX;
      this.imageOffsetY += deltaY;
      this.htmlCanvas.style.transform = `translate(${this.imageOffsetX}px, ${this.imageOffsetY}px)`;
      this.lastMouseX = event.clientX;
      this.lastMouseY = event.clientY;      

      return;
    }   

    const rect = this.htmlCanvas.getBoundingClientRect();
    const x = event.clientX - rect.left;
    const y = event.clientY - rect.top;
    let hoverIndex = -1;
    let activeHotPoint: HotPointExtended;

    // Find the hovered hotpoint
    this.illustrations[this.currentIllustrationIndex]?.hotPoints?.forEach((hotPoint, index) => {
      if (
        Math.pow(x - hotPoint.x * this.heightFactor, 2) +
        Math.pow(y - hotPoint.y * this.heightFactor, 2) <=
        Math.pow(hotPoint.radius * this.heightFactor, 2)
      ) {
        hoverIndex = index;
        activeHotPoint = hotPoint;
      }
    });

    // Update cursor style
    if (hoverIndex >= 0) {      
      this.drawHotPoints(activeHotPoint.item);
    }
    else {
      this.drawHotPoints(null);
    }
  }

  private drawHotPoints(item: string): void {
    this.illustrations[this.currentIllustrationIndex]?.hotPoints?.forEach(hotPoint => {
      const isLinked = this.illustrations[this.currentIllustrationIndex]?.hotPointsLink?.some(
        link => link.item === hotPoint.item
      );

      if (isLinked) {
        this.drawSquare(hotPoint, item);
      } else {
        this.drawCircle(hotPoint, item);
      }
    });
  }

  private onMouseDown(event: MouseEvent): void {    
    if (event.button === 0) { // Check if left mouse button is pressed
      this.isMouseDown = true;
      this.lastMouseX = event.clientX;
      this.lastMouseY = event.clientY;

      // Delay panning to prevent grabbing cursor from flickering past on click
      setTimeout(() => {
        if (this.isMouseDown) {
          this.isPanning = true;
        }
      }, 150);
    }
  }

  private onMouseUp(event: MouseEvent): void {
    if (event.button === 0) { // Check if left mouse button is released
      this.isMouseDown = false;
      this.isPanning = false;
    }
  }

  private onMouseLeave(event: MouseEvent): void {    
    this.isMouseDown = false;
    this.isPanning = false;
  }

  private handleWheel(event: WheelEvent): void {
    // Prevent the default scroll behavior
    event.preventDefault();
    
    if (event.deltaY > 0) {
      this.zoomOut();
    } else {
      this.zoomIn();
    }
  }
}
