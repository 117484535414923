import { AbstractControl, AsyncValidatorFn } from "@angular/forms";
import { HttpResponse } from "@microsoft/signalr";
import { MapsApiController } from "root/mibp-openapi-gen/controllers";
import { CacheScope, ClientSideCacheService } from "root/services";
import { catchError, map, of, switchMap, tap, timer } from "rxjs";

interface AddressLookupCacheItem {
  key: string;
  exists: boolean;
}

/***
 * Async address validator to make sure post code / city combination exists
 */
export function addressLookupValidator(
  mapsApi: MapsApiController,
  cache: ClientSideCacheService
): AsyncValidatorFn {
  return (control: AbstractControl) => {

    const city = control.value.city?.trim();
    const postalCode = control.value.postalCode;
    const countryCode = control.value.countryCode;

    if (!countryCode || !city || !postalCode) {
      return Promise.resolve(null);
    }

    const cacheKey = `deliveryaddresslookups`;
    const lookupKey = `${countryCode}/${city}/${postalCode}`;

    // Some simple caching so we don't call backend too much
    let cachedLookups = cache.get<AddressLookupCacheItem[]>(cacheKey);

    if (cachedLookups && Array.isArray(cachedLookups)) {
      const cachedItem = cachedLookups.find(addr => addr.key == lookupKey);
      if (cachedItem) {
        return Promise.resolve(cachedItem.exists ? null : {addressLookup: true});
      }
    } else {
      cachedLookups = [];
    }

    return timer(500).pipe(
      switchMap(() =>
        mapsApi.deliveryAddressExists({
          cityName: city,
          postalCode: postalCode,
          countryCode
        })
          .pipe(
            tap(result => {

              cachedLookups.unshift({
                key: lookupKey,
                exists: result.exists
              });

              cachedLookups = cachedLookups.slice(0, 20);
              cache.add(cacheKey, cachedLookups, null, CacheScope.GlobalStorage);
            }),
            map((result) => !result.exists ? {addressLookup: true} : null),
            catchError((httpResponse: HttpResponse) => {
              const d = httpResponse;
              return of({ addressLookupException: true });
            })
          )
      )
    );
  };
}
