import { Component, Input, OnChanges, SimpleChanges } from "@angular/core";
import { OperationsApiController } from "root/mibp-openapi-gen/controllers";
import { LogService, MibpLogger, ToastService } from "root/services";
import { ToastType } from "root/services/toast-service/toast.enum";
import { firstValueFrom } from "rxjs";

@Component({
  selector: 'mibp-blob-log-view-component',
  templateUrl: './blob-log-view.component.html',
  styleUrl: './blob-log-view.component.scss'
})
export class MibpBlobLogViewComponent implements OnChanges {
  @Input() blobLogFile: string;

  protected logRows: {
    value: string;
    timestampString?: string;
    timestamp?: Date;
    classNames?: string[];
  }[];
  protected rawLog: string;
  private log: MibpLogger;
  protected showLog = false;
  protected isLoading = false;

  constructor(private operationsApi: OperationsApiController, private toast: ToastService, log: LogService) {
    this.log = log.withPrefix('blob-log-view');
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!changes.blobLogFile.currentValue) {
      this.logRows = [];
    }
  }

  protected copyToClipboard(): void {
    try {
      const input = document.createElement('textarea');
      input.style.opacity = '0';
      input.style.position = 'absolute';
      input.style.zIndex = '0';
      input.value = this.rawLog;
      document.body.appendChild(input);

      /* Select the text field */
      input.select();
      input.setSelectionRange(0, 99999); /* For mobile devices */

      /* Copy the text inside the text field */
      document.execCommand("copy");

      document.body.removeChild(input);

      this.toast.showText('Copied to Clipboard', {type: ToastType.Success});
    } catch(e) {
      this.toast.showText('Error copying to Clipboard', {type: ToastType.Error});
    }
  }

  protected downloadLog(): void {
    const link = document.createElement("a");
    const file = new Blob([this.rawLog], { type: 'text/plain' });
    link.href = URL.createObjectURL(file);
    const extension = /\.[a-z0-9]+$/g.exec(this.blobLogFile);
    if (extension) {
      link.download = this.blobLogFile.substring(0, extension.index).replace(/[^a-zA-Z0-9_-]/g, '_') + extension[0];
    } else {
      link.download = 'log.txt';
    }
    link.click();
    URL.revokeObjectURL(link.href);
  }



  public refresh(): void {
    if (this.showLog && !this.isLoading) {
      this.loadLog();
    }
  }

  protected async loadLog(): Promise<void> {

    if (this.isLoading) {
      return;
    }

    this.showLog = true;
    this.isLoading = true;

    try {
      const logFileContent = await firstValueFrom(this.operationsApi.getBlobLogContent$Plain({
        filename: this.blobLogFile
      }));

      this.splitLogIntoRows(logFileContent);

    } catch (e) {
      this.log.error('Error loading log file content', e);
    }

    this.isLoading = false;

  }

  private splitLogIntoRows(rawLogContent: string): void {
    this.rawLog = rawLogContent;
    this.logRows = rawLogContent.split('\n').map(line => {
      const timestampRegex = /^\[(\d{4}.*?)\] /.exec(line);
      let timestampString: string;

      let lineValue = line;
      let timestamp: Date;

      if (timestampRegex) {
        timestampString = timestampRegex[1];
        lineValue = line.substring(timestampRegex[0].length);
        timestamp = new Date(timestampString);
      }



      return {
        timestamp: timestamp,
        value: lineValue,
        timestampString: timestampString,
        classNames: [
          timestampString && (line?.toLowerCase()?.includes('exception') || line?.toLowerCase()?.includes('error')) ? 'log-row--error' : null,
          !timestampString ? 'log-row--raw-data' : null
        ].filter(Boolean)
      };
    });
  }



}
