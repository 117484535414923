<div class="mibp-datetimepicker" [class.block]="displayBlock"
[class.mibp-datetimepicker--clear]="!hideClearIcon"
[class.mibp-datetimepicker--no-clear]="hideClearIcon">

  <mat-form-field>
    <input matInput type="datetime-local"
    [disabled]="isDisabled"
    [className]="stylingName"
    [(ngModel)]="dateValue"
    [min]="minDateValue"
    [max]="maxDateValue"
    (ngModelChange)="valuechange($event)">
    <span [hidden]="!dateValue" translate="no" class="material-icon clear-icon"
        (click)="dateValue = null; clear(); $event.stopPropagation(); false">close</span>
  </mat-form-field>
</div>
