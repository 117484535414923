
import { Router, NavigationEnd  } from '@angular/router';

import { Injectable } from '@angular/core';
import { LogService, MibpLogger } from '../logservice';
import { BiDashboardApiController } from 'root/mibp-openapi-gen/services/bi-dashboard-api-controller';
import { firstValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class NavigationLogService {

  previousUrl: string = null;
  log: MibpLogger;

  constructor(router: Router, private biDashboardApi: BiDashboardApiController, logger: LogService) {
    this.log = logger.withPrefix('navigation-log');
  }

  private tryGetReferrer() {
    if (this.previousUrl !== null) {
      return this.previousUrl;
    } else if (document.referrer) {
      return document.referrer;
    }
    return null;
  }

  logPageView(url: string): void {
    if (url.length > 512) {
      return;
    }
    const referrer = this.tryGetReferrer();
    url = `${window.location.protocol }//${window.location.host}${url}`;

    firstValueFrom(this.biDashboardApi.pageView({url: url, referrer: referrer}))
      .then(()=>{})
      .catch(error => {
        this.log.error(`Could not send PageViewEvent for ${url}`, error);
      });

    this.previousUrl = url;
  }



}
