import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from "@angular/core";
import { SafeHtml, DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'mibp-changelog-change',
  templateUrl: './changelog-change.component.html',
  styleUrls: ['./changelog-change.component.scss']
})
export class  MibpChangelogChangeComponent implements OnChanges {

  @Input() type: 'new' | 'improvement';
  @Input() description: string;

  // When clickId is provided the entire component can be clickable. Used in administration.
  @Input() clickId: number;
  @Output() clickChange = new EventEmitter<number>();

  safeDescription?: SafeHtml;

  constructor(private sanitizer: DomSanitizer) {}

  triggerClick($event: MouseEvent): void {

    // Do not trigger click if user clicked on a link
    if (($event.target as HTMLElement).localName === 'a') {
      return;
    }

    if (this.clickId) {
      this.clickChange?.emit(this.clickId);
    }
  }

  ngOnChanges(changes: SimpleChanges): void {

    if (changes.description) {
      this.safeDescription = this.sanitizer.bypassSecurityTrustHtml(changes.description.currentValue);
    }

  }



}

