/***
 * Product Weight component will take initial weight value and product code.
 * It will then listen for P&A events and update weight if an event is triggered
 * It will always show 0 in weight if given weight values are null or undefined
 */
import { Component, Input, OnChanges, SimpleChanges } from "@angular/core";

 @Component({
   selector: 'mibp-product-weight',
   templateUrl: './product-weight.component.html',
   styleUrls: ['./product-weight.component.scss']
 })
export class MibpProductWeightComponent implements OnChanges {

  currentWeight = 0;
  updatedFromEvent = false;
  originalWeight = 0;

   @Input() weight?: number;
   @Input() productCode?: string;

   constructor() {}

   ngOnChanges(changes: SimpleChanges): void {

     if (typeof changes.weight !== 'undefined') {
       this.currentWeight = changes.weight.currentValue;
       this.originalWeight = this.currentWeight;
     }

     if (changes.productCode) {
       this.updatedFromEvent = false;
       // If an object was set as productCode, try to read code or productCode from it
       if (typeof changes.productCode?.currentValue === 'object') {
         if (typeof changes.productCode.currentValue.productCode === 'string') {
           this.productCode = changes.productCode.currentValue.productCode;
         } else if (typeof changes.productCode?.currentValue.code === 'string') {
           this.productCode = changes.productCode.currentValue.code;
         }
       }
     }
   }
}
