<mibp-page-support-menu [items]="supportMenu" (action)="onSupportMenuAction($event)"></mibp-page-support-menu>

<mibp-page-load-error *ngIf="hasError && !isUnauthorized"></mibp-page-load-error>

<div *ngIf="isUnauthorized">
  <mibp-system-message #type [messageType]="type.Types.Error">
    <h2>
      <mibp-resource-string *ngIf="isUnauthorized" key="Global_Unauthorized"></mibp-resource-string>
    </h2>
  </mibp-system-message>
</div>

<mibp-breadrumbs-manual-control #breadcrumbControl></mibp-breadrumbs-manual-control>

<mibp-loader *ngIf="isLoading"></mibp-loader>

<div *ngIf="equipmentId && !hasError && !isLoading">
  <div class="header-container">
    <div>
      <button (click)="toggleTreeview()" class="my-button my-button--secondary my-button--fill" #treeViewToggleButton><span translate="no"
          class="material-icon material-icon--fill material-icon--strong">toc</span>&nbsp;<mibp-resource-string key="Global_Navigaton_Header"></mibp-resource-string></button>
      <h3 class="my-header">{{ partsManual.mediaName }}</h3>
    </div>
    <button *ngIf="enableReportPartsManualSupportCase" class="my-button my-button--secondary my-button--fill" (click)="showReportErrorModal()"><mibp-resource-string
      key="PartsManualReport_Report"></mibp-resource-string></button>
  </div>

  <div class="treeview-container" [hidden]="!showTreeview" #treeViewContainer>
    <div class="treeview-container__title">
      <h3 class="my-header"><mibp-resource-string key="Global_Navigaton_Header"></mibp-resource-string></h3>
      <span translate="no" class="material-icon material-icon--fill" (click)="toggleTreeview()">close</span>
    </div>    
  
    <div class="treeview-container__treeview">
      <mibp-parts-manual-treeview (pageSelected)="loadPage($event)" (searchCompleted)="handleSearchCompleted($event)" [mediaIdentifier]="mediaIdentifier"
      [initialFolderId]="initialFolderId"></mibp-parts-manual-treeview>
    </div>    
  </div>

  <main>
    <mibp-parts-manual-folder-details (itemSelected)="loadTree()" [mediaFolderId]="initialFolderId" [mediaId]="mediaId"
      [mediaIdentifier]="mediaIdentifier" [isFolderInSearchResult]="isActiveFolderInSearchResult" [searchQuery]="searchQuery"></mibp-parts-manual-folder-details>
    
      <mibp-tabs *ngIf="!initialFolderId">
        <mibp-tab [resourceKey]="'PartsManual_Tab_ChaptersPagesTitle'">
          <div class="my-card">
            <p>
              <mibp-resource-string key="PartsManual_StartPage_SelectChapterPageText"></mibp-resource-string>
            </p>
          </div>
        </mibp-tab>
        <mibp-tab [resourceKey]="'ElectronicManuals_RelatedMedia'">      
          <mibp-electronic-manual-related [mediaId]="mediaId"></mibp-electronic-manual-related>        
        </mibp-tab>
      </mibp-tabs>      
  </main>

</div>

<mibp-electronics-manual-report-error-dialog #reportErrorDialog
  [request]="reportErrorDto"></mibp-electronics-manual-report-error-dialog>