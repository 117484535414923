import { Injectable } from "@angular/core";
import { SessionApiController } from "root/mibp-openapi-gen/controllers";
import { MibpSessionStatus, MibpSessionViewModel, PunchoutProfileViewModel, SessionDeliverySequenceViewModel, UserStatus } from "root/mibp-openapi-gen/models";
import { MibpGetUserSessionResponseViewModel } from "root/mibp-openapi-gen/models/mibp-get-user-session-response-view-model";
import { BehaviorSubject, Observable, firstValueFrom } from "rxjs";
import { B2CAccount, BroadcastService, LoaderService, LogService, MibpLogger, NoticebarService } from "..";
import { NoticeType } from "root/components/noticebar/noticebar.enum";
import { MySandvikFeatures } from "../permission";
import { Router } from "@angular/router";

@Injectable({
  providedIn: 'root'
})
export class MibpSessionService {

  private currentSessionResponseViewModel?: MibpGetUserSessionResponseViewModel;
  private log: MibpLogger;
  private subject = new BehaviorSubject<MibpSessionViewModel>(null);
  private activeDeliverySequenceSubject = new BehaviorSubject<SessionDeliverySequenceViewModel>(null);
  private b2cAccount?: B2CAccount;
  private pingMs = (2 * 60) * 1000; // Ping every 2 minutes

  constructor(private sessionController: SessionApiController,
    private loader: LoaderService,
    private noticebarService: NoticebarService,
    private broadcast: BroadcastService,
    private router: Router,
    logger: LogService) {
    this.log = logger.withPrefix(`MibpSessionService`);
  }

  public async loadAtApplicationStart(b2cAccount: B2CAccount): Promise<MibpGetUserSessionResponseViewModel> {
    this.b2cAccount = b2cAccount;
    await this.reloadSessionFromBackend();
    if (this.b2cAccount) {
      this.ping();
      this.startPing();
    }
    return this.currentSessionResponseViewModel;
  }


  /**
   * We will ping the backend every X minutes so we can keep a little better track of when user was last
   */
  private async startPing(): Promise<void> {
    setTimeout(() => this.ping(), this.pingMs);
  }

  private async ping(): Promise<void> {
    try {
      await firstValueFrom(this.sessionController.ping());
      this.startPing();
    } catch (e) {
      this.log.warn(`Error pinging session`, e);
    }
  }


  public userMustPickPunchoutProfile(): boolean {
    return this.currentSessionResponseViewModel?.sessionStatus == MibpSessionStatus.UserMustPickPunchoutProfile;
  }

  public updateAndBroadcastCurrentSession(session: MibpGetUserSessionResponseViewModel, b2cAccount: B2CAccount = null): void {
    this.currentSessionResponseViewModel = session;

    if (b2cAccount) {
      this.b2cAccount = b2cAccount;
    }


    this.subject.next(session.sessionData);

    if (this.activeDeliverySequence?.deliverySequenceId != session.sessionData?.activeDeliverySequence?.deliverySequenceId) {
      this.activeDeliverySequenceSubject.next(session.sessionData?.activeDeliverySequence);
    }

    // We also broadcast this information so it can be fetched from broadcast service
    this.broadcast.setDeliverySequence(session.sessionData?.activeDeliverySequence);
    this.broadcast.setMibpSession(session.sessionData);
  }

  public async reloadSessionFromBackend(): Promise<MibpSessionViewModel> {

    try {
      const updatedViewmodel = await firstValueFrom(this.sessionController.getCurrentSession());
      this.updateAndBroadcastCurrentSession(updatedViewmodel);


      // if (updatedViewmodel.sessionStatus == MibpSessionStatus.UserMustPickPunchoutProfile) {

      // }

      return updatedViewmodel.sessionData;
    } catch (e) {
      this.log.error(`Error refreshing session`);
      throw e;
    }

  }

  /**
   * The latest available session information
   */
  public get current(): MibpSessionViewModel {
    return this.subject.value;
  }

  /**
   * An observable with the session data.
   * It will always return the current one first - can be skippsed using pipe(skip(1))
   */
  public get current$(): Observable<MibpSessionViewModel> {
    return this.subject.asObservable();
  }


  public get activeDeliverySequence(): SessionDeliverySequenceViewModel {
    return this.activeDeliverySequenceSubject.value;
  }

  /**
   * An observable with the active deliverysequence.
   * It will always return the current one first - can be skippsed using pipe(skip(1))
   */
  public get activeDeliverySequence$(): Observable<SessionDeliverySequenceViewModel> {
    return this.activeDeliverySequenceSubject.asObservable();
  }



  /**
   * Will return true if we have a valid b2c token
   */
  public isLoggedIn(): boolean {
    return !!this.b2cAccount;
  }

  public getAccessToken(): string {
    return this.b2cAccount?.accessToken;
  }

  public getIdentityObjectId(): string {
    return this.b2cAccount?.identityObjectId || null;
  }

  public async actAs(deliverySequenceId: number | null): Promise<SessionDeliverySequenceViewModel> {

    this.loader.showFullScreenLoader();

    try {

      let updatedSession: MibpGetUserSessionResponseViewModel;
      const currentDeliverySequenceId = this.currentSessionResponseViewModel.sessionData?.activeDeliverySequence?.deliverySequenceId || null;

      if (deliverySequenceId == null) {
        updatedSession = await firstValueFrom(this.sessionController.actAsAll());
      } else {
        updatedSession = await firstValueFrom(this.sessionController.actAsDeliverySequence({deliverySequenceId: deliverySequenceId}));
      }

      await this.updateSignalRDeliverySequence(currentDeliverySequenceId, deliverySequenceId);

      this.updateAndBroadcastCurrentSession(updatedSession);

      // If for some reason the selected ds, it's company or br is inactve - then reload page so user can pick a different one
      if (updatedSession.sessionData?.activeDeliverySequence) {
        if (!updatedSession.sessionData.activeDeliverySequence.isActive ||
          !updatedSession.sessionData.activeDeliverySequence.isCompanyActive ||
          !updatedSession.sessionData.activeDeliverySequence.isBusinessRelationActive
        ) {
          window.location.reload();
        }
      }

      this.loader.hideFullScreenLoader();

      return updatedSession.sessionData.activeDeliverySequence;
    } catch (e) {
      this.noticebarService.showText(`Could not change active delivery sequence`, NoticeType.Error, false);
      this.loader.hideFullScreenLoader();
      throw e;
    }
  }

  public async actAsPunchoutEncodedDeliverySequence(encodeddeliverySequence: string | null): Promise<SessionDeliverySequenceViewModel> {

    this.loader.showFullScreenLoader();

    try {

      let updatedSession: MibpGetUserSessionResponseViewModel;

      if (encodeddeliverySequence != undefined){
        updatedSession = await firstValueFrom(this.sessionController.actAsEncodedDeliverySequence({encodeddeliverySequence: encodeddeliverySequence}));
      }

      this.updateAndBroadcastCurrentSession(updatedSession);
      this.loader.hideFullScreenLoader();

      return updatedSession.sessionData.activeDeliverySequence;
    } catch (e) {
      this.noticebarService.showText(`Could not change active delivery sequence`, NoticeType.Error, false);
      this.loader.hideFullScreenLoader();
      throw e;
    }
  }

  /**
   * Session data will be up to date in SignalR already.
   * But we call this to make sure the user is in the correct SignalR groups
   * @param newDeliverySequenceiD
   */
  private async updateSignalRDeliverySequence(previousDeliverySequenceId: number | null, newDeliverySequenceId: number | null): Promise<void> {

    try {
  //    await firstValueFrom(this.signalrApi.Connection.ChangeDeliverySequence(previousDeliverySequenceId, newDeliverySequenceId));
    } catch (err) {
      this.log.error("Error updating SignalR delivery sequence", err);
    }

  }


  /**
   * Returns true if the current session has a user
   */
  public hasUser(): boolean {
    return !!this.current?.user;
  }

  /**
   * Returns true if the current session has a user with a role
   */
  public hasRole(): boolean {
    return !!this.current?.user?.roleId;
  }

  /**
   * Returns true if user is not null and user have the specified tatus
   */
  public hasStatus(stats: UserStatus): boolean {
    return this.hasUser() && this.current?.user?.status == stats;
  }

  /**
   * Returns true if the current session has an active user with a role
   * = User can use the site
   */
  public hasActiveUser(): boolean {
    return this.hasRole() && this.current?.user?.status == UserStatus.Active;
  }

  public isAuroraCompany(): boolean {
    return this.activeDeliverySequence?.isAuroraCompany == true;
  }

  public getUserFullName(): string {
    if (this.current.user) {
      return [this.current.user.firstName, this.current.user.lastName]
        .filter(nonEmpty => nonEmpty)
        .join(' ');
    }
    return ``;
  }

  public getPunchoutProfiles(): Observable<PunchoutProfileViewModel[]> {
    return this.sessionController.getPunchoutProfiles();
  }


  public isRegistrationRequired(): boolean {
    const status = this?.current?.user?.status;
    if (status == UserStatus.Created || status == UserStatus.Suspended) {
      return true;
    }
  }

  public isUserDeactivated(): boolean {
    const status = this?.current?.user?.status;
    if (status == UserStatus.Deactivated) {
      return true;
    }
  }

  public hasAnyRights(): boolean {
    if (this.current?.user?.enabledFeatures?.length > 0) {
      return true;
    }
    return false;
  }

  public hasFeature(feature: MySandvikFeatures): boolean {
    return this?.current?.user?.enabledFeatures?.includes(feature);
  }

  public isActingAs(): boolean {
    return !!this.current.activeDeliverySequence;
  }

  public redirectToNotLoggedInPage(): void {
    this.router.navigate([this.broadcast.snapshot.language, 'user', 'notloggedin']);
  }


}

