/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, forwardRef, Input, OnChanges, OnInit, SimpleChanges, ViewEncapsulation } from "@angular/core";
import { ControlValueAccessor, FormBuilder, NG_VALUE_ACCESSOR } from "@angular/forms";
import { MatDatepickerInputEvent } from "@angular/material/datepicker";
import { format } from "date-fns";
import { BroadcastService, FormattingService } from 'root/services';
import { TimezoneDateTimePickerComponentService } from "./timezone-datetimepicker.service";
import { MibpDateTimeService, TimezoneViewModel } from "root/services/datetime/datetime.service";

@Component({
  selector: 'mibp-timezone-datetimepicker',
  templateUrl: './timezone-datetimepicker.component.html',
  styleUrls: ['./timezone-datetimepicker.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [
    TimezoneDateTimePickerComponentService,
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => MibpTimezoneDateTimePickerComponent),
    }
  ]
})
export class MibpTimezoneDateTimePickerComponent implements OnInit, ControlValueAccessor, OnChanges {

  // Private fields used only by code
  private timezones: TimezoneViewModel[];
  private userTimezone: string;
  private browserTimezone: string;

  @Input() timezoneMode: 'user-select' | 'utc' = 'user-select';
  @Input() hideTimezoneList = false;
  @Input() hideTime = false;
  @Input() disabled = false;

  // Special kind of option. The date + time visible in the picker will be the select UTC values - ignoring timezones
  @Input() whatYouSeeIsUtc = false;

  // Protected fields used only by code and template
  protected isDisabled = false;
  protected dateValue?: Date;
  protected timeValue?: string;

  protected utcString: string;
  protected localString: string;
  protected selectedString: string;
  protected selectedIsoString: string;
  protected yourTimezoneString: string;
  protected distanceToNow: string;


  dtForm = this.fb.group({
    time: [''],
    timezone: ['']
  });

  constructor(private fb: FormBuilder, private format: FormattingService,
    protected broadcastService: BroadcastService,
    private datetimeService: MibpDateTimeService,
    private dtPickerService: TimezoneDateTimePickerComponentService) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.disabled) {
      this.isDisabled = changes.disabled.currentValue;
    }
  }

  clear(): void {
    this.dateValue = null;
    this.timeValue = null;
    this.onChange(null);
  }


  ngOnInit(): void {
    this.userTimezone = this.datetimeService.getBrowserTimezone();
    this.browserTimezone = this.userTimezone;
    this.timezones = this.datetimeService.getAvailableTimezones();
    this.timezones.sort((a,b) => {
      if (a.offset == b.offset) {
        return a.name < b.name ? -1 : 1;
      }
      return a.offset < b.offset ? -1 : 1;
    });
  }

  onChange: (value: any) => void = () => {};
  onTouch: () => void = () => {};

  /**
   * Component input can be a local date or a UTC date string
   * Output will always be a UTC ISO date string
   */
  writeValue(obj: any): void {

    if (obj && typeof obj === 'string') {
      // We only accept UTC formatted strings - ending with Z
      const parsedUtcDate = this.datetimeService.parseUtcISOString({utcDateString: obj, timezone: this.userTimezone});

      // We have the UTC date string. Let's work with local dates though
      const localDate = new Date(parsedUtcDate.utcDateString);
      this.dateValue = localDate;
      this.timeValue = format(localDate, 'HH:mm');
      this.refreshCurrentDate();

      this.dateValue = new Date(obj);
    } else if (obj instanceof Date ) {
      this.dateValue = obj;
      this.timeValue = format(obj, 'HH:mm');
    }

  }

  protected offsetToString(minutes: number): string {
    const isNegative = minutes < 0;
    const hrs = (minutes * (isNegative ? -1 : 1) ) / 60;
    const additional = hrs - parseInt(hrs.toString(), 10);
    const evenHours = hrs - additional;
    return `${isNegative ? '-' : '+'}${evenHours.toString().padStart(2, '0')}${(additional * 60).toString().padStart(2, '0')}`;
  }

  protected refreshStuff(): void {

    if (!this.dateValue) {
      return;
    }

    const selectedDate = this.dateValue;

    // const formatterUtc = new Intl.DateTimeFormat('sv-SE', <any>{
    //   dateStyle: 'short',
    //   timeStyle: 'medium',
    //   timeZone: 'UTC'
    // });

    // const formatterSelectedTz = new Intl.DateTimeFormat('sv-SE', <any>{
    //   dateStyle: 'short',
    //   timeStyle: 'medium',
    //   timeZone: this.userTimezone
    // });

    const formatterBrowserTz = new Intl.DateTimeFormat('sv-SE', <any>{
      dateStyle: 'short',
      timeStyle: 'medium',
      timeZone: this.browserTimezone
    });

    const utcDate = new Date(selectedDate.toLocaleString('sv-SE', { timeZone: 'UTC' }) + 'Z');
    const browserTzDate = new Date(selectedDate.toLocaleString('sv-SE'));
    const tzDate = new Date(selectedDate.toLocaleString('sv-SE', { timeZone: this.userTimezone }));

    const offset = (tzDate.getTime() - utcDate.getTime()) / 6e4;

    this.utcString = this.format.toServerUTCString(utcDate);
    this.localString = formatterBrowserTz.format(browserTzDate) + ' ' + browserTzDate.getTimezoneOffset();
    this.selectedString = tzDate.toLocaleString('sv-SE', { timeZone: this.userTimezone }) + this.offsetToString(offset);

    this.timeValue = format(tzDate, 'HH:mm');




  }

  /**
   * When changing timezone we want to make sure the UTC date is still the same as earlier
   * So the date / time values
   */
  userChangedTimezone(): void {
    // const updatedDate = this.dtPickerService.convertUTCStringIntoDateInputDate(this.utcString, this.userTimezone);
    // this.dateValue = updatedDate;
    // this.timeValue = format(this.dateValue, 'HH:mm');
    this.refreshCurrentDate();
  }

  timeInputEvent(): void {
    this.refreshCurrentDate();
  }


  private refreshCurrentDate(): void {

    if (this.dateValue) {
      const parsed = this.dtPickerService.parseDatePickerDate({
        date: this.dateValue,
        timezone: this.userTimezone,
        time: this.timeValue
      });

      let ngFormOutputValue = parsed.utcDateString;

      if (this.whatYouSeeIsUtc) {
        // Ok, we want the selected date + time to be the UTC value
        this.selectedIsoString = format(this.dateValue, `yyyy-MM-dd'T'HH:mm:ss'Z'`);
        this.utcString = this.selectedIsoString;
        ngFormOutputValue = this.utcString;
      } else {
        // We'll get an ISO datetime here in format yyyy-MM-ddThh:mm:ss.ffff+zzzz  - we want to remove the last .fff+zzzz part and just have +zzzz
        this.selectedString = parsed.timezoneDateString.replace('T', ' ').replace(/(\.\d{3})([\\+-]\d{4}$)/g, '$2');
        this.selectedIsoString = parsed.timezoneDateString.replace(/\.\d{3}/g, '');
        this.utcString = parsed.utcDateString;
      }

      if (this.userTimezone != this.browserTimezone) {
        this.yourTimezoneString = this.format.formatDateTime(this.utcString);
      } else {
        this.yourTimezoneString = null;
      }


      this.onChange(ngFormOutputValue);

    } else {
      this.utcString = null;
      this.onChange(null);
    }
  }

  datePickerEvent(type: string, event: MatDatepickerInputEvent<Date>) {
    if (type === 'change') {
      if (event.value) {
        this.refreshCurrentDate();
        this.onTouch();
      } else {
        this.onChange(null);
        this.onTouch();
      }
    }
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }
  setDisabledState?(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }

}
