
<mibp-active-account-picker></mibp-active-account-picker>

<div class="native-loader native-loader--ng" id="nativeLoader" [style.display]="showLoader ? 'flex' : 'none'">

  <div class="loader">
    <div class="my-app-state">

      <div class="my-app-state__content" *ngIf="!error">
        <div class="my-app-state__icon">
          <div class="my-css-loader"></div>
        </div>
        <div class="my-app-state__info">
          <h2 id="mySandvikTitle">{{ portalNameText }}</h2>
        </div>
      </div>
      <div class="my-app-state__content" *ngIf="error">
          <div class="my-app-state__icon">
            <span translate="no" class="material-icon material-icon--gold material-icon--xxxlarge">
              cancel
            </span>
          </div>
          <div class="my-app-state__info">
            <h3 class="my-header">{{ translations['general-error-title'].value }}</h3>
            <p>{{translations[error].value}}&nbsp;</p>
            <p>{{translations['what-to-do-now'].value}}</p>
            <ul class="my-list">
              <li>{{translations['reload-page'].value}}</li>
              <li>{{translations['try-later'].value}}</li>
              <li (click)="clearCacheClick($event)" [innerHTML]="translations['login-again'].value"></li>
              <li>{{translations['contact-support'].value}}</li>
            </ul>
            <p class="appload-error-client-id">{{clientId}}</p>
          </div>
      </div>
    </div>
  </div>
</div>

<router-outlet *ngIf="showApplication"></router-outlet>


<mibp-dialog #actAsDialog titleResourceStringKey="Customer_UpdateDefaultDS_Users"
[buttons]="dialogButtons" (buttonClick)="dialogClick($event)" hideCloseButton="true" >

  <div *ngIf="resourcesAreLoaded && showActAsPrompt" class="dialogContent">
    <h3 class="my-header">Hi {{name}}</h3>
    <p><label>{{message}}</label></p>
    <div class="dsPickerContent">
      <mibp-deliverysequence-picker *ngIf="canActAs" [organizationId]="currentOrganizationId" (valueChange)="onDeliverySequenceChanged($event)"></mibp-deliverysequence-picker>
    </div>
  </div>
</mibp-dialog>

<mibp-dialog #concurrenSessionDialog speadButtonsEvenly="true" hideCloseButton="true"
[buttons]="concurrenSessionDialogButtons" (buttonClick)="concurrenSessionDialogClick($event)">
  <mibp-resource-string key="Users_ConcurrentSession_WarningMessage" [isHtml]="true"></mibp-resource-string>
</mibp-dialog>

<mibp-dialog title="ContactUs_Title" fullheight="false" size="narrow" (closed)="onContactUsFormClosed()" #contactDialog hideCloseButton="true">
  <mibp-contact-dialog *ngIf="showContactUs" [formData]="contactUsFormData" [showLogoutButton]="true"></mibp-contact-dialog>
</mibp-dialog>
