<!-- carousel -->
<div class="carousel-container" *ngIf="slideImages?.length > 0">
  <mibp-loader *ngIf="!isLoading"></mibp-loader>
  <div>
  <div class="carousel" #carousel [ngClass]="{'hidden': !isLoading}">
    <ng-container *ngFor="let slide of slideImages; let i = index">
      <div class="image-container" *ngIf="i === currentSlide" @carouselAnimation>
        <div class="slide">
          <mibp-image [userFileId]="slide.userFileId" (imageLoaded)="imageLoaded()" [rendition]="renditionSize"></mibp-image>
        </div>
        <mibp-loader *ngIf="!loadedImage"></mibp-loader>
      </div>
    </ng-container>
  </div>

   <!-- prev next buttons -->
   <button class="control prev" (click)="onPreviousClick()" *ngIf="slideImages.length > 1">
    <span translate="no" class="material-icon material-icon--gold">navigate_before</span>
  </button>
  <button class="control next" (click)="onNextClick()" *ngIf="slideImages.length > 1">
    <span translate="no" class="material-icon material-icon--gold">navigate_next</span>
  </button>
  
  <div class="navigation">
    <ng-container *ngIf="slideImages.length > 1" >
      <ng-container *ngFor="let slide of slideImages let i = index">
        <button class="navigation-button" [ngClass]="{'selected': i === currentSlide}" (click)="navigateToSlide(i)"></button>
      </ng-container>
    </ng-container>
  </div>
  </div>
</div>

