import { Injectable } from '@angular/core';
import { DialogService } from '../dialog/dialog.service';
import { BehaviorSubject } from 'rxjs';
import { ContactUsDialogInit, ContactUsTopic} from 'root/components/contact-dialog/contact-us-form/contact-us-form.types';
import { ActivatedRoute } from '@angular/router';
import { SupportCaseItemEnquiryData } from 'root/components/contact-dialog/new-contact-us-form/contact-us-form-item-enquiry/contact-us-item-enquiry-form.component';
import { NewContactUsTopic, SupportCaseDialogInit } from 'root/components/contact-dialog/new-contact-us-form/new-contact-us-form.types';
import { SupportCaseItemEnquiryType } from 'root/components/contact-dialog/new-contact-us-form/contact-us-form-item-enquiry/contact-us-item-enquiry-form.interface';
import { SupportCaseShipppingTrackingType } from 'root/components/contact-dialog/new-contact-us-form/contact-us-form-shipping-tracking/contact-us-shipping-tracking-form.interface';
import { SupportCaseShipingTrackingData } from 'root/components/contact-dialog/new-contact-us-form/contact-us-form-shipping-tracking/contact-us-shipping-tracking-form.component';
import { SupportCaseGeneralData, SupportCaseGeneralType } from 'root/components/contact-dialog/new-contact-us-form/contact-us-form-general/contact-us-general-form.component';
import { Guid } from 'guid-typescript';
import { ProductUpgradeRequestQuoteData } from 'root/components/contact-dialog/new-contact-us-form/contact-us-request-quote/contact-us-request-quote-form.component';
@Injectable({
  providedIn: 'root'
})
export class ContactUsService {

  public openContactDialog: BehaviorSubject<ContactUsDialogInit> = new BehaviorSubject({open: false});
  public openNewContactUsDialog: BehaviorSubject<SupportCaseDialogInit> =  new BehaviorSubject({open: false});

  constructor(private dialogService: DialogService, private route: ActivatedRoute) {}

  public openItemEnquiryContactUs(productCode: string, quantity?: number, enquiryType?: SupportCaseItemEnquiryType) : void {
    this.openNewContactUsDialog.next({
      open: true, formData: {
        newContactUsTopic: NewContactUsTopic.ItemEnquiry,
        itemEnquiryData: <SupportCaseItemEnquiryData>{
          productCode: productCode,
          quantity: quantity,
          enquiryType: enquiryType
        },
        shipingTrackingData:null,
        GeneralData:null,
        upgradeQuoteData:null
      }
    });
  }

  public openShipmentTrackingContactUs(deliverysequenceId: number, orderNumber: string, description?: string, enquiryType?: SupportCaseShipppingTrackingType) : void {
    this.openNewContactUsDialog.next({
      open: true, formData: {
        newContactUsTopic: NewContactUsTopic.ShippingAndTracking,
        itemEnquiryData:null,
        shipingTrackingData: <SupportCaseShipingTrackingData>{
          deliverysequenceId: deliverysequenceId,
          orderNumber: orderNumber,
          enquiryType: enquiryType,
          description: description
        },
        GeneralData:null,
        upgradeQuoteData:null
      }
    });
  }

  public openDigitalServiceContactUs(orderNumber?: string, description?: string, enquiryType?: SupportCaseGeneralType) : void {
    this.openNewContactUsDialog.next({
      open: true, formData: {
        newContactUsTopic: NewContactUsTopic.GeneralQuestionOrRequest,
        itemEnquiryData:null,
        shipingTrackingData:null,
        upgradeQuoteData:null,
        GeneralData: <SupportCaseGeneralData>{
          orderNumber: orderNumber,
          enquiryType: enquiryType,
          description: description
        }

      }
    });
  }

  public openRequestUpgradeQuoteContactUs(equipmentid: number, upgradeName: string, productArea: string, shortTex: string, longText:string) : void {
    this.openNewContactUsDialog.next({
      open: true, formData: {
        newContactUsTopic: NewContactUsTopic.RequestUpgradeQuote,
        upgradeQuoteData: <ProductUpgradeRequestQuoteData>{
          topic: NewContactUsTopic.RequestUpgradeQuote,
          equipmentId: equipmentid,
          upgradeName: upgradeName,
          productArea: productArea,
          shortDescription: shortTex,
          longDescription: longText

        },
        itemEnquiryData:null,
        shipingTrackingData: null,
        GeneralData: null
      }
    });
  }

  public openContactUs(topic?: ContactUsTopic, message?: string, orderNumber?: string, partNumber?: string, orderType?: string): void {
    this.openContactDialog.next({
      open: true, formData: {
        topic: topic,
        message: message,
        orderNumber: orderNumber,
        orderType: orderType,
        partNumber: partNumber
      }
    });
  }

  public closeContactUs(): void {
    this.openContactDialog.next({open: false});
  }

}
