/* tslint:disable */
/* eslint-disable */
export enum MachineFilterType {
  Machine = 0,
  Model = 1,
  SubGroup = 2,
  Group = 3,
  Company = 4,
  Customer = 5,
  OperationSite = 6
}
