<mibp-dialog #dialog [fullheight]="true" (buttonClick)="dialog.close()" [buttons]="buttons">

  <div *ngIf="showSubmitFile" style="position:relative; width: 100%; height: 100%;">

      <div class="back">
        <a href="javascript:void(0)" (click)="showSubmitFile = false">
          <span translate="no" class="material-icon">arrow_back</span>
          Back
        </a>
      </div>

      <h1>{{fileList[activeFileIndex].title}}</h1>

      <p>What kind of file is this?</p>

      <ul class="my-list">
        <li *ngFor="let type of resubmitMessageTypes"><a [routerLink]="['/', langCode, 'operations', 'post-integration-messages', type.identifier]" [queryParams]="{ message: fileList[activeFileIndex].rawContent }">{{ type.title }}</a></li>
      </ul>

  </div>

  @if (!showSubmitFile) {

    <div *ngIf="isLoading">
      <span translate="no" class="material-icon material-icon--large material-icon--spin">progress_activity</span>
    </div>

    <div *ngIf="!isLoading" style="height: 100%; display: flex; flex-direction: column">
      <div class="files-list">
        @for (file of fileList; track file.title; let ix = $index) {

          <a [class.active]="ix == activeFileIndex" (click)="selectFile(ix)">{{file.title}}</a>

        }
      </div>
      <div class="toolbar">
        <div>
          <button class="my-button my-button--secondary" [disabled]="isLoading || hasError" (click)="downloadFile()">Download file</button>
          <button class="my-button my-button--secondary" [disabled]="isLoading || hasError" (click)="copyToClipboard()">Copy to clipboard</button>
        </div>
        <div class="toggle">
          <button class="my-button my-button--small my-button--secondary my-button--active" (click)="toggleView('raw')" [class.my-button--active]="view == 'raw'" >Raw</button>
          <button class="my-button my-button--small my-button--secondary" (click)="toggleView('pretty')" [class.my-button--active]="view == 'pretty'">Prettified</button>
          <!-- <button class="my-button my-button--small my-button--secondary" (click)="toggleView('pretty')" >Tree</button> -->
        </div>
        <div>
          <button class="my-button my-button--secondary" [disabled]="isLoading || hasError" (click)="showSubmitFile = true"><span translate="no" class="material-icon">send</span> Submit as integration message</button>
        </div>
      </div>
      <div *ngIf="(fileList[activeFileIndex].hasError) || (fileList[activeFileIndex].hasPrettifyError && view =='pretty')">
        An error occured
      </div>
      <div class="content">

        @if (view == 'raw') {
          @if (fileList[activeFileIndex].hasError) {
            error
          } @else {
            {{fileList[activeFileIndex].rawContent}}
          }
        } @else {
          {{fileList[activeFileIndex].prettifiedContent}}
        }

      </div>
    </div>
  }

</mibp-dialog>

