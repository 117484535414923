import { Component, Input, OnChanges, SimpleChanges } from "@angular/core";
import { DomSanitizer, SafeHtml } from "@angular/platform-browser";

@Component({
  selector: 'mibp-highlight-whitespace',
  templateUrl: './highlight-whitespaces.component.html',
  styleUrl: './highlight-whitespaces.component.scss'
})
export class MibpHighightWhitespacesComponent implements OnChanges {

  protected valueSafeHtml: SafeHtml;

  constructor(private sanitizer: DomSanitizer) {}

  @Input() value?: string;

  ngOnChanges(changes: SimpleChanges): void {

    if (changes.value.currentValue != changes.value.previousValue) {
      this.refresh();
    }

  }


  private refresh(): void {

    let newValue = this.value;

    newValue = newValue.replace(/(^\s|\s$)/g, '<span class="whitespace whitespace--space">&nbsp;</span>');
    newValue = newValue.replace(/(^\t|\t$)/g, '<span class="whitespace whitespace--tab">&nbsp;</span>');

    this.valueSafeHtml = this.sanitizer.bypassSecurityTrustHtml(newValue);

  }


}
