
<div [formGroup]="pickerForm">
  <div class="row">
    <div class="col-12" >
        <label><mibp-resource-string key="Global_Company"></mibp-resource-string><span  class="required">(<mibp-resource-string key="Global_Required"></mibp-resource-string>)</span></label>
        <mibp-dropdown
          #companyDropdown
          formControlName="companyDropdown"
          (filter)="onFilterCompanies($event)"
          (valueChange)="onChangeCompany($event)"
          [data]="companies$"
        ></mibp-dropdown>
    </div>
  </div>
  <div class="row" >
    <div class="col-12">
        <label><mibp-resource-string key="Global_Customer"></mibp-resource-string><span  class="required">(<mibp-resource-string key="Global_Required"></mibp-resource-string>)</span></label>
        <mibp-dropdown
        customerDropdown
          formControlName="customerDropdown"
          (filter)="onFilterCustomers($event)"
          (valueChange)="onChangeCustomer($event)"
          [data]="customers$"
        ></mibp-dropdown>
    </div>
  </div>
  <div class="row mb-0" >
    <div class="col-12">
        <label><mibp-resource-string key="Global_OperationSite"></mibp-resource-string><span class="required">(<mibp-resource-string key="Global_Required"></mibp-resource-string>)</span></label>
        <mibp-dropdown
          #operationsiteDropdown
           formControlName="operationsiteDropdown"
          (filter)="onFilterOperationSites($event)"
          (valueChange)="onChangeOperationSite($event)"
          [data]="operationSites$"
        ></mibp-dropdown>
    </div>
  </div>
</div>
