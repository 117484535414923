
import { MibpElectronicManualsPreviewComponent } from './home/modules/myfleet/components/electronic-manuals-preview/electronic-manuals-preview.component';
import { MibpChartLineComponent } from './../components/chart/mibp-chart-line/mibp-chart-line.component';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { PdfRenderComponent } from './../components/pdf-render/pdf-render.component';
import { MibpHeaderComponent } from '../components/navigation/header/header.component';
import { OrganizationPickerComponent } from './../components/organization-picker/organization-picker.component';
import { PageTitleComponent } from './../components/page-title/page-title.component';
import { TabsComponent } from './../components/tabs/tabs.component';
import { NgModule } from '@angular/core';
import {EcommerceRouterLinkDirective} from '../directives/ecommerce-router-link/ecommerce-router-link.directive';
import {LocalizedComponent} from '../components/localized-component/localized.component';
import {
  ButtonComponent,
  ManualDownloadButtonComponent,
  SystemMessageComponent,
  DialogComponent,
  DropdownComponent,
  LoaderComponent,
  RefinerComponent,
  DatepickerComponent,
  PaginatorComponent,
  MibpDataViewComponent,
  TabComponent,
  ResourceStringComponent,
  ResourceStringEditorComponent,
  TermsAndConditionsComponent,
  ProfilePickerComponent,
  ActAsComponent,
  QuickAddComponent,
  QuickAddTemplateComponent,
  QuickAddImportComponent,
  QuickAddRowComponent,
  MibpPopoverComponent,
  ProgressComponent,
  MibpValidationMessageComponent,
  MibpValidationMessagesComponent,
  DashboardProductSearchComponent,
  DeliverySequencePickerComponent,
  OperationsitePickerComponent,
  MibpDevopsFeedbackComponent,
  PermissionPickerComponent,
  ExpandableSectionComponent,
  ExpandableSectionTitleComponent,
  ResponsibilityPickerComponent,
  TargetedUsersComponent,
  RichTextEditorComponent,
  LanguageSelectorComponent,
  TableComponent,
  ContactDialogComponent,
  ContactUsFormComponent,
  MibpFullScreenIframeComponent,
  ImageComponent,
  ExpandableListComponent,
  HtmlViewComponent,
  UserFileUploadComponent,
  UserfileListComponent,
  DebugElementComponent,
  ProfilePickerCardComponent,
  ContextMenuComponent,
  MibpValidationTextComponent,
  InlineEditingOverlayComponent,
  OrganizationListSelectorComponent,
  MibpProductPriceComponent,
  MibpDateTimeComponent,
  MibpSidebarComponent,
  MibpSandvikIconComponent,
  MibpPopupContainerComponent,
  AvailabilityComponent,
  MibpHorizontalScrollContainerComponent,
  MapComponent,
  ProductEquipmentListComponent,
  EquipmentStatusComponent,
  EquipmentStatusDescriptionComponent,
  ExpectedDeliveryDateComponent,
  NumericInputComponent,
  CartQuickviewComponent,
  ContactUsMainPageComponent,
  MibpDocumotoWidgetComponent,
  MibpPartsManualComponent,
  MibpProductNotificationComponent

} from 'root/components';
import { MibpResourceStringTitleDirective, ShowLoaderDirective, TestIdDirective, MibpFiledropDirective, MibpResourceStringAttributesDirective, ResourceStringEditorDirective, } from 'root/directives';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatNativeDateModule, DateAdapter } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { TruncateStringPipe, FormatPricePipe, FormatWeightPipe, FormatDatePipe, FormatDateTimePipe, FormatBytesPipe } from 'root/pipes';
import { CustomDateAdapter } from 'root/components/datepicker/datepicker.dateadapter';
import { InputAutosizeDirective } from 'root/directives/input-autosize.directive';
import { ResolvedScopesListComponent } from 'root/components/responsibility-picker/resolved-scopes-list/resolved-scopes-list.component';
import { QuillModule } from 'ngx-quill';
import { RouterModule } from '@angular/router';
import { BaseChartDirective, provideCharts, withDefaultRegisterables } from 'ng2-charts';
import { MibpChartPieComponent, MibpChartBarComponent } from 'root/components';
import { CartNameComponent } from './home/modules/cart/components';
import { MibpGridComponent, MibpGridPagingComponent, MibpGridToolbarComponent, MibpGridFilterTextComponent, MibpGridFilterFacetComponent, MibpGridResizeHandleComponent, MibpGridFilterValuePipe } from 'root/components/mibp-grid';
import {ScrollingModule} from '@angular/cdk/scrolling';
import {NgIdleModule} from '@ng-idle/core';
import {DatePipe} from '@angular/common';
import { InputDefaultToNullValueDirective } from 'root/directives/input-default-to-null-value.directive';
import { InputDecimalValueDirective } from 'root/directives/input-decimal-value.directive';
import { MibpFooterV2Component } from 'root/components/navigation/footer/footer-v2.component';
import { MibpBreadcrumbsV2Component, MibpHeaderActivityComponent, MibpHeaderCartComponent, MibpHeaderLanguageComponent, MibpHeaderSearchComponent, MibpHeaderUserComponent, MibpMobileMenuComponent, MibpUserPhotoComponent } from 'root/components/navigation';
import { MibpGridEditColumnsComponent } from 'root/components/mibp-grid/components/mibp-grid-edit-columns/mibp-grid-edit-columns.component';
import { MibpOnboardingComponent } from 'root/components/onboarding';
import { MibpGridFilterCheckboxFacetComponent } from 'root/components/mibp-grid/components/mibp-grid-filter-checkbox-facet/mibp-grid-filter-checkbox-facet.component';
import { MibpProductPickerComponent } from 'root/components/product-picker/product-picker.component';
import { AddtocartDialogComponent } from 'root/components/addtocart-dialog/addtocart-dialog.component';
import { UpsellComponent } from 'root/components/upsell/upsell.component';
import { CrossSellComponent } from 'root/components/cross-sell/cross-sell.component';
import { MibpEquipmentPickerComponent } from './warranty-portal/components/equipment-picker/equipment-picker.component';
import { FilterComponent } from 'root/components/filter/filter.component';
import { MibpProductWeightComponent } from 'root/components/product-weight/product-weight.component';
import { MibpMyIfHasAllFeaturesDirective, MibpMyIfHasAnyFeaturesDirective, MibpMyIfHasFeatureDirective } from 'root/directives/permissions';
import { MibpInlineHelpComponent } from 'root/components/inline-help/inline-help.component';
import { MibpAspectRatioDirective } from 'root/directives/aspect-ratio/aspect-ratio.directive';
import { MibpFeatureTourComponent } from 'root/components/feature-tour/feature-tour.component';
import { DateTimepickerComponent } from 'root/components/datetimepicker/datetimepicker.component';
import { MibpHeaderSystemMessageComponent } from 'root/components/navigation/header/header-system-message/header-system-message.component';
import { SystemMessageDialogComponent } from 'root/components/system-message-dialog/system-message-dialog.component';
import { ToggleSwitchComponent } from 'root/components/toggle-switch/toggle-switch.component';
import { LightBoxComponent } from 'root/components/lightbox/lightbox.component';
import { MibpHighlightTextComponent } from 'root/components/highlight-text/highlight-text.component';
import { AddressLinesComponent } from './home/modules/shop/components/address-lines/address-lines.component';
import { MibpGeneralErrorComponent } from 'root/components/page-load-error/page-load-error.component';
import { MibpChangelogChangeComponent } from 'root/components/changelog-change/changelog-change.component';
import { MibpFeaturePickerComponent } from 'root/components/feature-picker/feature-picker.component';
import { MibpSalesforceEmbeddedServiceComponent } from 'root/components/salesforce-embedded-service/salesforce-embedded-service.component';
import { MibpSupportTeamToolbarComponent } from 'root/components/support-team-toolbar/support-team-toolbar.component';
import { MibpSupportTeamPageMenuComponent } from 'root/components/support-page-menu/support-page-menu.component';
import { MibpSupportTeamMemberContextMenuComponent } from 'root/components/support-context-menu/support-context-menu.component';
import { MibpBreadcrumbsV2ManualControlComponent } from 'root/components/navigation/breadcrumbs/breadcrumb-manual-control/breadcrumb-manual-control.component';
import { MachineFilterComponent } from 'root/components/machine-filter/machine-filter.component';
import { MibpGoogleMapsScriptComponent } from 'root/pages/application-root/google-maps-script.component';
import { MibpChangeTrackingChangesComponent } from 'root/components/change-tracking-change/change-tracking-change.component';
import { MibpChangeTrackingChangeDetailsComponent } from 'root/components/change-tracking-change-details/change-tracking-change-details.component';
import { MibpCopyToClipboardComponent } from 'root/components/copy-to-clipboard/copy-to-clipboard.component';
import { MibpClientIdComponent } from 'root/components/client-id/client-id.component';
import { InputIntegerOnlyDirective } from 'root/directives/input-integer-only.directive';
import { ChangeTrackingCommentDialogComponent } from './settings/components/change-tracking-comment-dialog/change-tracking-comment-dialog.component';
import { ExpectedDeliveryDateAvailabilityComponent } from 'root/components/expected-delivery-date/expected-delivery-date-availability.component';
import { MibpTimezoneDateTimePickerComponent } from 'root/components/timezone-datetimepicker/timezone-datetimepicker.component';
import { MibpAddressViewComponent } from 'root/components/address-view/address-view.component';
import { MibpInterimDeliverySequenceComponent } from 'root/components/interim-deliverysequence-message/interim-deliverysequence-message.component';
import { ArrayFirstPipe } from 'root/pipes/array-first/array-first.pipe';
import { ArrayLastPipe } from 'root/pipes/array-last/array-last.pipe';
import { ContactUsItemEnquiryFormComponent } from 'root/components/contact-dialog/new-contact-us-form/contact-us-form-item-enquiry/contact-us-item-enquiry-form.component';
import { NewContactUsFormComponent } from 'root/components/contact-dialog/new-contact-us-form/new-contact-us-form.component';
import { ContactUsShippingAndTrackingFormComponent } from 'root/components/contact-dialog/new-contact-us-form/contact-us-form-shipping-tracking/contact-us-shipping-tracking-form.component';
import { ContactUsReturnsAndOrderFormComponent } from 'root/components/contact-dialog/new-contact-us-form/contact-us-form-returns-and-orders/contact-us-returns-and-orders-form.component';
import { ContactUsTradingAccountFormComponent } from 'root/components/contact-dialog/new-contact-us-form/contact-us-form-trading-account/contact-us-trading-account-form.component';
import { ContactUsWebsiteFeedbackFormComponent } from 'root/components/contact-dialog/new-contact-us-form/contact-us-form-website-feedback/contact-us-website-feedback-form.component';
import { ContactUsTechnicalRequestFormComponent } from 'root/components/contact-dialog/new-contact-us-form/contact-us-form-technical-request/contact-us-technical-request-form.component';
import { ContactUsGeneralFormComponent } from 'root/components/contact-dialog/new-contact-us-form/contact-us-form-general/contact-us-general-form.component';
import { AbbreviateNumberPipe } from 'root/pipes/abbreviate-number/abbreviate-number.pipe';
import { FormatMsPipe } from 'root/pipes/format-ms/format-ms.pipe';
import { FormatDurationPipe } from 'root/pipes/format-duration/format-duration.pipe';
import { MibpCountdownComponent } from './operations/components';
import { MibpSupportTeamFileViewerDialogComponent } from 'root/components/support-team-file-viewer-dialog/support-team-file-viewer-dialog.component';
import { MibpPartsManualTreeviewComponent } from 'root/components/parts-manual/parts-manual-treeview/parts-manual-treeview.component';
import { MibpPartsManualIllustrationComponent } from 'root/components/parts-manual/parts-manual-illustration/parts-manual-illustration.component';
import { MibpPartsManualFolderDetailsComponent } from 'root/components/parts-manual/parts-manual-folder-details/parts-manual-folder-details.component';
import { PartsManualWhereUsedComponent } from 'root/components/parts-manual/parts-manual-whereused/parts-manual-whereused.component';
import { MibpImagesCarouselComponent } from 'root/components/images-carousel/images-carousel.component';
import { ContactUsRequestQuoteFormComponent } from 'root/components/contact-dialog/new-contact-us-form/contact-us-request-quote/contact-us-request-quote-form.component';
import { MibpMediaStatusComponent } from 'root/components/media-status/media-status.component';
import { MibpClickToCopyDirective } from 'root/directives/click-to-copy/click-to-copy.directive';
import { MibpTextAreaAutosizeDirective } from './warranty-portal/directives';
import { MibpManualDownloadStatusComponent } from 'root/components/manual-download-status/manual-download-status.component';
import { MibpHighightWhitespacesComponent } from 'root/components/highlight-whitespaces/highlight-whitespaces.component';
import { MibpMyFleetElectronicManualRelatedComponent } from './home/modules/myfleet/pages/electronic-manual-related/electronic-manual-related.component';
import { MibpElectronicManualsWhereUsedItemComponent } from './home/modules/shop/components/electronic-manuals-where-used-item/electronic-manuals-where-used-item.component';
import { MibpBlobLogViewComponent } from 'root/components/blob-log-view/blob-log-view.component';
import { MibpFilterTagComponent } from 'root/components/filter-tags/filter-tags.component';
import { MibpPdfViewerComponent } from 'root/components/pdf-viewer/mibp-pdf-viewer.component';
import { MibpActiveAcountPickerComponent } from 'root/components/active-account-picker/active-account-picker.component';
import { ElectronicManualReporterrorComponent } from 'root/components/parts-manual/electronics-manual-reporterror/electronics-manual-reporterror.component';

@NgModule({
  imports: [
    BaseChartDirective,
    CommonModule,
    FormsModule,
    MatDatepickerModule,
    MatFormFieldModule,
    MatInputModule,
    MatNativeDateModule,
    QuillModule.forRoot(),
    ReactiveFormsModule,
    RouterModule,
    ScrollingModule,
    NgIdleModule.forRoot(),
    PdfViewerModule
  ],
  declarations: [
    ActAsComponent,
    ButtonComponent,
    ManualDownloadButtonComponent,
    DashboardProductSearchComponent,
    DatepickerComponent,
    DateTimepickerComponent,
    DeliverySequencePickerComponent,
    OperationsitePickerComponent,
    DialogComponent,
    DropdownComponent,
    EcommerceRouterLinkDirective,
    ExpandableSectionComponent,
    ExpandableSectionTitleComponent,
    FormatBytesPipe,
    FormatDatePipe,
    FormatDateTimePipe,
    FormatPricePipe,
    FormatWeightPipe,
    FormatMsPipe,
    ArrayFirstPipe,
    ArrayLastPipe,
    AbbreviateNumberPipe,
    FormatDurationPipe,
    InputAutosizeDirective,
    InputDefaultToNullValueDirective,
    InputDecimalValueDirective,
    InputIntegerOnlyDirective,
    LoaderComponent,
    LocalizedComponent,
    MibpChartPieComponent,
    MibpChartBarComponent,
    MibpChartLineComponent,
    MibpDevopsFeedbackComponent,
    MibpDataViewComponent,
    MibpEquipmentPickerComponent,
    MibpFullScreenIframeComponent,
    MibpPopoverComponent,
    MibpResourceStringTitleDirective,
    MibpValidationMessageComponent,
    MibpValidationMessagesComponent,
    PageTitleComponent,
    PaginatorComponent,
    PermissionPickerComponent,
    ProfilePickerComponent,
    ProgressComponent,
    QuickAddComponent,
    QuickAddImportComponent,
    QuickAddTemplateComponent,
    QuickAddRowComponent,
    RefinerComponent,
    ResolvedScopesListComponent,
    ResourceStringComponent,
    ResourceStringEditorComponent,
    ResourceStringEditorDirective,
    ResponsibilityPickerComponent,
    TargetedUsersComponent,
    RichTextEditorComponent,
    SystemMessageComponent,
    TabComponent,
    TabsComponent,
    TermsAndConditionsComponent,
    PdfRenderComponent,
    TruncateStringPipe,
    MibpGridFilterValuePipe,
    LanguageSelectorComponent,
    TableComponent,
    CartNameComponent,
    ContactDialogComponent,
    ContactUsFormComponent,
    ContactUsItemEnquiryFormComponent,
    ContactUsReturnsAndOrderFormComponent,
    ContactUsShippingAndTrackingFormComponent,
    ContactUsTradingAccountFormComponent,
    ContactUsTechnicalRequestFormComponent,
    ContactUsWebsiteFeedbackFormComponent,
    ContactUsGeneralFormComponent,
    ContactUsRequestQuoteFormComponent,
    NewContactUsFormComponent,
    ImageComponent,
    ShowLoaderDirective,
    ExpandableListComponent,
    HtmlViewComponent,
    UserFileUploadComponent,
    UserfileListComponent,
    DebugElementComponent,
    ProfilePickerCardComponent,
    MibpGridComponent,
    MibpGridPagingComponent,
    MibpGridEditColumnsComponent,
    ContextMenuComponent,
    MibpGridToolbarComponent,
    MibpGridFilterTextComponent,
    MibpGridFilterFacetComponent,
    MibpGridFilterCheckboxFacetComponent,
    MibpValidationTextComponent,
    MibpGridResizeHandleComponent,
    TestIdDirective,
    InlineEditingOverlayComponent,
    OrganizationPickerComponent,
    OrganizationListSelectorComponent,
    MibpFiledropDirective,
    MibpProductPriceComponent,
    MibpDateTimeComponent,
    MibpSidebarComponent,
    MibpHeaderComponent,
    MibpSandvikIconComponent,
    MibpFooterV2Component,
    MibpBreadcrumbsV2Component,
    MibpResourceStringAttributesDirective,
    MibpPopupContainerComponent,
    MibpHeaderCartComponent,
    MibpHeaderActivityComponent,
    MibpHeaderSystemMessageComponent,
    MibpHeaderLanguageComponent,
    MibpHeaderUserComponent,
    MibpHeaderSearchComponent,
    MibpUserPhotoComponent,
    MibpMobileMenuComponent,
    MibpOnboardingComponent,
    MibpFeatureTourComponent,
    MibpProductPickerComponent,
    AddtocartDialogComponent,
    UpsellComponent,
    CrossSellComponent,
    AvailabilityComponent,
    ExpectedDeliveryDateAvailabilityComponent,
    FilterComponent,
    MibpHorizontalScrollContainerComponent,
    MibpProductWeightComponent,
    MibpMyIfHasFeatureDirective,
    MibpMyIfHasAllFeaturesDirective,
    MibpMyIfHasAnyFeaturesDirective,
    MibpInlineHelpComponent,
    MibpAspectRatioDirective,
    SystemMessageDialogComponent,
    ToggleSwitchComponent,
    LightBoxComponent,
    MibpHighlightTextComponent,
    MapComponent,
    AddressLinesComponent,
    MibpGeneralErrorComponent,
    MibpClientIdComponent,
    MibpChangelogChangeComponent,
    MibpChangeTrackingChangesComponent,
    MibpChangeTrackingChangeDetailsComponent,
    MibpFeaturePickerComponent,
    MibpSalesforceEmbeddedServiceComponent,
    MibpFeaturePickerComponent,
    MibpSupportTeamToolbarComponent,
    MibpSupportTeamPageMenuComponent,
    MibpSupportTeamMemberContextMenuComponent,
    ProductEquipmentListComponent,
    EquipmentStatusComponent,
    EquipmentStatusDescriptionComponent,
    MibpBreadcrumbsV2ManualControlComponent,
    MachineFilterComponent,
    MibpGoogleMapsScriptComponent,
    ExpectedDeliveryDateComponent,
    MibpCopyToClipboardComponent,
    ChangeTrackingCommentDialogComponent,
    MibpTimezoneDateTimePickerComponent,
    NumericInputComponent,
    MibpAddressViewComponent,
    MibpInterimDeliverySequenceComponent,
    CartQuickviewComponent,
    ContactUsMainPageComponent,
    MibpCountdownComponent,
    MibpDocumotoWidgetComponent,
    MibpSupportTeamFileViewerDialogComponent,
    MibpPartsManualComponent,
    ElectronicManualReporterrorComponent,
    MibpPartsManualTreeviewComponent,
    MibpPartsManualFolderDetailsComponent,
    MibpPartsManualIllustrationComponent,
    PartsManualWhereUsedComponent,
    MibpImagesCarouselComponent,
    MibpProductNotificationComponent,
    MibpMediaStatusComponent,
    MibpClickToCopyDirective,
    MibpTextAreaAutosizeDirective,
    MibpManualDownloadStatusComponent,
    MibpHighightWhitespacesComponent,
    MibpMyFleetElectronicManualRelatedComponent,
    MibpElectronicManualsWhereUsedItemComponent,
    MibpBlobLogViewComponent,
    MibpFilterTagComponent,
    MibpPdfViewerComponent,
    MibpActiveAcountPickerComponent,
   // MibpAddHorizontalScrollbarDirective,
    PartsManualWhereUsedComponent
  ],

  exports: [
    MibpMediaStatusComponent,
    ButtonComponent,
    ManualDownloadButtonComponent,
    BaseChartDirective,
    DashboardProductSearchComponent,
    DatepickerComponent,
    DateTimepickerComponent,
    DeliverySequencePickerComponent,
    OperationsitePickerComponent,
    DialogComponent,
    DropdownComponent,
    EcommerceRouterLinkDirective,
    ExpandableSectionComponent,
    ExpandableSectionTitleComponent,
    FormatBytesPipe,
    FormatDatePipe,
    FormatDateTimePipe,
    FormatPricePipe,
    FormatWeightPipe,
    FormatMsPipe,
    MibpChangeTrackingChangesComponent,
    MibpChangeTrackingChangeDetailsComponent,
    MibpGridFilterValuePipe,
    ArrayFirstPipe,
    ArrayLastPipe,
    AbbreviateNumberPipe,
    FormatDurationPipe,
    InputAutosizeDirective,
    InputDecimalValueDirective,
    InputIntegerOnlyDirective,
    InputDefaultToNullValueDirective,
    LoaderComponent,
    MibpChartPieComponent,
    MibpChartBarComponent,
    MibpChartLineComponent,
    MibpDataViewComponent,
    MibpFullScreenIframeComponent,
    MibpDevopsFeedbackComponent,
    MibpPopoverComponent,
    MibpResourceStringTitleDirective,
    MibpValidationMessageComponent,
    MibpValidationMessagesComponent,
    PageTitleComponent,
    PaginatorComponent,
    PermissionPickerComponent,
    ProfilePickerComponent,
    ProgressComponent,
    QuickAddComponent,
    QuickAddImportComponent,
    QuickAddTemplateComponent,
    RefinerComponent,
    ResolvedScopesListComponent,
    ResourceStringComponent,
    ResourceStringEditorComponent,
    ResourceStringEditorDirective,
    ResponsibilityPickerComponent,
    TargetedUsersComponent,
    RichTextEditorComponent,
    SystemMessageComponent,
    TabComponent,
    TabsComponent,
    TermsAndConditionsComponent,
    PdfViewerModule,
    PdfRenderComponent,
    TruncateStringPipe,
    LanguageSelectorComponent,
    TableComponent,
    CartNameComponent,
    ContactDialogComponent,
    ContactUsFormComponent,
    ContactUsItemEnquiryFormComponent,
    ContactUsReturnsAndOrderFormComponent,
    ContactUsShippingAndTrackingFormComponent,
    ContactUsTradingAccountFormComponent,
    ContactUsTechnicalRequestFormComponent,
    ContactUsWebsiteFeedbackFormComponent,
    ContactUsGeneralFormComponent,
    NewContactUsFormComponent,
    ContactUsRequestQuoteFormComponent,
    ImageComponent,
    ShowLoaderDirective,
    ExpandableListComponent,
    HtmlViewComponent,
    UserFileUploadComponent,
    UserfileListComponent,
    DebugElementComponent,
    ProfilePickerCardComponent,
    MibpGridComponent,
    MibpGridPagingComponent,
    ContextMenuComponent,
    MibpGridToolbarComponent,
    MibpGridEditColumnsComponent,
    MibpGridFilterTextComponent,
    MibpGridFilterFacetComponent,
    MibpGridFilterCheckboxFacetComponent,
    MibpGridResizeHandleComponent,
    MibpValidationTextComponent,
    TestIdDirective,
    InlineEditingOverlayComponent,
    OrganizationPickerComponent,
    OrganizationListSelectorComponent,
    MibpFiledropDirective,
    MibpProductPriceComponent,
    MibpDateTimeComponent,
    MibpSidebarComponent,
    MibpHeaderComponent,
    MibpSandvikIconComponent,
    MibpFooterV2Component,
    MibpBreadcrumbsV2Component,
    MibpResourceStringAttributesDirective,
    MibpPopupContainerComponent,
    MibpHeaderCartComponent,
    MibpHeaderActivityComponent,
    MibpHeaderSystemMessageComponent,
    MibpHeaderLanguageComponent,
    MibpHeaderUserComponent,
    MibpHeaderSearchComponent,
    MibpUserPhotoComponent,
    MibpMobileMenuComponent,
    MibpOnboardingComponent,
    MibpFeatureTourComponent,
    MibpProductPickerComponent,
    AddtocartDialogComponent,
    UpsellComponent,
    CrossSellComponent,
    AvailabilityComponent,
    ExpectedDeliveryDateAvailabilityComponent,
    MibpEquipmentPickerComponent,
    FilterComponent,
    MibpHorizontalScrollContainerComponent,
    MibpProductWeightComponent,
    MibpMyIfHasFeatureDirective,
    MibpMyIfHasAllFeaturesDirective,
    MibpMyIfHasAnyFeaturesDirective,
    MibpInlineHelpComponent,
    MibpAspectRatioDirective,
    SystemMessageDialogComponent,
    ToggleSwitchComponent,
    LightBoxComponent,
    MibpHighlightTextComponent,
    MapComponent,
    AddressLinesComponent,
    MibpGeneralErrorComponent,
    MibpClientIdComponent,
    MibpChangelogChangeComponent,
    MibpFeaturePickerComponent,
    MibpSalesforceEmbeddedServiceComponent,
    MibpSupportTeamToolbarComponent,
    MibpSupportTeamPageMenuComponent,
    MibpSupportTeamMemberContextMenuComponent,
    ProductEquipmentListComponent,
    EquipmentStatusComponent,
    EquipmentStatusDescriptionComponent,
    MibpBreadcrumbsV2ManualControlComponent,
    MachineFilterComponent,
    MibpGoogleMapsScriptComponent,
    ExpectedDeliveryDateComponent,
    MibpCopyToClipboardComponent,
    ChangeTrackingCommentDialogComponent,
    MibpTimezoneDateTimePickerComponent,
    NumericInputComponent,
    MibpAddressViewComponent,
    MibpInterimDeliverySequenceComponent,
    CartQuickviewComponent,
    ContactUsMainPageComponent,
    MibpCountdownComponent,
    MibpDocumotoWidgetComponent,
    MibpSupportTeamFileViewerDialogComponent,
    MibpPartsManualComponent,
    ElectronicManualReporterrorComponent,
    MibpPartsManualTreeviewComponent,
    MibpPartsManualFolderDetailsComponent,
    MibpPartsManualIllustrationComponent,
    PartsManualWhereUsedComponent,
    MibpImagesCarouselComponent,
    MibpProductNotificationComponent,
    MibpClickToCopyDirective,
    MibpTextAreaAutosizeDirective,
    MibpElectronicManualsWhereUsedItemComponent,
    MibpMyFleetElectronicManualRelatedComponent,
    MibpTextAreaAutosizeDirective,
    MibpManualDownloadStatusComponent,
    MibpHighightWhitespacesComponent,
    MibpBlobLogViewComponent,
    MibpFilterTagComponent,
    MibpPdfViewerComponent,
    MibpActiveAcountPickerComponent,
    //MibpAddHorizontalScrollbarDirective,
    PartsManualWhereUsedComponent
  ],
  providers: [
    {provide: Window, useValue: window},
    provideCharts(withDefaultRegisterables())
  ]
})

export class GlobalSharedModule {

}
