import { Component, Input, Output, EventEmitter, OnDestroy, OnInit } from '@angular/core';
import { MibpLogger, LogService, ResponsibilityPickerService, LocalizationService } from 'root/services';
import { MessageType } from 'root/components/system-message/system-message.enum';
import { ResolvedScope } from 'root/mibp-openapi-gen/models';

@Component({
  selector: 'mibp-resolved-scopes-list',
  templateUrl: './resolved-scopes-list.component.html',
  styleUrls: ['./resolved-scopes-list.component.scss']
})
export class ResolvedScopesListComponent implements OnInit, OnDestroy {


  private dependecyOrder = ['company', 'customer', 'deliverySequence', 'productArea', 'productGroup', 'productSubgroup', 'productModel', 'equipment'];

  resolvedScopeList: ExtendedResolveScoped[];
  originalScopeList: ResolvedScope[];
  log: MibpLogger;
  messageType = MessageType;
  anyValidationErrors = false;
  @Input() anyResourceStringKey: string;
  stopUsingResources: () => void;
  hasInactiveResposibility: boolean;
  @Input() addResposibilityDialog: boolean;
  @Output() delete: EventEmitter<number> = new EventEmitter();
  @Input() hideDeliverySequence = false;
  @Input() hideBusinessRelation = false;
  @Input() hideOperationSite = false;
  @Input() hideProductColumn = false;
  @Input() hideModelColumn = false;
  @Input() hideEquipmentColumn = false;
  @Input() useScrolledList = false;
  @Input() enableDependencyValidation = false;
  @Output() validation = new EventEmitter<{ isValid: boolean }>();
  @Input()
  set resolvedScopes(scopes: ResolvedScope[]) {

    if (scopes) {
      try {
        // Copy! We never want to change stuff by reference
        scopes = JSON.parse(JSON.stringify(scopes));
      } catch {
        // Do nothing
      }
    }
    this.originalScopeList = scopes ? scopes.slice(0) : [].slice(0);
    this.resolvedScopeList = scopes ? this.resolveScopeDependencies(scopes.slice(0)) : [];
    if(this.resolvedScopeList.length > 0){
      this.hasInactiveResposibility =  this.resolvedScopeList.filter((s) => { return !s.isActive;}).length > 0;
    }
  }

  get resolvedScopes(): ResolvedScope[] {
    return this.originalScopeList ? this.originalScopeList.slice(0) : [].slice(0);
  }

  constructor(logger: LogService, private responsibilityPickerService: ResponsibilityPickerService,
    private localization: LocalizationService) {
    this.log = logger.withPrefix('resolved-scopes-list');
  }

  anyText: string;

  ngOnInit(): void {
    //Called after the constructor, initializing input properties, and the first call to ngOnChanges.
    //Add 'implements OnInit' to the class.
    this.stopUsingResources = this.localization.using([this.anyResourceStringKey], (values: string[]) => this.anyText = values[0]);
  }

  ngOnDestroy(): void {
    if (this.stopUsingResources) {
      this.stopUsingResources();
    }
  }


  triggerDelete(index: number) {
    this.delete.emit(index);
  }

  getScopeIndex(scope: ResolvedScope, index: number): number {
    if (typeof(scope['originalIndex']) !== 'undefined') {
      return scope['originalIndex'];
    }
    return index;
  }

  clone(obj: any) {
    const clone = {};
    Object.keys(obj).forEach(key => {
      if(key == "isActive"){
        clone[key] = obj[key];
      }
      else{
        clone[key] = obj[key] ? Object.assign({}, obj[key]) : null;
      }
    });
    return clone;
  }



  resolveScopeDependencies(scopes: ResolvedScope[]): ExtendedResolveScoped[] {

    if (!scopes || scopes.length === 0) {
      this.validation.emit({isValid: true});
      this.anyValidationErrors = false;
      return [];
    }

    if (!this.enableDependencyValidation) {
      return scopes.map(x => <ExtendedResolveScoped>this.clone(x));
    }

    const copy = scopes.map(x => <ResolvedScope>this.clone(x));
    const result = this.responsibilityPickerService.validateScope(copy);

    this.anyValidationErrors = Object.keys(result).length > 0;

    this.validation.emit({isValid: !this.anyValidationErrors});

    const scopeList = copy.map<ExtendedResolveScoped>((item, index) => {
      const ex = <ExtendedResolveScoped>this.clone(item);
      ex.originalIndex = index;

      if (result.findIndex(f => f.itemIndex === index) !== -1) {
        return null;
      }

      const children = result.filter(f => f.conflictsWithIndex === index).map(f => Object.assign({}, this.clone( copy[f.itemIndex] ), {
        originalIndex: f.itemIndex
      }) as ExtendedResolveScoped );

      if (children.length > 0) {
        ex.children = children;
      }

      return ex;
    }).filter(f => f);

    return scopeList.slice();
  }

}

export interface ExtendedResolveScoped extends ResolvedScope {
  children?: ResolvedScope[];
  originalIndex?: number;
  validationError?: boolean;
}
