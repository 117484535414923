import { HttpClient } from "@angular/common/http";
import { Component, Input, ViewChild } from "@angular/core";
import { firstValueFrom } from "rxjs";
import { DialogComponent } from "../dialog/dialog.component";
import { DialogButton } from "../dialog/dialog.types";
import { MibpLogApiController } from "root/mibp-openapi-gen/controllers";
import { LocalizationService, MibpHttpApi, ToastService } from "root/services";
import { MibpOperationsService } from "root/services/operations/operations.service";
import { IntegrationMessageTypeVm } from "root/modules/operations/pages/post-integration-messages/post-integration-message.types";
import { ToastType } from "root/services/toast-service/toast.enum";

export class SupportTeamFileViewerItem {
  url: string;
  title?: string;
  rawContent?: string;
  prettifiedContent?: string;
  hasError?: boolean;
  hasPrettifyError?: boolean;
}

@Component({
  selector: 'mibp-support-team-file-view-dialog',
  templateUrl: './support-team-file-viewer-dialog.component.html',
  styleUrl: './support-team-file-viewer-dialog.component.scss'
})
export class MibpSupportTeamFileViewerDialogComponent {

  @Input() reSubmitMessageIdentifier?: string;
  @ViewChild(DialogComponent) dialog: DialogComponent;

  protected isLoading = true;
  protected rawResult?: string;
  protected resubmitMessageTypes: IntegrationMessageTypeVm[] = this.operationsService.getResubmittableIntegrationMessageTypes();
  protected fileList: SupportTeamFileViewerItem[];
  protected activeFileIndex = 0;
  protected view: 'raw' | 'pretty' = 'raw';
  protected showSubmitFile = false;
  protected buttons: DialogButton[] = [
    {
      resourceKey: 'Global_CloseButton',
      id: 'close'
    }
  ];

  constructor(private http: HttpClient, private httpApi: MibpHttpApi, private toast: ToastService, private operationsService: MibpOperationsService, private localization: LocalizationService) {}


  protected downloadFile() {

    let filename = this.fileList[this.activeFileIndex].title;
    let type = 'text/plain';

    if (filename.toLowerCase().endsWith('.json')) {
      type = 'application/json';
    } else if (filename.toLowerCase().endsWith('.xml')) {
      type = 'application/xml';
    } else if (!filename.toLowerCase().endsWith('.txt')) {
      filename += ".txt";
    }

    const file = new File([this.fileList[this.activeFileIndex].rawContent], filename, {
      type: type,
    });

    const link = document.createElement('a');
    const url = URL.createObjectURL(file);

    link.href = url;
    link.download = file.name;
    document.body.appendChild(link);
    link.click();

    document.body.removeChild(link);
    window.URL.revokeObjectURL(url);

  }

  protected copyToClipboard(): void {

    try {
      const input = document.createElement('textarea');
      input.style.opacity = '0';
      input.style.position = 'absolute';
      input.style.zIndex = '0';
      input.value = this.fileList[this.activeFileIndex].rawContent;
      document.body.appendChild(input);

      /* Select the text field */
      input.select();
      input.setSelectionRange(0, 99999); /* For mobile devices */

      /* Copy the text inside the text field */
      document.execCommand("copy");

      document.body.removeChild(input);

      this.toast.showText('Copied to clipboard', {type: ToastType.Success});
    } catch(e) {
      this.toast.showText('Error copying to clipboard', {type: ToastType.Error});
    }
  }

  protected async toggleView(view: 'raw' | 'pretty'): Promise<void> {

    this.view = view;

    if (view == 'pretty' && typeof this.fileList[this.activeFileIndex].prettifiedContent == 'undefined') {
      if (!this.fileList[this.activeFileIndex].hasError && this.fileList[this.activeFileIndex].rawContent) {
        const svar = await this.httpApi.MibpLog.prettifyMessage(this.fileList[this.activeFileIndex].rawContent);
        this.fileList[this.activeFileIndex].prettifiedContent = svar.result;
      }
    }
  }

  protected get langCode(): string {
    return this.localization.getLang();
  }

  private async loadFile(index: number): Promise<void> {

    if (typeof this.fileList[index].rawContent != 'undefined') {
      return;
    }

    try {
      this.fileList[index].hasError = false;
      const result = await firstValueFrom(this.http.get(this.fileList[index].url, {
        responseType: 'text'
      }));
      this.fileList[index].rawContent = result;
      this.isLoading = false;
    } catch (e) {
      this.rawResult = 'Error';
      this.fileList[index].hasError = true;
      this.isLoading = false;
    }
  }

  public async open(urls: string[], names: string[] = null, openIndex = 0): Promise<void> {
    this.isLoading = true;
    this.view = 'raw';
    this.dialog.open();
    this.fileList = [];
    this.activeFileIndex = 0;

    for (let i = 0; i < urls.length; i++) {

      this.fileList.push({
        url: urls[i],
        title: i < names.length ? names[i] : 'File ' + (i+1)
      });

    }

    if (openIndex < this.fileList.length) {
      this.activeFileIndex = openIndex;
    }


    this.loadFile(this.activeFileIndex);

  }

  protected async selectFile(index: number): Promise<void> {
    this.activeFileIndex = index;
    await this.loadFile(this.activeFileIndex);
    await this.toggleView(this.view);
  }

  protected triggerDownload(): void {
    alert("Not yet");
  }


}
