
import { Injectable } from '@angular/core';
import { addMinutes } from 'date-fns';
import { FormattingService } from 'root/services';
import { format } from 'date-fns';
import { MibpDateTimeService } from 'root/services/datetime/datetime.service';


export interface MibpParsedDate {
  timzone: string;
  utcDateString: string;
  timezoneDateString: string;
  offsetMinutes: number;
  offsetString: string;
}

export interface TimezoneDateTimePickerResult {

  /**
   * The original input type that was used to resolve the data
   */
  inputType: 'date' | 'string';

  /**
   * The string representation that was interpeted from input value
   */
  parsedInputValue: string;

  /**
   * The raw string/date input object
   */
  rawInputValue: Date | string;


  /**
   * The ISO UTC date string for the given date and timezone
   */
  utcValue: string;

  /**
   * The timezone for which we want the UTC date
   */
  tzName: string;

  /**
   * The ISO date time string with the offset for the selected timezone
   */
  tzValue: string;

  /**
   * The number of offset minutes from UTC
   */
  utcOffsetMinutes: number;

  /**
   * UTC offset time in string format
   */
  utcOffsetString: string;

}

@Injectable()
export class TimezoneDateTimePickerComponentService {

  private timezones: string[];
  private browserTimezone = 'UTC';

  constructor(private formattingService: FormattingService, private datetimeService: MibpDateTimeService) {
    this.browserTimezone = this.datetimeService.getBrowserTimezone();
  }

  private parseInputDateFromDate(date: Date, timezone: string): TimezoneDateTimePickerResult {
    const utcTimezoneDateString = new Date(date.toLocaleString('sv-SE', { timeZone: 'UTC' }) + 'Z');
    const seletedTimezoneDateString = new Date(date.toLocaleString('sv-SE', { timeZone: timezone }));
    const offset = (seletedTimezoneDateString.getTime() - addMinutes(utcTimezoneDateString, utcTimezoneDateString.getTimezoneOffset()).getTime() ) / 6e4;
    const selectedDateTimeWithOffset = format(date, `yyyy-MM-dd'T'HH:mm:ss`) + this.datetimeService.timezoneOffsetToString(offset);
    const selectedTimezoneConvertIntoUTCISOString = this.formattingService.toServerUTCString(new Date(selectedDateTimeWithOffset));

    return {
      inputType: 'date',
      parsedInputValue: format(utcTimezoneDateString, `yyyy-MM-dd'T'HH:mm:ss.SSS`) + ' (' + timezone + ')',
      rawInputValue: date,
      utcValue: selectedTimezoneConvertIntoUTCISOString,
      tzValue: format(utcTimezoneDateString, `yyyy-MM-dd'T'HH:mm:ss.SSS`) + this.datetimeService.timezoneOffsetToString(offset),
      utcOffsetMinutes: offset,
      utcOffsetString: this.datetimeService.timezoneOffsetToString(offset),
      tzName: timezone
    };

  }

  public convertUTCStringIntoDateInputDate(utcString: string, timezone: string): Date {
    const parsedTzDate = this.datetimeService.parseUtcISOString({utcDateString: utcString, timezone: timezone});
    if (parsedTzDate) {
      const dateMatch = /^(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2})/.exec(parsedTzDate.timezoneDateString);

      if (dateMatch) {
        const [_, year, month, day, hour, minute, second] = dateMatch;
        return new Date( parseInt(year, 10), parseInt(month, 10) - 1, parseInt(day, 10), parseInt(hour, 10), parseInt(minute, 10), parseInt(second, 10) );
      }

    }
    return null;
  }

  /**
   * To use when parsing a date from a datepicker.
   * The visible date/time is always assumed to be of the specified timezone
   *
   * So if user selects date 2023-04-03 11:23 in date/time picker, and selected timezone is Asia/Kolkata
   * Then the selected date is actually:
   *
   *   2023-04-03 11:23 +0530
   *
   * and UTC would be
   *
   *   2023-04-03T05:53:00Z
   *
   * @param date
   * @param timezone
   */
  public parseDatePickerDate(options: { date: Date, time?: string, timezone: string }): MibpParsedDate {
    if (options.time) {
      const time = this.datetimeService.parseStringAsTime(options.time);
      const newDate = new Date( options.date.getFullYear(), options.date.getMonth(), options.date.getDate(), time.hour, time.minute, time.second, time.ms );
      options.date = newDate;
    }

    const parsed = this.parseInputDateFromDate(options.date, options.timezone);

    return {
      utcDateString: parsed.utcValue,
      timezoneDateString: parsed.tzValue,
      timzone: parsed.tzName,
      offsetMinutes: parsed.utcOffsetMinutes,
      offsetString: parsed.utcOffsetString
    };

  }

  public parseInputDate(date: Date | string, timezone?: string): TimezoneDateTimePickerResult {
    if (typeof date === 'string') {
      return this.datetimeService.mapISODateStringIntoDatepickerResult(date, timezone || this.browserTimezone);
    } else if (date instanceof Date) {
      return this.parseInputDateFromDate(date, timezone || this.browserTimezone);
    } else {
      throw "Unknown input";
    }

    return null;
  }


  // Commented out attempts - keep for later improvements....

  /**
   * Use the date and time from the local date (ignoring any offset)
   * So the date 2023-04-03T11:23:00+0200 with timezone Asia/Kolkata would be:
   *  - Parsed as: 2023-04-03T11:23+0530     (+0200 is replaced with Asia/kolkata offset)
   *  - UTC:       2023-04-03T05:53:00Z
   * @param date
   * @param timezone
   */
  // public localDateAsTimezone(date: Date, timezone: string): void {

  // }

  /**
   * Using a local Date - Create a string using the offset for the given timezone instead
   *
   * Use when we get a local date object from input element
   *
   * @param date
   */
  // private dateToLocalStringWithTimezoneOffset(date: Date, timezone: string): string {
  //   const d = this.parseInputDateFromDate(date, timezone);
  //   return d.tzValue;
  // }

  // private toLocalStringWithTimezoneOffset(year: number, monthWithJanuaryAsZero: number, day: number, hour: number, minute: number, second: number, ms: number,
  //   timezone: string): string {
  //   const dateStrZ = `${year}-${(monthWithJanuaryAsZero + 1).toString().padStart(2, '0')}-${day.toString().padStart(2, '0')}T${hour.toString().padStart(2, '0')}:${minute.toString().padStart(2, '0')}:${second.toString().padStart(2, '0')}.${ms.toString().padStart(3, '0')}Z`;
  //   const d = this.datetimeService.mapISODateStringIntoDatepickerResult(dateStrZ, timezone);
  //   return dateStrZ.substring(0, dateStrZ.length - 1) + d.utcOffsetString;
  // }




}
