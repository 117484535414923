import { PaginationService } from './../../services/pagination-service/pagination.service';
import { Component, forwardRef, ViewChild } from "@angular/core";
import { Guid } from "guid-typescript";
import { ApiService, BroadcastService } from "root/services";
import { Observable } from "rxjs";
import { DropdownComponent } from "../dropdown/dropdown.component";
import { DropdownArgs, DropdownData, DropdownInput } from "../dropdown/dropdown.interface";
import { map } from 'rxjs/operators';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { ProductsApiController } from 'root/mibp-openapi-gen/controllers';
import { NewProduct, NewProductSearchSortBy, NewProductSearchItem } from 'root/mibp-openapi-gen/models';

@Component({
  selector: 'mibp-product-picker',
  templateUrl: './product-picker.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => MibpProductPickerComponent),
    }
  ]
})
export class MibpProductPickerComponent implements ControlValueAccessor {

    @ViewChild(DropdownComponent) dropdown: DropdownComponent;
    dropdownPageSize = 10;
    products$: Observable<DropdownData>;
    showLoader = false;
    isFirst = false;
    currentValue: any;

    // ControlValueAccessor
    isDisabled: boolean;
    // eslint-disable-next-line @typescript-eslint/no-empty-function, @typescript-eslint/no-explicit-any
    onChange: (value: any) => void = () => { };
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    onTouch: () => void = () => { };

    constructor(private api: ApiService, private pagination: PaginationService, private productsController: ProductsApiController, private broadcast : BroadcastService) {}

    writeValue(productCode: string | Guid | DropdownInput): void {

      if (typeof productCode === 'string') {
        this.showLoader = true;
        if (typeof this.currentValue === 'undefined') {
          this.isFirst = true;
        }
        this.productsController.getProductByCodeAndCompany({code:productCode,companyCode:null}).subscribe(product => {
          if (product) {
            this.dropdown.writeValue({
              text: this.formatProductText(product),
              value: product.id.toString()
            } as DropdownInput);
          }
          this.showLoader = false;
          this.isFirst = false;
        });
      } else if (productCode instanceof Guid) {
        this.productsController.getNewProductEntityById({id:productCode.toString()}).subscribe(product => {
          if (product) {
            this.dropdown.writeValue({
              text: this.formatProductText(product),
              value: product.id.toString()
            } as DropdownInput);
          }
          this.showLoader = false;
          this.isFirst = false;
        });
      } else if (productCode && 'value' in productCode ) {
        setTimeout(() => {
          this.dropdown.writeValue(productCode);
        }, 50);
      }
    }

    private formatProductText(product: NewProduct | NewProductSearchItem): string {
      return `${product.code} ${product.name}`;
    }

    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types,@typescript-eslint/no-explicit-any
    registerOnChange(fn: any): void {
      this.onChange = fn;
    }


    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types,@typescript-eslint/no-explicit-any
    registerOnTouched(fn: any): void {
      this.onTouch = fn;
    }
    setDisabledState?(isDisabled: boolean): void {
      this.isDisabled = isDisabled;
    }

    onDropdownValueChange(input: DropdownInput): void {
      if (input.value) {
        if (this.isFirst === false) {
          this.onTouch();
        }
        this.onChange(input);
      } else {
        this.onTouch();
        this.onChange(null);
      }
    }

    filterProducts(args: DropdownArgs): void {
      const pageNumber = this.pagination.getpageFromIndex(args.index, this.dropdownPageSize);
      this.products$ = this.productsController.searchProducts({
        body: {
          query: args.query,
          languageCode: this.broadcast.snapshot.language,
          take: args.take,
          skip: args.index*this.dropdownPageSize,
          itemClassNames: null,
          types: null,
          equipmentIds: null,
          hasPartsPictureUrl: null,
          sort: NewProductSearchSortBy.Relevance,
          onlyGlobal: true,
          filterRestrictedItems: true
        }
      }).pipe(map(searchResult => <DropdownData>{
        hasMoreResults: searchResult.count > args.index + searchResult.items.length,
        items: searchResult.items.map(product => <DropdownInput>{
          text: this.formatProductText(product),
          value: product.id
        }),
        totalCount: searchResult.count
      }));
    }

}

