<div class="title-container">
<h3>{{ pageTitle }}</h3>
</div>




<form [formGroup]="searchFormWhereUsedItem" (ngSubmit)="submitSearchForm()">

  <div class="searchbox">

    <div class="searchbox__input">
      <input formControlName="queryWhereusedItems" type="search"
        [mibpResourceStringAttributes]="{ placeholder: 'ElectronicManuals_Search_Placeholder'}">
    </div>
    <div class="searchbox__action">
      <button class="my-button"><mibp-resource-string key="ResourceString_Search"></mibp-resource-string></button>
    </div>
  </div>
</form>

<div *ngIf="showFilters" class="mobile-filters">
  <div class="filters">
      <div class="filterComponent">
        <label>
          <mibp-resource-string key="Global_ManualCategory"></mibp-resource-string>
        </label>
        <div>
          <mibp-dropdown [items]="categoryOptions" (valueChange)="onCategoryChange($event)"
          [multiselect]="true" [multiselectApply]="true" [alwaysTriggerSearch]="true" [newMultiSelectDesign]="true"></mibp-dropdown>
        </div>
      </div>
      <div class="filterComponent">
        <label>
          <mibp-resource-string key="UserReg_Language"></mibp-resource-string>
        </label>
        <div>
          <mibp-dropdown [items]="languageOptions" (valueChange)="onLanguageChange($event)"
          [multiselect]="true" [multiselectApply]="true" [alwaysTriggerSearch]="true" [newMultiSelectDesign]="true"></mibp-dropdown>
        </div>
      </div>
      <div class="filterComponent">
        <label>
          <mibp-resource-string key="WarrantyPortal_Field_MachineSerialNumber"></mibp-resource-string>
        </label>
        <div>
          <mibp-dropdown [items]="machineSerialOptions" (valueChange)="onMachineSerialChange($event)"
          [multiselect]="true" [multiselectApply]="true" [alwaysTriggerSearch]="true" [newMultiSelectDesign]="true"></mibp-dropdown>
        </div>
      </div>
      <div class="filterComponent">
        <label>
          <mibp-resource-string key="Global_Machine_Model"></mibp-resource-string>
        </label>
        <div>
          <mibp-dropdown [items]="productGroupOptions" (valueChange)="onProductGroupChange($event)"
          [multiselect]="true" [multiselectApply]="true" [alwaysTriggerSearch]="true" [newMultiSelectDesign]="true"></mibp-dropdown>
        </div>
      </div>
      <div class="filterComponent">
        <label>
          <mibp-resource-string key="Global_Machine_ModelGroup"></mibp-resource-string>
        </label>
        <div>
          <mibp-dropdown [items]="modelNameOptions" (valueChange)="onMachineModelChange($event)"
          [multiselect]="true" [multiselectApply]="true" [alwaysTriggerSearch]="true" [newMultiSelectDesign]="true"></mibp-dropdown>
        </div>
      </div>
  </div>
</div>

<div class="view">
  <div class="view__pagesize">
    <label>
      <mibp-resource-string key="Global_PageSizeDropdownLabel"></mibp-resource-string>
    </label>
    <mibp-dropdown [items]="pageSizeOptions" [selectedOption]="currentPageSizeOption"
      (valueChange)="onPageSizeChange($event)" #selectPageSize></mibp-dropdown>
  </div>
  <div class="view__sortby">
    <label>
      <mibp-resource-string key="BulletinSearch_Sortby"></mibp-resource-string>
    </label>
    <div>
      <mibp-dropdown [items]="sortByOptions" [selectedOption]="currentSortByOption"
        (valueChange)="onSortByChange($event)"></mibp-dropdown>
    </div>
  </div>
</div>

<div class="count" [class.count--hide]="!currentSearchResult?.count" [class.count--loading]="isLoading"
  *ngIf="!hasLoadingError">
  <mibp-resource-string key="Grid_StatusPageNumbers" [macros]="resultCountMacros"></mibp-resource-string>
</div>

<mibp-loader *ngIf="isLoading"></mibp-loader>

<mibp-system-message class="mt-5" #msg [messageType]="msg.Types.Error" *ngIf="hasLoadingError">
  <h2><mibp-resource-string key="General_Error_InlineTitle"></mibp-resource-string></h2>
</mibp-system-message>

<div class="no-items" *ngIf="!currentSearchResult?.count && !isLoading && !hasLoadingError">
  <mibp-resource-string key="Global_NoHits"></mibp-resource-string>
</div>
<div *ngIf="currentSearchResult?.count > 0">
    <div class="items" *ngIf="!isLoading">
      @for (media of pageItems; track media.identifier) {
      <div class="item">
        <div class="item__image">
          <span translate="no" class="material-icon">settings</span>
        </div>
        <div class="item__details">
          <h4>{{ media.name }}</h4>
          <table>
            <tbody>

              <tr>
                <th><mibp-resource-string key="Global_ManualCategory"></mibp-resource-string>:</th>
                <td>
                  @for (category of media.categories; track index; let i = $index) {
                  {{category}}<br *ngIf="i < media.categories.length - 1">
                  }
                </td>
              </tr>
              <tr>
                <th><mibp-resource-string key="Global_MachineSerialNumber"></mibp-resource-string>:</th>
                <td><a [routerLink]="media.equipmentRoute">{{media.equipmentName}}</a></td>
              </tr>
              <tr>
                <th><mibp-resource-string key="ElectronicManuals_MachineModel"></mibp-resource-string>:</th>
                <td>{{media.modelName}}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="item__actions">
          <a class="my-button my-button--primary" [routerLink]="media.mediaRoute" [queryParams]="{detailsSearchQuery: productcode}"><mibp-resource-string key="ElectronicManuals_ViewItemInManual"></mibp-resource-string></a>
        </div>
      </div>
      }
    </div>

    <div class="pagination">
      <mibp-paginator *ngIf="currentSearchResult?.count > 0" class="mibp-table-pagination"
        [startIndex]="currentSkipOption" [totalItems]="currentSearchResult.count" [pageSize]="currentTakeOption"
        (changePage)="onChangePage($event)"> </mibp-paginator>
    </div>
</div>
