
<div class="address-lines" *ngIf="(deliverySequenceId || orderId) && !isLoading && !hasError">
  <table class="my-table my-table--nowrap addresslines">
    <thead>
      <tr>
        <th *ngIf="deliveryType === 'deliver'"><mibp-resource-string key="Carts_DeliveryAddress"></mibp-resource-string></th>
        <th><mibp-resource-string key="Carts_InvoiceAddress"></mibp-resource-string></th>
      </tr>
    </thead>
    <tbody *ngIf="addressLines">
      <tr>
        <td *ngIf="deliveryType === 'deliver'"  style="border-bottom: none">
          <div class="deliveryaddress">
            <mibp-address-view *ngIf="!isEditingDeliveryAddress" [address]="overriddenDeliveryAddress || this.addressLines.deliveryAddress"></mibp-address-view>
          </div>

          <div class="mt-1">
            <mibp-button *ngIf="!isEditingDeliveryAddress && allowDeliveryAddressOverride" #btn [color]="btn.Enum.Colors.Secondary" type="button" resourceStringKey="EditAddress" (click)="beginEditDeliveryAddress()"></mibp-button>
          </div>

          <div class="mt-2 source-note" *ngIf="!isEditingDeliveryAddress && overriddenDeliveryAddress && broadcast.supportToolbarOpen | async">
            Address has been updated by user
          </div>
          <div class="mt-2 source-note" *ngIf="!isEditingDeliveryAddress && !overriddenDeliveryAddress && broadcast.supportToolbarOpen | async">
            This address was read from <strong>{{deliveryAddressSource}}</strong><br>
            {{editDisabledReason}}
          </div>

          <div *ngIf="isEditingDeliveryAddress">

            <form #deliveryAddressFormElement class="deliveraddressform" [formGroup]="deliveryAddressForm" (ngSubmit)="onSubmit()">

              <div class="forms__row">
                <label for="addressLine1"><mibp-resource-string key="addressLine1"></mibp-resource-string><span class="required">(<mibp-resource-string key="Global_Required"></mibp-resource-string>)</span></label>
                <input id="addressLine1" type="text" formControlName="addressLine1" maxlength="35" [mibpResourceStringAttributes]="{ placeholder: 'Global_ReferenceNumber_Placeholder' }">
                <mibp-validation-text
                  [additionalInformationResourceKeys]="{ 'pattern': 'Global_ReferenceNumber_Placeholder' }"
                  [formGroup]="deliveryAddressForm" controlName="addressLine1" [alwaysVisible]="isSubmitted"></mibp-validation-text>
              </div>

              <div class="forms__row">
                <!-- Maxlength in aurora is 35. Any more characters are truncated -->
                <label for="addressLine2"><mibp-resource-string key="addressLine2"></mibp-resource-string></label>
                <input id="addressLine2" type="text" formControlName="addressLine2" maxlength="35" [mibpResourceStringAttributes]="{ placeholder: 'Global_ReferenceNumber_Placeholder' }">
                <mibp-validation-text [formGroup]="deliveryAddressForm" controlName="addressLine2"></mibp-validation-text>
              </div>

              <div class="forms__row">
                <!-- Maxlength in aurora is 35. Any more characters are truncated -->
                <label for="addressLine3"><mibp-resource-string key="addressLine3"></mibp-resource-string></label>
                <input id="addressLine3" type="text" formControlName="addressLine3" maxlength="35" [mibpResourceStringAttributes]="{ placeholder: 'Global_ReferenceNumber_Placeholder' }">
                <mibp-validation-text [formGroup]="deliveryAddressForm" controlName="addressLine3"></mibp-validation-text>
              </div>

              <div formGroupName="municipality" id="municipalityGroup">

                <div class="forms__row">
                  <label for="postalCode"><mibp-resource-string key="Global_post_code"></mibp-resource-string><span class="required">(<mibp-resource-string key="Global_Required"></mibp-resource-string>)</span></label>
                  <input id="postalCode" [@autocorrectedField]="postalCodeAutocorrectState" (blur)="autoCorrectPostalCodeIfNeeded()" type="text" formControlName="postalCode" maxlength="50" [mibpResourceStringAttributes]="{ placeholder: 'Global_ReferenceNumber_Placeholder' }">
                  <mibp-validation-text
                  [additionalInformationResourceKeys]="{ 'postCodeInvalid':  getPostCodeValidationErrorForCurrentCountry() }"
                  [formGroup]="deliveryAddressForm.controls.municipality" controlName="postalCode" [alwaysVisible]="isSubmitted"></mibp-validation-text>
                </div>

                <div class="forms__row">
                  <!-- Maxlength in aurora is 35. Any more characters are truncated -->
                  <label for="city"><mibp-resource-string key="Global_City_Name"></mibp-resource-string><span class="required">(<mibp-resource-string key="Global_Required"></mibp-resource-string>)</span></label>
                  <input id="city" type="text" formControlName="city" maxlength="50" [mibpResourceStringAttributes]="{ placeholder: 'Global_ReferenceNumber_Placeholder' }">
                  <mibp-validation-text [formGroup]="deliveryAddressForm.controls.municipality" controlName="city" [alwaysVisible]="isSubmitted"></mibp-validation-text>
                </div>

              </div>

              <!-- Validation elements for the formGroup-->
              <label for="municipalityGroup" class="hidden"><mibp-resource-string key="Carts_DeliveryAddress"></mibp-resource-string></label>
              <mibp-validation-text class="mb-2" [formGroup]="deliveryAddressForm" [alwaysVisible]="true"
              [resourceKeyMappings]="{ 'addressLookup': 'ValidationMessage_DeliveryAddressNotFound' }"
              controlName="municipality"></mibp-validation-text>

              <div class="forms__row">
                <label for="countryName"><mibp-resource-string key="Global_Country"></mibp-resource-string></label>
                <input id="countryName" type="text" readonly formControlName="countryName">
                <mibp-validation-text [formGroup]="deliveryAddressForm" controlName="countryName" [alwaysVisible]="isSubmitted"></mibp-validation-text>
              </div>

              <div class="my-toolbar">
                <div class="my-toolbar__left">

                </div>
                <div class="my-toolbar__right">
                  <mibp-button #btn  [color]="btn.Enum.Colors.Secondary" type="button" type="button" resourceStringKey="Global_CancelButton" (click)="cancelEditDeliveryAddress()"></mibp-button>

                  <button type="submit" [disabled]="this.deliveryAddressForm.pending" class="my-button my-button--primary"><mibp-resource-string key="Global_Update"></mibp-resource-string></button>
                </div>
              </div>


              <div class="mt-2 source-note" *ngIf="broadcast.supportToolbarOpen | async">
                {{ this.globalConfig.enableEditAddressMapsLookup ? 'Address validation lookup is ENABLED.' : 'Address lookup is DISABLED. Any post code/city combination will work' }}
              </div>


            </form>

          </div>
        </td>
        <td class="invoiceaddress">
          <mibp-address-view [address]="this.addressLines.invoiceAddress"></mibp-address-view>
          <div class="mt-2 source-note" *ngIf="broadcast.supportToolbarOpen | async">
            This address was read from <strong>{{invoiceAdressSource}}</strong>
          </div>
        </td>
      </tr>
    </tbody>
  </table>
</div>
<mibp-loader *ngIf="isLoading && !hasError"></mibp-loader>

<mibp-system-message #msg [messageType]="msg.Types.Error" *ngIf="hasError">
  <mibp-resource-string key="Global_Error_LoadingAddresses"></mibp-resource-string>
</mibp-system-message>
