import { FormattingService } from 'root/services';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({name: 'abbreviateNumber'})
export class AbbreviateNumberPipe implements PipeTransform {

  constructor(private formattingService: FormattingService) {}

  transform(value: number): string {
    return !value ? '0' : this.formattingService.abbreviateNumber(value);
  }
}
