
  <mibp-active-account-picker #activeAccountPicker></mibp-active-account-picker>
  <div class="sidebar bg--navigation" [class.sidebar--hover-menu-enabled]="globalConfig.sidebarHoverEnabled ? 'true' : 'false'">

    <div class="mysandvik-sidebar panel1 my-sandvik-navigation-panel fg--navigation bg--navigation">

      <div class="mysandvik-sidebar__logotype logotype">
        <img  src="assets/images/logo_white.svg" alt="[Sandvik Logotype]">
      </div>

      <p class="mysandvik-sidebar__fullname fg--navigation">
        {{ userFullName }}
      </p>

      <p  class="mysandvik-sidebar__email fg-dark--navigation-dark" [attr.title]="userEmail">
        {{ userEmail }}
      </p>

      @if (globalConfig.enableEquipmentScopingBasedOnCustomers) {

        <div class="mysandvik-sidebar__actas mysandvik-sidebar__actas--no-overflow">
          <span class="current-account" [attr.title]="actingAsText">{{ currentActAsText }}</span>

          <div *ngIf="canActAs" class="change-account">
            <a (click)="activeAccountPicker.open()"><mibp-resource-string key="ActAs_ChangeAccount"></mibp-resource-string></a>
          </div>

        </div>
      } @else {
      <div class="mysandvik-sidebar__actas" [attr.title]="actingAsText" #actAsDropdown>
        <div *ngIf="canActAs" class="act-as-fake-select" (click)="showActAs($event)">
          <span>
            <span>
              {{ actingAsText }}
              <mibp-resource-string *ngIf="!actingAsText" key="ActAs_None"></mibp-resource-string>
            </span>
          </span>
        </div>
        <!-- <div *ngIf="!canActAs" class="act-as-fake-select cant-act-as">
          <span>
            <span [hidden]="!actingAsText">
              {{ actingAsText }}
            </span>
          </span>
        </div> -->
      </div>
      <mibp-act-as *ngIf="canActAs" #actAsMenu></mibp-act-as>
      <span class="mysandvik-sidebar__actas mysandvik-sidebar__actastext">{{currentActAsText}}</span>
    }
  </div>


  <div class="panel2 my-sandvik-navigation-panel bg--navigation">

    <nav>
      <ul>
        <ng-template ngFor let-item [ngForOf]="filteredItems(items)" [ngForTrackBy]="trackById">
          <li class="root-item"
            [class.has-hovermenu-open]="hoverItem == item"
            [class.root-item--expanded]="isRootNodeExpanded(item)"
            *ngIf="(!item.onlyShowWhenActive || (item.onlyShowWhenActive && isRootNodeExpanded(item)))">

            <!-- If navigation item has a route, then use angular routing -->
            <a
              (mouseover)="onRootItemHover($event, item)"
              *ngIf="item.calculated?.fullPath"
              [attr.data-aut]="item.automatedTestId"
              [routerLink]="item.calculated?.fullPath"
              [routerLinkActive]="item.calculated?.fullPath && !item.calculated?.hasChildWithEmptyPath && item.resourceKey != 'SmartMate_Title'  ? 'active' : ''"
              [routerLinkActiveOptions]="{exact: item.route?.exactMatch ? true : false}" (click)="collapseMenu(item)">

              <span *ngIf="item.sandvikIconName" translate="no" class="material-icon material-icon--white material-icon--xxlarge material_icon__menumargin">{{item.sandvikIconName}}</span>
              <span class="item__text">
                <mibp-resource-string [key]="item.resourceKey || 'x'" [defaultValue]="item.text"></mibp-resource-string>
                <span class="my-badge my-badge--inline my-badge--limit-width my-badge--bouncy-highlight" [class.my-badge--longtext]="item.calculated.badge?.value?.length > 2" *ngIf="item.calculated.badge?.value" [class.my-badge--orange]="item.calculated.badge?.color == 'orange'" [class.my-badge--blue]="item.calculated.badge?.color == 'blue'">{{ item.calculated.badge?.value }}</span>
              </span>
              <span *ngIf="isChildNodeVisible(item) && item.children?.length > 0 && !isRootNodeExpanded(item)" translate="no" class="material-icon material-icon--white material_icon__menumargin">navigate_next</span>
              <span *ngIf="isChildNodeVisible(item) && item.children?.length > 0 && isRootNodeExpanded(item)" translate="no" class="material-icon material-icon--white material_icon__menumargin">expand_more</span>
            </a>

            <!-- Display a Disabled link if no route or external url is available-->
            <span *ngIf="!item.calculated?.fullPath && !item.externalUrl">
              <span *ngIf="item.sandvikIconName" translate="no" class="material-icon material-icon--white material-icon--xxlarge material_icon__menumargin">{{item.sandvikIconName}}</span>
              <span class="item__text"><mibp-resource-string [key]="item.resourceKey"></mibp-resource-string></span>
            </span>

            <!--For external links, open in a new window -->
            <a *ngIf="!item.calculated?.fullPath && item.externalUrl" [href]="item.externalUrl" target="_blank">
              <span *ngIf="item.sandvikIconName" translate="no" class="material-icon material-icon--white material-icon--xxlarge material_icon__menumargin">{{item.sandvikIconName}}</span>
              <span class="item__text"><mibp-resource-string [key]="item.resourceKey"></mibp-resource-string></span>
            </a>


            <ng-template [ngTemplateOutlet]="childItemsTmpl" [ngTemplateOutletContext]="{ item: item, visible: item.children && isRootNodeExpanded(item) }"></ng-template>

          </li>
        </ng-template>

      </ul>

    </nav>

  </div>

</div>

<ng-template #hoverMenuTemplate>
  <div id="sidebarhovermenu" class="sidebar bg--navigation hovermenu" [style.visibility]="hoverItem ? 'visible' : 'hidden'"  [style.top]="hoverMenuTop + 'px'">
    <div class="my-sandvik-navigation-panel">
      <nav>
        <ng-template [ngTemplateOutlet]="childItemsTmpl" [ngTemplateOutletContext]="{ item: hoverItem, visible: hoverItem }"></ng-template>
      </nav>
    </div>
  </div>
</ng-template>

<ng-template #childItemsTmpl let-item="item" let-visible="visible">
  <ul *ngIf="visible">
    <li *ngFor="let child of filteredItems(item.children); trackBy:trackById" class="child-item">

      <a class="item__text" [attr.data-aut]="child.automatedTestId" *ngIf="!child.externalUrl && !child.disabled && child.calculated?.fullPath" [routerLink]="child.calculated?.fullPath" #xab="routerLinkActive"
      [routerLinkActiveOptions]="{exact: child.route?.exactMatch ? true : false}"
      [routerLinkActive]="child.calculated?.fullPath ? 'active' : undefined">
        <mibp-resource-string [key]="child.resourceKey || 'x'" [defaultValue]="child.text"></mibp-resource-string>
        <span class="my-badge my-badge--inline my-badge--limit-width my-badge--bouncy-highlight"
        [class.my-badge--longtext]="child.calculated.badge?.value?.length > 2"
        [class.my-badge--orange]="child.calculated.badge?.color == 'orange'"
        [class.my-badge--blue]="child.calculated.badge?.color == 'blue'"
        *ngIf="child.calculated.badge?.value">{{ child.calculated.badge?.value }}</span>
      </a>
      <a class="item__text" [attr.data-aut]="child.automatedTestId" [attr.href]="child.externalUrl" target="_blank" *ngIf="!child.disabled && child.externalUrl">
        <mibp-resource-string [key]="child.resourceKey || 'x'" [defaultValue]="child.text"></mibp-resource-string>
        <span *ngIf="item.rightSandvikIconName" translate="no" class="material-icon material-icon--white material_icon__menumargin">{{item.rightSandvikIconName}}</span>
        <span class="my-badge my-badge--inline my-badge--limit-width my-badge--bouncy-highlight"
        [class.my-badge--longtext]="child.calculated.badge?.value?.length > 2"
        [class.my-badge--orange]="child.calculated.badge?.color == 'orange'"
        [class.my-badge--blue]="child.calculated.badge?.color == 'blue'"
        *ngIf="child.calculated.badge?.value">{{ child.calculated.badge?.value }}</span>
      </a>
      <span class="item__text" [attr.data-aut]="child.automatedTestId" *ngIf="child.disabled || (!child.calculated?.fullPath && !child.externalUrl)">
        <mibp-resource-string [key]="child.resourceKey"></mibp-resource-string>
        <span *ngIf="item.rightSandvikIconName" translate="no" class="material-icon material-icon--white material_icon__menumargin">{{item.rightSandvikIconName}}</span>
        <span class="my-badge my-badge--inline my-badge--limit-width my-badge--bouncy-highlight"
        [class.my-badge--longtext]="child.calculated.badge?.value?.length > 2"
        [class.my-badge--orange]="child.calculated.badge?.color == 'orange'"
        [class.my-badge--blue]="child.calculated.badge?.color == 'blue'"
        *ngIf="child.calculated.badge?.value">{{ child.calculated.badge?.value }}</span>
      </span>
    </li>
  </ul>
</ng-template>
