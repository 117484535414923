import { OrderViewModelSearchResponse } from './../../mibp-openapi-gen/models/order-view-model-search-response';
import { OrdersApiController } from './../../mibp-openapi-gen/services/orders-api-controller';
import { Injectable } from '@angular/core';
import { PagedUserRefinementOptions } from 'root/mibp-openapi-gen/models';
import { firstValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class OrderService {

  constructor(private ordersController: OrdersApiController) { }

  public List(filter: PagedUserRefinementOptions, getQuotations: boolean, sortBy: 'newest' | 'oldest' = 'newest'): Promise<OrderViewModelSearchResponse> {
    return firstValueFrom(this.ordersController.listOrders({body:{
      take: filter.take,
      skip: filter.skip,
      query: filter.query,
      from: filter.from?.toString(),
      to: filter.to?.toString(),
      status: filter.status,
      companyDisplayNames: filter.companyDisplayNames,
      customerDisplayNames: filter.customerDisplayNames,
      deliverySequenceDisplayNames: filter.deliverySequenceDisplayNames,
      linkedSerialNumberId: filter.linkedSerialNumberId
    }, getQuotations:getQuotations, sortOrder: sortBy}));
  }
  
  public ListMachineOrders(filter: PagedUserRefinementOptions,  sortBy: 'newest' | 'oldest' = 'newest'): Promise<OrderViewModelSearchResponse> {
    return firstValueFrom(this.ordersController.listMachineOrders({body:{
      take: filter.take,
      skip: filter.skip,
      query: filter.query,
      from: filter.from?.toString(),
      to: filter.to?.toString(),
      status: filter.status,
      companyDisplayNames: filter.companyDisplayNames,
      customerDisplayNames: filter.customerDisplayNames,
      deliverySequenceDisplayNames: filter.deliverySequenceDisplayNames      
      }, sortOrder: sortBy}));
  }
}
