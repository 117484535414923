
import { Subscription, firstValueFrom } from 'rxjs';
import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { DownloadedManualErrorCode, DownloadedManualFileType, DownloadedManualStatus, MediaViewModel } from 'root/mibp-openapi-gen/models';
import { MediaApiController } from 'root/mibp-openapi-gen/controllers';
import { ApiService, GlobalConfigService, LogService, MibpHttpApi, MibpLogger, ToastService } from 'root/services';
import { DownloadedManualsService } from 'root/services/downloaded-manuals/downloaded-manuals.service';
import { ActiveMibpToast } from 'root/services/toast-service/toast.service.interface';
import { MibpSessionService } from 'root/services/mibp-session/mibp-session.service';
import { MySandvikFeatures } from 'root/services/permission';
@Component({
  selector: 'mibp-manual-download-button',
  templateUrl: './manual-download-button.component.html',
  styleUrl: './manual-download-button.component.scss',

})
export class ManualDownloadButtonComponent implements OnInit, OnDestroy {

  protected downloadFileTypes = DownloadedManualFileType;
  protected downloadedManualStatus = DownloadedManualStatus;
  private log: MibpLogger;
  private activeToast?: ActiveMibpToast;
  private activeToastActionSub?: Subscription;
  private activeToastCloseSub?: Subscription;
  private mediaDownloadEventsSub?: Subscription;
  protected canDownloadManuals = false;
  isSupportTeamMember = false;

  @Input() equipmentId: number;
  @Input() downloadErrorCode: DownloadedManualErrorCode;
  @Input() downloadStatus: DownloadedManualStatus;
  @Input() downloadedManualId?: number;
  @Input() fileSize?: number;
  @Input() fileType?: DownloadedManualFileType;
  @Input() mediaId: number;
  @Input() mediaName?: string;
  @Input() shelf?: string ;
  @Input() shelfId: number;
  @Input() userDownloadedManualId?: number;
  @Input() displayBlock = true;

  constructor(
    private signalRApi: ApiService,
    private session: MibpSessionService,
    private mediaApiController: MediaApiController,
    private downloadedManualsService: DownloadedManualsService,
    protected globalConfigService: GlobalConfigService,
    private mibpHttp: MibpHttpApi,
    logService: LogService,
    private toastService: ToastService) {
    this.log = logService.withPrefix('mibp-manual-download-button');
  }
  ngOnDestroy(): void {
    this.mediaDownloadEventsSub?.unsubscribe();

    if (this.activeToast) {
      this.toastService.removeToast(this.activeToast.toastId);
      this.activeToastActionSub?.unsubscribe();
      this.activeToastCloseSub?.unsubscribe();
    }
  }

  ngOnInit(): void {
  this.isSupportTeamMember = this.session.current?.user?.isSupportTeamMember;
  this.canDownloadManuals = this.session.hasFeature(MySandvikFeatures.MyfleetDownloadManuals);

  this.mediaDownloadEventsSub = this.signalRApi.EventHub.ManualDownloadStatusUpdate.subscribe(event => {
      if (event) {
        if (this.mediaId ==  event.mediaId) {
          this.downloadStatus = (<any>event.status as DownloadedManualStatus);
          this.fileSize = event.pdfFileSize;
          this.downloadErrorCode = <any>event.downloadErrorCode;
          this.fileType = <any>event.fileType;
        }
      }
    });
  }



  async onDownloadManualClick(): Promise<void> {

    if (!this.fileSize) {
      const previousStatus = this.downloadStatus;
      try {
        this.downloadStatus = DownloadedManualStatus.Queued;
        this.userDownloadedManualId = -1;
        const result = await firstValueFrom(this.mediaApiController.requestManualDownload({
          mediaId: this.mediaId,
          equipmentId: this.equipmentId
        }));

        this.downloadStatus = result.userDownloadedManuals[0].downloadedManualStatus;
        this.fileSize = result.userDownloadedManuals[0].fileSize;
        this.userDownloadedManualId = result.userDownloadedManuals[0].userDownloadedManualId;

        if (this.activeToast) {
          this.activeToastActionSub?.unsubscribe();
          this.activeToastActionSub?.unsubscribe();
          this.toastService.removeToast(this.activeToast.toastId);
        }

        if (this.downloadStatus == DownloadedManualStatus.Downloaded) {
          this.downloadedManualsService.showDownloadReadyToast(this.mediaName);
        } else {
          this.downloadedManualsService.showExportStartedToast(this.mediaName);
        }

      } catch (e) {
        this.userDownloadedManualId = null;
        this.downloadStatus = previousStatus;
        this.downloadedManualsService.showErrorStartingExport();
        this.log.error('Error starting export of media', e);
      }

    } else {
      window.location.href = this.mibpHttp.resolveUriWithJwtToken(`media/${this.mediaId}/${this.equipmentId}/download`);

      // When a user downloads a manual that is already available in My Sandvik, but user has not requested the manual

      if (!this.userDownloadedManualId) {
        this.downloadedManualsService.showManualAddedToDownloadsToast(this.mediaName);
        this.userDownloadedManualId = -1; // We will not know the id, but it should exist...
      }
    }
  }

//TODO Remove test methods when refactoring has completed.
  //#region METHODS USED FOR TESTING SCENARIOS, can be used when refactor logic.

  //User requested (blue button)
  //If fileSize is not null we can ignore status and always show Download button - so then request download button should always be hidden
  scenario1_1(){
    this.fileSize = 100;
    this.downloadStatus = null;
    this.userDownloadedManualId = 1;

  }

  //User NOT requested (grey button)
  //If fileSize is not null we can ignore status and always show Download button - so then request download button should always be hidden
  scenario1_2(){
    this.fileSize = 150;
    this.downloadStatus = null;
    this.userDownloadedManualId = null;
  }

  //If fileSize is null then we should show Request Download button when status is Unknown, Error or Expired
  scenario3(){
    this.fileSize = null;
    this.downloadStatus = this.downloadedManualStatus.Unknown;
  }

  //User requested
  //If fileSize is null and status is queued we should show a disabled "queued" button
  //(The queued/inprogress should only show if the current user has requested the file previously. Otherwise it will still show "Request download".()
  scenario4_1(){
    this.fileSize = null;
    this.downloadStatus = this.downloadedManualStatus.Queued;
    this.userDownloadedManualId = 1;
  }

  //User NOT requested
  //show "Request download"
  //The queued/inprogress should ONLY show if the current user has requested the file previously. Otherwise it will still show "Request download".()
  scenario4_2(){
    this.fileSize = null;
    this.downloadStatus = this.downloadedManualStatus.Queued;
    this.userDownloadedManualId = null;
  }


  //User requested
  //download button displayed in blue and also an icon informing that an update is in progress.
  scenario5_1(){
    this.fileSize = 150;
    this.downloadStatus = this.downloadedManualStatus.Queued;
    this.userDownloadedManualId = 1;
  }

  //User NOT requested
  //download button displayed in GREY and also an icon informing that an update is in progress.
  scenario5_2(){
    this.fileSize = 150;
    this.downloadStatus = this.downloadedManualStatus.Queued;
    this.userDownloadedManualId = null;

  }

  //User has requested
  //show a disabled "in progress" button with a spinner
  //If fileSize is null and status is inprogress we should show a disabled "in progress" button with a spinner (same here, not sure if it's that the same button in new component)
  scenario6_1(){
    this.fileSize = null;
    this.downloadStatus = this.downloadedManualStatus.InProgress;
    this.userDownloadedManualId = 1;
  }


  //User has NOT requested
  //Show request button
  scenario6_2(){
    this.fileSize = null;
    this.downloadStatus = this.downloadedManualStatus.InProgress;
    this.userDownloadedManualId = null;
  }

  //#endregion

}
