
import Quill, { Parchment } from 'quill';

/**
 * Makes sure that our elements with custom classes are not removed when editor loads
 */
export function registerQuillCustomMibpClass() {
  // attribute name 'class' - prefix 'ql-mibp'
  const customClass = new Parchment.ClassAttributor('class', 'ql-mibp', {
    scope: Parchment.Scope.BLOCK,
    whitelist: [
      'separator',
      'exit-section',     // Will allow for <prefix>-exit-section class
      'section',
      'section-separator' // Will allow for <prefix>-section-separator class
    ]
  });
  Quill.register( 'attributors/class/custom', customClass, true);
  Quill.register( 'formats/custom', customClass, true);
}
