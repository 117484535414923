/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { BomAttachmentVm } from '../models/bom-attachment-vm';
import { BomPartVm } from '../models/bom-part-vm';
import { downloadPDF } from '../fn/bill-of-material/download-pdf';
import { DownloadPDF$Params } from '../fn/bill-of-material/download-pdf';
import { EquipmentWithBillOfMaterialVm } from '../models/equipment-with-bill-of-material-vm';
import { getEquipment } from '../fn/bill-of-material/get-equipment';
import { GetEquipment$Params } from '../fn/bill-of-material/get-equipment';
import { getEquipment$Plain } from '../fn/bill-of-material/get-equipment-plain';
import { GetEquipment$Plain$Params } from '../fn/bill-of-material/get-equipment-plain';
import { getEquipmentMedia } from '../fn/bill-of-material/get-equipment-media';
import { GetEquipmentMedia$Params } from '../fn/bill-of-material/get-equipment-media';
import { getEquipmentMedia$Plain } from '../fn/bill-of-material/get-equipment-media-plain';
import { GetEquipmentMedia$Plain$Params } from '../fn/bill-of-material/get-equipment-media-plain';
import { GetEquipmentMediaResponseViewModel } from '../models/get-equipment-media-response-view-model';
import { getEquipmentMedias } from '../fn/bill-of-material/get-equipment-medias';
import { GetEquipmentMedias$Params } from '../fn/bill-of-material/get-equipment-medias';
import { getEquipmentMedias$Plain } from '../fn/bill-of-material/get-equipment-medias-plain';
import { GetEquipmentMedias$Plain$Params } from '../fn/bill-of-material/get-equipment-medias-plain';
import { GetEquipmentMediasResponseViewModel } from '../models/get-equipment-medias-response-view-model';
import { getFolderAttachments } from '../fn/bill-of-material/get-folder-attachments';
import { GetFolderAttachments$Params } from '../fn/bill-of-material/get-folder-attachments';
import { getFolderAttachments$Plain } from '../fn/bill-of-material/get-folder-attachments-plain';
import { GetFolderAttachments$Plain$Params } from '../fn/bill-of-material/get-folder-attachments-plain';
import { getFolderId } from '../fn/bill-of-material/get-folder-id';
import { GetFolderId$Params } from '../fn/bill-of-material/get-folder-id';
import { getFolderId$Plain } from '../fn/bill-of-material/get-folder-id-plain';
import { GetFolderId$Plain$Params } from '../fn/bill-of-material/get-folder-id-plain';
import { getFolderInfo } from '../fn/bill-of-material/get-folder-info';
import { GetFolderInfo$Params } from '../fn/bill-of-material/get-folder-info';
import { getFolderInfo$Plain } from '../fn/bill-of-material/get-folder-info-plain';
import { GetFolderInfo$Plain$Params } from '../fn/bill-of-material/get-folder-info-plain';
import { getFolderPath } from '../fn/bill-of-material/get-folder-path';
import { GetFolderPath$Params } from '../fn/bill-of-material/get-folder-path';
import { getFolderPath$Plain } from '../fn/bill-of-material/get-folder-path-plain';
import { GetFolderPath$Plain$Params } from '../fn/bill-of-material/get-folder-path-plain';
import { getHotPointLinks } from '../fn/bill-of-material/get-hot-point-links';
import { GetHotPointLinks$Params } from '../fn/bill-of-material/get-hot-point-links';
import { getHotPointLinks$Plain } from '../fn/bill-of-material/get-hot-point-links-plain';
import { GetHotPointLinks$Plain$Params } from '../fn/bill-of-material/get-hot-point-links-plain';
import { getHotPointPageDetails } from '../fn/bill-of-material/get-hot-point-page-details';
import { GetHotPointPageDetails$Params } from '../fn/bill-of-material/get-hot-point-page-details';
import { getHotPointPageDetails$Plain } from '../fn/bill-of-material/get-hot-point-page-details-plain';
import { GetHotPointPageDetails$Plain$Params } from '../fn/bill-of-material/get-hot-point-page-details-plain';
import { getHotpointsInfo } from '../fn/bill-of-material/get-hotpoints-info';
import { GetHotpointsInfo$Params } from '../fn/bill-of-material/get-hotpoints-info';
import { getHotpointsInfo$Plain } from '../fn/bill-of-material/get-hotpoints-info-plain';
import { GetHotpointsInfo$Plain$Params } from '../fn/bill-of-material/get-hotpoints-info-plain';
import { getImage } from '../fn/bill-of-material/get-image';
import { GetImage$Params } from '../fn/bill-of-material/get-image';
import { getImage$Plain } from '../fn/bill-of-material/get-image-plain';
import { GetImage$Plain$Params } from '../fn/bill-of-material/get-image-plain';
import { getMediaFolders } from '../fn/bill-of-material/get-media-folders';
import { GetMediaFolders$Params } from '../fn/bill-of-material/get-media-folders';
import { getMediaFolders$Plain } from '../fn/bill-of-material/get-media-folders-plain';
import { GetMediaFolders$Plain$Params } from '../fn/bill-of-material/get-media-folders-plain';
import { getPartsInFolder } from '../fn/bill-of-material/get-parts-in-folder';
import { GetPartsInFolder$Params } from '../fn/bill-of-material/get-parts-in-folder';
import { getPartsInFolder$Plain } from '../fn/bill-of-material/get-parts-in-folder-plain';
import { GetPartsInFolder$Plain$Params } from '../fn/bill-of-material/get-parts-in-folder-plain';
import { getRawImage } from '../fn/bill-of-material/get-raw-image';
import { GetRawImage$Params } from '../fn/bill-of-material/get-raw-image';
import { getRawPDF } from '../fn/bill-of-material/get-raw-pdf';
import { GetRawPDF$Params } from '../fn/bill-of-material/get-raw-pdf';
import { getWhereUsedMedia } from '../fn/bill-of-material/get-where-used-media';
import { GetWhereUsedMedia$Params } from '../fn/bill-of-material/get-where-used-media';
import { getWhereUsedMedia$Plain } from '../fn/bill-of-material/get-where-used-media-plain';
import { GetWhereUsedMedia$Plain$Params } from '../fn/bill-of-material/get-where-used-media-plain';
import { HotPointBomItemResponse } from '../models/hot-point-bom-item-response';
import { Hotpointlink } from '../models/hotpointlink';
import { HotPointPageResponse } from '../models/hot-point-page-response';
import { MediaFolderVm } from '../models/media-folder-vm';
import { PartsManualIndexDtoSearchResponse } from '../models/parts-manual-index-dto-search-response';
import { searchPartsManuals } from '../fn/bill-of-material/search-parts-manuals';
import { SearchPartsManuals$Params } from '../fn/bill-of-material/search-parts-manuals';
import { searchPartsManuals$Plain } from '../fn/bill-of-material/search-parts-manuals-plain';
import { SearchPartsManuals$Plain$Params } from '../fn/bill-of-material/search-parts-manuals-plain';
import { WhereUsedMediaResponseVmPagedResponse } from '../models/where-used-media-response-vm-paged-response';

@Injectable({ providedIn: 'root' })
export class BillOfMaterialApiController extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `billOfMaterialControllerSearchPartsManualsrequest()` */
  static readonly BillOfMaterialControllerSearchPartsManualsrequestPath = '/billofmaterial/partsmanuals/search';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `searchPartsManuals$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  searchPartsManuals$Plain$Response(params?: SearchPartsManuals$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<PartsManualIndexDtoSearchResponse>> {
    return searchPartsManuals$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `searchPartsManuals$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  searchPartsManuals$Plain(params?: SearchPartsManuals$Plain$Params, context?: HttpContext): Observable<PartsManualIndexDtoSearchResponse> {
    return this.searchPartsManuals$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<PartsManualIndexDtoSearchResponse>): PartsManualIndexDtoSearchResponse => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `searchPartsManuals()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  searchPartsManuals$Response(params?: SearchPartsManuals$Params, context?: HttpContext): Observable<StrictHttpResponse<PartsManualIndexDtoSearchResponse>> {
    return searchPartsManuals(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `searchPartsManuals$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  searchPartsManuals(params?: SearchPartsManuals$Params, context?: HttpContext): Observable<PartsManualIndexDtoSearchResponse> {
    return this.searchPartsManuals$Response(params, context).pipe(
      map((r: StrictHttpResponse<PartsManualIndexDtoSearchResponse>): PartsManualIndexDtoSearchResponse => r.body)
    );
  }

  /** Path part for operation `billOfMaterialControllerGetEquipment()` */
  static readonly BillOfMaterialControllerGetEquipmentPath = '/billofmaterial/equipment';

  /**
   * In first stage the BoM data is not in any search index
   * This will simply return equipment that has BoM data from database in a simple list.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getEquipment$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getEquipment$Plain$Response(params?: GetEquipment$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<EquipmentWithBillOfMaterialVm>>> {
    return getEquipment$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * In first stage the BoM data is not in any search index
   * This will simply return equipment that has BoM data from database in a simple list.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getEquipment$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getEquipment$Plain(params?: GetEquipment$Plain$Params, context?: HttpContext): Observable<Array<EquipmentWithBillOfMaterialVm>> {
    return this.getEquipment$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<EquipmentWithBillOfMaterialVm>>): Array<EquipmentWithBillOfMaterialVm> => r.body)
    );
  }

  /**
   * In first stage the BoM data is not in any search index
   * This will simply return equipment that has BoM data from database in a simple list.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getEquipment()` instead.
   *
   * This method doesn't expect any request body.
   */
  getEquipment$Response(params?: GetEquipment$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<EquipmentWithBillOfMaterialVm>>> {
    return getEquipment(this.http, this.rootUrl, params, context);
  }

  /**
   * In first stage the BoM data is not in any search index
   * This will simply return equipment that has BoM data from database in a simple list.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getEquipment$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getEquipment(params?: GetEquipment$Params, context?: HttpContext): Observable<Array<EquipmentWithBillOfMaterialVm>> {
    return this.getEquipment$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<EquipmentWithBillOfMaterialVm>>): Array<EquipmentWithBillOfMaterialVm> => r.body)
    );
  }

  /** Path part for operation `billOfMaterialControllerGetEquipmentMediasequipmentId()` */
  static readonly BillOfMaterialControllerGetEquipmentMediasequipmentIdPath = '/billofmaterial/equipment/{equipmentId}/media';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getEquipmentMedias$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getEquipmentMedias$Plain$Response(params: GetEquipmentMedias$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<GetEquipmentMediasResponseViewModel>> {
    return getEquipmentMedias$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getEquipmentMedias$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getEquipmentMedias$Plain(params: GetEquipmentMedias$Plain$Params, context?: HttpContext): Observable<GetEquipmentMediasResponseViewModel> {
    return this.getEquipmentMedias$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<GetEquipmentMediasResponseViewModel>): GetEquipmentMediasResponseViewModel => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getEquipmentMedias()` instead.
   *
   * This method doesn't expect any request body.
   */
  getEquipmentMedias$Response(params: GetEquipmentMedias$Params, context?: HttpContext): Observable<StrictHttpResponse<GetEquipmentMediasResponseViewModel>> {
    return getEquipmentMedias(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getEquipmentMedias$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getEquipmentMedias(params: GetEquipmentMedias$Params, context?: HttpContext): Observable<GetEquipmentMediasResponseViewModel> {
    return this.getEquipmentMedias$Response(params, context).pipe(
      map((r: StrictHttpResponse<GetEquipmentMediasResponseViewModel>): GetEquipmentMediasResponseViewModel => r.body)
    );
  }

  /** Path part for operation `billOfMaterialControllerGetEquipmentMediaequipmentIdMediaIdentifier()` */
  static readonly BillOfMaterialControllerGetEquipmentMediaequipmentIdMediaIdentifierPath = '/billofmaterial/equipment/{equipmentId}/media/{mediaIdentifier}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getEquipmentMedia$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getEquipmentMedia$Plain$Response(params: GetEquipmentMedia$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<GetEquipmentMediaResponseViewModel>> {
    return getEquipmentMedia$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getEquipmentMedia$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getEquipmentMedia$Plain(params: GetEquipmentMedia$Plain$Params, context?: HttpContext): Observable<GetEquipmentMediaResponseViewModel> {
    return this.getEquipmentMedia$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<GetEquipmentMediaResponseViewModel>): GetEquipmentMediaResponseViewModel => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getEquipmentMedia()` instead.
   *
   * This method doesn't expect any request body.
   */
  getEquipmentMedia$Response(params: GetEquipmentMedia$Params, context?: HttpContext): Observable<StrictHttpResponse<GetEquipmentMediaResponseViewModel>> {
    return getEquipmentMedia(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getEquipmentMedia$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getEquipmentMedia(params: GetEquipmentMedia$Params, context?: HttpContext): Observable<GetEquipmentMediaResponseViewModel> {
    return this.getEquipmentMedia$Response(params, context).pipe(
      map((r: StrictHttpResponse<GetEquipmentMediaResponseViewModel>): GetEquipmentMediaResponseViewModel => r.body)
    );
  }

  /** Path part for operation `billOfMaterialControllerGetMediaFoldersmediaIdentifierParentFolderIdIncludeAttachments()` */
  static readonly BillOfMaterialControllerGetMediaFoldersmediaIdentifierParentFolderIdIncludeAttachmentsPath = '/billofmaterial/media/{mediaIdentifier}/folders';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getMediaFolders$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMediaFolders$Plain$Response(params: GetMediaFolders$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<MediaFolderVm>>> {
    return getMediaFolders$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getMediaFolders$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMediaFolders$Plain(params: GetMediaFolders$Plain$Params, context?: HttpContext): Observable<Array<MediaFolderVm>> {
    return this.getMediaFolders$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<MediaFolderVm>>): Array<MediaFolderVm> => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getMediaFolders()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMediaFolders$Response(params: GetMediaFolders$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<MediaFolderVm>>> {
    return getMediaFolders(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getMediaFolders$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMediaFolders(params: GetMediaFolders$Params, context?: HttpContext): Observable<Array<MediaFolderVm>> {
    return this.getMediaFolders$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<MediaFolderVm>>): Array<MediaFolderVm> => r.body)
    );
  }

  /** Path part for operation `billOfMaterialControllerGetFolderPathmediaIdentifierFolderId()` */
  static readonly BillOfMaterialControllerGetFolderPathmediaIdentifierFolderIdPath = '/billofmaterial/media/{mediaIdentifier}/folders/{folderId}/path';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getFolderPath$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getFolderPath$Plain$Response(params: GetFolderPath$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<MediaFolderVm>>> {
    return getFolderPath$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getFolderPath$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getFolderPath$Plain(params: GetFolderPath$Plain$Params, context?: HttpContext): Observable<Array<MediaFolderVm>> {
    return this.getFolderPath$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<MediaFolderVm>>): Array<MediaFolderVm> => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getFolderPath()` instead.
   *
   * This method doesn't expect any request body.
   */
  getFolderPath$Response(params: GetFolderPath$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<MediaFolderVm>>> {
    return getFolderPath(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getFolderPath$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getFolderPath(params: GetFolderPath$Params, context?: HttpContext): Observable<Array<MediaFolderVm>> {
    return this.getFolderPath$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<MediaFolderVm>>): Array<MediaFolderVm> => r.body)
    );
  }

  /** Path part for operation `billOfMaterialControllerGetPartsInFoldermediaIdentifierFolderId()` */
  static readonly BillOfMaterialControllerGetPartsInFoldermediaIdentifierFolderIdPath = '/billofmaterial/folders/{folderId}/parts';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getPartsInFolder$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPartsInFolder$Plain$Response(params: GetPartsInFolder$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<BomPartVm>>> {
    return getPartsInFolder$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getPartsInFolder$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPartsInFolder$Plain(params: GetPartsInFolder$Plain$Params, context?: HttpContext): Observable<Array<BomPartVm>> {
    return this.getPartsInFolder$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<BomPartVm>>): Array<BomPartVm> => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getPartsInFolder()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPartsInFolder$Response(params: GetPartsInFolder$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<BomPartVm>>> {
    return getPartsInFolder(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getPartsInFolder$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPartsInFolder(params: GetPartsInFolder$Params, context?: HttpContext): Observable<Array<BomPartVm>> {
    return this.getPartsInFolder$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<BomPartVm>>): Array<BomPartVm> => r.body)
    );
  }

  /** Path part for operation `billOfMaterialControllerGetFolderInfofolderIdIncludeAttachments()` */
  static readonly BillOfMaterialControllerGetFolderInfofolderIdIncludeAttachmentsPath = '/billofmaterial/folders/{folderId}';

  /**
   * Get a details of a folder - including full path in hierarcy.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getFolderInfo$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getFolderInfo$Plain$Response(params: GetFolderInfo$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<MediaFolderVm>> {
    return getFolderInfo$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * Get a details of a folder - including full path in hierarcy.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getFolderInfo$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getFolderInfo$Plain(params: GetFolderInfo$Plain$Params, context?: HttpContext): Observable<MediaFolderVm> {
    return this.getFolderInfo$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<MediaFolderVm>): MediaFolderVm => r.body)
    );
  }

  /**
   * Get a details of a folder - including full path in hierarcy.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getFolderInfo()` instead.
   *
   * This method doesn't expect any request body.
   */
  getFolderInfo$Response(params: GetFolderInfo$Params, context?: HttpContext): Observable<StrictHttpResponse<MediaFolderVm>> {
    return getFolderInfo(this.http, this.rootUrl, params, context);
  }

  /**
   * Get a details of a folder - including full path in hierarcy.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getFolderInfo$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getFolderInfo(params: GetFolderInfo$Params, context?: HttpContext): Observable<MediaFolderVm> {
    return this.getFolderInfo$Response(params, context).pipe(
      map((r: StrictHttpResponse<MediaFolderVm>): MediaFolderVm => r.body)
    );
  }

  /** Path part for operation `billOfMaterialControllerGetHotpointsInfofolderId()` */
  static readonly BillOfMaterialControllerGetHotpointsInfofolderIdPath = '/billofmaterial/folders/{folderId}/hotpoints';

  /**
   * Get a details of hotpoints.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getHotpointsInfo$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getHotpointsInfo$Plain$Response(params: GetHotpointsInfo$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<HotPointBomItemResponse>> {
    return getHotpointsInfo$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * Get a details of hotpoints.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getHotpointsInfo$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getHotpointsInfo$Plain(params: GetHotpointsInfo$Plain$Params, context?: HttpContext): Observable<HotPointBomItemResponse> {
    return this.getHotpointsInfo$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<HotPointBomItemResponse>): HotPointBomItemResponse => r.body)
    );
  }

  /**
   * Get a details of hotpoints.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getHotpointsInfo()` instead.
   *
   * This method doesn't expect any request body.
   */
  getHotpointsInfo$Response(params: GetHotpointsInfo$Params, context?: HttpContext): Observable<StrictHttpResponse<HotPointBomItemResponse>> {
    return getHotpointsInfo(this.http, this.rootUrl, params, context);
  }

  /**
   * Get a details of hotpoints.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getHotpointsInfo$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getHotpointsInfo(params: GetHotpointsInfo$Params, context?: HttpContext): Observable<HotPointBomItemResponse> {
    return this.getHotpointsInfo$Response(params, context).pipe(
      map((r: StrictHttpResponse<HotPointBomItemResponse>): HotPointBomItemResponse => r.body)
    );
  }

  /** Path part for operation `billOfMaterialControllerGetFolderAttachmentsfolderId()` */
  static readonly BillOfMaterialControllerGetFolderAttachmentsfolderIdPath = '/billofmaterial/folders/{folderId}/attachments';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getFolderAttachments$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getFolderAttachments$Plain$Response(params: GetFolderAttachments$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<BomAttachmentVm>>> {
    return getFolderAttachments$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getFolderAttachments$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getFolderAttachments$Plain(params: GetFolderAttachments$Plain$Params, context?: HttpContext): Observable<Array<BomAttachmentVm>> {
    return this.getFolderAttachments$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<BomAttachmentVm>>): Array<BomAttachmentVm> => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getFolderAttachments()` instead.
   *
   * This method doesn't expect any request body.
   */
  getFolderAttachments$Response(params: GetFolderAttachments$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<BomAttachmentVm>>> {
    return getFolderAttachments(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getFolderAttachments$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getFolderAttachments(params: GetFolderAttachments$Params, context?: HttpContext): Observable<Array<BomAttachmentVm>> {
    return this.getFolderAttachments$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<BomAttachmentVm>>): Array<BomAttachmentVm> => r.body)
    );
  }

  /** Path part for operation `billOfMaterialControllerGetImageattachmentId()` */
  static readonly BillOfMaterialControllerGetImageattachmentIdPath = '/billofmaterial/images/{attachmentId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getImage$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getImage$Plain$Response(params: GetImage$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<BomAttachmentVm>> {
    return getImage$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getImage$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getImage$Plain(params: GetImage$Plain$Params, context?: HttpContext): Observable<BomAttachmentVm> {
    return this.getImage$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<BomAttachmentVm>): BomAttachmentVm => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getImage()` instead.
   *
   * This method doesn't expect any request body.
   */
  getImage$Response(params: GetImage$Params, context?: HttpContext): Observable<StrictHttpResponse<BomAttachmentVm>> {
    return getImage(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getImage$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getImage(params: GetImage$Params, context?: HttpContext): Observable<BomAttachmentVm> {
    return this.getImage$Response(params, context).pipe(
      map((r: StrictHttpResponse<BomAttachmentVm>): BomAttachmentVm => r.body)
    );
  }

  /** Path part for operation `billOfMaterialControllerGetRawImageattachmentId()` */
  static readonly BillOfMaterialControllerGetRawImageattachmentIdPath = '/billofmaterial/images/{attachmentId}/raw';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getRawImage()` instead.
   *
   * This method doesn't expect any request body.
   */
  getRawImage$Response(params: GetRawImage$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return getRawImage(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getRawImage$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getRawImage(params: GetRawImage$Params, context?: HttpContext): Observable<void> {
    return this.getRawImage$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `billOfMaterialControllerGetRawPdFattachmentId()` */
  static readonly BillOfMaterialControllerGetRawPdFattachmentIdPath = '/billofmaterial/pdf/{attachmentId}/raw';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getRawPDF()` instead.
   *
   * This method doesn't expect any request body.
   */
  getRawPDF$Response(params: GetRawPDF$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return getRawPDF(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getRawPDF$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getRawPDF(params: GetRawPDF$Params, context?: HttpContext): Observable<void> {
    return this.getRawPDF$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `billOfMaterialControllerDownloadPdFattachmentId()` */
  static readonly BillOfMaterialControllerDownloadPdFattachmentIdPath = '/billofmaterial/pdf/{attachmentId}/download';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `downloadPDF()` instead.
   *
   * This method doesn't expect any request body.
   */
  downloadPDF$Response(params: DownloadPDF$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return downloadPDF(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `downloadPDF$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  downloadPDF(params: DownloadPDF$Params, context?: HttpContext): Observable<void> {
    return this.downloadPDF$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `billOfMaterialControllerGetWhereUsedMediarequest()` */
  static readonly BillOfMaterialControllerGetWhereUsedMediarequestPath = '/billofmaterial/whereused';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getWhereUsedMedia$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getWhereUsedMedia$Plain$Response(params?: GetWhereUsedMedia$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<WhereUsedMediaResponseVmPagedResponse>> {
    return getWhereUsedMedia$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getWhereUsedMedia$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getWhereUsedMedia$Plain(params?: GetWhereUsedMedia$Plain$Params, context?: HttpContext): Observable<WhereUsedMediaResponseVmPagedResponse> {
    return this.getWhereUsedMedia$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<WhereUsedMediaResponseVmPagedResponse>): WhereUsedMediaResponseVmPagedResponse => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getWhereUsedMedia()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getWhereUsedMedia$Response(params?: GetWhereUsedMedia$Params, context?: HttpContext): Observable<StrictHttpResponse<WhereUsedMediaResponseVmPagedResponse>> {
    return getWhereUsedMedia(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getWhereUsedMedia$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getWhereUsedMedia(params?: GetWhereUsedMedia$Params, context?: HttpContext): Observable<WhereUsedMediaResponseVmPagedResponse> {
    return this.getWhereUsedMedia$Response(params, context).pipe(
      map((r: StrictHttpResponse<WhereUsedMediaResponseVmPagedResponse>): WhereUsedMediaResponseVmPagedResponse => r.body)
    );
  }

  /** Path part for operation `billOfMaterialControllerGetHotPointLinksfolderId()` */
  static readonly BillOfMaterialControllerGetHotPointLinksfolderIdPath = '/billofmaterial/folders/{folderId}/hotpointlinks';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getHotPointLinks$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getHotPointLinks$Plain$Response(params: GetHotPointLinks$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<Hotpointlink>>> {
    return getHotPointLinks$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getHotPointLinks$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getHotPointLinks$Plain(params: GetHotPointLinks$Plain$Params, context?: HttpContext): Observable<Array<Hotpointlink>> {
    return this.getHotPointLinks$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<Hotpointlink>>): Array<Hotpointlink> => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getHotPointLinks()` instead.
   *
   * This method doesn't expect any request body.
   */
  getHotPointLinks$Response(params: GetHotPointLinks$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<Hotpointlink>>> {
    return getHotPointLinks(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getHotPointLinks$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getHotPointLinks(params: GetHotPointLinks$Params, context?: HttpContext): Observable<Array<Hotpointlink>> {
    return this.getHotPointLinks$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<Hotpointlink>>): Array<Hotpointlink> => r.body)
    );
  }

  /** Path part for operation `billOfMaterialControllerGetHotPointPageDetailspageId()` */
  static readonly BillOfMaterialControllerGetHotPointPageDetailspageIdPath = '/billofmaterial/page/{pageId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getHotPointPageDetails$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getHotPointPageDetails$Plain$Response(params: GetHotPointPageDetails$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<HotPointPageResponse>> {
    return getHotPointPageDetails$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getHotPointPageDetails$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getHotPointPageDetails$Plain(params: GetHotPointPageDetails$Plain$Params, context?: HttpContext): Observable<HotPointPageResponse> {
    return this.getHotPointPageDetails$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<HotPointPageResponse>): HotPointPageResponse => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getHotPointPageDetails()` instead.
   *
   * This method doesn't expect any request body.
   */
  getHotPointPageDetails$Response(params: GetHotPointPageDetails$Params, context?: HttpContext): Observable<StrictHttpResponse<HotPointPageResponse>> {
    return getHotPointPageDetails(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getHotPointPageDetails$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getHotPointPageDetails(params: GetHotPointPageDetails$Params, context?: HttpContext): Observable<HotPointPageResponse> {
    return this.getHotPointPageDetails$Response(params, context).pipe(
      map((r: StrictHttpResponse<HotPointPageResponse>): HotPointPageResponse => r.body)
    );
  }

  /** Path part for operation `billOfMaterialControllerGetFolderIdhashKeyMediaIdentifier()` */
  static readonly BillOfMaterialControllerGetFolderIdhashKeyMediaIdentifierPath = '/billofmaterial/medias/{mediaIdentifier}/hotpoint-navigation-info/{hashKey}';

  /**
   * GetHotPointNavigationInfo.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getFolderId$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getFolderId$Plain$Response(params: GetFolderId$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<number>> {
    return getFolderId$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * GetHotPointNavigationInfo.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getFolderId$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getFolderId$Plain(params: GetFolderId$Plain$Params, context?: HttpContext): Observable<number> {
    return this.getFolderId$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<number>): number => r.body)
    );
  }

  /**
   * GetHotPointNavigationInfo.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getFolderId()` instead.
   *
   * This method doesn't expect any request body.
   */
  getFolderId$Response(params: GetFolderId$Params, context?: HttpContext): Observable<StrictHttpResponse<number>> {
    return getFolderId(this.http, this.rootUrl, params, context);
  }

  /**
   * GetHotPointNavigationInfo.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getFolderId$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getFolderId(params: GetFolderId$Params, context?: HttpContext): Observable<number> {
    return this.getFolderId$Response(params, context).pipe(
      map((r: StrictHttpResponse<number>): number => r.body)
    );
  }

}
