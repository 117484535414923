/* tslint:disable */
/* eslint-disable */
export enum ProductType {
  NotSet = 0,
  SparePart = 1,
  Kit = 2,
  Subscription = 3,
  SparePartOnly = 4,
  NotSparePart = 5
}
