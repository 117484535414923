<nav class="my-subnav">
  <aside>

    <ul>
      <li *ngFor="let i of items">
        <a [routerLink]="i.route" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: i.route[0] == './' }" [routerLinkOptions]>{{i.text}}</a>
      </li>
    </ul>

  </aside>
  <section>
    <ng-content></ng-content>
  </section>

</nav>
