/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, OnInit, forwardRef, Input, Output, EventEmitter, ElementRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import htmlEditButton from "quill-html-edit-button";
import Quill from 'quill';
import BlotFormatter from '@enzedonline/quill-blot-formatter2';
import { UserFileApiController } from 'root/mibp-openapi-gen/controllers';
import { firstValueFrom } from 'rxjs';
import { CreateUserFileViewModel, UserFileEventDto, UserFileReferenceAction } from 'root/mibp-openapi-gen/models';
import { FormattingService, GlobalConfigService, MibpHtmlHelperService, MibpHttpApi, MibpLogger, NoticebarService } from 'root/services';
import { NoticeType } from '../noticebar/noticebar.enum';
import { environment } from 'root/environment';
import { addHours } from 'date-fns';
import { registerBreakSectionBlot } from './register-quill-break-section-blot.function';
import { registerSectionHeaderBlot } from './register-quill-section-headerblot.function';
import { registerQuillCustomMibpClass } from './register-quill-custom-mibp-class.function';
import Toolbar from 'quill/modules/toolbar';

Quill.register('modules/htmlEditButton', htmlEditButton);
Quill.register('modules/blotFormatter', BlotFormatter);

registerBreakSectionBlot();
registerSectionHeaderBlot();
registerQuillCustomMibpClass();

@Component({
  selector: 'mibp-rich-text-editor',
  templateUrl: './rich-text-editor.component.html',
  styleUrls: ['./rich-text-editor.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => RichTextEditorComponent),
    }
  ]
})
export class RichTextEditorComponent implements OnInit, ControlValueAccessor {

  protected maxImageFileSize: number;
  protected allowedImageMimeTypes: string;
  protected value: string;
  protected isDisabled: boolean;
  private quill: Quill;
  private log: MibpLogger;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onChange: (event: any) => void;
  onTouched: (touched: boolean) => void;

  @Input() toolbarContainerId: string;
  @Input() placeHolder: string;
  @Input() minLength: number;
  @Input() maxLength: number;
  @Input() required: boolean;
  @Input() toolbar = [
    [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
    ['bold', 'italic', 'underline'],
    [{ 'list': 'ordered' }, { 'list': 'bullet' }],
    ['link', 'video'],
    ['clean']
  ];
  @Input() modules = {
    toolbar: null,
    clipboard: {
      matchVisual: false
    },
    htmlEditButton: {},
    blotFormatter: {}
  };
  @Input() styles = {
    minHeight: '100px'
  };
  @Input() fileSource: string;
  @Input() storeEmbeddedImagesAsBlobs: boolean;
  isImageEmbedded: boolean;
  @Input() set height(val: number) {
    if (val) {
      // Setting the height will prevent that the page scrolls upp when text is pasted into the editor
      (this.element.nativeElement as HTMLElement).style.height = val.toString() + 'px';
    }
  }
  @Output() userFileEvent = new EventEmitter<UserFileEventDto>();
  @Output() hasEmbeddedImage = new EventEmitter<boolean>();
  constructor(
    private httpApi: MibpHttpApi,
    private htmlHelper: MibpHtmlHelperService,
    private userFileApi: UserFileApiController,
    private element: ElementRef,
    private formatting: FormattingService,
    private notification: NoticebarService,
    globalConfig: GlobalConfigService) {
    this.maxImageFileSize = globalConfig.richTextEditorImageUploadMaxSize;
    this.allowedImageMimeTypes = globalConfig.richTextEditorAllowedImageMimeTypes;
  }

  ngOnInit(): void {
    this.modules.toolbar = this.toolbar;
  }

  editorCreated(quill: Quill): void {
    this.quill = quill;

    this.setupButtonExitSection();
    this.setupButtonSectionHeader();
    this.setupUserFileImages();

    if (this.toolbarContainerId) {
      this.tryMoveToolbar();
    }
  }

  private setupButtonSectionHeader(): void {
    const btn = this.quillContainerElement.parentElement.querySelector('button.ql-sectionheader') as HTMLButtonElement;
    if (btn) {
      btn.innerHTML = `<svg xmlns="http://www.w3.org/2000/svg" class="i--move-v i--size-xl i--color-black" id="xx196" viewBox="0 0 48 48" width="48" height="48"><use xlink:href="/assets/sandvik-icons.svg#arrow-down"></use></svg>`;
    }
  }

  private setupButtonExitSection(): void {
    const btn = this.quillContainerElement.parentElement.querySelector('button.ql-mibp-exit-section') as HTMLButtonElement;
    if (btn) {
      btn.setAttribute('title', 'Adds an element to exit the current collapsible section');
      btn.innerHTML = `<svg xmlns="http://www.w3.org/2000/svg" class="i--move-v i--size-xl i--color-black" id="xx196" viewBox="0 0 48 48" width="48" height="48"><use xlink:href="/assets/sandvik-icons.svg#move-v"></use></svg>`;
      btn.addEventListener('click', () => {
        const selection = (this.quill as Quill).getSelection();
        this.quillAny.insertEmbed(selection?.index || 0, 'breaksection', '', 'user');
      });
    }
  }


  private setupUserFileImages(): void {

    if (this.storeEmbeddedImagesAsBlobs) {
      if (!this.fileSource) {
        throw new Error('FileSource needs to be defined for storing images');
      }

      const toolbar = this.quill.getModule('toolbar') as Toolbar;
      toolbar.addHandler('image', () => { this.selectImage(); });
      this.quill.on('text-change', (delta, oldDelta) => {
        this.deleteUserFileEvent(delta, oldDelta);
      });
    }
  }

  // If we want to place toolbar somewhere else in the DOM
  private async tryMoveToolbar(): Promise<void> {
    const toolbarContainer = await this.waitForElement(document.body, `#${this.toolbarContainerId}`, 5000);
    const toolbar = await this.waitForElement(this.quillContainerElement.parentElement, '.ql-toolbar', 5000);

    if (toolbar && toolbarContainer) {
      toolbarContainer.appendChild(toolbar);
    }

  }

  private async waitForElement(element: HTMLElement, selector: string, maxWaitTimeMs = 2000): Promise<HTMLElement> {
    const start = new Date();
    return new Promise((resolve) => {

      const tryGetElement = () => {
        const e = element.querySelector(selector);
        if (e) {
          resolve(e as HTMLElement);
          return;
        }

        const diff = new Date().getTime() - start.getTime();
        if ( diff > maxWaitTimeMs ) {
          resolve(null);
          return;
        }

        setTimeout(() => tryGetElement(), 50);

      };
      tryGetElement();
    });
  }

  deleteUserFileEvent(_delta, oldDelta){
    const currrentContents = this.quill.getContents();
    const diff = currrentContents.diff(oldDelta);
    diff.forEach((op) =>{
      const image = op['insert'] as any;
      if(image){
        const imageUrl = image.image;
        if(imageUrl){
          const imageUrlSplit = (imageUrl as string).split('?jwt');
          const imageUrlWithoutJwt = imageUrlSplit[0];
          const userFileId = (imageUrlWithoutJwt.split('userfile/image/')[1]).split('.')[0];
          this.userFileEvent.emit(<UserFileEventDto>{
            userFileId: userFileId,
            userFileReferenceAction: UserFileReferenceAction.Delete
          });
        }
      }
    });
  }

  selectImage(): void {
    const input = document.createElement('input');
    input.setAttribute('type', 'file');
    //TODO: Read accepted types form config
    input.setAttribute('accept', this.allowedImageMimeTypes);
    input.click();

    input.onchange = async () => {
      const file = input.files[0];

      if (file.size > this.maxImageFileSize) {
        this.notification.show('RichTextEditor_MaxUploadImageFileSize', NoticeType.Error, {
          size: this.formatting.formatBytes(this.maxImageFileSize)
        });
        return;
      }

      if (/^image\//.test(file.type)) {
        const base64Str = await this.toBase64(file) as string;
        const [fileMetadata, base64Content] = base64Str.split(',');

        const fileToUpload: CreateUserFileViewModel = {
          data: base64Content,
          fileName: file.name,
          fileSource: this.fileSource,
          expireDate: addHours(new Date(), environment.userFiles.expirationTime.amount).toUTCString()
        };

        await firstValueFrom(this.userFileApi.save({ body: fileToUpload })).then(result => {
          const range = this.quill.getSelection();
          const fileExt = file.name.split('.')[1];
          this.quill.insertEmbed(range.index, 'image', this.httpApi.resolveUriWithJwtToken('userfile/image/' + result + '.' + fileExt));
          this.userFileEvent.emit(<UserFileEventDto>{
            userFileId: result,
            userFileReferenceAction: UserFileReferenceAction.Add
          });
        }).catch(error => {
          this.log.error("Error when uploading image to server", error);
        });

      }
    };
  }

  toBase64 = file => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = reject;
  });

  editorTouched(): void {
    if (this.onTouched) {
      this.onTouched(true);
    }
  }

  private isHtmlEmpty(html: string): boolean {
    if (!html) {
      return true;
    }
    return !!html.replace(/[\r\n]/g, '').match(/^<[a-zA-Z0-9]+>(<br>|\s+)<\/[a-zA-Z0-9]+>$/);
  }

  /**
   * If there html contains a single, empty HTML tag then we want to consider it empty so that any "required" validators kick in
   **/
  private getHtmlOrEmptyString(html: string): string {
    return this.isHtmlEmpty(html) ? null : html;

  }

  editorChanged(event: any): void {
    if (this.onChange) {
      this.onChange(this.getHtmlOrEmptyString(event.html));
    }
    if(event && event.html){
      this.checkIfImageIsEmbedded(event.html);
    }
  }

  private checkIfImageIsEmbedded(html: string){
    let hasImageEmbedded = false;
    if(html.includes('<img src="data:image')){
      hasImageEmbedded = true;
    }
    else{
      hasImageEmbedded = false;
    }
    if(hasImageEmbedded !== this.isImageEmbedded){
      this.isImageEmbedded = hasImageEmbedded;
      this.hasEmbeddedImage.emit(hasImageEmbedded);
    }
  }

  // Angular form stuff
  writeValue(val: string): void {
    if (!this.value && val) {
      this.value = this.htmlHelper.addJwtTokenToHtmlImages(val);
    } else {
      this.value = val || '';
    }
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }

  private get quillContainerElement(): HTMLElement {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return this.quillAny.container;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private get quillAny(): any {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return (this.quill as any);
  }



}
