<div class="filter-tags" [class.filter-tags--disabled]="disabled">
  <span [class.active]="selectedValues?.length == 0" (click)="onTagClick($event, null)">
    <mibp-resource-string key="Global_All"></mibp-resource-string>
  </span>
  @for (tag of tags; track tag?.value) {
    <span (click)="onTagClick($event, tag)" [class.active]="selectedValueDictionary[tag.value]">
      @if (tag.resourceString) {
        <mibp-resource-string [key]="tag.resourceString"></mibp-resource-string>
      } @else if (tag.text) {
        {{tag.text}}
      } @else {
        {{tag.value}}
      }
    </span>
  }
</div>
