import { Directive, ElementRef, OnDestroy, OnInit} from '@angular/core';
import { MibpLogger, LogService, ToastService } from 'root/services';
import { ToastType } from 'root/services/toast-service/toast.enum';
import { Subscription, fromEvent } from 'rxjs';

interface Point {
  x: number;
  y: number;
}

@Directive({
  // tslint:disable-next-line:directive-selector
  selector: '[click-to-copy]',
})
export class MibpClickToCopyDirective implements OnInit, OnDestroy {
  log: MibpLogger;
  id: string;
  private mouseDownSubscription: Subscription;
  private mouseUpSubscription: Subscription;
  private clickSubscription: Subscription;
  private isMouseDown = false;
  private mouseDownPoint?: Point;
  private removeClassTimer = 0;
  private mouseDownTime?: Date;
  private preventClick: boolean;
  private aElementHoldTimer: number;

  constructor(logger: LogService,  private elementRef: ElementRef, private toast: ToastService) {
    this.log = logger.withPrefix('click-to-copy.directive');
  }

  ngOnInit(): void {
    if (this.element) {
      this.element.classList.add('click-to-copy');
      if (!this.element.getAttribute('title')) {
        this.element.setAttribute('title', 'Click to copy');
      }
      this.clickSubscription = fromEvent(this.element, 'click').subscribe(e => this.onClick(e as MouseEvent));
      this.mouseDownSubscription = fromEvent(this.element, 'mousedown').subscribe(e => this.onMouseDown(e as MouseEvent));
      this.mouseUpSubscription = fromEvent(window, 'mouseup').subscribe(e => this.onMouseUp(e as MouseEvent));
    }
  }

  private get element(): HTMLElement {
    return (this.elementRef.nativeElement as HTMLElement);
  }

  ngOnDestroy() {
    this.mouseDownSubscription?.unsubscribe();
    this.mouseUpSubscription?.unsubscribe();
    clearTimeout(this.removeClassTimer);
  }

  protected onMouseDown(e: MouseEvent): void {
    clearTimeout(this.aElementHoldTimer);
    this.mouseDownTime = new Date();
    this.isMouseDown = true;
    this.mouseDownPoint = {
      x: e.clientX,
      y: e.clientY
    };

    if (this.element.localName == 'a') {
      this.aElementHoldTimer = window.setTimeout(() => {
        this.preventClick = true;
        this.copyToClipboard();
        //this.onMouseUp(e);
      }, 500);
    }

  }

  protected onClick(e: MouseEvent): void {
    // console.warn("CLICK1", this.preventClick);
    if (this.preventClick) {
      this.preventClick = false;
      e.preventDefault();
      e.stopPropagation();
      e.stopImmediatePropagation();
    }
  }

  protected onMouseUp(e: MouseEvent): void {

    clearTimeout(this.aElementHoldTimer);

    if (!this.mouseDownPoint || !this.isMouseDown) {
      return;
    }
    this.isMouseDown = false;
    const dif = (new Date().getTime()) - this.mouseDownTime.getTime();
    let diffX = e.clientX - this.mouseDownPoint.x;
    diffX = diffX < 0 ? diffX * -1 : diffX;

    let diffY = e.clientY - this.mouseDownPoint.y;
    diffY = diffY < 0 ? diffY * -1 : diffY;

    if (diffX < 2 && diffY < 2) {
      if (this.element.localName != 'a') {
        this.copyToClipboard();
      }
    } else {
      // User seems to be selecting text. Do not trigger copy
    }

    this.mouseDownPoint = undefined;

  }


  protected async copyToClipboard(): Promise<void> {

    clearTimeout(this.removeClassTimer);

    try {

      if (navigator.clipboard) {
        await navigator.clipboard.writeText(this.element.innerText);
      } else {

        const input = document.createElement('textarea');
        input.style.opacity = '0';
        input.style.position = 'absolute';
        input.style.zIndex = '0';
        input.value = this.element.innerText;
        document.body.appendChild(input);

        /* Select the text field */
        input.select();
        input.setSelectionRange(0, 99999); /* For mobile devices */

        /* Copy the text inside the text field */
        document.execCommand("copy");

        document.body.removeChild(input);
      }

      this.toast.showTextWithTitle('Text was successfully copied to Clipboard', 'Copy to clipboard', {
        type: ToastType.Success
      });
      this.element.classList.add('click-to-copy--copied');
      this.removeClassTimer = window.setTimeout(() => {
        this.element.classList.remove('click-to-copy--copied');
      }, 510);

    } catch(e) {
      this.toast.showTextWithTitle('Could not copy the text to Clipboard', 'Copy to clipboard', {
        type: ToastType.Error
      });
    }
  }

}
