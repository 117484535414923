import { Component, Input } from "@angular/core";
import { AddressVm } from "root/mibp-openapi-gen/models";
import { LogService, MibpLogger } from "root/services";

@Component({
  selector: 'mibp-address-view',
  templateUrl: './address-view.component.html',
  styleUrls: ['./address-view.component.scss']
})
export class MibpAddressViewComponent {

  protected addressVm: AddressVm;
  private originalAddressVm: AddressVm;
  private log: MibpLogger;
  @Input()
  set address(newAddress: AddressVm) {
    this.addressVm = this.cleanupAddress(newAddress);
  }

  constructor(log: LogService) {
    this.log = log.withPrefix(`address-view.component`);
  }


  private cleanupAddress(address: AddressVm): AddressVm {

    if (address) {

      if (address.name == address.addressLine1) {
        // Sometimes the delivery sequence name is in the first address line
        // If so - clear  the first address line to not show duplicates
        this.log.debug(`AddressLine1 is the ds name "${address.addressLine1}". Removing addressline1`);
        address.addressLine1 = null;
      }

      if (address.city == address.country) {
        // Sometimes delivery sequence city has the country value
        // We can't know for sure that it's not the other way around, but when displaying we just don't want duplicates
        this.log.debug(`City and country are both "${address.city}". Removing city`);
        address.city = null;
      }
    }

    return address;


  }
}
