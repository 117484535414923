import { Component, OnInit, ViewChild } from "@angular/core";
import { DialogComponent } from "../dialog/dialog.component";
import { SessionApiController } from "root/mibp-openapi-gen/controllers";
import { debounceTime, filter, firstValueFrom, map, Observable, Subscription } from 'rxjs';
import { AuthService, CacheScope, ClientSideCacheService, GlobalConfigService, LoaderService, LocalizationService, LogService, MibpLogger, ToastService } from "root/services";
import { ChangeAccountDeliverySequenceVm, UserAvailableAccountsVm } from "root/mibp-openapi-gen/models";
import { DropdownArgs, DropdownData, DropdownInput, DropdownComponent } from "../dropdown";
import { FormBuilder } from "@angular/forms";
import { MibpSessionService } from "root/services/mibp-session/mibp-session.service";
import { DialogButton } from "../dialog/dialog.types";
import { ButtonColors } from "../button/button.enum";
import { ToastType } from "root/services/toast-service/toast.enum";
import { Page } from "../paginator/page";
import { ContactUsFormData, ContactUsTopic } from "../contact-dialog/contact-us-form/contact-us-form.types";
import { NewContactUsTopic, SupportCaseFormData } from "../contact-dialog/new-contact-us-form/new-contact-us-form.types";
import { MySandvikFeatures } from "root/services/permission";


interface SelectedDeliverySequence {
  id: number;
  deliverySequenceNumber: string;
  displayName: string;
  erpCustomerId: string;
  customerName: string;
  companyCode: string;
  companyName: string;
}

export interface ActiveAccountPickerOptions {
  /**
   * Set when user must change their active account for some reason.
   * This will show a message to the user and show contatus
   */
  forcedChangeReason?: 'active-account-inactive';
}

@Component({
  selector: 'mibp-active-account-picker',
  templateUrl: './active-account-picker.component.html',
  styleUrl: './active-account-picker.component.scss'
})
export class MibpActiveAcountPickerComponent implements OnInit {

  private log: MibpLogger;
  protected selectedDeliverySequence: SelectedDeliverySequence;
  protected isSelectedDeliverySequenceInResults = false;
  protected items: ChangeAccountDeliverySequenceVm[];
  protected customerListItems$: Observable<DropdownData>;
  protected companyListItems: DropdownInput[];
  protected visibleCompanyListItems: DropdownInput[];
  protected formChangeSub: Subscription;
  protected selectionChangedAfterLastRefresh = false;
  protected isLoadingDeliverySequences = false;
  protected availableAccountInfo: UserAvailableAccountsVm;
  protected currentSearchQuery = null;
  private subs: Subscription[] = [];
  protected showCustomerField = false;
  protected showCompanyField = false;
  protected errorLoadingDeliverySequences = false;
  protected totalItemCount = 0;
  protected pageSize = 50;
  protected currentIndex = 0;
  protected currentOptions?: ActiveAccountPickerOptions;
  public contactUsFormData: ContactUsFormData;
  protected supportCaseFormData: SupportCaseFormData;
  private resolveCallback: (deliverySequenceId?: number) => void;
  
  protected showContactUs = false;
  protected showOldContactUs = false;
  

  protected searchForm = this.fb.group({
    query: [],
    customer: [null],
    company: [null]
  });
  protected allButtons: DialogButton[] = [
    {
      resourceKey: 'Global_CancelButton',
      id: 'cancel',
      color: ButtonColors.Secondary
    },
    {
      resourceKey: 'Global_ContactUs',
      id: 'contactus',
      color: ButtonColors.Secondary,
    },
    {
      resourceKey: 'ActAs_ChangeAccount_Confirm',
      id: 'ok',
      disabled: true
    }
  ];
  protected buttons: DialogButton[] = [
  ];
  @ViewChild('dialog') dialog: DialogComponent;
  @ViewChild('customerDropdown') customerDropdown: DropdownComponent;
  @ViewChild('companyDropdown') companyDropdown: DropdownComponent;
  @ViewChild('contactDialog') contactUsDialog: DialogComponent;

  constructor(private sessionApi: SessionApiController,
    private toast: ToastService,
    private loader: LoaderService,
    private localization: LocalizationService,
    private auth: AuthService,
    protected session: MibpSessionService,
    logService: LogService,
    private fb: FormBuilder,
    private cache: ClientSideCacheService) {
    this.log = logService.withPrefix('active-account-picker');

  }

  ngOnInit(): void {
    if (this.session.isLoggedIn()) {
      this.loadOrganizationInfo();
    }
  }

  private async loadOrganizationInfo(): Promise<void> {

    if (this.availableAccountInfo) {
      return;
    }

    try {
      const orgInfo =  await firstValueFrom(this.sessionApi.getAvailableAccountInformation());
      this.availableAccountInfo = orgInfo;
      this.showCustomerField = orgInfo.hasMultipleCustomers;
      this.showCompanyField = orgInfo.hasMultipleCompanies;
    } catch (e) {
      this.log.error('Error loading organization info', e);
    }
  }

  protected onPageChange(e: Page): void {
    const startIndex = e.startIndex < 0 ? 0 : e.startIndex;
    if (e.isUserEvent) {
      this.listDeliverySequences(startIndex);
    }
  }

  private async refreshCompanies(): Promise<void> {

    // Always populate from cache if available
    const cached = this.cache.get<DropdownInput[]>('availablecompanies');
    if (cached) {
      this.companyListItems = cached;
      this.refreshVisibleCompanies();
    }

    try {
      const companies = await firstValueFrom(this.sessionApi.listAvailableCompanies());

      this.companyListItems = companies.items.map(company => <DropdownInput>{
        text: `${company.code} - ${company.name}`,
        value: company.id.toString()
      });
      this.refreshVisibleCompanies();

      this.cache.add('availablecompanies', this.companyListItems, null, CacheScope.UserStorage);

    } catch (e) {
      this.toast.showText(`Could not load list of sales entities`, {
        type: ToastType.Error
      });
      this.log.error('Could not load list of sales entities', e);
    }


  }

  private refreshVisibleCompanies(): void {
    if (!this.companyListItems) {
      return;
    }
    const businessRelationId = this.searchForm.value?.customer?.data?.companyId;
    if (businessRelationId) {
      this.visibleCompanyListItems = this.companyListItems.filter(c => c.value == businessRelationId);
    } else {
      this.visibleCompanyListItems = this.companyListItems;
    }

  }


  private async listDeliverySequences(skip?: number | undefined): Promise<void> {
    this.errorLoadingDeliverySequences = false;
    this.isLoadingDeliverySequences = true;
    try {
      this.currentSearchQuery = this.searchForm.value.query as string;
      const deliverySequences = await firstValueFrom(this.sessionApi.listAvailableDeliverySequences({
        take: this.pageSize,
        skip: typeof skip == "undefined" ? 0 : skip,
        query: this.currentSearchQuery,
        companyId: this.searchForm.value.company?.value ? parseInt(this.searchForm.value.company?.value, 10) : null,
        businessRelationId: this.searchForm.value.customer?.value ? parseInt(this.searchForm.value.customer?.value, 10) : null
      }));

      document.querySelector('.items.light-scroll')?.scrollTo(0, 0);
      this.items = deliverySequences.items;
      this.totalItemCount = deliverySequences.totalCount;
      
      this.currentIndex = typeof skip == "undefined" ? 0 : skip;

      this.isSelectedDeliverySequenceInResults = !!this.items.find(i => i.deliverySequenceId == this.selectedDeliverySequence?.id);
      this.selectionChangedAfterLastRefresh = false;
    } catch(e) {
      this.errorLoadingDeliverySequences = true;
      this.log.error('Error listing delivery sequences', e);
    }
    this.isLoadingDeliverySequences = false;
  }


  protected customerValueChanged(): void {
    this.refreshVisibleCompanies();
  }

  protected companyValueChanged(): void {
    this.customerDropdown?.removeLastSearch();
  }

  protected filterCustomerListItems(args: DropdownArgs): void {
    this.customerListItems$ = this.sessionApi.listAvailableBusinessRelations({
      query: args.query || null,
      skip: args.index,
      take: args.take,
      companyId: this.searchForm.value.company?.value ? parseInt(this.searchForm.value.company?.value, 10) : null,
    }).pipe(map(searchResult => <DropdownData>{
      hasMoreResults: searchResult.totalCount > args.index + searchResult.items.length,
      items: searchResult.items.map(equipment => <DropdownInput>{
        text: `${equipment.erpCustomerId}`,
        htmlText: `${equipment.customerName} <span class="my-text--gray">(${equipment.erpCustomerId})</span>`,
        value: equipment.id.toString(),
        data: {
          companyId: equipment.companyId
        }
      }),
      totalCount: searchResult.totalCount
    }));
  }

  protected onClosed(): void {
    this.items = null;
    this.selectedDeliverySequence = null;
    this.close();
  }

  private close(): void {
    this.dialog.close();
    this.showContactUs = false;
    this.showOldContactUs = false;
    if (this.subs) {
      this.subs.forEach(sub => sub.unsubscribe());
    }
    this.subs = [];
    this.items = [];
    this.customerDropdown?.removeLastSearch();
  }

  protected select(ds: ChangeAccountDeliverySequenceVm): void {
    this.selectionChangedAfterLastRefresh = true;
    this.selectedDeliverySequence = {
      id: ds.deliverySequenceId,
      companyCode: ds.companyCode,
      deliverySequenceNumber: ds.deliverySequenceNumber,
      customerName: ds.customerName,
      erpCustomerId: ds.erpCustomerId,
      displayName:  ds.deliverySequenceDisplayName,
      companyName: ds.companyName
    };
    this.isSelectedDeliverySequenceInResults = true;
    if (this.selectedDeliverySequence.id != this.session.current.activeDeliverySequence.deliverySequenceId) {
      this.dialog.enableButton('ok');
    } else {
      this.dialog.disableButton('ok');
    }
  }


  protected async onDialogButtonClick(btn: DialogButton): Promise<void> {
    if (btn.id == 'cancel') {

      if (this.currentOptions?.forcedChangeReason) {
        this.loader.showFullScreenLoader();
        this.auth.signout();
        return;
      }

      this.resolveCallback(); // Resolve without a value as no new account was selected
      this.dialog.close();
      return;
    }
    
    if (btn.id == 'contactus') {

      if (this.session.hasFeature(MySandvikFeatures.ContactUsForm)) {
        this.showContactUs = true;
        this.contactUsDialog.open();
      } else {
        this.showOldContactUs = true;
        this.contactUsDialog.open();
      }

      return;
    }

    this.loader.showFullScreenLoader();

    try {
      const deliverySequenceId = this.selectedDeliverySequence.id;
      await this.session.actAs(deliverySequenceId);
      this.close();
      this.resolveCallback(deliverySequenceId); // Resolve with the selected delivery sequence id
    } catch (e) {
      this.toast.showText(`ActAs_ErrorChangingAccount`, {
        type: ToastType.Error
      });
      this.log.error('Error changing active account', e);
    } finally {
      this.loader.hideFullScreenLoader();
    }

  }

  public async open(options?: ActiveAccountPickerOptions): Promise<number> {

    return new Promise<number>(async resolve => {

      this.resolveCallback = resolve;

      this.currentOptions = options;

      this.selectedDeliverySequence = {
        id: this.session.current.activeDeliverySequence.deliverySequenceId,
        companyCode: this.session.current.activeDeliverySequence.companyCode,
        deliverySequenceNumber: this.session.current.activeDeliverySequence.deliverySequenceNumber,
        customerName: this.session.current.activeDeliverySequence.customerName,
        erpCustomerId: this.session.current.activeDeliverySequence.erpCustomerId,
        displayName:  this.session.current.activeDeliverySequence.deliverySequenceDisplayName,
        companyName: this.session.current.activeDeliverySequence.companyName
      };

      const activeCompany = {
        text: `${this.session.current.activeDeliverySequence.companyCode} - ${this.session.current.activeDeliverySequence.companyName}`,
        value: this.session.activeDeliverySequence.companyId.toString()
      } as DropdownInput;
      const activeBusinessRelation = {
        text: `${this.session.current.activeDeliverySequence.erpCustomerId}`,
        htmlText: `${this.session.current.activeDeliverySequence.customerName} <span class="my-text--gray">(${this.session.current.activeDeliverySequence.erpCustomerId})</span>`,
        value: this.session.current.activeDeliverySequence.businessRelationId.toString(),
        data: {
          companyId: this.session.current.activeDeliverySequence.companyId
        }
      } as DropdownInput;

      this.selectionChangedAfterLastRefresh = false;

      this.searchForm.patchValue({
        company: activeCompany,
        customer: activeBusinessRelation,
        query: null
      });

      this.refreshCompanies();
      this.refreshVisibleCompanies();
      await this.prepareContactFormData();

      const buttons = this.allButtons.slice(0);

      if (options?.forcedChangeReason) {
        // .. update cancel button to say "sign ouyt"
        (buttons.find(b => b.id == 'cancel') as DialogButton).resourceKey = "Global_Logout";
      } else {
        // Remove the "contact us button"
        const contactUsIndex = buttons.findIndex(b => b.id == 'contactus');
        buttons.splice(contactUsIndex, 1);
      }

      this.buttons = buttons;

      this.dialog.open();

      this.subs.push(this.searchForm.controls.query.valueChanges
        .pipe(
          filter(f => f != this.currentSearchQuery),
          debounceTime(500)
        )
        .subscribe(() => this.listDeliverySequences()));

      this.subs.push(this.searchForm.controls.customer.valueChanges.subscribe(() => this.dropdownValueChanged()));
      this.subs.push(this.searchForm.controls.company.valueChanges.subscribe(() => this.dropdownValueChanged()));

      this.listDeliverySequences();
    });

  }

  protected onContactUsSubmitted(successful: boolean): void {
    if (successful) {
      this.contactUsDialog.close();
      this.toast.show('SupportCase_SubmittedSuccessfully', {
        type: ToastType.Success
      });
    }
  }

  private async prepareContactFormData(): Promise<void> {
    const resourceStringValue = this.localization.get("Login_InactiveAccount_SupportCaseMessage");

    const deliverySequence = this.session.current?.activeDeliverySequence;
    let replaceString = "";
    if (deliverySequence) {
      replaceString = `${deliverySequence.companyCode}/${deliverySequence.erpCustomerId}/${deliverySequence.deliverySequenceNumber}`;
    }
    const message = resourceStringValue.replace('{account}', replaceString);

    this.contactUsFormData =  {
      topic: ContactUsTopic.TechnicalSupport,
      message: message,
      organization: this.session?.current?.user?.accessGroupName
    };

    this.supportCaseFormData = {
      newContactUsTopic: NewContactUsTopic.GeneralQuestionOrRequest,
      itemEnquiryData:null,
      shipingTrackingData: null,
      
      GeneralData: {
        
        description: message
      },
      upgradeQuoteData:null
    };

    
  }

  private dropdownValueChanged(): void {
    setTimeout(() => this.listDeliverySequences());
  }

}

