import { Directive, ElementRef, HostListener, Input, OnInit } from "@angular/core";

@Directive({
  selector: '[mibpTextAreaAutosize]'
})
export class MibpTextAreaAutosizeDirective implements OnInit {
  constructor(private elementRef: ElementRef) {}

  @Input() mibpAutosizeMaxHeight: number;
  @Input()
  set mibpAutosizeModel(value: string) {
    this.resize();
  }

  @HostListener(':input')
  onInput(): void {
    this.resize();
  }

  @HostListener('ngModelChange', ['$event']) ngModelChange(): void {
    setTimeout(() => {
      this.resize();
    }, 100);
  }

  @HostListener('change')
  onChange(): void {
    setTimeout(() => {
      this.resize();
    }, 100);

  }

  ngOnInit(): void {
    if (this.elementRef.nativeElement.scrollHeight) {
      setTimeout(() => this.resize());
    }
    this.elementRef.nativeElement.style.height = '1em';
  }

  resize(): void {
    this.elementRef.nativeElement.style.height = '0';
    let newHeight = (this.elementRef.nativeElement.scrollHeight + 10);
    if (this.mibpAutosizeMaxHeight) {
      if (newHeight > this.mibpAutosizeMaxHeight) {
        newHeight = this.mibpAutosizeMaxHeight;
      }
    }

    this.elementRef.nativeElement.style.height = `${newHeight}px`;
  }



}
