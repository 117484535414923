import { AddressLinesViewModel } from './../../../../../../mibp-openapi-gen/models/address-lines-view-model';
import { firstValueFrom } from 'rxjs';
import { OrdersApiController } from './../../../../../../mibp-openapi-gen/services/orders-api-controller';
import { Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild, ViewRef } from '@angular/core';
import { ApiService, BroadcastService, ClientSideCacheService, FormValidationService, FrontendContextService, GlobalConfigService, LogService, MibpLogger, NoticebarService } from 'root/services';
import { Guid } from 'guid-typescript';
import { MibpSessionService } from 'root/services/mibp-session/mibp-session.service';
import { FormBuilder, Validators } from '@angular/forms';
import { MibpPostCodeValidator } from 'root/modules/home/modules/shop/components/address-lines/postCode.validator';
import { MibpNoWhitespaceValidator } from 'root/validators/no-whitespace.validator';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { AddressVm, MibpOrderAddressSource } from 'root/mibp-openapi-gen/models';
import { addressLookupValidator } from './addressLookupValidator';
import { MapsApiController } from 'root/mibp-openapi-gen/controllers';
import { MySandvikFeatures } from 'root/services/permission';

export interface DeliveryAddressEvent {
  isOverride: boolean;
  deliveryAddress: AddressVm;
  addressLines: string[];
}

export interface InvoiceAddressEvent {
  adressLines: string[];
}

@Component({
  selector: 'mibp-address-lines',
  templateUrl: './address-lines.component.html',
  styleUrls: ['./address-lines.component.scss'],
  animations: [
    trigger('autocorrectedField', [
      state('false', style({
        backgroundColor: 'initial'
      })),
      state('true', style({
        backgroundColor: '#ffff99'
      })),
      transition('true<=>false', animate('250ms'))
    ]),
  ]
})
export class AddressLinesComponent implements OnInit,  OnChanges {

  @Input() deliveryType: 'deliver' | 'pickup' = 'deliver';
  @Input() deliverySequenceId: number;
  @Input() orderId: Guid;
  @Input() readonly = true; // Will allow editing for allowed countries
  @Output() deliveryAddressChanged = new EventEmitter<DeliveryAddressEvent>();
  @Output() invoiceAddressChanged = new EventEmitter<InvoiceAddressEvent>();
  @Output() isEditing = new EventEmitter<boolean>();
  @ViewChild('deliveryAddressFormElement') deliveryAddressFormElement: ElementRef<HTMLFormElement>;
  protected postalCodeAutocorrectState = false;

  isLoading = true;
  addressLines: AddressLinesViewModel;
  hasError = false;
  log: MibpLogger;

  protected originalDeliveryAddress?: AddressVm;
  protected overriddenDeliveryAddress?: AddressVm;
  protected currentDeliveryAddress?: AddressVm;

  protected invoiceAdressSource: string;
  protected deliveryAddressSource: string;
  protected editDisabledReason: string;

  protected isEditingDeliveryAddress = false;
  protected allowDeliveryAddressOverride = false;
  private validatorPattern = Validators.pattern("^[^'\"]*$");
  protected isSubmitted = false;

  protected deliveryAddressForm = this.fb.group({
    addressLine1: ['', [Validators.required, MibpNoWhitespaceValidator(), this.validatorPattern]],
    addressLine2: ['', [MibpNoWhitespaceValidator(), this.validatorPattern]],
    addressLine3: ['', [MibpNoWhitespaceValidator(), this.validatorPattern]],
    countryName: ['', [Validators.required, MibpNoWhitespaceValidator()]],

    municipality: this.fb.group({
      countryCode: [''],
      city: ['', [Validators.required, MibpNoWhitespaceValidator(), this.validatorPattern]],
      postalCode: ['', [Validators.required, MibpNoWhitespaceValidator(), MibpPostCodeValidator(this.mibpSession.current?.activeDeliverySequence?.countryCode), this.validatorPattern]]
    }),
  });


  constructor(private api: ApiService,
    private mapsApi: MapsApiController,
    private validation: FormValidationService,
    protected broadcast: BroadcastService,
    private noticebar: NoticebarService,
    private context: FrontendContextService,
    logger: LogService,
    private fb: FormBuilder, private  mibpSession: MibpSessionService, protected globalConfig: GlobalConfigService, private cache: ClientSideCacheService, private ordersController: OrdersApiController) {
    this.log = logger.withPrefix(`address-lines`);
  }

  ngOnInit(): void {

    if (!this.mibpSession.activeDeliverySequence) {
      return;
    }

    this.checkIfEditingIsAllowed();


    this.originalDeliveryAddress = {
      name: this.mibpSession.activeDeliverySequence.deliverySequenceNumber,
      addressLine1: null,
      addressLine2: null,
      addressLine3: null,
      country: this.mibpSession.activeDeliverySequence.countryName,
      phoneNumbers: [],
      city: this.mibpSession.activeDeliverySequence?.city,
      postalCode: null
    };

    // Enable validation via Azure Maps only if it's eabled in config and also allowed for currently active DS's country
    if (this.globalConfig.enableEditAddressMapsLookup && this.allowDeliveryAddressOverride) {
      this.deliveryAddressForm.controls.municipality.addAsyncValidators(addressLookupValidator(this.mapsApi, this.cache));
    }

    this.patchForm(this.originalDeliveryAddress);
  }


  protected getPostCodeValidationErrorForCurrentCountry(){
    let countryCode = this.deliveryAddressForm.value.municipality.countryCode;
    switch (countryCode) {
      case 'SE':
      case 'FI':
        return 'ValidationRequirementPattern_Numeric_Max5';

      case 'DE':
      case 'NO':
        return 'ValidationRequirementPattern_Numeric_Max4';

      case 'GB':
        return 'ValidationRequirementPattern_AlphaNumeric_Max8';

      case 'IE':
         return 'ValidationRequirementPattern_AlphaNumeric_Max7';
     }


  }


  protected autoCorrectPostalCodeIfNeeded(): void {
    if (this.deliveryAddressForm.controls.municipality.controls.postalCode.valid) {
      const value = this.deliveryAddressForm.value.municipality.postalCode;
      let newValue: string = null;

      if (typeof value == 'string') {
        if (this.deliveryAddressForm.value.municipality.countryCode == 'FI' || this.deliveryAddressForm.value.municipality.countryCode == 'GE') {
          newValue = value.replace(/ /g, '');
        } else if (this.deliveryAddressForm.value.municipality.countryCode == 'SE') {
          newValue = value.replace(/ /g, '').replace(/^(\d{3})(\d{2})$/, '$1 $2');
        }
      }

      if (newValue && newValue != value) {
        this.deliveryAddressForm.controls.municipality.patchValue({
          postalCode: newValue
        }, {emitEvent: false});

        // Tiny animation when we autocorrect
        this.postalCodeAutocorrectState = true;
        setTimeout(() => {
          this.postalCodeAutocorrectState = false;
        }, 250);
      }
    }
  }

  private submitValidatedForm(): void {
    const override: AddressVm = {
      name: this.originalDeliveryAddress.name,
      addressLine1: this.deliveryAddressForm.value.addressLine1.trim(),
      addressLine2: this.deliveryAddressForm.value.addressLine2.trim(),
      addressLine3: this.deliveryAddressForm.value.addressLine3.trim(),
      postalCode: this.deliveryAddressForm. value.municipality.postalCode,
      country: this.deliveryAddressForm.value.countryName,
      city: this.deliveryAddressForm.value.municipality.city.trim(),
      phoneNumbers: []
    };

    this.deliveryAddressChanged.emit({
      isOverride: true,
      deliveryAddress: override,
      addressLines: [
      ].filter(val => val)
    });
    this.overriddenDeliveryAddress = override;
    this.currentDeliveryAddress = this.overriddenDeliveryAddress;
    this.cancelEditDeliveryAddress();
  }

  protected async onSubmit(): Promise<void> {
    this.isSubmitted = true;
    this.noticebar.hide();

    if (!this.deliveryAddressForm.valid) {
      this.validation.validateAllFormFields(this.deliveryAddressForm);

      if (this.deliveryAddressForm.pending) {
        // Wait for async validators
        const sub = this.deliveryAddressForm.statusChanges.subscribe(() => {
          if (this.deliveryAddressForm.valid) {
            this.submitValidatedForm();
          } else {
            this.validation.showErrors(this.deliveryAddressFormElement);
          }
          sub.unsubscribe();
        });
      } else {
        this.validation.showErrors(this.deliveryAddressFormElement);
      }
      return;
    } else {
      this.submitValidatedForm();
    }

  }

  private formatSource(source: MibpOrderAddressSource): string {
    switch (source) {
    case MibpOrderAddressSource.DeliverySequence: return `DeliverySequence`;
    case MibpOrderAddressSource.ExtendedOrderInfo: return `Order Extended Info`;
    case MibpOrderAddressSource.ExtendedOrderInfoBestGuess: return `Extended Info (Best guess)`;
    case MibpOrderAddressSource.Unspecified: return 'Unspecified';
    }
  }


  ngOnChanges(changes: SimpleChanges): void {
    if (changes.deliverySequenceId && changes.deliverySequenceId.currentValue !== changes.deliverySequenceId.previousValue) {
      this.checkIfEditingIsAllowed();
      this.loadAddressLines();
    } else if (changes.orderId && changes.orderId.currentValue !== changes.orderId.previousValue) {
      this.checkIfEditingIsAllowed();
      this.loadAddressLines();
    }
  }

  private checkIfEditingIsAllowed(): void {

    if (this.readonly) {
      this.editDisabledReason = `Editing is disabled for this view`;
    } else {
      if (!this.mibpSession.current?.activeDeliverySequence.countryCode) {
        this.editDisabledReason = `Can't allow editing - delivery Sequence has no connected country`;
      }
    }

    if (!this.readonly && this.mibpSession.current?.activeDeliverySequence && this.mibpSession.current?.activeDeliverySequence.countryCode) {

      this.allowDeliveryAddressOverride = this.context.testPermission({ features: [MySandvikFeatures.ShopManageDeliveryAddress] });
      
      const countryCodes =  this.globalConfig.enablePostalCodeValidationForCountries.map(code => code.toLowerCase());
      //Postalcode validation for countrycode is enabled
      const postalCodeValidationEnabled = countryCodes.includes(this.mibpSession.current?.activeDeliverySequence?.countryCode.toLowerCase());

      this.allowDeliveryAddressOverride = this.allowDeliveryAddressOverride && postalCodeValidationEnabled;

      if (!this.allowDeliveryAddressOverride) {
        this.editDisabledReason = `You don't have permission to edit the delivery address or the country is not set up to use postalcode validation`;
      }
    }
  }

  protected cancelEditDeliveryAddress(): void {
    this.isEditingDeliveryAddress = false;
    this.isEditing.emit(false);
  }


  protected beginEditDeliveryAddress(): void {
    this.isEditing.emit(true);
    this.isEditingDeliveryAddress = true;
  }


  private patchForm(deliveryAddress: AddressVm): void {
    this.deliveryAddressForm.patchValue({
      countryName: deliveryAddress.country,

      municipality: {
        countryCode: this.mibpSession.activeDeliverySequence?.countryCode,
        city: deliveryAddress.city,
        postalCode: deliveryAddress.postalCode
      },
      addressLine1: deliveryAddress.addressLine1,
      addressLine2: deliveryAddress.addressLine2,
      addressLine3: deliveryAddress.addressLine3,
    });

  }

  private loadAddressLines() {
    this.addressLines = null;
    this.hasError = false;
    if (this.deliverySequenceId && !this.orderId) {
      this.isLoading = true;

      firstValueFrom(this.ordersController.getAddressLinesForDeliverySequence({deliverysequenceid: this.deliverySequenceId})).then(
        addressLines => {
          this.addressLines = addressLines;
          if (!addressLines || !addressLines.deliveryAddress || !addressLines.invoiceAddress) {
            this.hasError = true;
          }

          this.invoiceAdressSource = this.formatSource(addressLines.invoiceAddressSource);
          this.deliveryAddressSource = this.formatSource(addressLines.deliveryAddressSource);
          this.originalDeliveryAddress = addressLines.deliveryAddress;

          if (!this.originalDeliveryAddress.country) {
            this.originalDeliveryAddress.country = this.mibpSession.current.activeDeliverySequence?.countryName?.toUpperCase();
          }

          this.patchForm(this.originalDeliveryAddress);
          this.autoCorrectPostalCodeIfNeeded();

          this.currentDeliveryAddress = this.originalDeliveryAddress;
          this.isLoading = false;
          this.deliveryAddressChanged.emit({
            isOverride: false,
            deliveryAddress: this.originalDeliveryAddress,
            addressLines: [
            ].filter(val => val)
          });
        }
      ).catch(err=>{
        this.log.error('error fetching address lines',err);
        this.isLoading = false;
        this.hasError = true;
      });
    }
    else if (this.orderId){
      firstValueFrom(this.ordersController.getAddressLinesForOrder({orderId: this.orderId.toString()})).then(
        addressLines => {
          this.addressLines = addressLines;
          this.originalDeliveryAddress = addressLines.deliveryAddress;
          this.currentDeliveryAddress = this.originalDeliveryAddress;
          this.overriddenDeliveryAddress = null;

          this.deliveryAddressChanged.emit({
            isOverride: false,
            deliveryAddress: this.originalDeliveryAddress,
            addressLines: [
            ].filter(val => val)
          });

          this.invoiceAdressSource = this.formatSource(addressLines.invoiceAddressSource);
          this.deliveryAddressSource = this.formatSource(addressLines.deliveryAddressSource);
          if (!addressLines || !addressLines.deliveryAddress || !addressLines.invoiceAddress) {
            this.hasError = true;
          }
          this.isLoading = false;
        }
      ).catch(err=>{
        this.log.error('error fetching address for order',err);
        this.isLoading = false;
        this.hasError = true;
      });
    }

  }

}
