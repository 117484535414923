export interface IntegrationMessageTypeVm {
  identifier: string,
    title: string,
    messageName?: string;
    messageType: 'xml' | 'json';
    templateFilename?: string;
    description?: string;
}

export const resubmittableIntegrationMessageTypes: IntegrationMessageTypeVm[] = [
  {
    title: 'Product',
    identifier: 'product_create_item',
    messageName: 'CREATE_ITEM',
    messageType: 'xml',
    templateFilename: 'CREATE_ITEM_TEMPLATE.xml',
    description: 'Will save  message as blob in SMRT storage and send JSON message SMRT ServiceBus with blob filename'
  },
  {
    title: 'Global Product',
    identifier: 'product_create_item_mdg',
    messageName: 'CREATE_ITEM_MDG',
    messageType: 'xml',
    templateFilename: 'CREATE_ITEM_TEMPLATE.xml',
    description: 'Will save  message as blob in SMRT storage and send JSON message SMRT ServiceBus with blob filename'
  },
  {
    title: 'Order Acknowledgement',
    identifier: 'acknowledge_sales_order',
    messageName: 'acknowledge_sales_order',
    messageType: 'xml',
    description: 'Will save message as blob in SMRT storage and send JSON message SMRT ServiceBus with blob filename'
  },
  {
    title: 'Despatch Note',
    identifier: 'confirm_despatch',
    messageName: 'confirm_despatch',
    messageType: 'xml',
    description: ''
  },
  {
    title: 'Despatch Waybill',
    identifier: 'publish_waybill',
    messageName: 'publish_waybill',
    messageType: 'xml',
    description: ''
  },
  {
    title: 'Expected Delivery Date',
    identifier: 'publish_expected_delivery_date',
    messageName: 'publish_expected_delivery_date',
    messageType: 'xml',
    description: ''
  },

  {
    title: 'Customer',
    identifier: 'customer_notification',
    messageName: 'customer_notification',
    messageType: 'xml',
    // templateFilename: 'CustomerMaintainNotification_Template.xml',
  }
];
