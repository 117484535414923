import { GlobalConfigService } from './../../../services/global-config/global-config.service';
import { ResponsiveEvent } from './../../../services/responsive/responsive.types';
import { SignalR_NotificationEvent } from './../../../services/mibp-api-services/_mibp-api-generated.service.types';
import { PermissionService } from './../../../services/permission/permission.service';
import { FrontendContextService } from './../../../services/front-end-context/front-end-context.service';
import { Component, ElementRef, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from "@angular/core";
import { environment } from 'root/environment';
import { Subscription, filter, skip } from 'rxjs';
import { AuthService } from 'root/services/auth-service';
import { BroadcastService } from 'root/services/broadcast-service/broadcast.service';
import { NavigationService } from 'root/services/navigation/navigation.service';
import { ActAsComponent } from 'root/components/act-as/act-as.component';
import { MibpPopupContainerComponent } from 'root/components/popup-container/popup-container.component';
import { ApiService } from 'root/services/mibp-api-services';
import { SystemMessageService } from 'root/components/system-message-dialog/system-message.service';
import { allPermissionPolicies } from 'root/all-permission-policies';
import { MibpSessionUserViewModel, SessionDeliverySequenceViewModel, UserType } from 'root/mibp-openapi-gen/models';
import { MibpSessionService } from 'root/services/mibp-session/mibp-session.service';
import { MibpActiveAcountPickerComponent } from 'root/components/active-account-picker/active-account-picker.component';

@Component({
  selector: 'mibp-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class MibpHeaderComponent implements OnInit, OnDestroy {

  enableBugReport = environment.enableBugReport;
  unreadUserActivityCountText: string;
  canSeeCart = false;
  flagImageUrl: string;
  languageCodes: string[];
  resizeSub: Subscription;
  dlvSeqSub: Subscription;
  actingAsText: string;
  actingAsTextShort: string;
  isActAsOpen = false;
  canActAs = false;
  showLanguagePicker = false;
  cartSub: Subscription;
  notificationSub?: Subscription;
  itemsInCart?: number;
  notificationCount?: number;
  isSupportUser = false;
  isSystemMessageVisible = false;
  sessionSub: Subscription;
  systemMessageSubscription: Subscription;
  systemMessageCountAsString: string;
  currentUser?: MibpSessionUserViewModel;
  showHeaderSearch =  true;

  @ViewChild(ActAsComponent) actAsComponent: ActAsComponent;
  @ViewChild('actAsPopupContainer') actAsPopupContainer: MibpPopupContainerComponent;
  @ViewChild(MibpActiveAcountPickerComponent) activeAccountPicker: MibpActiveAcountPickerComponent;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  @ViewChild('actAsDropdown') actAsdropdown: ElementRef<any>;
  userEventSubscription: Subscription;
  isActiveUser = false;
  isToggleEnabled = false;
  sorgreater: boolean;
  userType: UserType;
  companyId:number=null;
  isCartQuickviewOpen = false;
  hideHeaderSearchSub: Subscription;

  constructor(public auth: AuthService,
              private frontendContext: FrontendContextService,
              private session: MibpSessionService,
              private permissionService: PermissionService,
              private navigationService: NavigationService,
              public broadcast: BroadcastService,
              private api: ApiService,
              protected globalConfig : GlobalConfigService,
              protected systemMessageService: SystemMessageService) {}

  public get isLoggedIn(): boolean {
    return this.auth.isLoggedIn();
  }

  public login(): void {
    this.auth.signinRedirect();
  }

  showActAs(e: Event): void {
    e.preventDefault();
    this.actAsComponent.show(this.actAsdropdown.nativeElement, 'bottomleft');
  }

  toggleActAs(relatedToElement: HTMLElement, toggleElement: HTMLElement): void {
    if (this.globalConfig.enableEquipmentScopingBasedOnCustomers) {

      this.activeAccountPicker.open();

      return;
    }

    if (this.canActAs) {
      this.actAsPopupContainer.toggle(relatedToElement, toggleElement);
    }
  }

  runSearch(e: KeyboardEvent): void {
    const input = (e.target as HTMLInputElement);
    const query = (e.target as HTMLInputElement)?.value;

    if (query) {
      this.navigationService.navigateTo('shop/products', {
        queryParams: {
          query: query
        }
      });
      input.value = '';
    }
  }

  actAsOpened(): void {
    this.isActAsOpen = true;
  }

  onMobileSearchVisible(): void {
    setTimeout(() => {
      document.getElementById('mobile-toolbar-search-input')?.focus();
    }, 50);
  }

  actAsClosed(): void {
    this.isActAsOpen = false;
  }

  private updateUserInfo(): void {
    if (this.session.hasActiveUser() && this.session.hasRole()) {
      this.canSeeCart = this.permissionService.test(allPermissionPolicies.canSeeCart);
      this.canActAs = this.permissionService.test(allPermissionPolicies.canActAs);
    }
    this.isActiveUser = this.session.hasActiveUser();
    this.currentUser = this.session.current?.user;
  }

  ngOnInit(): void {
    this.currentUser = this.session.current?.user;
    this.isToggleEnabled = !this.globalConfig.disableToggleSideBar;
    this.sessionSub = this.session.current$.pipe(skip(1), filter(u => u.user != this.currentUser)).subscribe(() => this.updateUserInfo());
    this.dlvSeqSub = this.broadcast.deliverySequence.subscribe(d => this.onDeliverySequenceChanged(d));
    this.resizeSub = this.broadcast.responsiveBreakpoint.subscribe(e => this.onResponsiveBreakpointChanged(e));
    this.notificationSub = this.api.EventHub.Notification.subscribe(e => this.onNotificationsUpdated(e));
    this.isSupportUser = this.frontendContext.testPermission(allPermissionPolicies.isSupportTeamMember);
    this.hideHeaderSearchSub = this.broadcast.hideHeaderSearch.subscribe(h => this.onHideHeadersearch(h));

    this.updateUserInfo();
    this.userEventSubscription = this.broadcast.mibpSession.subscribe(() => this.updateUserInfo());

    this.broadcast.cartQuickviewOpen.subscribe(isOpen =>
    {
      this.isCartQuickviewOpen = isOpen;
    });

    this.broadcast.mibpSession.subscribe(userEvent => {
      if (userEvent && userEvent.user) {
        this.userType = userEvent.user.type;
      }
      if (userEvent && userEvent.activeDeliverySequence?.companyId) {
        this.companyId = userEvent.activeDeliverySequence?.companyId;
      }else
      {
        this.companyId =null;
      }
    });

    this.broadcast.cartEvent.subscribe(cartUpdatedEvent => {
      this.itemsInCart = cartUpdatedEvent?.count;
    });

  }
  onHideHeadersearch(hideHeaderSearch: boolean): void {
    this.showHeaderSearch = !hideHeaderSearch;
  }

  onDeliverySequenceChanged(d: SessionDeliverySequenceViewModel): void {
    if (d) {
      this.actingAsText = `${d?.erpCustomerId} - ${d?.customerName} ${d?.deliverySequenceNumber}`;
      this.actingAsTextShort = `${d?.erpCustomerId} - ${d?.deliverySequenceNumber}`;
    } else {
      this.actingAsText = null;
      this.actingAsTextShort = null;
    }
  }

  onResponsiveBreakpointChanged(e: ResponsiveEvent): void {
    this.sorgreater = e.gteq('s');
  }

  onNotificationsUpdated(e: SignalR_NotificationEvent): void {
    this.notificationCount = e?.unreadNotifications;
  }

  toggleSidebar(){
    this.frontendContext.toggleSidebar(true);
  }

  onCartIconClick(){
    this.isCartQuickviewOpen = !this.isCartQuickviewOpen;
    this.broadcast.setCartQuickviewOpen(this.isCartQuickviewOpen);
  }

  ngOnDestroy(): void {
    this.resizeSub?.unsubscribe();
    this.dlvSeqSub?.unsubscribe();
    this.cartSub?.unsubscribe();
    this.notificationSub?.unsubscribe();
    this.userEventSubscription?.unsubscribe();
    this.systemMessageSubscription?.unsubscribe();
    this.sessionSub?.unsubscribe();
  }

}
