import { environment } from 'root/environment';
import { Component, OnInit } from '@angular/core';
import { BroadcastService } from './services/broadcast-service/broadcast.service';
import { ApiService } from './services/mibp-api-services/_mibp-api-generated.service';
import { Subscription } from 'rxjs';
import { ClientIdService } from './services/clientid-service/clientid.service';
import { LogService, MibpHttpApi, MibpLogger, MibpResponsiveService } from './services';
import { GoogleTagManagerService } from './services/google-tag-manager-service/google-tag-manager.service';
import { filter } from 'rxjs/operators';
import { SwUpdate } from '@angular/service-worker';
import { MibpSessionService } from './services/mibp-session/mibp-session.service';
import { MibpDateTimeService } from './services/datetime/datetime.service';
import { DownloadedManualsService } from './services/downloaded-manuals/downloaded-manuals.service';
import { MibpSessionRevokedService } from './services/session-revoked/session-revoked.service';

@Component({
  selector: 'ecommerce-app',
  templateUrl: './ecommerce-app.component.html'
})
export class EcommerceAppComponent implements OnInit {
  title = 'My Sandvik Customer Portal';
  userApiSubscription: Subscription;
  enableOfflinePage: boolean;
  log: MibpLogger;
  routerEventSub: Subscription;

  constructor(private broadcastService: BroadcastService,
    private apiService: ApiService,
    private clientIdService: ClientIdService,
    private mibpSession: MibpSessionService,
    private httpApiService: MibpHttpApi,
    private downloadManualService: DownloadedManualsService,
    private responsive: MibpResponsiveService,
    private googleTagManagerService: GoogleTagManagerService,
    private swUpdate: SwUpdate,
    private sessionRevokedService: MibpSessionRevokedService,
    private dateimeService: MibpDateTimeService, // TO make sure we prepare timezones
    private logService: LogService) {
    this.log = this.logService.withPrefix('ecommerce-app.component');
    this.clientIdService.getClientId(); // Just to make sure we have a ClientID
    this.responsive.begin();
    this.sessionRevokedService.startSubscription();
    this.enableOfflinePage = environment.enableServiceWorker;
  }

  ngOnInit(): void {
    this.dateimeService.testTimezoneSupport();
    this.downloadManualService.startListeningToEvents();

    if(this.swUpdate.isEnabled){
      this.swUpdate.versionUpdates.subscribe(() => {
        this.swUpdate.activateUpdate().then(() => location.reload());
      });
    }

    // When we receive this event we should request for updated session data
    // This could be when permissions or something is changed for the user
    this.apiService.EventHub.TriggerMibpSessionUpdate.pipe(filter(e => e !== null)).subscribe(() => {
      this.log.info(`Received TriggerMibpSessionUpdate event.`);
      this.mibpSession.reloadSessionFromBackend();
    });

    this.googleTagManagerService.setupGoogleTagManager();

    this.mibpLogErrors();
  }

  mibpLogErrors(): void {
    this.broadcastService.frontendError.subscribe(e => {
      if (e) {
        this.httpApiService.MibpLog.logFrontendError(e).then(() => {
          // No need to do anything here
        }, err => {
          // eslint-disable-next-line no-console
          console.warn('Could not log frontend error in MibpLog', err);
        });
      }
    });
  }
}
