/* tslint:disable */
/* eslint-disable */
export enum CaseType {
  Unspecified = 0,
  ContactUs = 1,
  PartsManuals = 2,
  ItemEnquriy = 3,
  ShippingAndTracking = 4,
  ReturnsAndOrder = 5,
  TradingAccountQuery = 6,
  TechnicalRequest = 7,
  WebsiteFeedBack = 8,
  GeneralQuestionOrRequest = 9,
  RequestQuoteForMachineUpgrade = 10,
  RequestQuote = 11,
  RemoteExpertise = 12
}
