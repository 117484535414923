
<div class="my-horizontal-container"
  [class.my-horizontal-container--scroll]="hasScroll"
  [class.my-horizontal-container--show-custom-elements]="showCustomScrollElements"
  [class.my-horizontal-container--small]="smallScrollButtons"
  [class.my-horizontal-container--border]="showBottomBorder && (!hasScroll && showCustomScrollElements)"
  [class.my-horizontal-container--no-bottom-margin]="!addBottomMargin"
  [class.my-bm]="addBottomMargin && !showCustomScrollElements"
  #containerElement>
  <div (mousedown)="onScroll($event, 40)" class="my-horizontal-container__arrow my-horizontal-container__arrow--right hide-print" [class.my-horizontal-container__arrow--small]="smallScrollButtons"><span translate="no" class="material-icon material-icon--blue material-icon--large">navigate_next</span></div>
  <div (mousedown)="onScroll($event, -40)" class="my-horizontal-container__arrow my-horizontal-container__arrow--left hide-print" [class.my-horizontal-container__arrow--small]="smallScrollButtons"><span translate="no" class="material-icon material-icon--blue material-icon--large">navigate_before</span></div>
  <div #scrollableElement
    class="my-horizontal-scroll"
    [class.my-horizontal-scroll--no-bottom-margin]="!addBottomMargin"
    [class.my-horizontal-scroll--small]="smallScrollButtons"
    [class.my-horizontal-scroll--has-scroll]="hasScroll"
    [class.my-horizontal-scroll--no-padding]="showBottomBorder && showCustomScrollElements && hasScroll"
    [class.my-horizontal-scroll--custom-elements]="showCustomScrollElements">
    <ng-content></ng-content>
  </div>
</div>
