import { MibpCrumb } from './../../components/navigation/breadcrumbs/breadcrumbs-v2.types';
import { Injectable } from "@angular/core";
import { Subject, Observable, BehaviorSubject } from "rxjs";
import { SignalR_ErrorEvent as MibpErrorEvent  } from './../mibp-api-services/_mibp-api-generated.service.types';
import { ApplicationState } from '../application-state/application-state.types';
import { ExtendedGlobalConfig } from '../global-config/global-config.types';
import { BreadcrumbControlMode, LayoutContainerInfo, SignalrConnectionStatusEvent, UnhandledExceptionInfo } from "./broadcast.service.types";
import { MySandvikNavigationItem } from "../navigation/navigation-types";
import { ResponsiveEvent } from '../responsive/responsive.types';
import {UserAction} from '../../components/context-menu/context-menu.types';
import { CartUpdatedEvent } from '../cart-service/cart.service';
import { MibpSessionViewModel, SessionDeliverySequenceViewModel } from 'root/mibp-openapi-gen/models';


@Injectable({
  providedIn: 'root'
})
export class BroadcastService {

  private errorSubject = new Subject<MibpErrorEvent>();
  private frontendErrorSubject = new Subject<any>();
  private languageSubject = new BehaviorSubject<string>(null);
  private languageChangeSubject = new Subject<string>();
  private deliverySequenceSubject = new BehaviorSubject<SessionDeliverySequenceViewModel>(null);
  private mibpSessionSubject = new BehaviorSubject<MibpSessionViewModel>(null);
  private clientIdSubject = new BehaviorSubject<string>(null);
  private screenSizeSubject = new BehaviorSubject<ScreenSizeEvent>(null);
  private onDocumotoSignoutSubject = new Subject<boolean>();
  private applicationStateSubject = new BehaviorSubject<ApplicationState>(null);
  private dateFormatResourceStringsSubject = new BehaviorSubject<DateFormatResourceStrings>(null);
  private accessTokenSubject = new BehaviorSubject<AccessTokenEvent>(null);
  private signalrStatusSubject = new BehaviorSubject<SignalrConnectionStatusEvent>(null);
  private globalConfigSubject = new BehaviorSubject<ExtendedGlobalConfig>(null);
  private unhandledExceptionSubject = new BehaviorSubject<UnhandledExceptionInfo>(null);
  private navigationSubject = new BehaviorSubject<MySandvikNavigationItem[]>(null);
  private breadcrumbSubject = new BehaviorSubject<MibpCrumb[]>([]);
  private layoutContainerSubject = new Subject<LayoutContainerInfo>();
  private responsiveSubject = new BehaviorSubject<ResponsiveEvent>(null);
  private sidebarCollapsedSubject = new BehaviorSubject<boolean>(false);
  private supportPageMenuItemsSubject = new BehaviorSubject<UserAction[]>(null);
  private supportPageMenuActionSubject = new Subject<UserAction>();
  private supportToolbarOpenSubject = new BehaviorSubject<boolean>(false);
  private hasConfigurationErrorsSubject = new BehaviorSubject<boolean>(false);
  private cartEventSubject = new BehaviorSubject<CartUpdatedEvent>(null);
  private breadcrumbControlSubject = new BehaviorSubject<BreadcrumbControlMode>('auto');
  private cartQuickviewOpenSubject = new BehaviorSubject<boolean>(false);
  private hideHeaderSearchSubject = new BehaviorSubject<boolean>(false);
  private focusContentSubject = new BehaviorSubject<boolean>(false);
  private sessionRevokedSubject = new BehaviorSubject<boolean>(false);

  isLanguageInitialLoad = true;

  public get focusContent(): Observable<boolean> {
    return this.focusContentSubject.asObservable();
  }

  public get hideHeaderSearch(): Observable<boolean> {
    return this.hideHeaderSearchSubject.asObservable();
  }

  public get supportPageMenuItems(): Observable<UserAction[]> {
    return this.supportPageMenuItemsSubject.asObservable();
  }

  public get breadcrumbControl(): Observable<BreadcrumbControlMode> {
    return this.breadcrumbControlSubject.asObservable();
  }

  public get supportPageMenuAction(): Observable<UserAction> {
    return this.supportPageMenuActionSubject.asObservable();
  }

  public get supportToolbarOpen(): Observable<boolean> {
    return this.supportToolbarOpenSubject.asObservable();
  }

  public get breadcrumbs(): Observable<MibpCrumb[]> {
    return this.breadcrumbSubject.asObservable();
  }

  public get screenSize(): Observable<ScreenSizeEvent> {
    return this.screenSizeSubject.asObservable();
  }

  public get sidebarCollapsed(): Observable<boolean> {
    return this.sidebarCollapsedSubject.asObservable();
  }

  public get aplicationState(): Observable<ApplicationState> {
    return this.applicationStateSubject.asObservable();
  }

  public get navigation(): Observable<MySandvikNavigationItem[]> {
    return this.navigationSubject.asObservable();
  }

  public get unhandledException(): Observable<UnhandledExceptionInfo> {
    return this.unhandledExceptionSubject.asObservable();
  }

  public get dateFormatResourceStrings(): Observable<DateFormatResourceStrings> {
    return this.dateFormatResourceStringsSubject.asObservable();
  }

  public get language(): Observable<string> {
    return this.languageSubject.asObservable();
  }

  public get languageChange(): Observable<string> {
    return this.languageChangeSubject.asObservable();
  }

  public get deliverySequence(): Observable<SessionDeliverySequenceViewModel> {
    return this.deliverySequenceSubject.asObservable();
  }

  public get mibpSession(): Observable<MibpSessionViewModel> {
    return this.mibpSessionSubject.asObservable();
  }

  public get clientId(): Observable<string> {
    return this.clientIdSubject.asObservable();
  }

  public get documotoSignout(): Observable<boolean> {
    return this.onDocumotoSignoutSubject.asObservable();
  }

  public get signalR(): Observable<SignalrConnectionStatusEvent> {
    return this.signalrStatusSubject.asObservable();
  }

  public get applicationState(): Observable<ApplicationState> {
    return this.applicationStateSubject.asObservable();
  }

  public get globalConfig(): Observable<ExtendedGlobalConfig> {
    return this.globalConfigSubject.asObservable();
  }

  public get responsiveBreakpoint(): Observable<ResponsiveEvent> {
    return this.responsiveSubject.asObservable();
  }

  public get frontendError(): Observable<any> {
    return this.frontendErrorSubject.asObservable();
  }

  public get hasConfigurationErrors(): Observable<boolean> {
    return this.hasConfigurationErrorsSubject.asObservable();
  }

  public get cartEvent(): Observable<CartUpdatedEvent> {
    return this.cartEventSubject.asObservable();
  }

  public get cartEventValue():CartUpdatedEvent {
    return this.cartEventSubject.value;
  }

  public get cartQuickviewOpen(): Observable<boolean> {
    return this.cartQuickviewOpenSubject.asObservable();
  }

  public get sessionRevoked(): Observable<boolean> {
    return this.sessionRevokedSubject.asObservable();
  }

  public setCartEvent(event : CartUpdatedEvent){
    this.cartEventSubject.next(event);
  }

  public setHasConfigurationErrors(val: boolean): void {
    this.hasConfigurationErrorsSubject.next(val);
  }

  public setLanguage(lang: string): void {
    this.languageSubject.next(lang);
    if (!this.isLanguageInitialLoad) {
      this.languageChangeSubject.next(lang);
    }
    this.isLanguageInitialLoad = false;
  }

  public setScreenSize(size: ScreenSizeEvent): void {

    if (this.screenSizeSubject.value) {
      if (this.screenSizeSubject.value.width != size.width || this.screenSizeSubject.value.height != size.height) {
        this.screenSizeSubject.next(size);
      }
    } else {
      this.screenSizeSubject.next(size);
    }

  }

  public setSupportPageMenuItems(items: UserAction[]): void{
    this.supportPageMenuItemsSubject.next(items);
  }

  public triggerSupportMenuAction(action: UserAction): void {
    this.supportPageMenuActionSubject.next(action);
  }

  public setSupportToolbarOpen(isOpen: boolean): void {
    this.supportToolbarOpenSubject.next(isOpen);
  }

  public get accessToken(): Observable<AccessTokenEvent> {
    return this.accessTokenSubject.asObservable();
  }

  public get layoutContainer(): Observable<LayoutContainerInfo> {
    return this.layoutContainerSubject.asObservable();
  }

  public setDocumotoSignout(isDone: boolean): void {
    this.onDocumotoSignoutSubject.next(isDone);
  }

  public setDeliverySequence(ds: SessionDeliverySequenceViewModel): void {
    // Only trigger "next" if the delivery sequence was actually changed
    if (this.deliverySequenceSubject.value?.deliverySequenceId != ds?.deliverySequenceId) {
      this.deliverySequenceSubject.next(ds);
    }
  }

  public setMibpSession(u: MibpSessionViewModel): void {
    this.mibpSessionSubject.next(u);
  }

  public setSidebarCollapsed(isCollapsed: boolean): void {
    this.sidebarCollapsedSubject.next(isCollapsed);
  }

  public setFrontendError(u: any): void {
    this.frontendErrorSubject.next(u);
  }
  public setHideHeaderSearch(hide: boolean): void {
    this.hideHeaderSearchSubject.next(hide);
  }

  public setClientId(u: string): void {
    this.clientIdSubject.next(u);
  }

  public setResponsiveBreakpoint(u: ResponsiveEvent): void {
    this.responsiveSubject.next(u);
  }

  public setDateFormatResourceStrings(u: DateFormatResourceStrings): void {
    this.dateFormatResourceStringsSubject.next(u);
  }

  public setAccessToken(u: AccessTokenEvent): void {
    if (u?.accessToken !== this.accessTokenSubject.value?.accessToken || u?.expiresOn?.getTime() !== this.accessTokenSubject.value?.expiresOn?.getTime()) {
      this.accessTokenSubject.next(u);
    }
  }

  public setSignalrStatus(signalrEvent: SignalrConnectionStatusEvent): void {
    this.signalrStatusSubject.next(signalrEvent);
  }

  public setGlobalConfig(u: ExtendedGlobalConfig): void {
    this.globalConfigSubject.next(u);
  }

  public setNavigationItems(u: MySandvikNavigationItem[]): void {
    this.navigationSubject.next(u);
  }

  public get errors(): Observable<MibpErrorEvent> {
    return this.errorSubject.asObservable();
  }

  public addError(error: MibpErrorEvent): void {
    this.errorSubject.next(error);
  }

  public setApplicationState(state: ApplicationState): void {
    this.applicationStateSubject.next(state);
  }

  public setUnhandledException(ex: UnhandledExceptionInfo): void {
    this.unhandledExceptionSubject.next(ex);
  }

  public setBreadcrumbConrolMode(mode: BreadcrumbControlMode): void {
    this.breadcrumbControlSubject.next(mode);
  }

  public setBreadcrumbs(crumbs: MibpCrumb[]): void {
    this.breadcrumbSubject.next(crumbs);
  }

  public setCartQuickviewOpen(isOpen: boolean): void {
    this.cartQuickviewOpenSubject.next(isOpen);
  }

  public setFocusContent(focusContent: boolean): void {
    this.focusContentSubject.next(focusContent);
  }

  public setSessionRevoked(isRevoked: boolean): void {
    this.sessionRevokedSubject.next(isRevoked);
  }

  /**
   * Change general layout options
   *
   * @param {LayoutContainerInfo} layout
   * @memberof BroadcastService
   */
  public setLayoutContainer(layout: LayoutContainerInfo): void {
    this.layoutContainerSubject.next(layout);
  }

  get snapshot() {

    return {
      language: this.languageSubject.value,
      deliverySequence: this.deliverySequenceSubject.value,
      mibpSession: this.mibpSessionSubject.value,
      clientId: this.clientIdSubject.value,
      applicationState: this.applicationStateSubject.value,
      accessTokenExpirationDate: this.accessTokenSubject.value,
      signalR: this.signalrStatusSubject.value,
      globalConfig: this.globalConfigSubject.value,
      unhandledException: this.unhandledExceptionSubject.value,
      navigation: this.navigationSubject.value,
      breadcrumbs: this.breadcrumbSubject.value,
      breadcrumbControlMode: this.breadcrumbControlSubject.value,
      responsiveBreakpoint: this.responsiveSubject.value,
      screenSize: this.screenSizeSubject.value,
      sidebarCollapsed: this.sidebarCollapsedSubject.value,
      hasConfigurationErrors: this.hasConfigurationErrorsSubject.value,
      focusContent: this.focusContentSubject.value,
      sessionRevoked: this.sessionRevokedSubject.value
    };
  }

}

export interface DateFormatResourceStrings {
  shortYearPlaceholder: string;
  shortMonthPlaceholder: string;
  shortDatePlaceholder: string;
  timezone: string;
}

export interface AccessTokenEvent {
  accessToken?: string;
  expiresOn?: Date;
  fromCache?: boolean;
}

export interface ScreenSizeEvent {
  width: number;
  height: number;
}
