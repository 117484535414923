import { EquipmentDto } from '../../mibp-openapi-gen/models/equipment-dto';
import { EquipmentsApiController } from 'root/mibp-openapi-gen/controllers';
import { Component, Input, OnInit, QueryList, ViewChildren } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { GlobalConfigService, LocalizationService, LogService, MibpErrorHandler, MibpLogger, ScrollToService } from 'root/services';
import { ActivatedRoute, Router } from '@angular/router';
import { PaginationService } from 'root/services/pagination-service/pagination.service';
import { id } from 'date-fns/locale';
import { PaginatorComponent } from '../paginator/paginator.component';
import { firstValueFrom } from 'rxjs';


@Component({
  selector: 'mibp-equipment-list',
  templateUrl: './equipment-list.component.html',
  styleUrls: ['./equipment-list.component.scss']
})
export class ProductEquipmentListComponent implements OnInit {
  log: MibpLogger;
  isLoading = false;
  equipments:  EquipmentDto[]=[];
  totalItems = 0;
  defaultPageSize = 10;
  take = 10;
  skip = 0;
  private currentUrlWithoutQuerystring: string;
  protected enableEquipmentScopingBasedOnCustomers: boolean;

  @Input() productId: string;
  @Input() kitProductId: string;
  @ViewChildren(PaginatorComponent) paginatorComponents!: QueryList<PaginatorComponent>;
  constructor(private equipmentApi: EquipmentsApiController,
    private localizationService: LocalizationService,
    private router: Router,
    private logService: LogService,
    private mibpErrorHandler: MibpErrorHandler,
    private scrollToService: ScrollToService,
    private route: ActivatedRoute,
    private paginationService: PaginationService,
    private globalConfig: GlobalConfigService) {
    this.log = this.logService.withPrefix("equipment-list.component");
    const queryStringIndex = this.router.url.indexOf('?');
    this.currentUrlWithoutQuerystring = queryStringIndex >= 0 ? this.router.url.substring(0, queryStringIndex) : this.router.url;

  }

  ngOnInit(): void {
    this.enableEquipmentScopingBasedOnCustomers = this.globalConfig.enableEquipmentScopingBasedOnCustomers;
    if(this.kitProductId){
      this.getEquipmentsForKit();
    }
    else{
      this.getEquipments();
    }
  }

  async getEquipmentsForKit() {
    try{
      this.isLoading = true;
      const result = await firstValueFrom(this.equipmentApi.getEquipmentsByKit({kitProductId: this.kitProductId, take:this.take,skip:this.skip
      }));
      this.equipments = result.equipmentDTOs;
      this.totalItems = result.totalCount;
      this.paginatorComponents.forEach(o => {
        o.setIndex(this.skip, false);
      });
      this.isLoading = false;
    }
    catch(error){
      this.log.error('Error loading equipments', error);
      this.isLoading = false;
      this.mibpErrorHandler.displayErrorNoticeForSpecificException({
        exceptionTypeName: 'EquipmentDbException',
        defaultErrorText: 'Equipments could not be loaded',
        httpErrorResponse: error
      });
    }
  }

  getEquipments() {
    this.isLoading = true;
    this.equipmentApi.getEquipmentsByProductid({productId: this.productId, take:this.take,skip:this.skip
    }).subscribe({
      next: (result) => {
        this.equipments = result.equipmentDTOs;
        this.totalItems = result.totalCount;
        this.paginatorComponents.forEach(o => {
          o.setIndex(this.skip, false);
        });
        this.isLoading = false;
      },

      error: (errorResponse: HttpErrorResponse) => {
        this.log.error('Error loading equipments', errorResponse);
        this.isLoading = false;
        this.mibpErrorHandler.displayErrorNoticeForSpecificException({
          exceptionTypeName: 'EquipmentDbException',
          defaultErrorText: 'Equipments could not be loaded',
          httpErrorResponse: errorResponse
        });
      }
    });
  }

  viewDetails(equipmentId: string) : void {
    this.router.navigate([`/${this.localizationService.getLang()}/home/myfleet/machines/${encodeURIComponent(equipmentId)}`]);
  }

  onPageChange(e: {currentPage: number, totalItems: number, isUserEvent: boolean}): void {

    if (!e.totalItems || !e.isUserEvent) {
      return;
    }
    const newSkipValue = e ? this.paginationService.getIndexFromZeroBasedPageNumber(e.currentPage - 1, this.take) : null;
    this.skip = newSkipValue;
    this.getEquipments();

  }
}
