import { Injectable } from '@angular/core';
import { Subject, Observable, BehaviorSubject, ReplaySubject, of, shareReplay } from 'rxjs';
import { environment } from 'root/environment';
import { ClientSideCacheService } from 'root/services/client-side-cache/client-side-cache.service';
import { CacheScope } from 'root/services/client-side-cache/client-side-cache.service';
import { LocalizationDetails } from './localization-data.types';
import { LogService, MibpLogger } from '../logservice';
import { BroadcastService } from '../broadcast-service/broadcast.service';
import { addMonths, format, parse } from 'date-fns';
import { Router } from '@angular/router';
import { ResourceApiController } from 'root/mibp-openapi-gen/services/resource-api-controller';
import {ResourceValue} from 'root/mibp-openapi-gen/models';
import { firstValueFrom } from 'rxjs';
import { FormattingService } from '../formatting/formatting.service';

@Injectable({
  providedIn: 'root'
})

export class LocalizationService {
  private lang: string;
  private log: MibpLogger;
  private isDebuggerEnabled = false;
  private debugLocalization?: LocalizationDetails;
  private debugPrefix = ``;
  public readonly debugMissingPrefix = `❌`;

  private resourceStrings: ReplaySubject<LocalizationDetails> = new ReplaySubject(1);
  public readonly currentLocale: Observable<LocalizationDetails> = this.resourceStrings.asObservable();
  public readonly isLoaded = new Subject<LocalizationDetails>();

  private tm;
  private availableResourceStringSubject = new BehaviorSubject<string[] | null>(null);
  private resourceStringUpdateDelay = 250;
  private availableResourceStrings: string[] = [];
  private isFirstLoad = true;
  private availableLanguages?: string[];

  private localeSubject = new BehaviorSubject(null);
  public switchLanguageSubject : BehaviorSubject<boolean> = new BehaviorSubject(false);
  public get Locale$(): Observable<LocalizationDetails> {
    return this.localeSubject.asObservable();
  }

  public get Locale(): LocalizationDetails {
    return this.localeSubject.value;
  }

  public get isDebugEnabled(): boolean {
    return this.isDebuggerEnabled;
  }

  constructor(private formattingService: FormattingService,
    private cacheService: ClientSideCacheService,
    private router: Router,
    private broadcastService: BroadcastService,
    logger: LogService, private resourceApiController: ResourceApiController) {
    this.log = logger.withPrefix('localization');
    this.debugPrefix = this.cacheService.get<string>('localizationdebug');
    this.isDebuggerEnabled = !!this.debugPrefix;
  }

  public toggleLocalizationDebug(prefix: string): void {
    if (this.isDebuggerEnabled) {
      this.cacheService.remove('localizationdebug');
    } else {
      this.cacheService.add('localizationdebug', prefix, null, CacheScope.GlobalSessionStorage);
    }
    document.location.reload();
  }

  public get loaded(): Observable<LocalizationDetails> {
    return this.isLoaded.asObservable();
  }

  public async getAvailableLanguages(): Promise<string[]> {
    if (this.availableLanguages?.length > 0) {
      return this.availableLanguages;
    }

    return new Promise((resolve, reject) => {
      this.resourceApiController.allSelectableLanguagesResource().subscribe({
        next: languageCodes => {
          this.availableLanguages = languageCodes;
          resolve(languageCodes);
        },
        error: err => reject(err)
      });
    });

  }

  /**
   * Delete cached values for languages except the specified one
   *
   * @param keepLanguageCode Keep cache for this language code
   */
  private clearOldCache(keepLanguageCode: string): void {
    const resourceStrings = this.cacheService
      .getAllKeys()
      .filter(f => f.includes('resourceStrings') && !f.endsWith('_' + keepLanguageCode) );

    resourceStrings.forEach(key => {
      this.cacheService.remove(key);
    });
  }


  /***
   * Fetch the specified resource strings and subscribe to changes
   *
   * @param keys Array of resource strings to use
   * @param callback Callback function when resources are available or updated
   * @returns Function to stop using the resource strings. Must be called on *ngOnDestroy*
   */
  public using(keys: string[], callback: (values: string[]) => void): () => void {

    const watch = this.localeSubject.subscribe(() => {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      this.getValues(keys).then(values => callback(values)).catch(() => {});
    });

    this.addAvailableResourceString(keys);

    return () => {
      this.removeAvailableResourceString(keys);
      watch.unsubscribe();
    };
  }

  public format(resourceStringValue: string, macros: { [f: string]: string}):string {
    Object.keys(macros).forEach(macroName => {
      resourceStringValue = resourceStringValue.replace( `{${macroName}}`, macros[macroName]);
    });
    return resourceStringValue;
  }

  public getFromServer(key: string, languageCode: string):Promise<ResourceValue> {
    return new Promise<ResourceValue>((resolve, reject) => {
      this.resourceApiController.resource({languageCode : languageCode, key : key}).subscribe({
        next: resolve,
        error: reject
      });
    });
  }

  private async getValues(keys: string[]) {
    return new Promise<string[]>((resolve, reject) => {
      const values: string[] = [];

      if (!this.Locale) {
        this.getResourceStrings().toPromise().then((locale) => {
          if (locale) {
            this.localeSubject.next(locale);
          }
          this.getValues(keys).then(resolve, reject);
        }, (e) => reject(e));
        return;
      }


      if (this.Locale) {
        keys.forEach(key => {
          key = key.toLowerCase();
          if (this.Locale.translations[key]) {
            values.push(this.Locale.translations[key]);
          } else {
            values.push(`${this.lang}:${key}`);
          }
        });
        resolve(values);
      } else {
        this.log.error("getValues Error - No locale", keys);
        reject("No locale loaded");
      }
    });
  }

  private setLangCookie(code: string): void {
    this.cacheService.writeCookie({
      name: 'mibp_lang',
      value: code,
      sameSite: 'Strict',
      expires: addMonths(new Date(), 3)
    });
  }

  setLang(code:string):void {
    if (this.lang !== code) {
      this.setLangCookie(code);
      this.lang = code;
      this.broadcastService.setLanguage(code);
    }
  }

  getLang():string {

    if (!this.lang) {
      const fromCookie = this.cacheService.getCookie('mibp_lang');
      if (fromCookie) {
        return fromCookie;
      }
      return 'en';
    }

    return this.lang;

  }

  private enrichWithDebugPrefix(details: LocalizationDetails): LocalizationDetails {


    if (this.isDebugEnabled) {
      if (details?.translations) {

        if (this.debugLocalization?.code === details.code && this.debugLocalization.lastModified === details.lastModified ) {
          return this.debugLocalization;
        }

        const newDetails: LocalizationDetails = {
          code: details.code,
          lastModified: details.lastModified,
          translations: {}
        };

        const firstKey = Object.keys(details.translations);
        if (firstKey.length > 0) {
          if (details.translations[firstKey[0]].startsWith(this.debugPrefix)) {
            return details;
          }
        }

        Object.keys(details.translations).forEach(key => {
          newDetails.translations[key] = `${this.debugPrefix}${details.translations[key]}`;
        });

        this.debugLocalization = newDetails;

        return newDetails;
      }
    }

    return details;

  }

  public get AvailableResourceStrings$():Observable<string[]> {
    return this.availableResourceStringSubject.asObservable();
  }

  public getAvailableResourceStrings():string[] {
    return this.availableResourceStringSubject.value;
  }

  public addAvailableResourceString(resourseStrings: string[]):void {
    if (this.tm) {
      clearTimeout(this.tm);
      if (this.availableResourceStringSubject.value != null) {
        this.availableResourceStringSubject.next(null);
      }
    }
    resourseStrings.filter(rs => rs).forEach(rs => {
      if (this.availableResourceStrings.indexOf(rs) < 0) {
        this.availableResourceStrings.push(rs);
      }
    });
    this.tm = setTimeout(() => {
      this.availableResourceStringSubject.next(this.availableResourceStrings);
    }, this.resourceStringUpdateDelay);
  }

  public removeAvailableResourceString(resourseStrings: string[]):void {
    if (this.tm) {
      clearTimeout(this.tm);
      if (this.availableResourceStringSubject.value != null) {
        this.availableResourceStringSubject.next(null);
      }
    }

    resourseStrings.filter(rs => rs).forEach(rs => {
      const stringIndex = this.availableResourceStrings.findIndex(val => val === rs);
      if (stringIndex !== -1) {
        this.availableResourceStrings.splice(stringIndex, 1);
      }
    });

    this.tm = setTimeout(() => {
      this.availableResourceStringSubject.next(this.availableResourceStrings);
    }, this.resourceStringUpdateDelay);
  }

  public removeCurrentLanguageFromCache():void {
    this.removeLanguageFromCache(this.lang);
  }

  public removeLanguageFromCache(lang: string):void {
    this.cacheService.remove(`resourceStrings_${lang}`);
  }

  /**
   * Test if the specified langauge code is enabled and valid
   * @param string Language code
   */
  testLanguageCode(langCode: string): boolean {
    const languages = environment.availableLanguages;
    langCode = this.trimLanguageCode(langCode);
    return languages.filter(val => val.toLocaleLowerCase() === langCode ).length !== 0;
  }

  /**
   * Attempt to get the preferred language from the browser
   */
  getLanguageFromBrowser(): string {

    const fromCookie = this.cacheService.getCookie('mibp_lang');
    if (fromCookie) {
      return fromCookie;
    }

    let langCode: string = navigator.languages && navigator.languages[0] || navigator.language || navigator['userLanguage'];
    if (langCode) {
      langCode = this.trimLanguageCode(langCode);
      if (this.testLanguageCode(langCode)) {
        return langCode;
      }
    }

    return environment.defaultLanguage.toLocaleLowerCase();
  }

  private trimLanguageCode(langCode: string): string {
    if (!langCode) {
      return langCode;
    }
    return langCode.split('-')[0].toLocaleLowerCase();
  }

  public get(key: string, fallbackText?: string):string {
    if (!this.lang) {
      this.lang = this.getLang();
    }
    if (key) {
      key = key.toLowerCase();
    }
    const cached = this.getFromCache(true);
    if (cached && cached.translations[key]) {
      return cached.translations[key];
    } else {
      return fallbackText ? fallbackText : `${this.isDebugEnabled ? this.debugMissingPrefix : ''}${this.getLang()}:${key}`;
    }
  }

  public getResourceString(key: string, lang: string = null): Promise<string> {
    if (lang === null) {
      lang = this.getLang();
    }
    if (key) {
      key = key.toLowerCase();
    }
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    return new Promise((resolve, reject) => {
      const cached = this.getFromCache(true);

      if (cached && cached.translations[key]) {
        return resolve(cached.translations[key]);
      }
      this.log.debug("getResourceString", key);

      this.getResourceStrings().pipe(shareReplay()).subscribe({
        next: data => {
          if (typeof data && data.translations[key]) {
            return resolve(data.translations[key]);
          }
          return resolve(`${this.isDebugEnabled ? this.debugMissingPrefix : ''}${this.lang}:'${key}'`);
      },
      error: e => {
        reject(e);
      }});
    });
  }

  private getFromCache(applyDebugPrefix = false): LocalizationDetails {
    const cached = this.cacheService.get<LocalizationDetails>(`resourceStrings_${this.lang}`);
    if (applyDebugPrefix) {
      const debugVersion = this.enrichWithDebugPrefix(cached);
      if (debugVersion) {
        return debugVersion;
      }
    }
    return cached;
  }

  private addToCache(data: LocalizationDetails) {
    this.clearOldCache(this.lang);
    this.cacheService.add(`resourceStrings_${this.lang}`, data, null, CacheScope.GlobalStorage);
  }

  private broadcastDateStrings(): void {
    this.broadcastService.setDateFormatResourceStrings({
      shortYearPlaceholder: this.get('Global_Placeholder_Short_Year'),
      shortMonthPlaceholder: this.get('Global_Placeholder_Short_Month'),
      shortDatePlaceholder: this.get('Global_Placeholder_Short_Date'),
      timezone: this.get('Global_Timezone')
    });
  }

  loadOnApplicationStart(): Observable<LocalizationDetails> {
    return this.getResourceStrings();
  }

  getResourceStrings(force = false, tryGetUpdatedOnly = false): Observable<LocalizationDetails> {
    const subject = new Subject<LocalizationDetails>();
    if (!this.lang) {
      return of(<LocalizationDetails>{translations: {}, code: null, lastModified: null});
    }

    const cached = this.getFromCache();
    if (cached && !force) {
      const debugVersion = this.enrichWithDebugPrefix(cached);
      cached.get = (key) => {
        return `${key}?`;
      };

      // Start: If cached - check if there are any updates after the last cached date
      if (this.isFirstLoad || tryGetUpdatedOnly) {

        this.refreshResourcesFromApi(new Date(cached.lastModified ?? Date.now())).then(data => {
          if (data.translations !== null) {
            cached.lastModified = data.lastModified;
            const rsKeys = Object.keys(data.translations);
            rsKeys.forEach(key => {
              cached.translations[key] = data.translations[key];
            });


            const debugVersion2 = this.enrichWithDebugPrefix(cached);
            this.resourceStrings.next(debugVersion2 || cached);
            this.broadcastDateStrings();
            this.isLoaded.next(debugVersion2 || cached);
            this.localeSubject.next(debugVersion2 || cached);
            subject.next(debugVersion2 || cached);
            subject.complete();
            this.addToCache(cached);
          } else {
            subject.next(debugVersion || cached);
            subject.complete();
          }
        }, err => {
          this.log.error('Could not load translation texts', err);
          subject.error(err);
        });
        return subject.asObservable();
      }

      // End: If cached - check if there are any updates after the last cached date
      return of(cached);
    } else {
      // Start: Fetch resource strings from backend
      this.refreshResourcesFromApi().then(data => {
        if (data) {
          this.addToCache(data);
          const debugVersion = this.enrichWithDebugPrefix(data);
          this.resourceStrings.next(debugVersion || data);
          this.broadcastDateStrings();
          this.isLoaded.next(debugVersion || data);
          this.localeSubject.next(debugVersion || data);
          subject.next(debugVersion || data);
          subject.complete();

        }
      }, e => {
        this.log.warn('Could not load translation texts', e);
        subject.error(e);
      });
      // End: Fetch resource strings from backend

    }

    return subject.asObservable();
  }

  private refreshResourcesFromApi(ifModifiedAfter?: Date): Promise<LocalizationDetails> {

    return new Promise<LocalizationDetails>((resolve, reject) => {
      this.isFirstLoad = false;
      firstValueFrom(this.resourceApiController.listResource({
        languageCode : this.lang,
        ifModifiedAfter : (!ifModifiedAfter) ? "" : this.formattingService.toServerUTCStringWithMs(ifModifiedAfter),
      })).then(resourceListResult => {
        const data = {
          code: this.lang,
          lastModified: new Date(resourceListResult.lastModified),
          translations: resourceListResult.items
        };
        resolve(data as LocalizationDetails);
      }, err => {
        reject(err);
      });
    });

  }

  /**
   * Returns true if URL contains a language code
   * Returns suggested redirect URL if not
   */
  public verifyLanguageFromUrl(url: string, setAsCurrentLangauge =false): boolean | string {
    // Remove host and query string parts of URL
    let subDirAndPath = url.replace(`https://${window.location.host}`, '').replace(`http://${window.location.host}`, '').split('?')[0];
    const urlParts = subDirAndPath.length > 0 ? subDirAndPath.split('/').filter(s => s) : [];
    const languageFromurl = urlParts.length > 0 ? urlParts[0] : '';

    if (this.testLanguageCode(languageFromurl)) {
      this.setLang(languageFromurl);
    } else {
      this.log.debug(`Test of language failed: '${languageFromurl}' (${url})`);

      const queryString = url.length > 0 && url.split('?').length > 1 ? url.split('?')[1] : '';

      if (subDirAndPath.length > 0) {
        subDirAndPath = `/${subDirAndPath}`;
      }

      const urlWithLanguageCode = `/${this.getLanguageFromBrowser()}${subDirAndPath}`.replace('//', '/').replace(/\/$/, '');
      this.log.debug(`Redirect to ${urlWithLanguageCode}`);

      return queryString == '' ? urlWithLanguageCode : urlWithLanguageCode + '?' + queryString;
    }

    return true;
  }

  public switchLanguage(state:boolean): void {

    this.switchLanguageSubject.next(state);
  }

  async userChangeLanguage(toLanguage: string): Promise<void> {
    return new Promise(resolve => {
      // this.switchLanguage(true);
      const currentUrl = this.router.url;
      const currentLanguage = this.getLang();
      const currentLanguageUrl = "/" + currentLanguage + "/";
      this.setLang(toLanguage);
      let param: string;
      if (currentUrl.startsWith(currentLanguageUrl)) {
        param = currentUrl.slice(currentLanguageUrl.length);
      } else {
        param = "home";
      }
      this.switchLanguage(true);
      const newLang = "/" + toLanguage + "/" + param;
      this.router.navigateByUrl(newLang).finally(()=>{
        this.switchLanguage(false);
        resolve();
      });
    });
  }

}
