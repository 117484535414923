import { id } from 'date-fns/locale';
import { Component, OnDestroy, OnInit} from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { ChangeTrackingApiController } from "root/mibp-openapi-gen/controllers";
import { ChangeTrackingDetailsDto, ChangeTrackingHistoryDto, LoggedChange } from "root/mibp-openapi-gen/models";
import { BroadcastService, FormattingService, LogService, MibpLogger, NavigationService, UrlHelperService } from "root/services";
import { Subscription, firstValueFrom } from "rxjs";

@Component({
  selector: 'mibp-change-tracking-change-details',
  templateUrl: './change-tracking-change-details.component.html',
  styleUrls: ['./change-tracking-change-details.component.scss']
})
export class  MibpChangeTrackingChangeDetailsComponent implements OnInit, OnDestroy {

  changes: LoggedChange[];
  changesDetails: ChangeTrackingDetailsDto;
  log: MibpLogger;
  macros: { [f: string]: string};
  isLoading = true;
  hasError = false;
  breadcrumbsSubscription: Subscription;

  constructor(private changeTrackingApiController: ChangeTrackingApiController,
    private route: ActivatedRoute,
    logger: LogService,
    private urlHelper: UrlHelperService,
    private navigationService: NavigationService,
    private formattingService: FormattingService,
    private broadcastService: BroadcastService){
    this.log = logger.withPrefix('change-tracking-change-details.component');
  }
  ngOnDestroy(): void {
    this.breadcrumbsSubscription?.unsubscribe();
  }
  async ngOnInit() {
    const id = this.route.snapshot.params.id;
    await this.getChanges(id);
    this.breadcrumbsSubscription = this.broadcastService.breadcrumbs.subscribe(()=>{
       this.modifyBreadCrumbs();
    });
  }
  async getChanges(id: number){
    this.isLoading = true;
    try{
      this.changesDetails = await firstValueFrom(this.changeTrackingApiController.getChangeDetails({changeId:id}));
      this.isLoading = false;
    }
    catch(error){
      this.hasError = true;
      this.isLoading = false;
      this.log.error('error fetching change tracking change details',error);
    }
    this.changes = this.changesDetails.changes;
    this.macros = {
      'user' : this.changesDetails.userName,
      'date' : this.formattingService.formatDateTime(this.changesDetails.createdDate)
    };
  }
  modifyBreadCrumbs(){
    const p = this.urlHelper.parseUrl();
    const listPage = p.path.split('/');
    this.navigationService.updateBreadcrumbs(c => {
      c[c.length - 1].text = ` ${this.formattingService.formatDate(this.changesDetails.createdDate) + ' ' + this.changesDetails.userName}`;
      c[c.length - 2].route = listPage.slice(1,listPage.length-1);
      return c;
    });
  }
}

