<div class="doit" [class.doit--collapsed]="!anyVisibleFilters" [class.doit--expanded]="anyVisibleFilters">

  <div class="doit__toolbar">
    <div *ngIf="false" class="doit__button doit__button--disabled">
      <div><span translate="no" class="material-icon">download</span></div>
    </div>
    <div class="doit__search" *ngIf="showSearch && anySearchableColumns" [formGroup]="searchForm">
      <div class="doit__searchinput">
        <input formControlName="query" [attr.placeholder]="searchPlaceholder" type="search" (keydown.enter)="queueSearchUpdate($event)">
      </div>
      <div class="doit__searchbutton">
        <mibp-button [resourceStringKey]="'ResourceString_Search'"  [style]="buttonStyles.Fill" [color]="buttonColors.Blue" (click)="queueSearchUpdate($event)"></mibp-button>
      </div>
    </div>
  </div>

  <div *ngIf="showCustomFilters" class="doit__custom-filter-container">
    <ng-content select="[customFilters]"></ng-content>
  </div>  

  <div class="content" *ngIf="filterList?.length > 0 || checkboxFacetFilter">
    <div class="columns">
      <div *ngIf="checkboxFacetFilter && checkboxFacetFilter.column.type === filterTypes.CheckboxFacet" class="column is-one-fifth">
        <label *ngIf="checkboxFacetFilter && checkboxFacetFilter.column.type !== filterTypes.DateTime && checkboxFacetFilter.column.type !== filterTypes.Boolean" style="display: inline-block;"><mibp-resource-string [key]="checkboxFacetFilter.column.resourceStringKey"></mibp-resource-string></label>
        <span translate="no" class="questionIcon material-icon material-icon--fill" (click)="triggerUserFlow()">help</span>
        <div>
          <mibp-grid-filter-checkbox-facet [filter]="checkboxFacetFilter" [disabled]="disabled" [value]="checkboxFacetFilter.value" (update)="filterUpdated(checkboxFacetFilter, $event)"></mibp-grid-filter-checkbox-facet>
        </div>
      </div>
      <div class="column">
        <div class="columns is-multiline">

          <div *ngFor="let filter of filterList; trackBy: trackByFilter" class="column is-one-third">
            <label *ngIf="filter.column.type !== filterTypes.DateTime && filter.column.type !== filterTypes.Boolean && filter.column.type !== filterTypes.CheckboxFacet"><mibp-resource-string [key]="filter.column.resourceStringKey"></mibp-resource-string></label>

            <div *ngIf="filter.column.type === filterTypes.String">
              <mibp-grid-filter-text [filter]="filter" [value]="filter.value" (update)="filterUpdated(filter, $event)"></mibp-grid-filter-text>
            </div>

            <div *ngIf="filter.column.type === filterTypes.Facet">
              <mibp-grid-filter-facet [filter]="filter" [value]="filter.value" (update)="filterUpdated(filter, $event)"></mibp-grid-filter-facet>
            </div>

            <!-- <div *ngIf="filter.column.type === filterTypes.Boolean">
              Boolean filter is not implemented
            </div>

            <div *ngIf="filter.column.type === filterTypes.DateTime">
              Date filter is not implemented
              <mibp-datepicker></mibp-datepicker>
            </div> -->
          </div>          
        </div>
      </div>
    </div>
  </div>      
</div>
