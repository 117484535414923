<div>
  <div class="heading-margin">
    <mibp-resource-string [key]="'ChangeTrackingDetails_Text'" [macros]="macros"></mibp-resource-string>
    <div *ngIf="changesDetails?.comment !== null" class="change-tracking-comment">
      <label><mibp-resource-string [key]="'Global_Comment'"></mibp-resource-string>:</label>
      {{changesDetails?.comment}}
    </div>
  </div>
  <mibp-page-load-error *ngIf="hasError"></mibp-page-load-error>
  <table *ngIf="!hasError" class="my-table my-table--nowrap">
    <thead>
      <tr>
        <th><mibp-resource-string key="Global_Property"></mibp-resource-string></th>
        <th><mibp-resource-string key="ChangeTracking_BeforeChange"></mibp-resource-string></th>
        <th><mibp-resource-string key="ChangeTracking_AfterChange"></mibp-resource-string></th>
      </tr>
    </thead>
    <tbody *ngIf="changes">
      <mibp-loader *ngIf="isLoading"></mibp-loader>
      <tr *ngFor="let change of changes">
        <td>{{ change.propertyName }}</td>
        <td class="change-value-width">{{ change.beforeValue }}</td>
        <td class="change-value-width">{{ change.afterValue }}</td>
      </tr>
    </tbody>
  </table>
</div>
