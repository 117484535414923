import { LogService } from './../../services/logservice/log.service';
import { ApiService } from 'root/services/mibp-api-services';
import { FrontendContextService, GlobalConfigService, LoaderService } from 'root/services/index';
import { DropdownComponent } from 'root/components/dropdown';
import { Component, OnInit, ElementRef, Input, ViewChild, Output, EventEmitter, OnDestroy } from "@angular/core";
import { UntypedFormGroup, UntypedFormBuilder, Validators } from "@angular/forms";
import { MibpLogger } from '../../services/logservice/index';
import { fromEvent, Subscription } from 'rxjs';
import { skip } from 'rxjs/operators';
import { allPermissionPolicies } from 'root/all-permission-policies';
import { ActAsService } from 'root/services/act-as/act-as.service';
import { MibpSessionService } from 'root/services/mibp-session/mibp-session.service';

@Component({
  selector: 'mibp-act-as',
  templateUrl: './act-as.component.html',
  styleUrls: ['./act-as.component.scss']
})
export class ActAsComponent implements OnInit, OnDestroy {

  @Input() isVisible = false;
  @Input() alwaysVisible = false;
  actAsForm: UntypedFormGroup;
  hideTimer = null;
  isLockedOpen = false;
  isToggleMode = false;
  isMouseOver = false;

  clickOutsideSub: Subscription;
  resizeSub: Subscription;
  log: MibpLogger;
  currentDeliverySequenceSub: Subscription;
  displayButtonsAsBlocks = false;
  protected showActAsAllButton = true;

  @ViewChild("actAsElement", { read: ElementRef }) actAsElement: ElementRef;
  @ViewChild('companyDropdown') companyDropdown: DropdownComponent;
  @Input() isActingAs = false;
  @Input() openerElement: HTMLElement;
  // TODO: Rename to non-native event name
  // eslint-disable-next-line @angular-eslint/no-output-native
  @Output() open = new EventEmitter();

  // TODO: Rename to non-native event name
  // eslint-disable-next-line @angular-eslint/no-output-native
  @Output() close = new EventEmitter();
  @Output() actas = new EventEmitter();
  disableActAsSubscription: Subscription;
  disableActAs = false;

  public toggle(): void {
    if (this.isVisible) {
      this.hide();
    } else {
      this.isToggleMode = true;
      this.show(null);
    }
  }


  private closeOnClickOutside(): void {
    this.clickOutsideSub?.unsubscribe();
    this.clickOutsideSub = fromEvent<MouseEvent>(document, 'click')
      .pipe(skip(1))
      .subscribe(event => {
        // If mouse is not over trigger element (relative to ) or container - then close..
        const clickTarget = event.target as HTMLElement;

        if (!this.actAsElement.nativeElement.contains(clickTarget)) {
          this.hide();
          this.clickOutsideSub?.unsubscribe();
        }
      });
  }

  public show(elementRef: HTMLElement, anchorTo?: 'bottomleft'): void {

    if (!this.frontendContext.testPermission(allPermissionPolicies.canActAs)) {
      return;
    }

    if (this.hideTimer) {
      clearTimeout(this.hideTimer);
    }
    if (this.isVisible) {
      return;
    }
    if (elementRef && this.actAsElement) {
      const rect = elementRef.getBoundingClientRect();

      let leftPosition = (rect.left + rect.width) - 700;
      const topPosition = rect.top - elementRef.offsetTop;
      if (anchorTo === 'bottomleft') {
        leftPosition = elementRef.offsetLeft;
      }

      this.actAsElement.nativeElement.style.left = leftPosition + 'px';
      this.actAsElement.nativeElement.style.top = topPosition + 'px';



    } else {
      this.actAsElement.nativeElement.style.left = 'auto';
    }
    this.isVisible = true;
    this.closeOnClickOutside();
    this.open.emit();
  }

  disableHideOnMouseout(): void {
    this.isLockedOpen = true;
    this.hide(); // To disable timer
  }
  enableHideOnMouseout(): void {
    this.isLockedOpen = false;
    this.hide(1000); // Begin hide
  }

  onMouseEnter(): void {
    this.isMouseOver = true;

    this.show(null);
  }

  onMouseLeave(): void {
    this.isMouseOver = false;
    this.hide(1000);
  }

  public hide(delayBeforeHiding: number = null): void {
    if (this.alwaysVisible) {
      // if (this.isVisible) {
      //   this.close.emit();
      // }
      return;
    }
    if (this.hideTimer) {
      clearTimeout(this.hideTimer);
    }
    if (this.isLockedOpen) {
      return;
    }
    if (delayBeforeHiding !== null) {
      if (!this.isToggleMode) {
        this.hideTimer = setTimeout(() => {
          if (!this.isMouseOver) {
            this.isVisible = false;
            this.close.emit();
          }
        }, delayBeforeHiding);
      }
    } else {
      this.isVisible = false;
      this.isMouseOver = false;
      this.isToggleMode = false;
      this.close.emit();
    }
  }



  constructor(
    private formBuilder: UntypedFormBuilder,
    private sessionService: MibpSessionService,
    private signalrApi: ApiService,
    private loader: LoaderService,
    private globalConfig: GlobalConfigService,
    private frontendContext: FrontendContextService,
    logger: LogService,
    private actAsService: ActAsService) {
    this.log = logger.withPrefix('act-as');
  }

  ngOnInit(): void {
    this.showActAsAllButton = this.globalConfig.enableEquipmentScopingBasedOnCustomers ? false : true;
    this.disableActAsSubscription = this.actAsService.disableActAsSubject.subscribe(disabled => {
      if(disabled){
        this.disableActAs = true;
      }
      else{
        this.disableActAs = false;
      }
    });
    const currentDeliverySequence = this.sessionService.activeDeliverySequence;
    this.displayButtonsAsBlocks = window.innerWidth < 500;
    this.resizeSub = fromEvent<Event>(window, 'resize').subscribe(() => this.displayButtonsAsBlocks = window.innerWidth < 500);

    this.currentDeliverySequenceSub = this.sessionService.activeDeliverySequence$.subscribe(d => {
      if (this.actAsForm) {
        if (d) {
          this.actAsForm.patchValue({
            deliverySequence: d.deliverySequenceId
          });
        }
      }
    });

    // Configure FormGroup
    this.actAsForm = this.formBuilder.group({
      deliverySequence: [
        currentDeliverySequence,
        [Validators.required]
      ]
    });
  }

  ngOnDestroy(): void {
    this.hide();
    this.clickOutsideSub?.unsubscribe();
    this.resizeSub?.unsubscribe();
  }

  async submitForm(action: string): Promise<boolean> {
    if (!this.actAsForm.valid) {
      return false;
    }
    const deliverySequenceId = action === 'actAs' ? this.actAsForm.value.deliverySequence : null;

    this.loader.showFullScreenLoader();
    this.isLockedOpen = false;
    this.actas.emit();
    this.hide();

    try {
      await this.sessionService.actAs(deliverySequenceId);
      if (!deliverySequenceId) {
        this.actAsForm.setValue({
          deliverySequence: null
        });
      }
    } catch (e) {
      this.log.error(`Erroring acting as`, e);
    } finally {
      this.loader.hideFullScreenLoader();
    }
  }

}
