import { ValidatorFn, AbstractControl } from "@angular/forms";


export function MibpPostCodeValidator(countryCode: string): ValidatorFn {

  return (control: AbstractControl): { [key: string]: any } | null => {

     const value = control.value;

    if (!value) {
      return null; // handled by Required validator
    }
    else {
      let hasError = false;
      if (typeof value == 'string') {

        switch (countryCode) {
          case 'SE':
          case 'FI': {
            let regex = /^[0-9]{3}\s?[0-9]{2}$/;
            hasError = !regex.test(value);
            break;
          }
          case 'DE': {
            let regex = /^\d{4}$/;
            hasError = !regex.test(value);
            break;
          }
          case 'NO': {
            let regex = /^\d{4}$/;
            hasError = !regex.test(value);
            break;
          }
          case 'GB': {
            let regex = /[A-Z]{1,2}\d[A-Z]?\s?\d[A-Z]{2}/i;
            hasError = !regex.test(value);
            break;
          }
          case 'IE': {
            let regex = /^([AC-FHKNPRTV-Y]{1}[0-9]{2}|D6W)[ ]?[0-9AC-FHKNPRTV-Y]{4}$/mgi;
            hasError = !regex.test(value);
            break;
          }
        }
      }

      return hasError ? {
        postCodeInvalid: true
      } : null;

    }
  };
}
