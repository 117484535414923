import { Directive, ElementRef, HostListener, Input } from "@angular/core";
import { LogService, MibpLogger } from "root/services";

@Directive({
  selector: 'input[mibpInputDecimalValue]',
})

export class InputDecimalValueDirective {
  // Allow decimal numbers and negative values
  private regex = new RegExp(/^\d*\.?\d{0,3}$/g);
  // Allow key codes for special events. Reflect :
  // Backspace, tab, end, home
  private specialKeys: Array<string> = [
    'Backspace',
    'Tab',
    'End',
    'Home',
    'ArrowLeft',
    'ArrowRight',
    'Del',
    'Delete',
  ];
  
  log: MibpLogger;

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  constructor(private el: ElementRef, logger: LogService) {
    this.log = logger.withPrefix('mibpInputDecimalValue');
  }
  @HostListener('keydown', ['$event'])

  onKeyDown(event: KeyboardEvent) {
    this.log.info(this.el.nativeElement.value);
    // Allow Backspace, tab, end, and home keys
    if (this.specialKeys.indexOf(event.key) !== -1) {
      return;
    }
    const current = this.el.nativeElement;
    const value = current.value;
    //const isDecimal = current.attributes['data-isDecimal']?.value;
    const position = this.el.nativeElement.selectionStart;
    const next: string = [
      value.slice(0, position),
      event.key == 'Decimal' ? '.' : event.key,
      value.slice(position),
    ].join('');
    //if (next && ((isDecimal == 'false' && next.indexOf('.') != -1) || !String(next).match(this.regex))) {
    if (next && !String(next).match(this.regex)) {
      event.preventDefault();
    }
  }
}
