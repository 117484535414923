<mibp-dialog #dialog
  [useSmallHeader]="true"
  (closed)="onClosed()"
  [buttons]="buttons"
  size="contentful"
  subTitleResourceStringKey='ActAs_ChangeAccountInformation'
  (buttonClick)="onDialogButtonClick($event)"
  [speadButtonsEvenly]="true"
  [disableEscButton]="currentOptions?.forcedChangeReason ? 'true' : null"
  [hideCloseButton]="!!currentOptions?.forcedChangeReason"
  [title]="'ActAs_ChangeAccount'">
  <div class="wrapper">

    <div class="container">
      <div class="forced-change" *ngIf="currentOptions?.forcedChangeReason">
        <p>
          <span translate="no" class="material-icon">warning</span>
          <mibp-resource-string key="Login_InactiveAccount_Message"></mibp-resource-string>
        </p>
      </div>
      <form class="searchform" [formGroup]="searchForm"
        [class.searchform--no-dropdowns]="!showCustomerField && !showCompanyField"
        [class.searchform--2-dropdowns]="showCustomerField && showCompanyField"
        [class.searchform--1-dropdown]="(showCustomerField && !showCompanyField) || (!showCustomerField && showCompanyField) ">
        <div class="searchform__query">
          <span class="material-icon" translate="no" >search</span>
          <input type="search" [mibpResourceStringAttributes]="{placeholder: 'ActAs_ChangeAccount_SearchQueryPlaceholder'}" formControlName="query">
        </div>
        <div class="searchform__customer" [style.display]="showCustomerField ? null : 'none'">
          <mibp-dropdown

            #customerDropdown
            placeholder="ActAs_SelectCustomer"
            formControlName="customer"
            [data]="customerListItems$"
            (valueChange)="customerValueChanged()"
            (filter)="filterCustomerListItems($event)"
            [enableClearSingleSelect]="true"></mibp-dropdown>
        </div>
        <div class="searchform__company" [style.display]="showCompanyField ? null : 'none'">
          <mibp-dropdown
          #companyDropdown
          placeholder="ActAs_SelectSalesEntity"
          formControlName="company"
          (valueChange)="companyValueChanged()"
          [items]="visibleCompanyListItems"
          [enableClearSingleSelect]="true"
          [filterDelayMs]="50"></mibp-dropdown>
        </div>
      </form>
      <div class="pagination-info">
        @if (totalItemCount) {
          <mibp-resource-string key="Global_Page_FirstLast_Index" [macros]="{ firstIndex: currentIndex > 0 ? currentIndex + 1 : 1, lastIndex: currentIndex > 0 ? currentIndex + items.length : items.length, totalCount: totalItemCount }"></mibp-resource-string>
        } @else if (totalItemCount === 0) {
          <mibp-resource-string key="Global_NoHits"></mibp-resource-string>          
        }
      </div>

      <div class="items light-scroll">

        <mibp-page-load-error *ngIf="errorLoadingDeliverySequences"></mibp-page-load-error>

        <table *ngIf="!errorLoadingDeliverySequences">
          <thead>
            <tr>
              <th><mibp-resource-string key="Global_DeliverySequence"></mibp-resource-string></th>
              <th><mibp-resource-string key="Global_Customer"></mibp-resource-string>&nbsp;<span>(<mibp-resource-string key="Global_CustomerAccountNumber"></mibp-resource-string>)</span></th>
              <th><mibp-resource-string key="Global_SandvikSalesEntity"></mibp-resource-string></th>
            </tr>
          </thead>
          <tbody *ngIf="isLoadingDeliverySequences">
            <tr>
              <td colspan="5">
                <span class="material-icon material-icon--xxlarge material-icon--spin">progress_activity</span>
              </td>
            </tr>
          </tbody>
          <tbody *ngIf="!isLoadingDeliverySequences">
            <tr class="selected" *ngIf="selectedDeliverySequence && !isSelectedDeliverySequenceInResults">
              <td>
                {{selectedDeliverySequence.deliverySequenceNumber}} - {{selectedDeliverySequence.displayName}}
              </td>
              <td>
                {{selectedDeliverySequence.customerName}} <span class="my-text--gray">({{selectedDeliverySequence.erpCustomerId}})</span>
              </td>
              <td class="sales-entity">
                <ng-template *ngTemplateOutlet="salesEntityTemplate; context: {item: selectedDeliverySequence}"></ng-template>
              </td>
            </tr>
            @for (ds of items; track ds.deliverySequenceId; let isFirst = $first) {
              @if ( selectedDeliverySequence?.id != ds.deliverySequenceId || (isSelectedDeliverySequenceInResults && selectedDeliverySequence?.id == ds.deliverySequenceId)) {
                <tr (click)="select(ds)" [class.selected]="selectedDeliverySequence?.id == ds.deliverySequenceId">
                  <td>
                    {{ds.deliverySequenceNumber}} - {{ds.deliverySequenceDisplayName}}
                  </td>
                  <td>
                    {{ds.customerName}} <span class="my-text--gray">({{ds.erpCustomerId}})</span>
                  </td>
                  <td class="sales-entity">
                    <ng-template *ngTemplateOutlet="salesEntityTemplate; context: {item: ds}"></ng-template>
                  </td>
                </tr>
              }
            }
          </tbody>
        </table>
      </div>

      <div class="pagination-control">
        <mibp-paginator 
          (changePage)="onPageChange($event)" 
          [noBottomMargin]="true"
          [totalItems]="totalItemCount"
          [pageSize]="pageSize"
          [startIndex]="currentIndex">
          </mibp-paginator>
      </div>
   </div>
  </div>
</mibp-dialog>

<ng-template #salesEntityTemplate let-item="item">
  <div>
    <div>
      {{item.companyCode}}  - {{ item.companyName}}
    </div>
    <div>
      <span class="selected-badge"
        [class.selected-badge--inactive]="selectedDeliverySequence?.id == session.current?.activeDeliverySequence?.deliverySequenceId && currentOptions?.forcedChangeReason"
        [style.opacity]="(selectedDeliverySequence?.id == item.deliverySequenceId || selectedDeliverySequence?.deliverySequenceId == item.deliverySequenceId) ? 1 : 0">
          
        @if (selectedDeliverySequence?.id == session.current?.activeDeliverySequence?.deliverySequenceId && currentOptions?.forcedChangeReason) {
          <mibp-resource-string key="Global_Inactive"></mibp-resource-string>
        } @else {
          <mibp-resource-string key="Global_Selected"></mibp-resource-string>
        }
      </span>
    </div>
  </div>
</ng-template>



<mibp-dialog title="ContactUs_Title" fullheight="false" size="narrow"  #contactDialog>
  <mibp-new-contact-us-form *ngIf="showContactUs" [formData]="supportCaseFormData" (successfullySubmitted)="onContactUsSubmitted($event)"></mibp-new-contact-us-form> <!--  (successfullySubmitted)="closeForm($event)" -->
  <mibp-contact-dialog *ngIf="showOldContactUs" [formData]="contactUsFormData" (successfullySubmitted)="onContactUsSubmitted($event)" [showLogoutButton]="true"></mibp-contact-dialog>
</mibp-dialog>