
<mibp-loader *ngIf="isLoadingPage"></mibp-loader>

<mibp-tabs *ngIf="mediaFolderId && justListChildren && !isLoadingPage">
  <mibp-tab [resourceKey]="'PartsManual_Tab_ChaptersPagesTitle'">
    <div class=" my-card">
      <div class="child-list">
        <div *ngFor="let child of childList" class="child" (click)="goToFolder(child)">
          <div *ngIf="child.thumbnailImage && !child.hasPdf" class="thumbnail">
            <img [src]="child.thumbnailImage">
          </div>
          <div *ngIf="child.hasPdf" class="icon">
            <span translate="no" class="material-icon material-icon--xxxlarge">picture_as_pdf</span>
          </div>
          <div *ngIf="!child.hasPdf && !child.thumbnailImage" class="icon">
            <span translate="no" class="material-icon material-icon--xxxlarge">image</span>
          </div>

          {{child.name}}
        </div>
      </div>
    </div>
  </mibp-tab>
  <mibp-tab [resourceKey]="'ElectronicManuals_RelatedMedia'">
    <mibp-electronic-manual-related [mediaId]="mediaId"></mibp-electronic-manual-related>
</mibp-tab>
</mibp-tabs>

<div *ngIf="mediaFolderId && !justListChildren && !isLoadingPage">
  <mibp-tabs class="illustration-tabs">
    <mibp-tab [resourceKey]="'PartsManual_Illustration_Header'">
      <div class="pdf-wrapper" *ngIf="pdfUrl">
        <h3 class="my-header">{{ pdfName }}</h3>
        <mibp-pdf-viewer [url]="pdfUrl" [filename]="pdfName" [fileSize]="pdfSize" class="pdf-viewer"></mibp-pdf-viewer>
      </div>
      <div [class.verticalview]="useViewModeVertical">
        <div class="my-card mb-5" *ngIf="illustrations && illustrations.length > 0">
          <mibp-parts-manual-illustration [illustrations]="illustrations" [image]="selectedImage"
            [imageIsMissing]="imageIsMissing">
            <button (click)="toggleViewMode()" *ngIf="!windowIsMediumOrSmaller"
            class="my-button my-button--secondary my-button--fill">{{toggleViewModeButtonText}}</button>
          </mibp-parts-manual-illustration>
        </div>
        <div class="my-card" *ngIf="isLoadingParts || parts?.length > 0">
          <h3 class="my-header"><mibp-resource-string key="PartsManual_BillOfMaterial_Header"></mibp-resource-string></h3>

          <mibp-loader *ngIf="isLoadingParts"></mibp-loader>
          <mibp-horizontal-scroll-container>
          <form [formGroup]="quantityForm" class="quantity-form">
            <table class="my-table my-table--compact my-table--hover" *ngIf="!isLoadingParts">
              <thead>
                <tr>
                  <th>#</th>
                  <th>
                    <mibp-resource-string key="Global_ProductNumber"></mibp-resource-string>
                  </th>
                  <th>
                    <mibp-resource-string key="Global_Name"></mibp-resource-string>
                  </th>
                  <th>
                    <mibp-resource-string key="Global_RecommendedQuantity"></mibp-resource-string>
                  </th>
                  <th *ngIf="canAddToCart"></th>
                </tr>
              </thead>
              <tbody>
                <tr
                  *ngFor="let part of parts; let i = index"
                  [id]="'node_' + part.item"
                  [class.is-loading]="part.addingToCart"
                  [class.isSelected]="part.item === selectedNode"
                  [class.isSearchMatch]="isFolderInSearchResult && isMatchWithSearchQuery(part)"
                  (click)="highlightHotpoint(part.item)"
                >
                  <td class="flex-center-gap">
                    {{ part.item }}
                    <span
                      translate="no"
                      class="material-icon pointer-cursor"
                      *ngIf="part.isLinked && enablePartsManualIllustrationHotpointLinks"
                      target="_blank"
                      (click)="redirectToHotpoint(part.item)"
                    >
                      attachment
                    </span>
                  </td>
          
                  <td *ngIf="part.existsInMySandvik">
                    <a href="javascript:void(0)" (click)="navigateToProductDetailsPage(part.partNumber,$event)">
                      {{ part.partNumber }}
                    </a>
                  </td>
                  <td *ngIf="!part.existsInMySandvik">{{ part.partNumber }}</td>
                  <td class="name-description-cell">
                    {{ part.name }}&nbsp;{{ part.description }}
                  </td>
                  <td class="flex-center-gap">
                    <mibp-numeric-input
                      [formControlName]="'quantity_' + i"
                      (click)="stopEventPropagation($event)"
                      (inputChange)="updateQuantity(i, $event)"
                      (inputBlur)="onQuantityBlur(i)"
                    ></mibp-numeric-input>
                    <mibp-validation-text
                      [formGroup]="quantityForm"
                      [alwaysVisible]="true"
                      [controlName]="'quantity_' + i"
                    ></mibp-validation-text>
                    <span class="my-sandvik-uom" *ngIf="part.mySandvikUnitOfMeasure">
                      <mibp-resource-string
                        [key]="'Global_UoM_' + part.mySandvikUnitOfMeasure"
                        [defaultValue]="part.mySandvikUnitOfMeasure"
                      ></mibp-resource-string>
                    </span>
                    <span *ngIf="!part.mySandvikUnitOfMeasure">
                      {{ part.unitOfMeasure }}
                    </span>
                  </td>
                  <td *ngIf="canAddToCart">
                    <button
                      type="button"
                      *ngIf="part.existsInMySandvik"
                      [disabled]="part.addingToCart || quantityForm.get('quantity_' + i)?.invalid"
                      (click)="addToCart(i)"
                      class="my-button my-button--small"
                    >
                      <mibp-resource-string key="Carts_Active_AddToCartButton"></mibp-resource-string>
                    </button>
                    <a
                      href="javascript:void(0)"
                      *ngIf="!part.existsInMySandvik"
                      class="contact-us"
                      (click)="openProductEnquiryDialog(part.partNumber, part.quantity || 1)"
                    >
                      <mibp-resource-string key="Global_ContactUs"></mibp-resource-string>
                    </a>
                  </td>
                </tr>
              </tbody>
            </table>
          </form>          
          </mibp-horizontal-scroll-container>
        </div>
      </div>

    </mibp-tab>
    <mibp-tab [resourceKey]="'PartsManual_WhereUsed_Header'" [text]="whereUsedCount">
      <div class="my-card mb-5" *ngIf="mediaFolderId">
        <mibp-parts-manual-whereused [folderId]="mediaFolderId"
          (whereUsedSelected)="whereUsedSelected()"></mibp-parts-manual-whereused>
      </div>
    </mibp-tab>
    <mibp-tab [resourceKey]="'ElectronicManuals_RelatedMedia'">
        <mibp-electronic-manual-related [mediaId]="mediaId"></mibp-electronic-manual-related>
    </mibp-tab>
  </mibp-tabs>

</div>
