<div class="my-filter">

  <div class="my-filter__toggle media@s-and-smaller" (click)="collapsed = !collapsed"
    [attr.aria-expanded]="collapsed ? 'true' : 'false'">
    <mibp-resource-string key="Filter_RefineYourSearch"></mibp-resource-string>
  </div>

  <div class="my-filter__filters" [@collapse]="collapsed">
    <div class="my-filterwrapper">
      <div class="my-filterwrapper__ngcontent" #ref><ng-content></ng-content></div>
      <div class="my-filterwrapper__before" #refBefore><ng-content select="[before-filters]"></ng-content></div>

      <ng-container *ngFor="let filter of filters">
        <div class="my-filter__filter" [attr.data-filter-name]="filter.name"
        [attr.data-filter-type]="filter.type" *ngIf="!filter.hidden">

        <div class="filter_content">


          <!-- DATE -->
          <ng-template [ngIf]="filter.type === 'date'">

            <label>
              <mibp-resource-string [key]="filter.resourceStringKey"></mibp-resource-string> ({{dateFormatPlaceholder}})
            </label>
            <div>
              <mibp-datepicker [displayBlock]="true" [date]="filter.value" (change)="onDateChanged(filter, $event)">
              </mibp-datepicker>
            </div>

          </ng-template>

          <!-- TEXT -->
          <ng-template [ngIf]="filter.type === 'text'">

            <label>
              <mibp-resource-string [key]="filter.resourceStringKey"></mibp-resource-string>
            </label>
            <div>
              <input type="search" [attr.value]="filter.value" (keydown)="onTextFilterKeyDown(filter, $event)"
                     (keyup)="onTextFilterKeyUp(filter, $event)">
            </div>

          </ng-template>


          <!-- DROPDOWN -->
          <ng-template [ngIf]="filter.type === 'dropdown' &&  (showEmptyDropdowns || (!showEmptyDropdowns && filter.items?.length > 0))">

            <label>
              <mibp-resource-string [key]="filter.resourceStringKey"></mibp-resource-string>
            </label>
            <div>
              <mibp-dropdown [selectedOption]="filter.initialSelectedValues" [items]="filter.items" [newMultiSelectDesign]="true" [multiselect]="true" [multiselectApply]="true" (valueChange)="onDropdownChange(filter, $event)"></mibp-dropdown>
            </div>

          </ng-template>

        </div>
      </div>
      </ng-container>
      
      <div class="my-filterwrapper__after" #refAfter><ng-content select="[after-filters]"></ng-content></div>

  </div>

  </div>

</div>
