import { ButtonColors } from '../button/button.enum';
import { Component, ViewChild, Input, forwardRef, EventEmitter, Output } from '@angular/core';
import { DialogButton } from '../dialog/dialog.types';
import { DropdownArgs, DropdownData, DropdownInput, DropdownComponent } from '../dropdown';
import { Guid } from 'guid-typescript';
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';
import { firstValueFrom, Observable } from 'rxjs';
import { SafeHtml } from '@angular/platform-browser';
import { ScopeInformation, ResponsibilityPickerService, ExtendedDropdownInput } from 'root/services/responsibility-picker/responsibility-picker.service';
import { SimpleScope, ResponsibilityPickerScope, ExtendedResolvedScope } from './responsibility-picker.types';
import { DialogComponent } from '../dialog/dialog.component';
import { MibpLogger, LogService, CanDeactivateService, DialogService } from 'root/services';
import { ResolvedScope, ResolvedScopeSearchRequest, PagedSearchOptions } from 'root/mibp-openapi-gen/models';
import { AccessGroupsApiController } from 'root/mibp-openapi-gen/controllers';
import { MibpSessionService } from 'root/services/mibp-session/mibp-session.service';
import { ApiErrorHandlerService } from 'root/services/api-error-handler/api-error-handler';
import { NoticeType } from '../noticebar/noticebar.enum';
import { KeyValue } from '@angular/common';

export interface ExtendedDropdownData extends DropdownData {
  extendedItems?: ExtendedDropdownInput;
}

@Component({
  selector: 'mibp-responsibility-picker',
  templateUrl: './responsibility-picker.component.html',
  styleUrls: ['./responsibility-picker.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => ResponsibilityPickerComponent),
    }
  ]
})
export class ResponsibilityPickerComponent implements ControlValueAccessor {

  log: MibpLogger;
  isLoading = true;
  userScope: ScopeInformation;
  isFormValid = true;

  customerError: 'userscope' | '' = '';
  deliverySequenceError: 'userscope' | '' = '';
  productModelErrors: 'invalid-items' | '' = '';
  productAreaErrors: 'invalid-items' | '' = '';
  productGroupErrors: 'invalid-items' | '' = '';
  productSubgroupErrors: 'invalid-items' | '' = '';
  serialErrors: 'invalid-items' | '' = '';
  showFullScreenLoader = false;
  request: PagedSearchOptions;
  dialogButtons: DialogButton[] = [
    { resourceKey: 'Global_Dialog_Close', id: 'close', color: ButtonColors.Secondary },
    { resourceKey: 'Global_Save', id: 'save', disabled: true }
  ];
  fileImportValidationDialogButtons: DialogButton[] = [
    { resourceKey: 'Global_Dialog_Close', id: 'close', color: ButtonColors.Secondary }
  ];
  selectionIsTargetingAll = false;
  @Input() hideDeliverySequence = false;
  @Input() hideBusinessRelation = false;
  @Input() hideProductColumn = false;
  @Input() hideTargetedUsers = false;
  @Input() hideModelColumn = false;
  @Input() hideEquipmentColumn = false;
  @Input() hideOperationSite = true;
  @Input() allowTargetingAll = true;
  @Input() useAllText = false;
  @Input() anyResourceStringKey = 'Component_ResponsibilityPicker_Any';
  @Input() allowPromotionFileImport = false; //Should get a general name when support for all fields is implemented
  @Input() useScrolledList = false;

  @Output() notifyScopes: EventEmitter<string> = new EventEmitter<string>();
  @Output() targetedUsersLoading: EventEmitter<boolean> = new EventEmitter<boolean>();
  // We should always validate the selected scopes.
  // We'll make it an option as we evaluate the validation
  @Input() validateScopes = false;

  @ViewChild('mainDialog') dialog: DialogComponent;
  @ViewChild('helpDialog') helpDialog: DialogComponent;
  @ViewChild('fileImportValidationDialog') fileImportValidationDialog: DialogComponent;

  @ViewChild('companyDropdown') companyDropdown: DropdownComponent;
  @ViewChild('customerDropdown') customerDropdown: DropdownComponent;
  @ViewChild('deliverysequenceDropdown') deliverysequenceDropdown: DropdownComponent;
  @ViewChild('modelDropdown') modelDropdown: DropdownComponent;
  @ViewChild('productgroupDropdown') productgroupDropdown: DropdownComponent;
  @ViewChild('productsubgroupDropdown') productsubgroupDropdown: DropdownComponent;
  @ViewChild('serialDropdown') serialDropdown: DropdownComponent;
  @ViewChild('productAreaDropdown') productAreaDropdown: DropdownComponent;
  @ViewChild('operationSiteDropdown') operationSiteDropdown: DropdownComponent;

  targetedUserMacros = {
    count: 0
  };
  isTargetedUsersDisabled = true;
  isTargetedUsersLoading = false;
  scopes: ExtendedResolvedScope[] = [];

  companies$: Observable<DropdownData>;
  customers$: Observable<DropdownData>;
  deliverySequences$: Observable<DropdownData>;
  productAreas$: Observable<DropdownData>;
  productGroups$: Observable<DropdownData>;
  productSubGroups$: Observable<DropdownData>;
  productModels$: Observable<DropdownData>;
  serials$: Observable<DropdownData>;
  operationSites$: Observable<DropdownData>;

  selection: ResponsibilityPickerScope = {};
  scopeDescription: SafeHtml;
  isLoadingScopes = false;
  targetedUsersOpen = false;
  scopePreview: SimpleScope[];
  scopePreviewKeys: string[];
  scopeString: SafeHtml;
  dialogScopeList: ResolvedScope[];
  dropdownPageSize = 10;
  fileImportValidationErrors: [];

  constructor(
    private accessGroupApi: AccessGroupsApiController,
    private dialogService: DialogService,
    private sessionService: MibpSessionService,
    private rspSvc: ResponsibilityPickerService,
    private apiErrorHandlerService: ApiErrorHandlerService,
    logger: LogService,
    private canDeactivateService: CanDeactivateService) {
    this.log = logger.withPrefix('responsibility-picker');
    this.dropdownPageSize = rspSvc.pageSize;
  }

  // ControlValueAccessor

  writeValue(obj: any): void {
    this.scopes = null;
    this.triggerChange();

    if (obj instanceof Guid) {

      this.isLoadingScopes = true;
      this.rspSvc.getAccessGroupScopes(obj).subscribe(theGroups => {
        const scopes = theGroups as ExtendedResolvedScope[];
        this.rspSvc.validateScope(scopes).forEach(err => {
          scopes[err.itemIndex].invalid = this.validateScopes;
        });
        this.scopes = scopes;
        this.isLoadingScopes = false;
        this.triggerChange();
      });

    } else if (Array.isArray(obj)) {

      const scopes = obj as ExtendedResolvedScope[];
      scopes.forEach(s => s.invalid = false);
      this.rspSvc.validateScope(obj).forEach(err => scopes[err.itemIndex].invalid = this.validateScopes);
      this.scopes = scopes;
      this.triggerChange();
    }
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  triggerChange() {
    if (!this.scopes || this.scopes.length === 0) {
      this.onChange(null);
    } else {
      this.onChange(this.scopes);
      this.emitNotifyScopesEvent();
      this.getTargetedUsers(this.scopes);
    }
  }

  onChange = (newValue: ResolvedScope[]) => {
    // this.pendingChanges.push(rating);
  };

  // Function to call when the input is touched (when value is changed).
  onTouched = () => { };

  setDisabledState?(isDisabled: boolean): void {
  }

  // End ControlValueAccessor

  validationChanged(e: { isValid: boolean }) {

  }

  emitNotifyScopesEvent() {
    if (this.scopes) {
      this.notifyScopes.emit(JSON.stringify(this.scopes));
    }
  }

  dialogClick(btn: DialogButton) {
    if (btn.id === 'save') {
      if (!this.scopes) {
        this.scopes = [];
      }
      const scopes = this.scopes.concat(this.dialogScopeList);
      this.rspSvc.validateScope(scopes).forEach(err => scopes[err.itemIndex].invalid = this.validateScopes);
      this.scopes = scopes;

      this.dialogScopeList = [];
      this.dialog.close();
      this.onChange(this.scopes);
      this.getTargetedUsers(this.scopes);
      this.emitNotifyScopesEvent();
    } else if (btn.id === 'close') {
      this.dialog.close();
    }
  }

  getTargetedUsers(scopes: any[]) {
    if (this.hideTargetedUsers) {
      return;
    }

    this.request = {
      query: null,
      skip: 0,
      take: 0
    };
    if (this.scopes.length > 0) {
      this.targetedUsersLoading.emit(true);
      this.isTargetedUsersLoading = true;
      const request: ResolvedScopeSearchRequest = {
        pagedSearchOptions: this.request,
        scopes: this.scopes
      };

      firstValueFrom(this.accessGroupApi.searchTargetedUsers({ body: request })).then(targetedUsers => {
        this.isTargetedUsersLoading = false;
        this.targetedUsersLoading.emit(false);
        this.targetedUserMacros = { count: targetedUsers.count };
        if (targetedUsers.count > 0) {
          this.isTargetedUsersDisabled = false;
        }
      }).catch(error => {
        this.log.error("error getting targeted users", error);
      });
    } else {
      this.targetedUserMacros = { count: 0 };
      this.isTargetedUsersDisabled = true;
    }
  }

  toggleTargetedUsers(event: any) {
    this.targetedUsersOpen = event;
  }

  doit() {
    this.showFullScreenLoader = true;
    this.rspSvc.selectionToScopes(this.selection).subscribe(scopes => {

      if (!this.dialogScopeList) {
        this.dialogScopeList = [];
      }
      scopes.forEach((rs) => { rs.isActive = true; });

      this.dialogScopeList = this.dialogScopeList.slice(0).concat(scopes);
      this.dialogButtons.find(btn => btn.id === 'save').disabled = false;
      this.resetDropdowns();
      this.showFullScreenLoader = false;
    },
      err => {
        this.showFullScreenLoader = false;
        // Catch the specific ERRBSCO001 to let the user know that a BusinessRelation was missing
        if (err?.stack?.errors?.find(e => e.code === 'ERRBSCO001')) {
          const deepestError = err?.stack?.errors[err?.stack?.errors.length - 1].description;
          this.dialogService.prompt(`text://Could not add Responsibility - ${deepestError}`, 'Global_Dialog_Close');
        } else {
          this.dialogService.prompt('Global_Error_OperationCouldNotBeCompleted', 'Global_Dialog_Close');
        }
        this.log.error("Error converting to ResolvedScopes", err);
      });
  }


  private updateDropdown(dropdown: DropdownComponent, data: { value?: DropdownInput[], disabled?: boolean, loading?: boolean }) {
    if (!dropdown) {
      return;
    }
    if (data.value !== undefined) {
      dropdown.writeValue(data.value);
    }

    if (data.disabled !== undefined) {
      if (data.disabled === true) {
        dropdown.disable();
      } else {
        dropdown.enable();
      }
    }

    if (data.loading !== undefined) {
      if (data.loading === true) {
        dropdown.loading = true;
        dropdown.enable();
      } else {
        dropdown.loading = false;
      }
    }
  }


  filterCompanies(a: DropdownArgs): void {
    this.companies$ = this.rspSvc.getCompanies(a.query, a.index, a.take);
    this.log.debug(`Get all companies`);
  }

  filterCustomers(a: DropdownArgs): void {
    if (this.selection.companies.length === 1) {
      const companyId = this.firstSelectedCompanyCode;
      this.log.debug(`Get customers for company ${companyId} ('${a.query}')`);
      this.customers$ = this.rspSvc.getCustomers(companyId, a.query, a.index, a.take);
    }
  }

  filterDeliverySequences(a: DropdownArgs): void {
    const companyId = this.firstSelectedCompanyCode;
    const customerNumber = this.firstSelectedCustomerNumber;
    this.log.debug(`Get delivery sequences for company ${companyId}, customer ${customerNumber} ('${a.query}')`);
    this.deliverySequences$ = this.rspSvc.getDeliverySequences(companyId, customerNumber, a.query, a.index, a.take);
  }

  filterOperationSites(a: DropdownArgs): void {
    if (this.selection.customers.length === 1) {
      const businessRelationId = (<ExtendedDropdownInput>this.selection.customers[0]).altValue;
      this.operationSites$ = this.rspSvc.getOperationSites(null, businessRelationId, a.query, a.index, a.take);
      this.log.debug(`Get operation site for Business Id ${businessRelationId}`);
    } else if (this.selection.companies.length === 1) {      
      const companyId = (<ExtendedDropdownInput>this.selection.companies[0]).altValue;
      this.operationSites$ = this.rspSvc.getOperationSites(companyId, null, a.query, a.index, a.take);
      this.log.debug(`Get operation site for Company Id ${companyId}`);
    } else {
      this.operationSites$ = this.rspSvc.getOperationSites(null, null, a.query, a.index, a.take);
    }
  }

  filterProductAreas(args: DropdownArgs) {
    const companyCodes = this.selection.companies ? this.selection.companies.map(c => c.value) : null;
    const customerNumber = this.firstSelectedCustomerNumber;
    const deliverySequenceNumber = this.firstSelectedDeliverySequenceNumber;
    this.log.debug(`Get product areas for Company#${companyCodes.join(',')}, Customer#${customerNumber}, DeliverySequence#${deliverySequenceNumber} ('${args.query}')`);

    this.productAreas$ = this.rspSvc.getProductAreas({
      companyCodes: companyCodes,
      customerNumbers: customerNumber ? [customerNumber] : null,
      deliverySequenceNumbers: deliverySequenceNumber ? [deliverySequenceNumber] : null,
      query: args.query,
      skip: args.index,
      take: args.take
    });
  }

  filterProductGroups(args: DropdownArgs) {
    const companyCodes = this.selection.companies ? this.selection.companies.map(c => c.value) : null;
    const customerNumber = this.firstSelectedCustomerNumber;
    const deliverySequenceNumber = this.firstSelectedDeliverySequenceNumber;
    const productArea = this.firstSelectedProductAreaCode;
    this.log.debug(`Get product groups for Company#${companyCodes.join(',')}, Customer#${customerNumber}, DeliverySequence#${deliverySequenceNumber}, productArea#${productArea} ('${args.query}')`);

    this.productGroups$ = this.rspSvc.getProductGroups({
      companyCodes: companyCodes,
      deliverySequenceNumbers: deliverySequenceNumber ? [deliverySequenceNumber] : null,
      productAreaCodes: productArea ? [productArea] : null,
      customerNumbers: customerNumber ? [customerNumber] : null,
      query: args.query,
      skip: args.index,
      take: args.take
    });

  }

  filterProductSubGroups(args: DropdownArgs) {
    const companyCodes = this.selection.companies ? this.selection.companies.map(c => c.value) : null;
    const customerNumber = this.firstSelectedCustomerNumber;
    const deliverySequenceNumber = this.firstSelectedDeliverySequenceNumber;
    const productArea = this.firstSelectedProductAreaCode;
    const productGroup = this.firstSelectedProductGroupCode;
    this.log.debug(`Get product subgroups for Company#${companyCodes.join(',')}, Customer#${customerNumber}, DeliverySequence#${deliverySequenceNumber}, productArea#${productArea}, productGroup#${productGroup} ('${args.query}')`);

    this.productSubGroups$ = this.rspSvc.getProductSubGroups({
      companyCodes: companyCodes,
      deliverySequenceNumbers: deliverySequenceNumber ? [deliverySequenceNumber] : null,
      productAreaCodes: productArea ? [productArea] : null,
      productGroupCodes: productGroup ? [productGroup] : null,
      customerNumbers: customerNumber ? [customerNumber] : null,
      query: args.query,
      skip: args.index,
      take: args.take
    });

  }

  filterProductModels(args: DropdownArgs) {
    const companyCodes = this.selection.companies ? this.selection.companies.map(c => c.value) : null;
    const customerNumber = this.firstSelectedCustomerNumber;
    const deliverySequenceNumber = this.firstSelectedDeliverySequenceNumber;
    const productAreas = this.selection.productAreas ? this.selection.productAreas.map(c => c.value) : null;
    const productGroups = this.selection.productGroups ? this.selection.productGroups.map(c => c.value) : null;
    const productSubGroups = this.selection.productSubgroups ? this.selection.productSubgroups.map(c => c.value) : null;
    this.log.debug(`Get product models for Company#${companyCodes.join(',')}, Customer#${customerNumber}, DeliverySequence#${deliverySequenceNumber}, productArea#${productAreas || ''}, productGroup#${productGroups}, productSubgroup#${productSubGroups} ('${args.query}')`);

    this.productModels$ = this.rspSvc.getProductModels({
      companyCodes: companyCodes,
      deliverySequenceNumbers: deliverySequenceNumber ? [deliverySequenceNumber] : null,
      productAreaCodes: productAreas,
      productGroupCodes: productGroups,
      productSubGroupCodes: productSubGroups,
      customerNumbers: customerNumber ? [customerNumber] : null,
      query: args.query,
      skip: args.index,
      take: args.take
    });
  }

  filterSerialNumbers(args: DropdownArgs) {
    const companyCodes = this.selection.companies ? this.selection.companies.map(c => c.value) : null;
    const customerNumber = this.firstSelectedCustomerNumber;
    const deliverySequenceNumber = this.firstSelectedDeliverySequenceNumber;
    const productAreas = this.selection.productAreas && this.selection.productAreas.length > 0 ? this.selection.productAreas.map(c => c.value) : null;
    const productGroups = this.selection.productGroups && this.selection.productGroups.length > 0 ? this.selection.productGroups.map(c => c.value) : null;
    const productSubGroups = this.selection.productSubgroups && this.selection.productSubgroups.length > 0 ? this.selection.productSubgroups.map(c => c.value) : null;
    const models = this.selection.models && this.selection.models.length > 0 ? this.selection.models.map(c => c.value) : null;
    this.log.debug(`Get product models for Company#${companyCodes.join(',')}, Customer#${customerNumber}, DeliverySequence#${deliverySequenceNumber}, productArea#${productAreas || ''}, productGroup#${productGroups}, productSubgroup#${productSubGroups} ('${args.query}')`);

    this.serials$ = this.rspSvc.getSerialNumbers({
      companyCodes: companyCodes,
      deliverySequenceNumbers: deliverySequenceNumber ? [deliverySequenceNumber] : null,
      productAreaCodes: productAreas,
      productGroupCodes: productGroups,
      productSubGroupCodes: productSubGroups,
      productModelNames: models,
      customerNumbers: customerNumber ? [customerNumber] : null,
      query: args.query,
      skip: args.index,
      take: args.take
    });
  }

  get firstSelectedCompanyCode(): string {
    if (!this.selection.companies || this.selection.companies.length === 0) {
      return null;
    }
    return this.selection.companies[0].value;
  }

  get firstSelectedCustomerNumber(): string {
    if (!this.selection.customers || this.selection.customers.length === 0) {
      return null;
    }
    return this.selection.customers[0].value;
  }

  get firstSelectedDeliverySequenceNumber(): string {
    if (!this.selection.deliverySequences || this.selection.deliverySequences.length === 0) {
      return null;
    }
    return this.selection.deliverySequences[0].value;
  }

  get firstSelectedProductAreaCode(): string {
    if (!this.selection.productAreas || this.selection.productAreas.length === 0) {
      return null;
    }
    return this.selection.productAreas[0].value;
  }

  get firstSelectedProductGroupCode(): string {
    if (!this.selection.productGroups || this.selection.productGroups.length === 0) {
      return null;
    }
    return this.selection.productGroups[0].value;
  }

  get firstSelectedProductSubGroupCode(): string {
    if (!this.selection.productSubgroups || this.selection.productSubgroups.length === 0) {
      return null;
    }
    return this.selection.productGroups[0].value;
  }

  onDropdownValueChanged(type:
    'company' | 'customer' | 'deliverysequence' | 'operationsite' |
    'model' | 'productgroup' | 'productsubgroup' | 'serial' | 'productarea', e: DropdownInput[]) {

    e = e || [];

    if (type === 'company') {
      this.selection.companies = e;
      this.onCompanyChanged();
    } else if (type === 'customer') {
      this.selection.customers = e;
      this.onCustomerChanged();
    } else if (type === 'deliverysequence') {
      this.selection.deliverySequences = e;
      this.onDeliverySequenceChanged();
    } else if (type === 'operationsite') {
      this.selection.operationSites = e;
      this.onOperationSiteChanged();
    }else if (type === 'productarea') {
      this.selection.productAreas = e;
      this.onProductAreaChanged();
    } else if (type === 'productgroup') {
      this.selection.productGroups = e;
      this.onProductGroupChanged();
    } else if (type === 'productsubgroup') {
      this.selection.productSubgroups = e;
      this.onProductSubGroupChanged();
    } else if (type === 'model') {
      this.selection.models = e;
      this.onProductModelChanged();
    } else if (type === 'serial') {
      this.selection.serials = e;
    }

    this.updateSimpleScope();
  }

  private updateSimpleScope() {
    this.scopePreview = this.rspSvc.convertToScopes(this.selection);
    if (this.scopePreview.length > 0) {
      this.scopePreviewKeys = Object.keys(this.scopePreview[0]);
    }
    this.scopeString = this.rspSvc.scopeToString(this.selection);

    const isTargetingAll = !this.selection?.companies?.length &&
      !this.selection?.customers?.length &&
      !this.selection?.deliverySequences?.length &&
      !this.selection?.operationSites?.length &&
      !this.selection?.models?.length &&
      !this.selection?.productAreas?.length &&
      !this.selection?.productGroups?.length &&
      !this.selection?.productSubgroups?.length &&
      !this.selection?.serials?.length;

    if (this.selectionIsTargetingAll !== isTargetingAll) {
      this.selectionIsTargetingAll = isTargetingAll;
    }

  }

  /**
   * Returns true if the user selection is "Any" for all fields
   */
  private isTargetingAll(selection: ResponsibilityPickerScope): boolean {

    return false;
    // return !selection.companies.length &&
    //         !selection.customers.length &&
    //         !selection.deliverySequences.length &&
    //         !selection.models.length &&
    //         !selection.productAreas.length &&
    //         !selection.productGroups.length &&
    //         !selection.productSubgroups.length &&
    //         !selection.serials.length;
  }



  onProductModelChanged() {
    this.serials$ = null;
    this.validateSelectedSerialNumbers();
  }

  onProductGroupChanged() {
    this.productSubGroups$ = null;
    this.productModels$ = null;
    this.serials$ = null;

    if (this.selection.productGroups.length === 0 || this.selection.productGroups.length > 1) {
      this.updateDropdown(this.productsubgroupDropdown, { value: [], disabled: true });
    } else {
      this.updateDropdown(this.productsubgroupDropdown, { value: [], disabled: false });
    }

    this.validateSelectedProductModels();
    this.validateSelectedProductSubGroups();
    this.validateSelectedSerialNumbers();

  }

  onProductSubGroupChanged() {
    this.productModels$ = null;
    this.serials$ = null;
    this.validateSelectedProductModels();
    this.validateSelectedSerialNumbers();
  }

  validateSelectedProductGroups(): Promise<void> {

    if (this.hideProductColumn) {
      return Promise.resolve();
    }

    return new Promise(resolve => {
      if (this.selection.productGroups && this.selection.productGroups.length > 0 && !this.productgroupDropdown.loading) {
        this.updateDropdown(this.productgroupDropdown, { loading: true });

        const companyCodes = this.selection.companies && this.selection.companies.length > 0 ? this.selection.companies.map(c => c.value) : null;
        const customerNumber = this.firstSelectedCustomerNumber;
        const deliverySequenceNumber = this.firstSelectedDeliverySequenceNumber;
        const productAreas = this.selection.productAreas && this.selection.productAreas.length > 0 ? this.selection.productAreas.map(pa => pa.value) : null;

        this.rspSvc.getProductGroups({
          companyCodes: companyCodes,
          customerNumbers: customerNumber ? [customerNumber] : null,
          deliverySequenceNumbers: deliverySequenceNumber ? [deliverySequenceNumber] : null,
          productAreaCodes: productAreas,
          productGroupCodes: this.selection.productGroups.map(pa => pa.value),
          query: null,
          skip: 0,
          take: this.selection.productGroups.length
        }).subscribe(searchResponse => {

          // If not in the search result, then mark those items as invalid
          const invalidValues = this.selection.productGroups.filter(selectedOption => {
            return searchResponse.items.findIndex(searchRow => searchRow.value === selectedOption.value) === -1;
          }).map(selectedItem => selectedItem.value);

          this.productgroupDropdown.setInvalidValues(invalidValues);
          this.updateDropdown(this.productgroupDropdown, { loading: false });
          resolve();
        });

      } else {
        this.productgroupDropdown.setInvalidValues(null);
        resolve();
      }
    });
  }

  validateSelectedProductSubGroups(): Promise<void> {

    if (this.hideProductColumn) {
      return Promise.resolve();
    }

    return new Promise(resolve => {
      if (this.selection.productSubgroups && this.selection.productSubgroups.length > 0 && !this.productsubgroupDropdown.loading) {
        this.updateDropdown(this.productsubgroupDropdown, { loading: true });

        const companyCodes = this.selection.companies && this.selection.companies.length > 0 ? this.selection.companies.map(c => c.value) : null;
        const customerNumber = this.firstSelectedCustomerNumber;
        const deliverySequenceNumber = this.firstSelectedDeliverySequenceNumber;
        const productAreas = this.selection.productAreas && this.selection.productAreas.length > 0 ? this.selection.productAreas.map(pa => pa.value) : null;

        this.rspSvc.getProductSubGroups({
          companyCodes: companyCodes,
          customerNumbers: customerNumber ? [customerNumber] : null,
          deliverySequenceNumbers: deliverySequenceNumber ? [deliverySequenceNumber] : null,
          productAreaCodes: productAreas,
          productGroupCodes: this.selection.productGroups.map(pa => pa.value),
          productSubGroupCodes: this.selection.productSubgroups.map(pa => pa.value),
          query: null,
          skip: 0,
          take: this.selection.productGroups.length
        }).subscribe(searchResponse => {

          // If not in the search result, then mark those items as invalid
          const invalidValues = this.selection.productSubgroups.filter(selectedOption => {
            return searchResponse.items.findIndex(searchRow => searchRow.value === selectedOption.value) === -1;
          }).map(selectedItem => selectedItem.value);

          this.productsubgroupDropdown.setInvalidValues(invalidValues);
          this.updateDropdown(this.productsubgroupDropdown, { loading: false });
          resolve();
        });

      } else {
        this.productsubgroupDropdown.setInvalidValues(null);
        resolve();
      }
    });
  }

  validateSelectedProductAreas(): Promise<void> {

    if (this.hideProductColumn) {
      return Promise.resolve();
    }

    return new Promise((resolve, reject) => {
      if (this.selection.productAreas && this.selection.productAreas.length > 0 && !this.productAreaDropdown.loading) {
        this.updateDropdown(this.productAreaDropdown, { loading: true });

        const companyCodes = this.selection.companies && this.selection.companies.length > 0 ? this.selection.companies.map(c => c.value) : null;
        const customerNumber = this.firstSelectedCustomerNumber;
        const deliverySequenceNumber = this.firstSelectedDeliverySequenceNumber;

        this.rspSvc.getProductAreas({
          companyCodes: companyCodes,
          customerNumbers: customerNumber ? [customerNumber] : null,
          deliverySequenceNumbers: deliverySequenceNumber ? [deliverySequenceNumber] : null,
          productAreaCodes: this.selection.productAreas.map(pa => pa.value),
          query: null,
          skip: 0,
          take: this.selection.productAreas.length
        }).subscribe(searchResponse => {

          // If not in the search result, then mark those items as invalid
          const invalidValues = this.selection.productAreas.filter(selectedOption => {
            return searchResponse.items.findIndex(searchRow => searchRow.value === selectedOption.value) === -1;
          }).map(selectedItem => selectedItem.value);

          this.productAreaDropdown.setInvalidValues(invalidValues);
          this.updateDropdown(this.productAreaDropdown, { loading: false });
          resolve();
        });

      } else {
        this.productAreaDropdown.setInvalidValues(null);
        resolve();
      }
    });
  }

  validateSelectedProductModels(): Promise<void> {

    if (this.hideModelColumn) {
      return Promise.resolve();
    }

    return new Promise(resolve => {

      if (this.selection.models && this.selection.models.length > 0 && !this.modelDropdown.loading) {
        this.updateDropdown(this.modelDropdown, { loading: true });

        const companyCodes = this.selection.companies ? this.selection.companies.map(c => c.value) : null;
        const customerNumber = this.firstSelectedCustomerNumber;
        const deliverySequenceNumber = this.firstSelectedDeliverySequenceNumber;
        const productAreas = this.selection.productAreas ? this.selection.productAreas.map(c => c.value) : null;
        const productGroups = this.selection.productGroups ? this.selection.productGroups.map(c => c.value) : null;
        const productSubGroups = this.selection.productSubgroups ? this.selection.productSubgroups.map(c => c.value) : null;
        const productModels = this.selection.models.map(c => c.value);
        this.log.debug(`Validate product models Company#${companyCodes.join(',')}, Customer#${customerNumber}, DeliverySequence#${deliverySequenceNumber}, productArea#${productAreas || ''}, productGroup#${productGroups}, productSubgroup#${productSubGroups}, models#${productModels.join(', ')})`);

        this.rspSvc.getProductModels({
          companyCodes: companyCodes,
          deliverySequenceNumbers: deliverySequenceNumber ? [deliverySequenceNumber] : null,
          productAreaCodes: productAreas,
          productGroupCodes: productGroups,
          productSubGroupCodes: productSubGroups,
          productModelNames: productModels,
          customerNumbers: customerNumber ? [customerNumber] : null,
          query: null,
          skip: 0,
          take: productModels.length
        }).subscribe(searchResponse => {

          // If not in the search result, then mark those items as invalid
          const invalidValues = this.selection.models.filter(selectedOption => {
            return searchResponse.items.findIndex(searchRow => searchRow.value === selectedOption.value) === -1;
          }).map(selectedItem => selectedItem.value);

          this.modelDropdown.setInvalidValues(invalidValues);
          this.updateDropdown(this.modelDropdown, { loading: false });
          resolve();
        });

      } else {
        this.modelDropdown.setInvalidValues(null);
        resolve();
      }
    });
  }

  validateSelectedSerialNumbers(): Promise<void> {

    if (this.hideEquipmentColumn) {
      return Promise.resolve();
    }

    return new Promise(resolve => {

      if (this.selection.serials && this.selection.serials.length > 0 && !this.serialDropdown.loading) {
        this.updateDropdown(this.serialDropdown, { loading: true });

        const companyCodes = this.selection.companies && this.selection.companies.length > 0 ? this.selection.companies.map(c => c.value) : null;
        const customerNumber = this.firstSelectedCustomerNumber;
        const deliverySequenceNumber = this.firstSelectedDeliverySequenceNumber;
        const productAreas = this.selection.productAreas && this.selection.productAreas.length > 0 ? this.selection.productAreas.map(c => c.value) : null;
        const productGroups = this.selection.productGroups && this.selection.productGroups.length > 0 ? this.selection.productGroups.map(c => c.value) : null;
        const productSubGroups = this.selection.productSubgroups && this.selection.productSubgroups.length > 0 ? this.selection.productSubgroups.map(c => c.value) : null;
        const productModels = this.selection.models && this.selection.models.length > 0 ? this.selection.models.map(c => c.value) : null;
        const serialNumbers = this.selection.serials.map(c => c.value);

        this.rspSvc.getSerialNumbers({
          companyCodes: companyCodes,
          deliverySequenceNumbers: deliverySequenceNumber ? [deliverySequenceNumber] : null,
          productAreaCodes: productAreas,
          productGroupCodes: productGroups,
          productSubGroupCodes: productSubGroups,
          productModelNames: productModels,
          equipmentNames: serialNumbers,
          customerNumbers: customerNumber ? [customerNumber] : null,
          query: null,
          skip: 0,
          take: serialNumbers.length
        }).subscribe(searchResponse => {

          // If not in the search result, then mark those items as invalid
          const invalidValues = this.selection.serials.filter(selectedOption => {
            return searchResponse.items.findIndex(searchRow => searchRow.value === selectedOption.value) === -1;
          }).map(selectedItem => selectedItem.value);

          this.serialDropdown.setInvalidValues(invalidValues);
          this.updateDropdown(this.serialDropdown, { loading: false });
          resolve();
        });

      } else {
        this.serialDropdown.setInvalidValues(null);
        resolve();
      }
    });
  }

  invalidValuesChanged(dropdown: 'productarea' | 'productgroup' | 'productsubgroup' | 'model' | 'serial', values: string[]) {

    let anyInvalid = false;

    if (dropdown === 'productarea') {
      if (values && values.length > 0) {
        this.productAreaErrors = 'invalid-items';
        anyInvalid = true;
      } else {
        this.productAreaErrors = '';
      }
    }

    if (dropdown === 'productgroup') {
      if (values && values.length > 0) {
        this.productGroupErrors = 'invalid-items';
        anyInvalid = true;
      } else {
        this.productGroupErrors = '';
      }
    }

    if (dropdown === 'productsubgroup') {
      if (values && values.length > 0) {
        this.productSubgroupErrors = 'invalid-items';
        anyInvalid = true;
      } else {
        this.productSubgroupErrors = '';
      }
    }

    if (dropdown === 'model') {
      if (values && values.length > 0) {
        this.productModelErrors = 'invalid-items';
        anyInvalid = true;
      } else {
        this.productModelErrors = '';
      }
    }

    if (dropdown === 'serial') {
      if (values && values.length > 0) {
        this.serialErrors = 'invalid-items';
        anyInvalid = true;
      } else {
        this.serialErrors = '';
      }
    }

    if (anyInvalid && this.isFormValid) {
      this.isFormValid = false;
    } else if (!anyInvalid && !this.isFormValid) {
      this.isFormValid = true;
    }

  }

  onProductAreaChanged() {
    if (this.hideProductColumn) {
      return;
    }

    this.productGroups$ = null;
    this.productModels$ = null;
    this.serials$ = null;

    if (this.selection.productAreas.length === 0 || this.selection.productAreas.length > 1) {
      this.updateDropdown(this.productgroupDropdown, { value: [], disabled: true });
    } else {
      this.updateDropdown(this.productgroupDropdown, { value: [], disabled: false });
    }

    this.validateSelectedProductModels();
    this.validateSelectedSerialNumbers();
  }

  onCustomerChanged() {
    this.deliverySequenceError = '';
    this.deliverySequences$ = null;
    this.operationSites$ = null;
    this.productAreas$ = null;
    this.productGroups$ = null;
    this.productSubGroups$ = null;
    this.productModels$ = null;
    this.serials$ = null;

    if (this.selection.customers.length === 0) {
      this.updateDropdown(this.deliverysequenceDropdown, { value: [], disabled: true });
    } else if (this.selection.customers.length > 1) {
      this.updateDropdown(this.deliverysequenceDropdown, { value: [], disabled: true });
      this.updateDropdown(this.operationSiteDropdown, { value: [], disabled: true });
    }
    else {
      if (!this.hideDeliverySequence) {
        if (this.userScope.hasAnyDeliverysequence(this.firstSelectedCompanyCode, this.firstSelectedCustomerNumber)) {
          this.log.debug(`Customer Changed - hasAnyDeliverySequence is true. Any is alright`);
          this.deliverysequenceDropdown.cannotRemoveLastItem = false;
          this.updateDropdown(this.deliverysequenceDropdown, { value: [], disabled: false });
        } else {
          const first = this.userScope.getFirstDeliverySequenceFromScope(this.firstSelectedCompanyCode, this.firstSelectedCustomerNumber);
          if (first) {
            this.deliverysequenceDropdown.cannotRemoveLastItem = true;
            this.updateDropdown(this.deliverysequenceDropdown, {
              value: [
                { text: first.number, value: first.number }
              ], disabled: false, loading: false
            });
          } else {
            this.deliverySequenceError = 'userscope';
          }
        }
      }

      if (!this.hideOperationSite && this.selection.customers.length === 1) {
        this.updateDropdown(this.operationSiteDropdown, { value: [], disabled: false });
      }
    }

    this.validateSelectedProductAreas();
    this.validateSelectedProductGroups();
    this.validateSelectedProductSubGroups();
    this.validateSelectedProductModels();
    this.validateSelectedSerialNumbers();
  }

  private runValidation(fns: Promise<void>[]) {
    return new Promise((resolve, reject) => {
      Promise.all(fns).then(resolve, reject);
    });
  }

  onCompanyChanged() {
    this.updateDropdown(this.deliverysequenceDropdown, { value: [], disabled: true });
    this.deliverySequences$ = null;
    this.customers$ = null;
    this.productAreas$ = null;
    this.productGroups$ = null;
    this.productSubGroups$ = null;
    this.productModels$ = null;
    this.serials$ = null;
    this.customerError = '';

    if (this.selection.companies.length > 1) {
      this.updateDropdown(this.operationSiteDropdown, { value: [], disabled: true });
      this.operationSites$ = null;
    }

    if (this.selection.companies.length > 1 || this.selection.companies.length === 0) {
      // 0 or > 1 selected? Then we can't choose customers. "Any" only.
      this.updateDropdown(this.customerDropdown, { value: [], disabled: true });
      this.customers$ = null;           
    } else if(!this.hideBusinessRelation) {
      if (this.userScope.hasAnyCustomer(this.firstSelectedCompanyCode)) {
        this.log.debug(`Company Changed - hasAnyCustomer is true. Any is alright`);
        // The selected companies has "ANY" in scope for its customers
        // Let user choose freely from available customers
        this.customerDropdown.cannotRemoveLastItem = false;
        this.updateDropdown(this.customerDropdown, {
          value: [],
          disabled: false,
          loading: false
        });
        this.updateDropdown(this.operationSiteDropdown, { value: [], disabled: false });
      } else {
        this.log.debug(`Company Changed - hasAnyCustomer is false. Find first customer in scope`);
        // Customer scope for this company is not ANY.
        // Find the first customer for this company via db scopes and populate customer dropdown
        const first = this.userScope.getFirstCompanyCustomerFromScope(this.firstSelectedCompanyCode);
        if (first) {
          this.customerDropdown.cannotRemoveLastItem = true;

          this.updateDropdown(this.customerDropdown, {
            value: [{ text: first.erpCustomerID, value: first.erpCustomerID }],
            disabled: false,
            loading: false
          });
        } else {
          this.customerError = 'userscope';
        }
      }
    }

    this.validateSelectedProductAreas();
    this.validateSelectedProductGroups();
    this.validateSelectedProductSubGroups();
    this.validateSelectedProductModels();
    this.validateSelectedSerialNumbers();
  }

  onDeliverySequenceChanged() {
    this.productAreas$ = null;
    this.productModels$ = null;
    this.productGroups$ = null;
    this.productSubGroups$ = null;
    this.serials$ = null;

    this.validateSelectedProductAreas();
    this.validateSelectedProductGroups();
    this.validateSelectedProductSubGroups();
    this.validateSelectedProductModels();
    this.validateSelectedSerialNumbers();
  }

  onOperationSiteChanged() {
    this.productAreas$ = null;
    this.productModels$ = null;
    this.productGroups$ = null;
    this.productSubGroups$ = null;
    this.serials$ = null;

    this.validateSelectedProductAreas();
    this.validateSelectedProductGroups();
    this.validateSelectedProductSubGroups();
    this.validateSelectedProductModels();
    this.validateSelectedSerialNumbers();
  }

  checkIfTargetingAll(): void {

  }


  addResponsibility() {

  }

  clearSelection() {
    this.dialogOpen();
  }

  resetDropdowns() {
    this.companies$ = null;
    this.companies$ = null;
    this.deliverySequences$ = null;
    this.operationSites$ = null;
    this.productAreas$ = null;
    this.productGroups$ = null;
    this.productSubGroups$ = null;

    this.rspSvc.getUserScopes().subscribe(scopes => {
      this.log.warn("SCOPES", scopes);
      this.userScope = scopes;
      const companyValue: DropdownInput[] = [];

      //If current user acting as a customer, sep pre selected Company
      if (this.sessionService.current.activeDeliverySequence) {
        companyValue.push(<ExtendedDropdownInput>
          {
            text: this.sessionService.current.activeDeliverySequence.companyCode,
            value: this.sessionService.current.activeDeliverySequence.companyCode,
            altValue: this.sessionService.current.activeDeliverySequence.companyId?.toString()
          });
      }

      if (!scopes.hasAnyCompany) {
        this.log.debug('User does not have *any* company in scopes. Selecting first company, disable any.');
        this.companyDropdown.cannotRemoveLastItem = true;

        const firstCompanyInScope = scopes.scopes.find(scope => !!scope.company);
        if (companyValue.length < 1 && firstCompanyInScope) {
          this.log.debug(`Selecting first company in scope: ${firstCompanyInScope.company.code}`);
          companyValue.push({ text: firstCompanyInScope.company.code, value: firstCompanyInScope.company.code });
        }

      } else {
        this.log.debug('Found user scope with *any* company');
        this.companyDropdown.cannotRemoveLastItem = false;
      }

      this.updateDropdown(this.companyDropdown, { disabled: false, value: companyValue, loading: false });
      this.onCompanyChanged();
      this.onCustomerChanged();
      
      this.updateDropdown(this.operationSiteDropdown, { disabled: false, value: [], loading: false });
      this.updateDropdown(this.productAreaDropdown, { disabled: false, value: [], loading: false });
      this.updateDropdown(this.modelDropdown, { disabled: false, value: [], loading: false });
      this.updateDropdown(this.productgroupDropdown, { disabled: true, value: [], loading: false });
      this.updateDropdown(this.productsubgroupDropdown, { disabled: true, value: [], loading: false });
      this.updateDropdown(this.serialDropdown, { disabled: false, value: [], loading: false });

      this.onProductAreaChanged();
      this.onProductGroupChanged();
      this.onProductSubGroupChanged();

      this.isLoading = false;
      this.updateSimpleScope();
    });
  }

  dialogOpen() {
    this.canDeactivateService.block();
    this.dialogButtons.find(btn => btn.id === 'save').disabled = true;
    this.dialogScopeList = [];
    this.resetDropdowns();
  }

  dialogClosed() {
    this.canDeactivateService.unblock();
  }

  helpDialogOpen() {
    this.canDeactivateService.block();
  }


  removeDialogScope(index: number) {
    const newList = this.dialogScopeList.slice(0);
    newList.splice(index, 1);
    this.dialogScopeList = newList;
  }

  removeScope(index: number) {
    const newList = this.scopes.slice(0);
    newList.splice(index, 1);
    newList.forEach(s => s.invalid = false);
    this.rspSvc.validateScope(newList).forEach(err => newList[err.itemIndex].invalid = this.validateScopes);
    this.scopes = newList;

    this.onChange(this.scopes);
    this.getTargetedUsers(this.scopes);
    this.emitNotifyScopesEvent();
  }

  // Keep dictionary as it is from backend. Default will try and sort on key which will be interpreted as string
  originalOrder = (a: KeyValue<number,string>, b: KeyValue<number,string>): number => {
    return 0;
  };

  importFile(): void {
    let input = document.createElement('input');
    input.setAttribute('type', 'file');
    //TODO: Read accepted types form config
    input.setAttribute('accept', 'application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,text/csv');
    input.click();

    input.onchange = async () => {
      const file = input.files[0];
      this.showFullScreenLoader = true;
      await this.rspSvc.importFile(file).then(scopes => {
        if (!this.dialogScopeList) {
          this.dialogScopeList = [];
        }
        scopes.forEach((rs) => { rs.isActive = true; });

        this.dialogScopeList = this.dialogScopeList.slice(0).concat(scopes);

        if (this.dialogScopeList.length > 0) {
          this.dialogButtons.find(btn => btn.id === 'save').disabled = false;
        }

        this.showFullScreenLoader = false;
      }).catch(error => {
        this.apiErrorHandlerService.parseError(error)
          .catchNamedException('FileHeadersValidationException', e  => {
            this.dialogService.prompt('Component_ResponsibilityPicker_InvalidFileHeaderError', 'Global_Dialog_Close');
            this.showFullScreenLoader = false;
          })
          .catchNamedException('RowsValidationException', e => {
            this.fileImportValidationErrors = e.data?.ValidationErrors;
            this.fileImportValidationDialog.open();
            this.showFullScreenLoader = false;
          })
          .catchNamedException('GetResolvedScopesFromFileQueryException', e => {
            this.dialogService.prompt('Component_ResponsibilityPicker_UnexpectedError', 'Global_Dialog_Close');
            this.showFullScreenLoader = false;
          })
          .else(() => {
            this.dialogService.prompt('Component_ResponsibilityPicker_UnexpectedError', 'Global_Dialog_Close');
            this.showFullScreenLoader = false;
          });
      });
    };
  }
}
