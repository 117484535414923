import { Component, Input, OnInit} from "@angular/core";
import { MibpSubnavItem } from "./subnav.component.types";
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'mibp-subnav',
  templateUrl: './subnav.component.html',
  styleUrls: ['./subnav.component.scss']
})
export class MibpSubnavComponent implements OnInit {

  @Input() items?: MibpSubnavItem[];

  relativeTo?: ActivatedRoute;

  constructor(private activatedRoute: ActivatedRoute) {

  }
  ngOnInit(): void {
    this.relativeTo = this.activatedRoute;
  }

}
