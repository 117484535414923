<div #chart [showElementLoader]="loading" class="chart" [style.width]="width" [style.height]="height">

  <div *ngIf="(!chartData || chartData?.length === 0) && (!chartDataSets || chartDataSets.length === 0)" class="no-data">
    <div><mibp-resource-string key="Global_NoDataAvailable"></mibp-resource-string></div>
  </div>

  <canvas baseChart [height]="height" [width]="width"
    *ngIf="!loading && isReady && ( (chartData && chartData?.length > 0) || chartDataSets && chartDataSets?.length > 0 )"
    [data]="!chartDataSets ? chartData : undefined"
    [datasets]="chartDataSets"
    [labels]="chartLabels"
    [type]="'line'"
    [options]="chartOptions"
    [plugins]="chartPlugins"
    [colors]="chartColors">
  </canvas>
</div>
