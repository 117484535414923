@if(canDownloadManuals){

<ng-container
  *ngIf="!fileSize && userDownloadedManualId && (downloadStatus == downloadedManualStatus.InProgress || downloadStatus == downloadedManualStatus.Queued); then inProgressQueuedButtonTemplate; else downloadButtonTemplate">
</ng-container>


<ng-container *ngIf="!fileSize" >


  <button *ngIf="(downloadStatus == null || downloadStatus == downloadedManualStatus.Unknown ||  downloadStatus == downloadedManualStatus.Error || downloadStatus == downloadedManualStatus.Expired) || (downloadStatus == downloadedManualStatus.Queued && !userDownloadedManualId) || (downloadStatus == downloadedManualStatus.InProgress && !userDownloadedManualId)"
    class="my-button my-button--secondary"
    [class.my-button--block]="displayBlock"
    (click)="onDownloadManualClick(manual)">
      <span translate="no" class="material-icon">play_circle</span>
      <mibp-resource-string key="DownloadedManual_RequestDownloadButton"></mibp-resource-string>
</button>
</ng-container>


<ng-template #downloadButtonTemplate >
  <button *ngIf="fileSize"
    (click)="onDownloadManualClick(manual)"
    class="my-button" [class.my-button--block]="displayBlock" [class.my-button--secondary]="!userDownloadedManualId">
      <span translate="no" class="material-icon" [class.material-icon--white]="userDownloadedManualId">download</span>

      @if (fileType == downloadFileTypes.Pdf) {
        PDF
      } @else if (fileType == downloadFileTypes.MicrosoftExcel || fileType == downloadFileTypes.MicrosoftExcelOpenXml) {
        Excel
      } @else if (fileType == downloadFileTypes.PlainText) {
        Text
      } @else if (fileType == downloadFileTypes.Zip) {
        Zip
      }

  ({{fileSize | formatBytes}})
</button>

<mibp-inline-help
*ngIf="(fileSize && (downloadStatus == downloadedManualStatus.InProgress || downloadStatus == downloadedManualStatus.Queued))"
iconName="sync"
iconSize="large"
[applyContentStyles]="true" >
  <mibp-resource-string isHtml="true" key="DownloadedManuals_ManualUpdateInProgress"></mibp-resource-string>
</mibp-inline-help>
</ng-template>


<ng-template #inProgressQueuedButtonTemplate>
  <!-- In progress / Queued -->
  <button
  disabled
  class="my-button loading"
  [class.my-button--block]="displayBlock">

  <span translate="no" class="material-icon"
    [class.material-icon--spin]="downloadStatus == downloadedManualStatus.InProgress">
    {{ downloadStatus == downloadedManualStatus.InProgress ? 'progress_activity'  : 'hourglass' }}</span>
  <mibp-resource-string [key]="'DownloadedManualStatusCode_' + downloadStatus"></mibp-resource-string>
</button>

</ng-template>

}



<div *ngIf="isSupportTeamMember" style="position: relative; display: none;">
  <ul style="position: absolute; top: -400px">
    <li><span  (click)="scenario1_1()">scenario1.1</span></li>
    <li><span  (click)="scenario1_2()">scenario1.2</span></li>
    <li><span  (click)="scenario3()">scenario3</span></li>
    <li><span  (click)="scenario4_1()">scenario4.1</span></li>
    <li><span  (click)="scenario4_2()">scenario4.2</span></li>
    <li><span  (click)="scenario5_1()">scenario5.1</span></li>
    <li><span  (click)="scenario5_2()">scenario5.2</span></li>
    <li><span  (click)="scenario6_1()">scenario6.1</span></li>
    <li><span  (click)="scenario6_2()">scenario6.2</span></li>
  </ul>
</div>




