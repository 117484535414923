<div *ngIf="newFileList.length === 0" class="no-files">
  <p><mibp-resource-string key="UserFile_NoFiles"></mibp-resource-string></p>
</div>

<div class="file-upload">
  <div *ngFor="let file of newFileList" [class.removed]="file.removed" [class.image-preview]="isImage(file) && previewImages">
    <div class="single-file">
      <div *ngIf="previewImages && isImage(file)" class="preview">
        <img src="{{file.previewUrl}}" *ngIf="file.previewUrl" width="180" alt="Preview">
        <div class="preview-placeholder"*ngIf="!file.previewUrl">
          <mibp-resource-string key="Global_WaitMessage"></mibp-resource-string>
        </div>
      </div>
      <div class="displayName">
        <span *ngIf="!editable">{{ file.displayName }} </span>
        <input type="text" *ngIf="editable" [attr.placeholder]="file.displayName ? file.displayName : file.fileName" [disabled]="file.removed" [(ngModel)]="file.displayName" (ngModelChange)="updateDisplayName(file)" maxlength="100" />
      </div>
      <div class="information">
        <div class="file-information" *ngIf="file.status !== 'error' && !file.removed">
          {{ file.size | formatBytes }}
          <span translate="no" class="material-icon material-icon--green" *ngIf="file.status === 'idle' && !file.removed">check</span>
        </div>
        <div class="file-information" *ngIf="file.removed">
          <strong><mibp-resource-string *ngIf="file.removed" key="UserFile_FileWasRemoved"></mibp-resource-string></strong>
        </div>
      </div>
      <div class="break"></div>
      <div *ngIf="!hideAddFileBUtton" class="action">
        <mibp-button [icon]="btn.Enum.Icons.Trash" *ngIf="!file.removed" [disabled]="file.status === 'deleting' || disableDelete" #btn (click)="removeFile(file)"></mibp-button>
        <mibp-button [icon]="btn.Enum.Icons.Undo" [mibpResourceString.title]="'UserFile_UndoRemove'" *ngIf="file.removed" #btn (click)="undoRemove(file)"></mibp-button>
      </div>
    </div>
    <div class="error-loader">
      <div class="status-text" [class.error]="file.status === 'error'">
        <span translate="no" class="material-icon material-icon--red" *ngIf="file.status === 'error'">error</span>
        <mibp-resource-string *ngIf="file.error === 'filetoobig'" key="UserFile_FileExceedsAllowedSizeError" [macros]="fileSizeExceededMacros"></mibp-resource-string>
        <mibp-resource-string *ngIf="file.error === 'badfiletype'" key="UserFile_FileFormatNotAllowed" [macros]="badFileTypeMacros"></mibp-resource-string>
        <mibp-resource-string *ngIf="file.error && file.error !== 'filetoobig' && file.error !== 'badfiletype'" key="UserFile_UploadError"></mibp-resource-string>
      </div>
      <div class="loader">
        <mibp-loader type='indeterminate-progressbar' *ngIf="isLoading(file)"></mibp-loader>
      </div>
    </div>
  </div>
</div>

<div class="forms__row buttons">
  <mibp-button *ngIf="!(hideAddFileBUtton || (currentNumberOfFiles == maxNrOfFiles))" class="button-select-file" [resourceStringKey]="buttonText" (click)="addFile()" [color]="btn.Enum.Colors.Primary" [disabled]="uploadsInProgress > 0 || currentNumberOfFiles == maxNrOfFiles" #btn></mibp-button>
  <input type="file" #fileInput id="fileInput" multiple="true" [accept]="fileTypes" style="display:none;" class="inputfile inputfile-6" (change)="fileChangeEvent($event)">
  <span class="loading">
    <mibp-loader type="spinner" *ngIf="uploadsInProgress > 0"></mibp-loader>
    <mibp-resource-string key="Global_WaitMessage" *ngIf="uploadsInProgress > 0"></mibp-resource-string>
    <mibp-resource-string *ngIf="!hideAddFileBUtton && currentNumberOfFiles == maxNrOfFiles && uploadsInProgress === 0" key="UserFile_MaxNumberOfFilesReached" [macros]="{ maxCount: maxNrOfFiles }"></mibp-resource-string>
  </span>
</div>

