import { Component, Input, ViewEncapsulation } from "@angular/core";

@Component({
  selector: 'mibp-interim-deliverysequence-message',
  styleUrls: ['./interim-deliverysequence-message.component.scss'],
  templateUrl: './interim-deliverysequence-message.component.html',
  encapsulation: ViewEncapsulation.None
})
export class MibpInterimDeliverySequenceComponent {

  
}
