/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { OperationSiteIndexSearchResponse } from '../../models/operation-site-index-search-response';

export interface SearchOperationSitesForPicker$Plain$Params {
  companyId?: number;
  businessRelationId?: number;
  query?: string;
  skip?: number;
  take?: number;
}

export function searchOperationSitesForPicker$Plain(http: HttpClient, rootUrl: string, params?: SearchOperationSitesForPicker$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<OperationSiteIndexSearchResponse>> {
  const rb = new RequestBuilder(rootUrl, searchOperationSitesForPicker$Plain.PATH, 'post');
  if (params) {
    rb.query('companyId', params.companyId, {});
    rb.query('businessRelationId', params.businessRelationId, {});
    rb.query('query', params.query, {});
    rb.query('skip', params.skip, {});
    rb.query('take', params.take, {});
  }

  return http.request(
    rb.build({ responseType: 'text', accept: 'text/plain', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<OperationSiteIndexSearchResponse>;
    })
  );
}

searchOperationSitesForPicker$Plain.PATH = '/operationsites/sitesforpicker';
