<mibp-loader *ngIf="isLoadingScopes"></mibp-loader>
<div class="my-responsibilitypicker" *ngIf="!isLoadingScopes">

  <mibp-resolved-scopes-list (validation)="validationChanged($event)" [enableDependencyValidation]="validateScopes"
    [resolvedScopes]="scopes" (delete)="removeScope($event)" [hideModelColumn]="hideModelColumn" [hideOperationSite]="hideOperationSite" 
    [hideEquipmentColumn]="hideEquipmentColumn" [hideDeliverySequence]="hideDeliverySequence" [hideBusinessRelation]="hideBusinessRelation"
    [anyResourceStringKey]="anyResourceStringKey" [hideProductColumn]="hideProductColumn"
    [useScrolledList]="useScrolledList"></mibp-resolved-scopes-list>

  <nav class="responsibility-picker level">
    <div class="level-left">
      <div class="level-item">
        <mibp-button (click)="mainDialog.open()" [type]="'button'" [color]="btn.Enum.Colors.Primary"
          resourceStringKey="Component_ResponsibilityPicker_AddResponsibilityButton"></mibp-button>
      </div>
    </div>
    <div class="level-right" [ngClass]="{'hidden': hideTargetedUsers}">
      <div class="level-item">
        <mibp-button resourceStringKey="Component_ResponsibilityPicker_TargetedUsersButton"
          [resourceStringMacros]="targetedUserMacros" [disabled]="isTargetedUsersDisabled"
          [showElementLoader]="isTargetedUsersLoading" (click)="toggleTargetedUsers($event)"></mibp-button>
      </div>
      <ng-content select=".content-right"></ng-content>
    </div>
  </nav>

  <mibp-targeted-users [isOpen]="targetedUsersOpen" [scopes]="scopes"
    (closed)="toggleTargetedUsers($event)"></mibp-targeted-users>

  <mibp-dialog #mainDialog titleResourceStringKey="Component_AddResponsibility_Title" (opened)="dialogOpen()"
    (closed)="dialogClosed()" [buttons]="dialogButtons" (buttonClick)="dialogClick($event)" fullheight="true">
    <!-- <span><strong>Current selection:</strong> <span [innerHtml]="scopeDescription"></span></p> -->
    <div style="min-height: 250px">
      <mibp-loader *ngIf="isLoading"></mibp-loader>
      <div class="columns" [style.display]="isLoading ? 'none' : null">
        <div class="column column-arrow column-arrow-1">

          <div class="field">
            <label><mibp-resource-string key="Global_Company"></mibp-resource-string></label>
            <mibp-dropdown #companyDropdown (filter)="filterCompanies($event)" [data]="companies$"
              [placeholder]="anyResourceStringKey" [multiselect.tagPlaceholder]="true" [multiselect.hideOnClick]="true"
              [multiselect]="true" [take]="dropdownPageSize"
              (valueChange)="onDropdownValueChanged('company', $event)"></mibp-dropdown>
          </div>

          <div class="field" *ngIf="!hideBusinessRelation">
            <label><mibp-resource-string key="Global_Customer"></mibp-resource-string></label>
            <mibp-dropdown #customerDropdown (filter)="filterCustomers($event)" [data]="customers$"
              [multiselect.hideOnClick]="true" [multiselect.tagPlaceholder]="true" [disabled]="true"
              [placeholder]="anyResourceStringKey" [multiselect]="true" [take]="dropdownPageSize"
              (valueChange)="onDropdownValueChanged('customer', $event)"></mibp-dropdown>
            <p *ngIf="customerError == 'userscope'" class="inline-error-text"><mibp-resource-string
                key="Component_ResponsibilityPicker_UserScopeLoadError"></mibp-resource-string></p>
          </div>

          <div class="field" *ngIf="!hideDeliverySequence">
            <label><mibp-resource-string key="Global_DeliverySequence"></mibp-resource-string></label>
            <mibp-dropdown #deliverysequenceDropdown [multiselect.hideOnClick]="true"
              [multiselect.tagPlaceholder]="true" [data]="deliverySequences$" (filter)="filterDeliverySequences($event)"
              [placeholder]="anyResourceStringKey" [multiselect]="true" [take]="dropdownPageSize"
              (valueChange)="onDropdownValueChanged('deliverysequence', $event)"></mibp-dropdown>
            <p *ngIf="deliverySequenceError == 'userscope'" class="inline-error-text"><mibp-resource-string
                key="Component_ResponsibilityPicker_UserScopeLoadError"></mibp-resource-string></p>
          </div>

          <div class="field" *ngIf="!hideOperationSite">
            <label><mibp-resource-string key="Global_OperationSite"></mibp-resource-string></label>
            <mibp-dropdown #operationSiteDropdown [multiselect.hideOnClick]="true"
              [multiselect.tagPlaceholder]="true" [data]="operationSites$" (filter)="filterOperationSites($event)"
              [placeholder]="anyResourceStringKey" [multiselect]="true" [take]="dropdownPageSize"
              (valueChange)="onDropdownValueChanged('operationsite', $event)"></mibp-dropdown>
          </div>
        </div>

        <div class="column" *ngIf="!hideProductColumn">

          <div class="field arrow-left">
            <label><mibp-resource-string key="Global_Product_Area"></mibp-resource-string></label>
            <mibp-dropdown #productAreaDropdown [data]="productAreas$" (filter)="filterProductAreas($event)"
              [multiselect.hideOnClick]="true" [multiselect.tagPlaceholder]="true" [placeholder]="anyResourceStringKey"
              [multiselect]="true" [take]="dropdownPageSize"
              (valueChange)="onDropdownValueChanged('productarea', $event)"
              (invalidValuesChanged)="invalidValuesChanged('productarea', $event)">
            </mibp-dropdown>
          </div>


          <div class="field">
            <label><mibp-resource-string key="Global_Product_Group"></mibp-resource-string></label>
            <mibp-dropdown #productgroupDropdown [data]="productGroups$" (filter)="filterProductGroups($event)"
              [multiselect.hideOnClick]="true" [multiselect.tagPlaceholder]="true" [disabled]="true"
              [placeholder]="anyResourceStringKey" [multiselect]="true" [take]="dropdownPageSize"
              (valueChange)="onDropdownValueChanged('productgroup', $event)"
              (invalidValuesChanged)="invalidValuesChanged('productgroup', $event)"></mibp-dropdown>
          </div>

          <div class="field">
            <label><mibp-resource-string key="Global_Product_Sub_Group"></mibp-resource-string></label>
            <mibp-dropdown #productsubgroupDropdown [data]="productSubGroups$" (filter)="filterProductSubGroups($event)"
              [multiselect.hideOnClick]="true" [multiselect.tagPlaceholder]="true" [disabled]="true"
              [placeholder]="anyResourceStringKey" [multiselect]="true" [take]="dropdownPageSize"
              (valueChange)="onDropdownValueChanged('productsubgroup', $event)"
              (invalidValuesChanged)="invalidValuesChanged('productsubgroup', $event)"></mibp-dropdown>
          </div>


        </div>


        <div class="column" *ngIf="!hideModelColumn">

          <div class="field">
            <label><mibp-resource-string key="Global_Model"></mibp-resource-string></label>
            <mibp-dropdown #modelDropdown [data]="productModels$" (filter)="filterProductModels($event)"
              [multiselect.hideOnClick]="true" [multiselect.tagPlaceholder]="true" [placeholder]="anyResourceStringKey"
              [multiselect]="true" [take]="dropdownPageSize" (valueChange)="onDropdownValueChanged('model', $event)"
              (invalidValuesChanged)="invalidValuesChanged('model', $event)"></mibp-dropdown>
          </div>


        </div>
        <div class="column" *ngIf="!hideEquipmentColumn">
          <div class="field">
            <label><mibp-resource-string key="Global_MachineSerialNumber"></mibp-resource-string></label>
            <mibp-dropdown #serialDropdown [data]="serials$" (filter)="filterSerialNumbers($event)"
              [multiselect.hideOnClick]="true" [multiselect.tagPlaceholder]="true" [placeholder]="anyResourceStringKey"
              (valueChange)="onDropdownValueChanged('serial', $event)" [multiselect]="true" [take]="dropdownPageSize"
              (invalidValuesChanged)="invalidValuesChanged('serial', $event)"></mibp-dropdown>
          </div>


        </div>
      </div>
    </div>

    <div class="actionbar">
      <div class="buttons">
        <mibp-button #btn [icon]="btn.Enum.Icons.Plus" [color]="btn.Enum.Colors.Primary"
          resourceStringKey="Component_ResponsibilityPicker_AddScope"
          [disabled]="!isFormValid || (selectionIsTargetingAll && !allowTargetingAll) || isLoading"
          (click)="doit()"></mibp-button>
        <div *ngIf="allowPromotionFileImport">
          <a href="/assets/blob/Excel%20Template%20Responsibility.xlsx" target="_blank"><span translate="no" class="material-icon material-icon--small">download</span>&nbsp;Excel Template.xslx</a>
          <mibp-button #btn [color]="btn.Enum.Colors.Secondary"
            resourceStringKey="Component_ResponsibilityPicker_ImportFromFile"
            [disabled]="!isFormValid || (selectionIsTargetingAll && !allowTargetingAll) || isLoading"
            (click)="importFile()"></mibp-button>
          <!-- <div class="list-item-content-file">
          <a href="javascript:void(0)" (click)="helpDialog.open()"><fa-icon class="infoIconPadd" [icon]="faInfo"></fa-icon><mibp-resource-string key="Quick-Add-Dashboard-Import-FileInfo"></mibp-resource-string></a>
        </div> -->
        </div>
      </div>
      <div class="message" *ngIf="!isFormValid || (selectionIsTargetingAll && !allowTargetingAll)">
        <div>
          <p *ngIf="!isFormValid">
            <mibp-resource-string key="Component_ResponsibilityPicker_InvalidFilter"></mibp-resource-string>
          </p>
          <p *ngIf="selectionIsTargetingAll && !allowTargetingAll">
            <mibp-resource-string key="Component_ResponsibilityPicker_NotAllowedTargetingAll"></mibp-resource-string>
          </p>
        </div>
      </div>
    </div>

    <mibp-resolved-scopes-list [hideModelColumn]="hideModelColumn" [hideEquipmentColumn]="hideEquipmentColumn"
      [anyResourceStringKey]="anyResourceStringKey" [enableDependencyValidation]="validateScopes"
      [hideDeliverySequence]="hideDeliverySequence" [hideBusinessRelation]="hideBusinessRelation" [hideOperationSite]="hideOperationSite" [resolvedScopes]="dialogScopeList"
      [hideProductColumn]="hideProductColumn" (delete)="removeDialogScope($event)"
      [addResposibilityDialog]="true"></mibp-resolved-scopes-list>

    <mibp-dialog #helpDialog titleResourceStringKey="Quick-Add-Dashboard_ImportHelp_Header" (opened)="helpDialogOpen()">
      <h1>This is the help dialog</h1>
      <div class="tile tiles--size1 tile--clean plain-text">
        <h3 style="margin-left: 0;"><mibp-resource-string
            key="Quick-Add-Dashboard_ImportHelp_ExampleFilesHeader"></mibp-resource-string></h3>
        <ul style="font-size: 125%; margin-top: 1em;">
          <li><a href="/assets/blob/CSV%20Template.csv" target="_blank"><span translate="no" class="material-icon material-icon--small">download</span> CSV
              Template.csv</a></li>
          <li><a href="/assets/blob/Excel%20Template.xlsx" target="_blank"><span translate="no" class="material-icon material-icon--small">download</span>
              Excel Template.xslx</a></li>
        </ul>
      </div>
    </mibp-dialog>

    <mibp-dialog #fileImportValidationDialog titleResourceStringKey="Global_ValidationErrorsTitle"
      (opened)="helpDialogOpen()" (buttonClick)="fileImportValidationDialog.close()"
      [buttons]="fileImportValidationDialogButtons">
      <div>
        <p class="validation-dialog-info"><mibp-resource-string
            key="Component_ResponsibilityPicker_ValidationErrorDialogInfo"></mibp-resource-string></p>
        <table class="my-table my-table--zebra">
          <thead>
            <tr>
              <th>Row</th>
              <th>Message</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let error of fileImportValidationErrors | keyvalue : originalOrder">
              <td>{{error.key}}</td>
              <td>{{error.value}}</td>
            </tr>
          </tbody>
        </table>
        <!-- <ul>
              <li *ngFor="let error of fileImportValidationErrors">{{error}}</li>
          </ul> -->
      </div>
    </mibp-dialog>
  </mibp-dialog>
  <mibp-loader *ngIf="showFullScreenLoader" type='fullscreen'></mibp-loader>
