import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from "@angular/core";
import { BillOfMaterialApiController } from "root/mibp-openapi-gen/controllers";
import { AddItemToCartSource } from "root/mibp-openapi-gen/models";
import { BomPartVm } from './../../../mibp-openapi-gen/models/bom-part-vm';
import { CartService, BroadcastService, LocalizationService, GlobalConfigService, PermissionService, MibpLogger, LogService } from 'root/services';
import { skip, Subscription, firstValueFrom } from 'rxjs';
import { PartsManualIllustrationImage } from "../parts-manual.types";
import { ApiErrorHandlerService } from 'root/services/api-error-handler/api-error-handler';
import { HotPointExtended, PartManualsIllustration } from "../parts-manual-illustration/parts-manual-illustration.component";
import { MediaFolderAttachmentType, MediaFolderFileVersion, MediaFolderVm } from "root/mibp-openapi-gen/models";
import { MibpHttpApi } from './../../../services/mibp-http-api/mibp-http-api.service';
import { SafeUrl, DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { BomAttachmentVm } from './../../../mibp-openapi-gen/models/bom-attachment-vm';
import { ContactUsService } from './../../../services/contact-us/contact-us.service';
import { ContactUsTopic } from "root/components/contact-dialog/contact-us-form/contact-us-form.types";
import { PartsManualsService } from './../parts-manual.service';
import { SupportCaseItemEnquiryType } from "root/components/contact-dialog/new-contact-us-form/contact-us-form-item-enquiry/contact-us-item-enquiry-form.interface";
import { allPermissionPolicies } from "root/all-permission-policies";
import { MibpSessionService } from "root/services/mibp-session/mibp-session.service";
import { MySandvikFeatures } from "root/services/permission";
import { FormArray, FormBuilder, FormGroup, Validators } from "@angular/forms";

interface ExtendedBomPartVm extends BomPartVm {
  addingToCart?: boolean;
  isSearchHit?: boolean;
  isLinked?: boolean;
  navigationRoute?: string;
}

interface ChildListItem {
  id: number;
  name: string;
  hasPdf: boolean;
  thumbnailImage: SafeUrl;
}


@Component({
  selector: 'mibp-parts-manual-folder-details',
  styleUrls: ['./parts-manual-folder-details.component.scss'],
  templateUrl: './parts-manual-folder-details.component.html',
  providers: [PartsManualsService]
})
export class MibpPartsManualFolderDetailsComponent implements OnChanges, OnInit, OnDestroy {

  @Input() mediaFolderId: number;
  @Input() mediaIdentifier: string;
  @Input() mediaId: number;
  @Input() isFolderInSearchResult = false;
  @Input() searchQuery: string;
  @Output() itemSelected = new EventEmitter();

  imageIsMissing = false;
  parts: ExtendedBomPartVm[] = [];
  currentImageBase64Url?: string;
  selectedImage?: PartsManualIllustrationImage;
  isLoadingPage = false;
  justListChildren = false;
  illustrations: PartManualsIllustration[];
  isLoadingParts = false;
  windowIsMediumOrSmaller = false;

  pdfUrl: string;
  pdfName: string;
  pdfSize: number;
  downloadPdfUrl: string;

  actAsSub: Subscription;
  responsiveSub: Subscription;

  childList: ChildListItem[];
  whereUsedCount: number;
  queryParamSub: Subscription;
  selectedNode: number;
  log: MibpLogger;

  canAddToCart = false;

  toggleViewModeButtonText: string;
  useViewModeVertical = true;
  quantityForm!: FormGroup; // FormGroup to manage the FormArray for quantities

  protected enablePartsManualIllustrationHotpointLinks: boolean;

  constructor(private bomApi: BillOfMaterialApiController,
    private broadcast: BroadcastService,
    private cartService: CartService,
    private httpApi: MibpHttpApi,
    private sanitizer: DomSanitizer,
    private route: ActivatedRoute,
    private router: Router,
    private localizationService: LocalizationService,
    private errorHandler: ApiErrorHandlerService,
    private contactUsService: ContactUsService,
    private partsManualService: PartsManualsService,
    private globalConfig: GlobalConfigService,
    private permissionService: PermissionService,
    private session: MibpSessionService,
    log: LogService,
    private localization: LocalizationService,
    private fb: FormBuilder) {
    this.log = log.withPrefix('parts-manual-folder-details');
  }

  ngOnInit(): void {
    this.parts = []; // Initialize parts to avoid null issues
    this.initializeForm(); // Initialize the form on component load
    this.enablePartsManualIllustrationHotpointLinks = this.globalConfig.enablePartsManualIllustrationHotpointLinks;

    this.actAsSub = this.broadcast.deliverySequence.pipe(skip(1)).subscribe({
      next: () => this.updateVariablesFromActiveDeliverySequence()
    });
    this.updateVariablesFromActiveDeliverySequence();
    this.queryParamSub = this.route.queryParams.subscribe((params) => this.onQuerystringFilterUpdate(params));
    this.setToggleViewModeButtonText();

    this.responsiveSub = this.broadcast.responsiveBreakpoint.subscribe(result => {
      const previousSizeWasMediumOrSmaller = this.windowIsMediumOrSmaller;
      this.windowIsMediumOrSmaller = result.lteq('m');
      this.useViewModeVertical = this.windowIsMediumOrSmaller ? false : previousSizeWasMediumOrSmaller ? true : this.useViewModeVertical;
      this.setToggleViewModeButtonText();
    });
  }

initializeForm(): void {
  const quantityControls = this.parts.reduce((controls, part, index) => {
    controls[`quantity_${index}`] = [
      part.quantity || 1,
      [Validators.required, Validators.min(0.05), Validators.max(99999999.999)],
    ];
    return controls;
  }, {});

  this.quantityForm = this.fb.group(quantityControls);
}

updateQuantity(index: number, newQuantity: number): void {
  const part = this.parts[index]; // Get the part using the index
  part.quantity = newQuantity; // Update the corresponding part's quantity
  this.validateQuantity(index); // Validate the updated quantity
}

validateQuantity(index: number): void {
  const controlName = `quantity_${index}`;
  const quantityControl = this.quantityForm.get(controlName);

  if (!quantityControl?.valid) {
    return; // Skip if the control is already invalid
  }

  const quantityValue = quantityControl.value;

  // Custom validation: Decimal values not allowed
  if (quantityValue?.toString().includes('.')) {
    quantityControl.setErrors({ DecimalValueNotSupported: true });
    quantityControl.markAsTouched();
  }
}

onQuantityBlur(index: number): void {
  const controlName = `quantity_${index}`;
  const quantityControl = this.quantityForm.get(controlName);

  if (!quantityControl?.valid) {
    return; // Skip if the control is invalid
  }

  const quantityValue = quantityControl.value;

  // Custom validation: Check for decimal values
  if (quantityValue?.toString().includes('.')) {
    quantityControl.setErrors({ DecimalValueNotSupported: true });
    quantityControl.markAsTouched();
  }
}
  
  highlightHotpoint(selectedHotpointItemNumber: string) {
    this.router.navigate([], { queryParams: { node: selectedHotpointItemNumber }, queryParamsHandling: 'merge' });
  }
  setToggleViewModeButtonText() {
    this.toggleViewModeButtonText = this.useViewModeVertical ?
      this.localizationService.get("PartsManual_ToggleView_Vertical") : this.localizationService.get("PartsManual_ToggleView_Horizontal");
  }

  onQuerystringFilterUpdate(params: Params) {
    const nodeParamString = params['node'];
    if (nodeParamString) {
      this.selectedNode = nodeParamString;
      const elemnt = document.getElementById('node_' + this.selectedNode);
      setTimeout(() => {
        if (elemnt) {
          elemnt.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
        }
      });
    }
    else {
      this.selectedNode = 0;
    }
  }

  updateVariablesFromActiveDeliverySequence(): void {
    const canSeeCart = this.permissionService.test(allPermissionPolicies.canSeeCart);
    const isActingAs = this.session.isActingAs();
    const hasPersistentCart = this.session.hasFeature(MySandvikFeatures.ShopPersistentCart);
    this.canAddToCart = canSeeCart && (isActingAs || hasPersistentCart);
  }


  openProductEnquiryDialog(productCode: string, quantity: number): void {
    if (this.globalConfig.enableNewContactUs) {
      this.contactUsService.openItemEnquiryContactUs(productCode, quantity, SupportCaseItemEnquiryType.ProductAvailability);
    }
    else {
      const message = this.localizationService.format(
        this.localizationService.get('Carts_AvailabilityForProductMessage'),
        { code: productCode, qty: quantity.toString() }
      );
      this.contactUsService.openContactUs(ContactUsTopic.Enquiry, message);
    }
  }

  ngOnDestroy(): void {
    this.actAsSub?.unsubscribe();
    this.responsiveSub?.unsubscribe();
    this.queryParamSub?.unsubscribe();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.mediaFolderId && changes.mediaFolderId.currentValue != changes.mediaFolderId.previousValue) {
      if (changes.mediaFolderId.currentValue && this.mediaIdentifier) {
        this.illustrations = null;
        this.loadPage();
      }
    }
    if (changes['parts'] && !changes['parts'].firstChange) {
      this.initializeForm();
    }
  }

  getBackgroundImageUrl(): string {
    return `url("${this.currentImageBase64Url}")`;
  }

  addToCart(index: number): void {
    const part = this.parts[index]; // Get the part using the index
    const controlName = `quantity_${index}`;
    const quantity = this.quantityForm.get(controlName)?.value || part.quantity;
    part.addingToCart = true;
    this.cartService
      .addToCart(
        [
          {
            productCode: part.partNumber,
            quantity: quantity,
          },
        ],
        AddItemToCartSource.PartsManual
      )
      .finally(() => {
        part.addingToCart = false;
      });
  }
  

  downloadPdf() {
    const a = document.createElement("a");
    a.style.display = "none";
    document.body.appendChild(a);
    a.href = this.downloadPdfUrl;
    a.click();
    // window.URL.revokeObjectURL(a.href);
    document.body.removeChild(a);
  }

  goToFolder(child: ChildListItem): void {
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: {
        folder: child.id
      }
    });
    this.itemSelected.emit();
  }

  whereUsedSelected() {
    this.itemSelected.emit();
  }


  async loadChildren(): Promise<void> {
    const folders = await firstValueFrom(this.bomApi.getMediaFolders({
      mediaIdentifier: this.mediaIdentifier,
      parentFolderId: this.mediaFolderId,
      includeAttachments: true
    }));

    this.partsManualService.prepareFolders(folders);

    this.childList = folders.map(folder => {
      const firstThumbnail = folder?.attachments?.find(a => a.type === MediaFolderAttachmentType.Image && a.version == MediaFolderFileVersion.ThumbnailImage);

      return {
        id: folder.id,
        name: folder.name + (folder.assemblyNumber ? ` ${folder.assemblyNumber}` : '') + (folder.revision ? ` ${folder.revision}` : ''),
        hasPdf: folder.attachments?.find(a => a.type == MediaFolderAttachmentType.PDF) ? true : false,
        thumbnailImage: firstThumbnail ? this.sanitizer.bypassSecurityTrustUrl(this.httpApi.resolveUriWithJwtToken(`/billofmaterial/images/${firstThumbnail.id}/raw`)) : null
      } as ChildListItem;

    });
  }


  private async onAttachmentsLoaded(attachments: BomAttachmentVm[]): Promise<void> {
    this.imageIsMissing = attachments.length == 0;

    const pdf = attachments.find(a => a.type == MediaFolderAttachmentType.PDF);
    if (pdf) {
      this.pdfUrl = this.httpApi.resolveUriWithJwtToken(`/billofmaterial/pdf/${pdf.id}/raw`);
      this.downloadPdfUrl = this.httpApi.resolveUriWithJwtToken(`/billofmaterial/pdf/${pdf.id}/download`);
      this.pdfName = pdf.name;
      this.isLoadingPage = false;
      this.pdfSize = pdf.bytes;
      return;
    }

    // Make sure we have the images in the correct order
    attachments.sort((a, b) => {
      if (a.displayOrder == b.displayOrder) {
        return 0;
      }
      return a.displayOrder > b.displayOrder ? 1 : -1;
    });


    const hotPointData = await firstValueFrom(this.bomApi.getHotpointsInfo({ folderId: this.mediaFolderId })).catch(ex => {
      this.log.error(ex, 'error fetching hotpoint info');
    });

    let hotPointLinksData = null;

    if (this.enablePartsManualIllustrationHotpointLinks) {
      hotPointLinksData = await firstValueFrom(this.bomApi.getHotPointLinks({ folderId: this.mediaFolderId }))
        .catch(ex => {
          this.log.error(ex, 'Error fetching hotpoint links info');
          return null; // or some other fallback value, depending on your needs
        });
    }


    // Create a list of the images to pass in to illustration component
    const theImages: PartManualsIllustration[] = attachments
      .filter(a => a.version == MediaFolderFileVersion.OriginalFile)
      .map(attachment => {
        return {
          name: attachment.name,
          images: attachments.filter(f => f.name == attachment.name)
        } as PartManualsIllustration;
      });

    if (theImages.length > 0) {
      // Early return if hotPointData is null or undefined
      if (!hotPointData) {
        return; // Skip processing if hotPointData is not available
      }

      theImages.forEach(image => {
        // Now we are sure hotPointData is available
        image.hotPoints = hotPointData.sheets?.find(s => s.displayOrder === image.images[0].displayOrder)?.hotpoints?.map(hp => ({
          x: hp.x,
          y: hp.y,
          radius: hp.radius,
          item: hp.item,
          isSelected: false
        })) || [];

        // Add hotPointsLink with item and url
        image.hotPointsLink = (hotPointLinksData || [])
          .filter(link => {
            const sheet = hotPointData.sheets?.find(s => s.displayOrder === image.images[0].displayOrder);
            return sheet?.hotpoints?.some(hp => hp.item === link.item);
          })
          .map(link => ({
            id: link.id,
            item: link.item,  // Map item
            url: link.url     // Map url
          })) || [];
      });
    }
    this.illustrations = theImages;
  }

  private onPartsLoaded(parts: BomPartVm[]): void {
    this.parts = parts;
    this.initializeForm();

    // Exit early if this.illustrations is falsy
    if (!this.illustrations) {
      return;
    }

    // Process each part if illustrations exist
    this.parts.forEach(part => {
      part.isLinked = this.illustrations.some(illustration =>
        illustration.hotPointsLink.some(link => link.item === part.item)
      );
    });
  }


  async loadPage(): Promise<void> {

    // Show loader and clear data
    this.isLoadingPage = true;
    this.illustrations = [];
    this.childList = null;
    this.pdfUrl = null;
    this.currentImageBase64Url = null;
    this.parts = null;
    this.selectedImage = null;

    let folderInfo: MediaFolderVm;

    try {
      folderInfo = await firstValueFrom(this.bomApi.getFolderInfo({ folderId: this.mediaFolderId }));
    } catch (e) {
      this.errorHandler.parseError(e).showNotice();
    }

    this.justListChildren = !folderInfo.hasAttachments && !folderInfo.hasParts;
    if (this.justListChildren) {
      await this.loadChildren();
      this.isLoadingPage = false;
      return;
    }

    try {
      const [attachments, parts] = await Promise.all([
        folderInfo.hasAttachments ? await firstValueFrom(this.bomApi.getFolderAttachments({ folderId: this.mediaFolderId })) : await Promise.resolve([]),
        folderInfo.hasParts ? await firstValueFrom(this.bomApi.getPartsInFolder({ folderId: this.mediaFolderId, mediaIdentifier: this.mediaIdentifier })) : await Promise.resolve([])
      ].filter(arrayItem => arrayItem != null));

      await this.onAttachmentsLoaded(attachments);
      this.onPartsLoaded(parts);

      this.isLoadingPage = false;
      this.isLoadingParts = false;

    } catch (e) {
      this.errorHandler.parseError(e).showNotice();
    }
  }

  toggleViewMode() {
    this.useViewModeVertical = !this.useViewModeVertical;
    this.setToggleViewModeButtonText();
  }

  isMatchWithSearchQuery(part: ExtendedBomPartVm): boolean {
    if (!this.searchQuery || this.searchQuery.length < 1) {
      return;
    }

    const searchQueryLowerCase = this.searchQuery.toLowerCase();

    return part.name?.toLowerCase().includes(searchQueryLowerCase) ||
      part.partNumber?.toLowerCase().includes(searchQueryLowerCase) ||
      part.description?.toLowerCase().includes(searchQueryLowerCase);
  }

  protected navigateToProductDetailsPage(productCode: string,event:Event) {
    event.stopPropagation();
    this.router.navigate([this.localization.getLang(), 'shop', 'products', `${productCode}`, 'information']);
  }

  redirectToHotpoint(item: string): void {
    let matchingHotpoint: any = undefined;

    // Iterate over the illustrations array to search for a match in hotpointsLink
    for (const illustration of this.illustrations) {
      if (Array.isArray(illustration.hotPointsLink)) {
        // Search in the hotpointsLink array for a matching item
        matchingHotpoint = illustration.hotPointsLink.find(
          (hotpoint: any) => hotpoint.item === item
        );

        if (matchingHotpoint) {
          break;
        }
      }
    }

    if (matchingHotpoint && matchingHotpoint.url) {
      const pageId = matchingHotpoint.url.split('/').pop();

      // Fetch page details first
      firstValueFrom(this.bomApi.getHotPointPageDetails({ pageId: pageId }))
        .then((response) => {
          if (response && response.hashKey) {
            const hashKey = response.hashKey;

            // Once the hashKey is available, make the second API call
            return firstValueFrom(
              this.bomApi.getFolderId({ hashKey: hashKey, mediaIdentifier: this.mediaIdentifier })
            );
          } else {
            this.log.error('HashKey not found in the page details response');
            return null;
          }
        })
        .then((result_Folder_Id) => {
          if (result_Folder_Id) {
            const folderId = result_Folder_Id;
            // Construct the navigation URL
            this.router.navigate([], { queryParams: { folder: folderId }, queryParamsHandling: 'merge' });
          } else {
            this.log.error('Failed to fetch navigation information');
          }
        })
        .catch((error) => {
          this.log.error(error, 'Error fetching hotpoint links info');
        });

    } else {
      this.log.error(`No matching hotpoint link found for item: ${item}`);
    }
  }

 protected stopEventPropagation(event: Event): void {
    event.stopPropagation(); // Prevent the click from propagating to the row
  }
   
}
