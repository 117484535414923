

import { DropdownData, DropdownInput, DropdownArgs } from 'root/components/dropdown';
import { MibpLogger, LogService } from 'root/services';
import { Component, OnInit,  Input, Output, EventEmitter, forwardRef, ViewEncapsulation, INJECTOR } from "@angular/core";
import { firstValueFrom, Observable, of } from "rxjs";
import { UntypedFormBuilder, AbstractControl, Validators, UntypedFormGroup, ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms";
import { map } from "rxjs/operators";
import { CompanyIndexSearchResponse } from 'root/mibp-openapi-gen/models/company-index-search-response';
import { BusinessRelationIndexSearchResponse } from 'root/mibp-openapi-gen/models/business-relation-index-search-response';
import { OperationSiteIndexSearchResponse } from 'root/mibp-openapi-gen/models';
import { BusinessRelationsApiController, CompaniesApiController, OperationSitesApiController } from 'root/mibp-openapi-gen/controllers';

@Component({
  selector: 'mibp-operationsite-picker',
  templateUrl: './operationsite-picker.component.html',
  styleUrls: ['./operationsite-picker.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => OperationsitePickerComponent),
    }
  ]
})
export class OperationsitePickerComponent implements OnInit, ControlValueAccessor {
 
@Output() valueChange = new EventEmitter();
@Input() operationSiteId;
@Input() businessrelationId;

 selectedCustomer = null;
 selectedCompany = null;
 selectedOperationSite = null;

  pickerForm: UntypedFormGroup;
  log: MibpLogger;

  // Observables for the dropdown data
  companies$: Observable<DropdownData>;
  customers$: Observable<DropdownData>;
  operationSites$: Observable<DropdownData>;
  
  constructor(private formBuilder: UntypedFormBuilder,
    logger: LogService,
    private companiesApi : CompaniesApiController,
    private businessRelationApi: BusinessRelationsApiController,
    private operationsiteapi: OperationSitesApiController
   ) {
    this.log = logger.withPrefix('operationsite-picker');
    
  }


  ngOnInit(): void {
 

    if(this.operationSiteId !== undefined){
      this.loadExistingTarget();
    }
 

    // Configure FormGroup
    this.pickerForm = this.formBuilder.group({
      companyDropdown: [this.selectedCompany,Validators.required],
      customerDropdown:[this.selectedCustomer,Validators.required],
      operationsiteDropdown:[this.selectedOperationSite,Validators.required],
     
    });

      this.pickerForm.get('customerDropdown').disable();
      this.pickerForm.get('operationsiteDropdown').disable();
     
  }
  private async loadExistingTarget() {

    try {

      const target = await firstValueFrom(this.operationsiteapi.getTargetForPicker({ operationSiteId: this.operationSiteId, businessrelationId: this.businessrelationId }));
      this.selectedOperationSite = target.operationSiteName;

      this.pickerForm.get('companyDropdown').setValue({
        text: target.companyName,
        value: target.companyId + ',' + target.companyCode
      });
      this.pickerForm.get('customerDropdown').setValue({
        text: target.businessRelationName,
        value: target.businessRelationId + ',' + target.erpCustomerId
      });
      this.pickerForm.get('operationsiteDropdown').setValue({
        text: target.operationSiteName,
        value: target.operationSiteId
      });
    }
    catch (ex) {
        this.log.error(ex.error.error);
    }
  }




  protected triggerChange(event: DropdownInput): void {
    if (event) {
      if (event.value) {
        const selectedOperationSiteId = parseInt(event.value, 10);

        if (selectedOperationSiteId) {

           const selectedCompanyId = this.getFieldValue(`companyDropdown`);
           const selectedBusinessRelationId = this.getFieldValue(`customerDropdown`);

          let data = {
            selectedCompanyId: Number(selectedCompanyId),
            selectedBusinessRelationId: Number(selectedBusinessRelationId),
            selectedOperationSiteId: selectedOperationSiteId
          };

          this.onChange(data);
          this.valueChange.emit(data);

        } else {

          this.onChange(null);
          this.valueChange.emit(null);
        }
      } else {
        this.onChange(null);
        this.valueChange.emit(null);
      }
    } 
  }


   onChange = (value: any): void => { };
 
   onTouched = (): void => { };

    registerOnChange(fn: () => void): void {
    this.onChange = fn;
  }
   registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }


  protected onFilterCustomers(args: DropdownArgs): void {
    
    const companyCode = this.getFieldText(`companyDropdown`);
    let searchResponse$: Observable<BusinessRelationIndexSearchResponse>;

      searchResponse$ = this.businessRelationApi.search(
        {
          body:
          {
            query: args.query,
            skip: args.index,
            take: args.take,
            companyCode: companyCode.toString(),
            searchErpCustomerIdOnly: false,
            fetchActiveOnly: true
          }
        });
    

    this.customers$ = searchResponse$.pipe(
      map(companyListResult => {
        // Map result to an IDropdownData object
        return <DropdownData>{
          items: companyListResult.items.map(businessRelation => {
            return { text: `${businessRelation.erpCustomerId} ${businessRelation.customerName}`, value: businessRelation.id + ','+ businessRelation.erpCustomerId,
              disabled: !(businessRelation.isActive && businessRelation.hasActiveDeliverySequences) };
          }),
          totalCount: companyListResult.count,
          hasMoreResults: args.index + 10 < companyListResult.count
        };
      })
    );
    this.pickerForm.get('customerDropdown').enable();
  }

  protected onChangeCompany(event): void {
    this.customers$ = null;
    this.operationSites$ = null;

    if(event != null){
      this.resetDropDown('customerDropdown', true);
      this.resetDropDown('operationsiteDropdown', false);
    }
  }

  protected onChangeCustomer(event): void {
    this.operationSites$ = null;
    if(event != null){
      this.resetDropDown('operationsiteDropdown', true);
    }
   }

  private resetDropDown(field: string, enable: boolean) {
    this.pickerForm.get(field).reset();
    this.pickerForm.get(field).clearValidators();
    this.pickerForm.get(field).updateValueAndValidity();

    if (enable) {
      this.pickerForm.get(field).enable();
    }
    else {
      this.pickerForm.get(field).disable();
    }
  }

 
  onChangeOperationSite(event: DropdownInput): void {
    this.triggerChange(event);
  }
  
  
 protected onFilterCompanies(args: DropdownArgs): void {
    let searchResponse$: Observable<CompanyIndexSearchResponse>;

      searchResponse$ = this.companiesApi.searchActive(
        {
          body:
          {
            query: args.query,
            skip: args.index,
            take: args.take
          }
        });
    

    this.companies$ = searchResponse$
      .pipe(
        map(companyListResult => {
          // Map result to an IDropdownData object
          return <DropdownData>{
            items: companyListResult.items.map(company => {
              return { text: company.code + ' - ' + company.name, value: company.id.toString()+ ','+company.code,
                disabled: !(company.isActive && company.hasActiveBusinessRelations) };
            }),
            totalCount: companyListResult.count,
            hasMoreResults: args.index + 10 < companyListResult.count
          };
        })
      );

   
  }

 

 protected onFilterOperationSites(args: DropdownArgs): void {
    const businessRelationId = this.getFieldValue(`customerDropdown`);
    const companyId = this.getFieldValue('companyDropdown');

    let searchResponse$: Observable<OperationSiteIndexSearchResponse>;
   
      searchResponse$ = this.operationsiteapi.searchOperationSitesForPicker(
        {
         companyId: Number(companyId),
         businessRelationId: Number(businessRelationId),
         query: args.query,
         skip:args.index,
         take: args.take
        });
    
    this.operationSites$ = searchResponse$.pipe(
      map(operationSitesListResult => {
        
        return <DropdownData>{
          items: operationSitesListResult.items.map(operationSite => {
            const displayText =  operationSite.name;
              
            return { text: displayText, value: operationSite.id.toString(), disabled: false };
          }),
          totalCount: operationSitesListResult.count,
          hasMoreResults: args.index + 10 < operationSitesListResult.count
        };
      })
    );
  }

  private getFieldValue(name: string): string {
    if (!this.pickerForm) {
      return null;
    }
    const field = this.pickerForm.get(name);
    if (!field) {
      return null;
    }
    const text = field.value.value.toString();
    const array = text.split(',') ;
    return array[0];
  }

  private getFieldText(name: string): string {
    if (!this.pickerForm) {
      return null;
    }
    const field = this.pickerForm.get(name);
    if (!field) {
      return null;
    }
    const text = field.value.value.toString();
     const array = text.split(',') ;
     return array[1];
  }
  
   writeValue(obj: any): void {


  }

}
