<div class="tzdatetimepicker">

  <div  [class.timezone-datetimepicker--disabled]="isDisabled" class="tzdatetimepicker__date">
    <mat-form-field>
    <input autocomplete="off" #field matInput
      style="min-width: 100px;"
        [contentEditable]="false"
        [disabled]="isDisabled"
        [class.focus]="picker.opened"
        [matDatepicker]="picker"
        (dateInput)="datePickerEvent('input', $event)"
        (dateChange)="datePickerEvent('change', $event);"
        format='YYYY'
        [(ngModel)]="dateValue">
        <mat-datepicker #picker ></mat-datepicker>

        <span (click)="clear()" [hidden]="!dateValue" translate="no" class="material-icon">close</span>

        <!-- <fa-icon [hidden]="!dateValue" class="clear-icon" [icon]="clearSearchIcon" (click)="dateValue = null; clear(); $event.stopPropagation(); false">X</fa-icon> -->
      </mat-form-field>

      <div class="picker-icon" (click)="picker.open();">
        <span translate="no" class="material-icon">calendar_month</span>
      </div>
  </div>

  <input
    class="tzdatetimepicker__time"
    *ngIf="!hideTime"
    type="time"
    [disabled]="isDisabled" (change)="timeInputEvent('change', $event)" style="max-width: 130px;" [(ngModel)]="timeValue">

  <select *ngIf="!hideTimezoneList || whatYouSeeIsUtc" [disabled]="isDisabled || whatYouSeeIsUtc" class="my-select" (change)="userChangedTimezone()" [(ngModel)]="userTimezone" style="min-height: 44px;" >
    <option *ngFor="let tz of timezones" value="{{tz.name}}">(UTC{{tz.offsetString}}) {{tz.name}}</option>
  </select>

</div>


<div style="margin-top: 5px; margin-left: 8px; font-size: .85rem">

  <p *ngIf="selectedIsoString">
    <code>{{utcString || '(nothing)'}} (UTC)</code>
    @if (yourTimezoneString) {
       | {{yourTimezoneString}} ({{browserTimezone}})
    }
  </p>
  <p *ngIf="!selectedIsoString">
    No date selected
  </p>
</div>

