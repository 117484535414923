import { AuthService } from './../auth-service/auth.service';
import { LogService } from './../logservice/log.service';
import { HttpClient } from '@angular/common/http';
import { SignalR_GlobalConfigClass, SignalR_GlobalConfig } from 'root/services/mibp-api-services/_mibp-api-generated.service.types';
import { Injectable } from '@angular/core';
import { environment } from 'root/environment';
import { MibpLogger } from '../logservice/mibplogger.class';
import { ApplicationStateService } from '../application-state/application-state.service';
import { ApplicationStates } from '../application-state/application-state.types';
import { ApiConfiguration } from 'root/mibp-openapi-gen/api-configuration';
import { GlobalConfigResponseViewModelApiResponse } from 'root/mibp-openapi-gen/models';
import { BroadcastService } from '../broadcast-service/broadcast.service';
import { ClientIdService } from '../clientid-service/clientid.service';
import { TermsAndConditionsService } from '../terms-and-conditions/terms-and-conditions.service';
import { TimeSpan } from 'root/types/timeSpan';


@Injectable({
  providedIn: 'root'
})
export class GlobalConfigService extends SignalR_GlobalConfigClass {

  private resolvedUrl?: string;
  private resolvedCookieUrl?: string;
  private log: MibpLogger;

  /**
   * Logger with common prefix for logging MibpSession related things
   *  - Signing in, user events etc
   */
  private mibpSessionLog: MibpLogger;


  constructor(
    private http: HttpClient,
    private auth: AuthService,
    private clientIdService: ClientIdService,
    private broadcast: BroadcastService,    
    private restApiConfiguration: ApiConfiguration,    
    private termsService: TermsAndConditionsService,
    logger: LogService, 
    private appState: ApplicationStateService) {
    super();
    this.log = logger.withPrefix('global-config');
    this.mibpSessionLog = logger.withPrefix(environment.mibpSessionLogPrefix + ':global-config.service');
    this.resolvedUrl = this.resolveApiUrl();
  }

  public get backendUrl(): string {
    return this.resolvedUrl;
  }

  public get cookieDomain(): string {
    return this.resolvedCookieUrl;
  }

  async load(): Promise<SignalR_GlobalConfig>  {

    // Make sure we have a client id as early as possible
    const clientId = this.clientIdService.getClientId();

    // Set the root REST API URL
    this.restApiConfiguration.rootUrl = this.resolvedUrl?.replace(/\/+$/, ''); // We don't want any trailing slashes here

    if(!window.navigator.onLine && environment.enableServiceWorker){
      this.appState.setState({ state: ApplicationStates.Offline, error: true,  resourceStringKey: 'GLOBALCONFIGERROR', textFallback: 'NO INTERNET CONNECTION FOUND', exception: 'Please check your internet connection and try again' });
      return new Promise<SignalR_GlobalConfig>((resolve,reject) => {});
    }

    // This is before resources are loaded. We need to use the index.html version to attempt to fetch the translated text
    const fallbackText = (window['tryGetResourceString'] && window['tryGetResourceString']('AppLoading_Config')) || 'Loading Application Settings';

    this.appState.setState({ state: ApplicationStates.LoadingGlobalConfig, resourceStringKey: 'AppLoading_Config', textFallback: fallbackText });

    // Even before we load the configuration, make sure to let B2C do it's job before continuing
    await this.auth.processB2CEvents();

    // If this is an SSO attempt we want detect and handle it as early as possible
    await this.auth.processSingleSignOnEvents();




    await this.auth.testForImmediateSignin();

    this.mibpSessionLog.info(`ClientId: ${clientId}`);

    return new Promise<SignalR_GlobalConfig>((resolve) => {
      this.http.get<GlobalConfigResponseViewModelApiResponse>(`${this.resolvedUrl}application/globalConfig`) // /${encodeURIComponent(this.reverseString(token))}
        .toPromise()
        .then(globalConfigResponse => {
          if (globalConfigResponse.success) {

            this.termsService.generalTermsAndConditionsPublishDate = globalConfigResponse.data.termsAndConditionsPublishDate ? new Date(globalConfigResponse.data.termsAndConditionsPublishDate) : null;

            // Parse some special types into usable objects
            Object.keys(globalConfigResponse.data.configuration).filter(name => name.endsWith('.type')).forEach(name => {
              const propertyName = name.replace('.type', '');
              const dataType = globalConfigResponse.data.configuration[name];
              if (globalConfigResponse.data.configuration[propertyName] && dataType == 'TimeSpan') {
                globalConfigResponse.data.configuration[propertyName] = TimeSpan.parse(globalConfigResponse.data.configuration[propertyName]);
              }
            });

            const globalConfig = globalConfigResponse.data.configuration as SignalR_GlobalConfig;
            if (globalConfigResponse.data.hasConfigurationErrors) {
              this.broadcast.setHasConfigurationErrors(true);
            } else {
              this.broadcast.setHasConfigurationErrors(false);
            }
            document.body.setAttribute('data-api-region', globalConfigResponse.data.currentRegionName ?? '-');
            this.log.debug(`Config loaded`, globalConfig);
            this.log.debug(`API Url`, this.resolvedUrl);
            if (this.appState.currentState.state === ApplicationStates.UnhandledException) {
              this.log.warn(`A critical exception has occured, loading stopped`, this.appState.currentState.state);
              return;
            }

            document.querySelector('html').classList.add('ux-version--v2');
            document.querySelector('body').classList.remove('has-native-message');

            this.appState.setGlobalConfig({
              ...globalConfig,
              backendUrl: this.backendUrl
            });


            Object.assign(this, globalConfig);
            return resolve(globalConfig);
          }
          this.fail(globalConfigResponse);
          resolve(globalConfigResponse.data?.configuration);
        }, (err) => {
          this.fail(err);
        });
    });
  }

  /***
   * Resolve the API URL based on the frontend url
   * For the future: We could use this to avoid having any urls to backend in environment settings (one package)
   */
  resolveApiUrl(hostname?: string, protocol?: string): string {
    protocol = protocol || ( hostname ? 'https://' :  `${window.location.protocol}//`);
    hostname = hostname || window.location.hostname;
    if (hostname.indexOf('.portal') !== -1) {
      // Handle test and dev domains
      const parts = hostname.split('.');
      parts.splice(1, 0, 'api');
      return this.ensureUrlFormat(`${protocol}${parts.join('.')}/`);
    } else if (hostname.indexOf('portal') !== -1) {
      // Handle new qa and prod domains
      return this.ensureUrlFormat(`${protocol}api.${hostname}/`);
    } else if (hostname.indexOf('rocktechnology') !== -1) {
      // Handle the "old" domains
      const parts = hostname.split('.');
      parts[0] += 'api';
      return this.ensureUrlFormat(`${protocol}${parts.join('.')}/`);
    }else if (hostname.indexOf('mibp-dev-front-door-frontend.azurefd') !== -1) {
      // just for testing with front door service

      return 'https://dev-ef21b9ad.api.portal.my.sandvik/';
    }
    else if (hostname.indexOf('mibp-prod-front-door-frontend.azurefd') !== -1) {
      // just for testing with front door service

      return 'https://api.portal.my.sandvik/';
    } else if (hostname.indexOf('mibp-qa-front-door-frontend.azurefd') !== -1) {
      // just for testing with front door service

      return 'https://qa.api.portal.my.sandvik/';
    }else {
      return this.ensureUrlFormat(environment?.backendLdeUrl);
    }
  }

  private ensureUrlFormat(url: string): string {
    if (!url) {
      return '/';
    }
    if (!url.endsWith('/')) {
      url += '/';
    }
    return url;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private fail(error: any): void {
    this.appState.setState({ state: ApplicationStates.UnhandledException, internalStatus: 'appload.globalconfig', error: true,  resourceStringKey: 'GLOBALCONFIGERROR', textFallback: 'Failed to load application settings', exception: error });
  }

}
