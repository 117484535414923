import { FeatureApiController } from 'root/mibp-openapi-gen/controllers';
import { Component, forwardRef, OnInit, ViewChild } from "@angular/core";
import { ControlValueAccessor, FormArray, FormControl, FormGroup, NG_VALUE_ACCESSOR } from "@angular/forms";
import { DialogComponent } from './../dialog/dialog.component';
import { ApiService } from 'root/services';
import { firstValueFrom } from "rxjs";
import { LocalizationService } from './../../services/localization/localization.service';
import { DialogButton } from './../dialog/dialog.types';
import { ButtonColors } from "../button/button.enum";
import { LoaderService } from './../../services/loader/loader.service';
import { FeatureForEditingVm, FeatureLevel } from 'root/mibp-openapi-gen/models';

interface FeatureGroupForm {
  identifier?: FormControl<string>;
  name?: FormControl<string>;
  features: FormArray;
}

interface FeatureForm {
  identifier: FormControl<string>;
  name: FormControl<string>;
  selected: FormControl<boolean>;
}

@Component({
  selector: 'mibp-feature-picker',
  templateUrl: './feature-picker.component.html',
  styleUrls: ['./feature-picker.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => MibpFeaturePickerComponent),
    }
  ]
})
export class MibpFeaturePickerComponent implements OnInit, ControlValueAccessor {

  status: 'loading' | 'ready' | 'error' = 'loading';
  disabled = false;
  @ViewChild(DialogComponent) dialog: DialogComponent;
  currentValue?: string[];
  formGroups: FormArray;
  dialogButtons: DialogButton[] = [
    {
      id: 'cancel',
      resourceKey: 'Global_CancelButton',
      color: ButtonColors.Blue
    },
    {
      id: 'ok',
      resourceKey: 'Global_OK',
      color: ButtonColors.Orange
    }
  ];

  constructor(private api: ApiService,
    private localizationService: LocalizationService,
    private loader: LoaderService,
    private featuresController: FeatureApiController) {}

  ngOnInit(): void {
    this.formGroups = new FormArray([]);
  }

  removeSelectedIndex(index: number): void {
    this.currentValue.splice(index, 1);
    this.onChange(this.currentValue);
  }

  onChange = (newValue: string[] | undefined) => {};
  onTouched = () => {};

  writeValue(obj: any): void {

    const value: string[] = [];
    if (obj && Array.isArray(obj)) {
      obj.forEach(v => {
        if (typeof(v) == 'string') {
          value.push(v);
        }
      });
    }

    if (value.length === 0) {
      this.currentValue = null;
    } else {
      this.currentValue = value;
    }
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  extractGroupsAndFeatures(features: FeatureForEditingVm[]): void {

    const groups: {identifier: string; name: string}[] = [];

    features.forEach(feature => {
      const groupName = feature.identifier.substring( 0, feature.identifier.indexOf('_') );
      if (!groups.find(g => g.identifier == groupName)) {
        groups.push({
          identifier: groupName,
          name: this.localizationService.get(`Featuregroup_${groupName}_name`)
        });
      }
    });

    groups.sort((a,b) => a.name < b.name ? -1 : 1);

    for (const group of groups) {
      const groupForm = new FormGroup<FeatureGroupForm>({
        name: new FormControl(group.name, { nonNullable: true}),
        identifier: new FormControl(group.identifier, { nonNullable: true}),
        features: new FormArray([])
      });

      const featuresInGroup = features.filter(f => f.identifier.startsWith(`${group.identifier}_`));

      for (const feature of featuresInGroup) {
        groupForm.controls.features.push(new FormGroup<FeatureForm>({
          identifier: new FormControl(feature.identifier),
          name: new FormControl(this.localizationService.get(`feature_${feature.identifier}_name`)),
          selected: new FormControl(false)
        }));
      }

      groupForm.controls.features.controls.sort((a: FormGroup<FeatureForm>,b: FormGroup<FeatureForm>) => a.value.name < b.value.name ? -1 : 1);

      this.formGroups.push(groupForm);
    }

  }

  openDialog(): void {
    this.loader.showFullScreenLoader();
    this.formGroups.clear();

    firstValueFrom(this.featuresController.getFeaturesForEdit({level: FeatureLevel.Role})).then(features => {
      this.extractGroupsAndFeatures(features);

      // Make sure selected items match currentvalue

      this.formGroups.controls.forEach((groupForm: FormGroup<FeatureGroupForm>) => {
        for (const featureForm of <FormGroup<FeatureForm>[]>groupForm.controls.features.controls) {
          if (this.currentValue?.includes(featureForm.value.identifier)) {
            featureForm.patchValue({
              selected: true
            });
          } else {
            featureForm.patchValue({
              selected: false
            });
          }
        }
      });

      this.loader.hideFullScreenLoader();
      this.dialog.open().then((btn: DialogButton) => {
        if (btn?.id === 'ok') {

          let selectedFeatures: string[] = [];
          this.formGroups.controls.forEach((groupForm: FormGroup<FeatureGroupForm>) => {
            const selectedFeaturesInGroup = groupForm.controls.features.controls.filter((f: FormGroup<FeatureForm>) => f.value.selected).map((f: FormGroup<FeatureForm>) => { return f.value.identifier; });

            selectedFeatures.push(...selectedFeaturesInGroup);

          });
          this.currentValue = selectedFeatures.length > 0 ? selectedFeatures : undefined;

          this.onChange(this.currentValue);
        }
        this.dialog.close();
      });

    });


  }

}
