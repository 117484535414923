import { Injectable } from "@angular/core";
import { ApiService, SignalR_ConnectionRevokedEvent } from "../mibp-api-services";
import { AuthService, BroadcastService, DialogService, LoaderService, LogService, MibpLogger } from "..";

@Injectable({providedIn: 'root'})
export class MibpSessionRevokedService {

  private log: MibpLogger;

  constructor(private signalRApi: ApiService, private broadcastService: BroadcastService, private loader: LoaderService, private auth: AuthService, logger: LogService, private dialogService: DialogService) {
    this.log = logger.withPrefix('session-revoked-service');
  }

  public startSubscription(): void {
    this.signalRApi.EventHub.ConnectionRevoked.subscribe(e => this.onConnectionRevokedEvent(e));
  }

  /**
   * Event is received from backend when a connection has been revoked for any reason.
   * We will broadcast that session is revoked (so AuthInspector will block any API requests)
   * And will we will show dialog to user that session is no longer usable
   * @param e
   * @returns
   */
  private onConnectionRevokedEvent(e: SignalR_ConnectionRevokedEvent): void {

    if (!e) {
      // We will always receive a null event initially
      return;
    }

    // If we get the event, check so we have not received earlier
    if (this.broadcastService.snapshot.sessionRevoked == true) {
      return;
    }

    this.log.info(`Received 'ConnectionRevoked' event`);

    // Broadcast that the session is revoked.
    // The AuthInterceptor will consider this and block any subsequent requests
    this.broadcastService.setSessionRevoked(true);

    this.dialogService.prompt("Users_Session_LogOutMessage", "Global_Ok").finally(() => {
      this.loader.showFullScreenLoader();
      this.auth.signout(null, 'revoked');
    });

  }
}
