import { ContactUsTopic } from './../contact-dialog/contact-us-form/contact-us-form.types';
import { Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { LogService, MibpLogger, MibpLogLevel } from 'root/services/logservice';
import { FrontendContextService } from 'root/services/front-end-context/front-end-context.service';
import { LocalizationService } from 'root/services/localization/localization.service';
import { ContactUsService } from 'root/services/contact-us/contact-us.service';
import { MibpPopoverComponent } from '../popover-component/popover.component';

import { allPermissionPolicies } from 'root/all-permission-policies';
import { MibpSessionService } from 'root/services/mibp-session/mibp-session.service';
import { PriceAndAvailabilitySource, ProductAvailability, ProductExpectedDate, ResponseExpectedDeliveryDate, SessionDeliverySequenceViewModel } from 'root/mibp-openapi-gen/models';
import { GlobalConfigService } from 'root/services';
import { SupportCaseItemEnquiryType } from '../contact-dialog/new-contact-us-form/contact-us-form-item-enquiry/contact-us-item-enquiry-form.interface';
import { MySandvikFeatures } from 'root/services/permission';

interface AvailabilityErrorVm {
  errorType: 'unknown' | 'stockroom_or_company';
}

interface StockroomDetail {
  stockroomResourceStringKey: string;
  availableQuantity: number;
  stockrooms: StockroomDetailStockroom[];
}

interface StockroomDetailStockroom {
  name: string;
  availableQuantity: number;
}

@Component({
  selector: 'mibp-availability',
  templateUrl: './availability.component.html',
  styleUrls: ['./availability.component.scss']
})
export class AvailabilityComponent implements OnInit , OnChanges, OnDestroy{
  @ViewChild(MibpPopoverComponent) popover: MibpPopoverComponent;
  log: MibpLogger;
  isDebugLogLevel: boolean;

  @Input() productCode?: string;
  @Input() quantity?: number;
  @Input() priceAndAvailabilitySource: PriceAndAvailabilitySource;
  @Input() productAvailability: ProductAvailability;
  @Input() isSubscription = false;
  @Input() productExpectedDate: ProductExpectedDate;

  triggerForFirstUpdate: boolean;
  isLoading = true;
  updateIsTriggered: boolean;
  availabilities?: ResponseExpectedDeliveryDate[];
  availabilityDescription = "";
  localizationSubscription: Subscription;
  availabilityDescriptionForContactUs: string;
  protected stockroomDetails: StockroomDetail[];

  stopUsingResources: () => void;

  //used in old component
  userCanSeeAvailability: boolean;

  showAvailability: boolean;
  showContactInfo: boolean;
  localAvailabilityClass: string;
  globalAvailabilityClass: string;
  nationalAvailabilityClass: string;
  availability: any;
  hasAvailabilityError: boolean;
  clickForDetailsText: string;
  localAvailabilityDescription: any;
  nationalAvailabilityDescription: any;
  globalAvailabilityDescription: any;
  deliverySequence: SessionDeliverySequenceViewModel;
  currentProductAvailability: ProductAvailability;

  availabilityError?: AvailabilityErrorVm;
  isAuroraCompany = false;
  private contactUsItemEquiryFormEnabled: boolean;
  protected showStockroomDetails = false;

  constructor(
    logger: LogService,
    private frontEndContext: FrontendContextService,
    private localizationService: LocalizationService,
    private contactUsService: ContactUsService,
    private sessionService: MibpSessionService,
    private globalConfig: GlobalConfigService) {
    this.log = logger.withPrefix('availability-component');
    this.isDebugLogLevel = LogService.getLogLevel() === MibpLogLevel.Debug;
    this.deliverySequence = this.sessionService.activeDeliverySequence;
  }



  ngOnDestroy(): void {
    if (this.stopUsingResources) {
      this.stopUsingResources();
    }
  }

  ngOnInit(): void {
    this.contactUsItemEquiryFormEnabled = this.frontEndContext.testPermission({ features: [MySandvikFeatures.ContactUsFormItemEnquiry] });
    const currentCompanyCode =  this.sessionService.activeDeliverySequence &&
      this.sessionService.activeDeliverySequence.companyCode.toLowerCase();
    this.sessionService.activeDeliverySequence$.subscribe(ds => this.onChangeInDeliverySequence(ds));

    this.showStockroomDetails = this.frontEndContext.testPermission({ features: [MySandvikFeatures.ShopProductAvailabilityStockroomdetail] });

    this.stopUsingResources = this.localizationService.using(['availabilityDescription_'+currentCompanyCode, 'availabilityDescription',  'Carts_AvailabilityForProductMessage', 'Global_ClickForDetails',], resourceStrings => {
      this.availabilityDescription = resourceStrings[0].endsWith('_'+currentCompanyCode) ? resourceStrings[1] : resourceStrings[0];
      this.availabilityDescriptionForContactUs = resourceStrings[2];
      this.clickForDetailsText = resourceStrings[3];
    });


    this.stopUsingResources = this.localizationService.using(
      ['carts_availability_local_description_'+currentCompanyCode, 'carts_availability_local_description',
        'carts_availability_national_description_'+currentCompanyCode, 'carts_availability_national_description',
        'carts_availability_global_description_'+currentCompanyCode, 'carts_availability_global_description'], resourceStrings => {

        this.localAvailabilityDescription = resourceStrings[0].endsWith('_'+currentCompanyCode) ? resourceStrings[1] : resourceStrings[0];
        this.nationalAvailabilityDescription = resourceStrings[2].endsWith('_'+currentCompanyCode) ? resourceStrings[3] : resourceStrings[2];
        this.globalAvailabilityDescription = resourceStrings[4].endsWith('_'+currentCompanyCode) ? resourceStrings[5] : resourceStrings[4];
      });
  }

  private parseAvailabilityError(availability: ProductAvailability): AvailabilityErrorVm | undefined {
    if (availability?.errorCode) {
      if (availability.errorCode == 'INVALID_STOCKROOM_OR_COMPANY_SETUP') {
        return {
          errorType: 'stockroom_or_company'
        };
      } else {
        return {
          errorType: 'unknown'
        };
      }
    }
    return undefined;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(changes.productAvailability && changes.productAvailability.currentValue){
      this.currentProductAvailability = changes.productAvailability.currentValue;
      this.parseStockroomDetails();
      if (this.currentProductAvailability.errorCode) {
        this.availability = null;
        this.isLoading = false;
        this.hasAvailabilityError = true;
        this.availabilityError = this.parseAvailabilityError(this.currentProductAvailability);
      } else {
        this.availability = this.currentProductAvailability.availability;
        this.isLoading = false;
        this.hasAvailabilityError = false;
        this.availabilityError = undefined;
      }
      this.isLoading = false;
    }
    else if(!changes.quantity && !changes.productCode){
      this.isLoading = true;
    }
    this.refreshUI();
  }
  showPopover(elm: HTMLElement): void {
    if (elm) {
      this.popover.open(elm);
    }
  }

  private parseStockroomDetails(): void {
    this.stockroomDetails = [];
    if (this.currentProductAvailability?.stockroomDetails?.length > 0) {
      this.stockroomDetails = [
        this.mapToStockroom('LOCAL', 'Carts_Availability_Local'),
        this.mapToStockroom('NATIONAL', 'Carts_Availability_National'),
        this.mapToStockroom('GLOBAL', 'Carts_Availability_Global')
      ];
    }
  }

  private mapToStockroom(location: string, resourceString: string): StockroomDetail {
    const filteredItems = this.currentProductAvailability?.stockroomDetails.filter(s => s.location == location && s.availableQuantity > 0) || [];
    const totalQuantity = filteredItems.reduce((prev, item) => prev + item.availableQuantity, 0);

    const uniqueStockroomNames = filteredItems
      .map((item) => item.stockRoomName)
      .filter(
        (value, index, current_value) => current_value.indexOf(value) === index
      );

    return {
      stockroomResourceStringKey: resourceString,
      availableQuantity: totalQuantity >= 0 ? totalQuantity : 0,
      stockrooms: uniqueStockroomNames
        .map(stockroomName => {
          return {
            availableQuantity: filteredItems
              .filter(stockroom => stockroom.stockRoomName == stockroomName)
              .reduce((totalCount, stockroom) => (stockroom.availableQuantity ? stockroom.availableQuantity : 0) + totalCount, 0),
            name: stockroomName
          } as StockroomDetailStockroom;
        })
    };
  }



  refreshUI(): void {
    // See wiki for logic for hiding/showing traffic lights and contact us
    // https://dev.azure.com/sandvik/MIBP/_wiki/wikis/MIBP.wiki/5072/Product-Availability-(P-A-version)

    this.isAuroraCompany = this.sessionService.isAuroraCompany();
    const totalInStock =  this.availability ?
      this.availability.local + this.availability.global + this.availability.national: 0;

    this.showAvailability = totalInStock > 0 && this.isAuroraCompany;
    this.showContactInfo = !this.showAvailability;

    this.userCanSeeAvailability = this.sessionService.activeDeliverySequence && this.frontEndContext.testPermission(allPermissionPolicies.canSeeAvailability);
    this.calculateAvailabilityClasses();
  }

  private calculateAvailabilityClasses() {

    if (this.availability) {
      const national = this.availability.national;
      const global = this.availability.global;
      const local = this.availability.local;
      const allInStock = national + global + local;
      const quantity = this.quantity;


      // Not enough in stock
      // Mark all as partially available
      if (allInStock < quantity) {
        this.localAvailabilityClass = 'partially-available incomplete';
        this.globalAvailabilityClass = 'partially-available incomplete';
        this.nationalAvailabilityClass = 'partially-available incomplete';
        return;
      } else {

        const noSingleStockroomHasTheQuantity = local < quantity && global < quantity && national < quantity;
        if (allInStock >= quantity && noSingleStockroomHasTheQuantity ) {
          // All in stock but not in one single stock room.
          // Mark all as partially available
          this.localAvailabilityClass = 'partially-available';
          this.globalAvailabilityClass = 'partially-available';
          this.nationalAvailabilityClass = 'partially-available';
        } else {
          // Otherwise mark the single stockrooms as available where quantity is alright
          this.localAvailabilityClass =  local >= quantity ? 'available' : 'not-available';
          this.globalAvailabilityClass = global >= quantity ? 'available' : 'not-available';
          this.nationalAvailabilityClass = national >= quantity ? 'available' : 'not-available';
        }

      }

    }


  }

  clickedAvailabilityLink(): void {
    if(this.globalConfig.enableNewContactUs && this.contactUsItemEquiryFormEnabled){
      this.contactUsService.openItemEnquiryContactUs(this.productCode, this.quantity, SupportCaseItemEnquiryType.ProductAvailability);
    }
    else{
      const message = this.localizationService.format(
        this.availabilityDescriptionForContactUs,
        { code: this.productCode, qty: this.quantity.toString() }
      );
      this.contactUs(ContactUsTopic.Enquiry, message);
    }
  }

  contactUs(topic: ContactUsTopic, message: string): void {
    this.contactUsService.openContactUs(topic, message);
  }
  onChangeInDeliverySequence(ds : SessionDeliverySequenceViewModel) : void {
    if(ds !== this.deliverySequence){
      this.deliverySequence = ds;
    }
    
    this.showStockroomDetails = this.frontEndContext.testPermission({ features: [MySandvikFeatures.ShopProductAvailabilityStockroomdetail] });
  }

}
