import { DropdownComponent } from 'root/components/dropdown';
import { ApiService } from 'root/services';
import { Component, EventEmitter, forwardRef, Input, OnChanges, Output, SimpleChanges, ViewChild } from "@angular/core";
import { Observable } from 'rxjs';
import { DropdownArgs, DropdownData, DropdownInput } from 'root/components';
import { map } from 'rxjs/operators';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { EquipmentsApiController } from 'root/mibp-openapi-gen/controllers';
import {EquipmentIndexDto, PagedUserMyFleetRefinementOptions} from 'root/mibp-openapi-gen/models';
import { LogService, MibpLogger} from "root/services";


@Component({
  selector: 'mibp-equipment-picker',
  templateUrl: './equipment-picker.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => MibpEquipmentPickerComponent),
    }
  ]
})
export class MibpEquipmentPickerComponent implements ControlValueAccessor, OnChanges {

  @ViewChild(DropdownComponent) dropdown: DropdownComponent;
  dropdownPageSize = 10;
  equipment$: Observable<DropdownData>;
  showLoader = false;
  isFirst = false;
  currentValue: any;
  public currentSelectedItem : any;
  logger: LogService;
 private log: MibpLogger;

  @Input() dsChanged = false;
  @Input() deliverySequenceId : number = null;
  @Input() operationSiteId : number = null;
  @Input() isDisabled: boolean;
  @Input() onlyWithProducts = false;
  @Input() claimTempDisableWriteValueOnClear = false;
  @Input() showCustomer = false;
  @Input() ignoreActiveDeliverySequence = false;
  @Input() ignoreProductArea = null;
  /**
   * Set to true to only list equipment that have no Equipment Calendar (Maintenance planner)
   */
  @Input() onlyWithoutCalendar = false;
  @Input() alwaysIncludeEquipmentId: number;
  @Input() canBeCleared = false;
  @Output() valueChange = new EventEmitter();
  // eslint-disable-next-line @typescript-eslint/no-empty-function, @typescript-eslint/no-explicit-any
  onChange: (value: any) => void = () => { };
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onTouch: () => void = () => { };

  constructor(private api: ApiService, logger: LogService, private equipmentsApiController : EquipmentsApiController)
  {
    this.log = logger.withPrefix('equipment-picker.component');
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(changes.deliverySequenceId && changes.deliverySequenceId.previousValue && changes.deliverySequenceId.currentValue!==changes.deliverySequenceId.previousValue){
      this.equipment$ = null;
      this.dropdown.selectedOption = null;
    }
    if(changes.operationSiteId && changes.operationSiteId.previousValue && changes.operationSiteId.currentValue!==changes.operationSiteId.previousValue){
      this.equipment$ = null;
      this.dropdown.selectedOption = null;
    }
  }

  writeValue(equipmentId: any): void {

    if (!this.claimTempDisableWriteValueOnClear && this.dropdown && (equipmentId == '' || equipmentId == null)) {
      this.dropdown.writeValue(null);
      this.currentValue = null;
      return;
    }

    if (equipmentId && !isNaN(equipmentId)) {
      this.showLoader = true;
      if (typeof this.currentValue === 'undefined') {
        this.isFirst = true;
      }
      this.currentValue = equipmentId;
      this.equipmentsApiController.getEquipmentDetails({equipmentId:equipmentId}).subscribe(equipment => {
        if (equipment) {
          this.dropdown.writeValue({
            htmlText: `<span class="dropdown-item-tag ">${equipment.model}</span> ${equipment.alias || equipment.name}`,
            value: equipment.id.toString()
          } as DropdownInput);
        }
        this.showLoader = false;
        this.isFirst = false;
      },
      (error) => {
        this.log.error('error Equipment details',error);
      });
    }
  }
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }
  setDisabledState?(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }

  onDropdownValueChange(input: DropdownInput): void {
    if (input && input.value) {
      this.currentSelectedItem = input;
      if (this.isFirst === false) {
        this.onTouch();
      }
      if(this.currentValue !== input.value){
        this.valueChange.emit(input.value);
        this.currentValue = input.value;
      }
      this.onChange(parseInt(input.value, 10));
    } else {
      this.onTouch();
      this.onChange(null);
      if(this.currentValue !== input?.value){
        this.valueChange.emit(input?.value);
        this.currentValue = input?.value;
      }
    }
  }

  filterEquipment(args: DropdownArgs): void {

    if(this.deliverySequenceId == null && this.operationSiteId == null){
      this.equipment$ = this.equipmentsApiController.searchEquipment({body:{
        query: args.query,
        skip: args.index,
        take: args.take,
        fleetStatus:[],
        sortBy:null,
        onlyWithoutCalendar: this.onlyWithoutCalendar,
        onlyWithProducts: this.onlyWithProducts,
        alwaysIncludeEquipmentId: this.alwaysIncludeEquipmentId,
        ignoreActiveDeliverySequence:this.ignoreActiveDeliverySequence,
        ignoreProductArea:this.ignoreProductArea
      }
      }).pipe(map(searchResult => <DropdownData>{
        hasMoreResults: searchResult.count > args.index + searchResult.items.length,
        items: searchResult.items.map(equipment => <DropdownInput>{
          text: `${equipment.productModel} ${equipment.alias || equipment.name}` + this.getCustomerInfoString(equipment),
          htmlText: `<span class="dropdown-item-tag ">${equipment.productModel}</span> ${equipment.alias || equipment.name}` + this.getCustomerInfoString(equipment),
          value: equipment.id
        }),
        totalCount: searchResult.count
      }));
    }
    else if(this.operationSiteId != null){
      const payload : PagedUserMyFleetRefinementOptions = {query: args.query,
        skip: args.index,
        take: args.take,
        fleetStatus:[],
        sortBy:null,
        onlyWithProducts: this.onlyWithProducts,
        ignoreActiveDeliverySequence:this.ignoreActiveDeliverySequence,
        ignoreProductArea:this.ignoreProductArea};

      this.equipment$ = this.equipmentsApiController.searchEquipmentWithOperationSite({
        body:payload,
        operationSiteId : this.operationSiteId
      }).pipe(map(searchResult => <DropdownData>{
        hasMoreResults: searchResult.count > args.index + searchResult.items.length,
        items: searchResult.items.map(equipment => <DropdownInput>{
          text: `${equipment.productModel} ${equipment.alias || equipment.name}` + this.getCustomerInfoString(equipment),
          htmlText: `<span class="dropdown-item-tag ">${equipment.productModel}</span> ${equipment.alias || equipment.name}` + this.getCustomerInfoString(equipment),
          value: equipment.id
        }),
        totalCount: searchResult.count
      }));

    }
    else{
      const payload : PagedUserMyFleetRefinementOptions = {query: args.query,
        skip: args.index,
        take: args.take,
        fleetStatus:[],
        sortBy:null,
        onlyWithProducts: this.onlyWithProducts,
        ignoreActiveDeliverySequence:this.ignoreActiveDeliverySequence,
        ignoreProductArea:this.ignoreProductArea};

      this.equipment$ = this.equipmentsApiController.searchEquipmentwithDeliverySequence({
        body:payload,
        deliverySequenceId : this.deliverySequenceId
      }).pipe(map(searchResult => <DropdownData>{
        hasMoreResults: searchResult.count > args.index + searchResult.items.length,
        items: searchResult.items.map(equipment => <DropdownInput>{
          text: `${equipment.productModel} ${equipment.alias || equipment.name}` + this.getCustomerInfoString(equipment),
          htmlText: `<span class="dropdown-item-tag ">${equipment.productModel}</span> ${equipment.alias || equipment.name}` + this.getCustomerInfoString(equipment),
          value: equipment.id
        }),
        totalCount: searchResult.count
      }));

    }
  }

  private getCustomerInfoString(equipment: EquipmentIndexDto): string {
    return  this.showCustomer ? `<span class="dropdown-item-tag--secondary">${ equipment.companyCode + ' | ' + equipment.customerNumber + ' | ' + equipment.deliverySequence + '</span>'}` : '';
  }

}
