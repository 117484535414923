<mibp-dialog #dialog [buttons]="dialogButtons" [titleResourceStringKey]="resourceStringTitle" size="auto">
  <div>
    <mibp-resource-string  [key]="resourceStrcommentTitle"></mibp-resource-string>    
    <form [formGroup]="commentForm">
      <div class="forms__row" >
        <label><mibp-resource-string key="Global_Comment"></mibp-resource-string>
          <span class="required">(<mibp-resource-string key="Global_Required"></mibp-resource-string>)</span>
        </label>
        <textarea maxlength="{{ maxLength }}" formControlName="comment" placeholder="{{ placeholderTextforComment }}" class="comment"></textarea>
        <mibp-validation-text [formGroup]="commentForm" [alwaysVisible]="formSubmitted" controlName="comment"></mibp-validation-text>
      </div>
    </form>
  </div>
</mibp-dialog>