import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import { MibpGridFrontendFilter } from '../..';
import { DropdownInput } from 'root/components';
import { ArrayHelperService, MibpGridService } from 'root/services';
import { MibpGridFacet } from 'root/mibp-openapi-gen/models';

@Component({
  selector: 'mibp-grid-filter-facet',
  templateUrl: './mibp-grid-filter-facet.component.html',
  styleUrls: ['./mibp-grid-filter-facet.component.scss']
})
export class MibpGridFilterFacetComponent implements OnInit {

  private currentValue: MibpGridFacet[];
  private searchUpdateTimer: number;
  private updateMs = 500;
  private currentFilter: MibpGridFrontendFilter;
  filterForm: UntypedFormGroup;

  @Input()
  set filter(value: MibpGridFrontendFilter) {
    if (value.facets) {

      this.facets = value.facets.filter(f => f.value);
      if (!this.currentValue || this.currentValue.length === 0) {
        if (!value.column.disableEmptyFacetFilter) {
          this.facets.splice(0, 0, {text: this.gridService.getEmptyFacetText(), value: '(null)'});
        }
      }
    }
    this.currentFilter = value;
  }

  get filter() {
    return this.currentFilter;
  }

  @Output() update = new EventEmitter<string[]>();
  facets: DropdownInput[];

  @Input()
  set value(val: MibpGridFacet[]) {
    this.currentValue = val;
    if (this.filterForm) {
      this.filterForm.setValue({
        selection: this.facetArrayToDropdownInput(val)
      });
    }
  }

  constructor(private fb: UntypedFormBuilder, private arrayHelper: ArrayHelperService,
    private gridService: MibpGridService) { }

  ngOnInit() {
    this.filterForm = this.fb.group({
      selection: [this.facetArrayToDropdownInput(this.currentValue)]
    });
  }

  onItemRemoved(event: { item: DropdownInput, dropdown: boolean}) {
    // We only want to trigger a filter update if the item was removed by clicking on it's "x"
    if (!event.dropdown) {
      this.onDropdownHide();
      const selectionStringArray = this.filterForm.value.selection ? (this.filterForm.value.selection as MibpGridFacet[]) : [];
      this.update.emit(selectionStringArray.map(x => `${x.text}|(${x.value})`));
    }
  }


  onDropdownHide() {
    const selectionStringArray = this.filterForm.value.selection ? (this.filterForm.value.selection as MibpGridFacet[]) : [];
    this.update.emit(selectionStringArray.map(x => `${x.text}|(${x.value})`));
    this.currentValue = selectionStringArray.length > 0 ? selectionStringArray : null;
  }

  private facetArrayToDropdownInput(list: string[] | MibpGridFacet[]): DropdownInput[] {

    if (list && list.length > 0) {
      let workingList: MibpGridFacet[];

      if (typeof list[0] === 'string') {
        workingList = (list as string[]).map(strValue => {
          return this.gridService.mapStringToFacet(strValue);
        });
      } else {
        workingList = list as MibpGridFacet[];
      }

      return workingList.map<DropdownInput>((value: MibpGridFacet): DropdownInput => {
        if (value === null) {
          return { text: this.gridService.getEmptyFacetText(), value: null };
        }
        return {
          text: value.value === '(null)' ? this.gridService.getEmptyFacetText() : value.text,
          value: value.value === '(null)' ? null : value.value
        };
      });
    } else {
      return [];
    }
  }

  queueSearchUpdate() {
    if (this.searchUpdateTimer) {
      clearTimeout(this.searchUpdateTimer);
    }

    this.searchUpdateTimer = window.setTimeout(() => {
      this.update.emit(this.filterForm.value.selection);
    }, this.updateMs);
  }

}
