import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from "@angular/core";
import { differenceInSeconds } from 'date-fns';
import { ContextMenuComponent, UserAction } from "root/components";
import { FormattingService } from "root/services";


export interface ReloadClickEvent {
  timerWasRunning: boolean;
}

@Component({
  selector: 'mibp-countdown',
  templateUrl: './countdown.component.html',
  styleUrls: ['./countdown.component.scss']
})
export class MibpCountdownComponent implements OnInit {

  @Input() seconds = 10;
  @Input() useNewStyle = false;
  @Input() automaticallyStart = true;
  @Output() started = new EventEmitter();
  @Output() done = new EventEmitter();
  @Output() userChangedTimer = new EventEmitter<number>();
  @Output() reloadClick = new EventEmitter<ReloadClickEvent>();
  @ViewChild('ctxMenu') ctxMenu: ContextMenuComponent;


  protected contextMenuItems: UserAction[] = [5, 10, 15, 30, 60, 120, 180, 240].map<UserAction>(seconds =>
    <UserAction>{
      textOverride: `Reload every ${seconds} seconds`,
      name: seconds.toString()
    });

  protected startTime?: Date;
  protected inProgress = false;
  protected timer?: number;
  protected remainingSeconds: number;
  private manuallyStopped = false;

  constructor(private formatting: FormattingService) {}
  ngOnInit(): void {
    if (this.automaticallyStart == false) {
      this.manuallyStopped = true;
    }
  }

  toggle(): void {
    if (!this.inProgress) {
      this.start();
    } else {
      this.stop();
    }
  }

  protected startStop(e: Event): void {
    if (!this.inProgress) {
      this.ctxMenu.open(e as MouseEvent);
    } else {
      this.stop();
      this.manuallyStopped = true;
    }
  }

  protected onContextMenuSelect(item: UserAction): void {
    const seconds = parseInt(item.name, 10);
    if (this.seconds != seconds) {
      this.seconds = seconds;
      this.userChangedTimer.emit(seconds);
    }
    this.manuallyStopped = false;
    this.start();
  }

  public startIfNotManuallyStopped(): void {
    if (!this.manuallyStopped) {
      this.start();
    }
  }

  public start(): void {
    this.startTime = new Date();
    this.remainingSeconds = this.seconds;
    clearTimeout(this.timer);
    this.timer = window.setTimeout(() => this.tick(), 450 );
    this.inProgress = true;
  }

  private tick(): void {

    if (!this.inProgress) {
      return;
    }

    const diff = differenceInSeconds(new Date(), this.startTime);

    let  remaining = this.seconds - diff;

    if (remaining < 0) {
      remaining = 0;
    } else if (remaining >  this.seconds) {
      remaining = this.seconds;
    }

    this.remainingSeconds = remaining;

    if (diff >= this.seconds ) {
      this.inProgress = false;
      this.done.emit();

      // This was not stoppedf by the user.
      // So when startIfNotManuallyStopped is called the timer should resume
      // this.manuallyStopped = false;
      return;
    }
    this.timer = window.setTimeout(() => this.tick(), 450 );
  }

  protected triggerManualReload(): void {
    this.reloadClick.emit({timerWasRunning: this.inProgress});
    this.stop();
  }

  public stop(): void {
    clearTimeout(this.timer);
    this.inProgress = false;
  }

}
