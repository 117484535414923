import { Component, ElementRef, Input, OnChanges, SimpleChanges, ViewEncapsulation } from "@angular/core";
import { DomSanitizer, SafeHtml } from "@angular/platform-browser";


@Component({
  selector: 'mibp-highlight-text',
  template: `
  <span [innerHtml]="safeHighlightedHtml"></span>
  `,
  styleUrls: ['./highlight-text.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class MibpHighlightTextComponent implements OnChanges {

  safeHighlightedHtml?: SafeHtml;
  originalText?: string;

  @Input() text?: string;
  @Input() highlightText?: string;

  constructor(private elementRef: ElementRef, private sanitizer: DomSanitizer) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.text || changes.highlightText) {
      this.safeHighlightedHtml = this.highlight(changes.text?.currentValue || this.text );
    }
  }

  escapeRegExp(string: string): string {
    return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // $& means the whole matched string
  }

  private highlight(value: string): SafeHtml {
    if (this.highlightText) {
      if (value) {
        let newText = value?.trim();
        const parts = this.highlightText?.trim().split(' ');
        parts.forEach(part => {
          const rep = new RegExp(`(${this.escapeRegExp(part)})`, 'gi');
          newText = newText.replace(rep, '<mark class="highlight-text">$1</mark>');
        });
        return this.sanitizer.bypassSecurityTrustHtml(newText);
      }
    }

    return this.sanitizer.bypassSecurityTrustHtml(value);
  }



}
