import { Component, Input, OnInit, Output, ViewChild, EventEmitter, OnChanges, SimpleChanges, OnDestroy } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { DialogButton, DialogComponent, DropdownInput } from 'root/components';
import { ButtonColors } from 'root/components/button/button.enum';
import { ApiService, LocalizationService } from 'root/services';
import { MibpNoWhitespaceValidator } from 'root/validators/no-whitespace.validator';
import { Subscription } from 'rxjs';


@Component({
  selector: 'mibp-change-tracking-comment-dialog',
  templateUrl: './change-tracking-comment-dialog.component.html',
  styleUrls: ['./change-tracking-comment-dialog.component.scss']
})

export class ChangeTrackingCommentDialogComponent implements OnInit, OnDestroy {

  dialogButtons: DialogButton[] = [
    { resourceKey: 'Global_CancelButton', id: 'cancel', color: ButtonColors.Secondary },
    { resourceKey: 'Global_Save', id: 'save', color: ButtonColors.Primary, disabled: true }
  ];
  @ViewChild('dialog') dialog: DialogComponent;
  formSubmitted: false;
  resourceStringsUpdated: Subscription;
  @Output() okEvent = new EventEmitter();
  @Input() openDialog: boolean;
  @Output() cancelledEvent = new EventEmitter<boolean>();
  commentForm: UntypedFormGroup;
  placeholderTextforComment: string;
  maxLength = 250;
  resourceStringTitle: string;
  resourceStrcommentTitle: string;
  openPromise: (comment: string) => void;

  constructor(private fb: UntypedFormBuilder, private api: ApiService, private localization: LocalizationService) {
  }

  ngOnDestroy(): void {
    this.resourceStringsUpdated.unsubscribe();
  }

  ngOnInit(): void {
    this.commentForm = this.fb.group({
      comment: [null, [Validators.required, MibpNoWhitespaceValidator()]]
    });
    this.commentForm.valueChanges.subscribe(() => {
      if (!this.commentForm.invalid) {
        this.dialogButtons[1].disabled = false;
      }
      else {
        this.dialogButtons[1].disabled = true;
      }
    });
    this.resourceStringsUpdated = this.localization.loaded.subscribe(() => {
      // Required when using resource strings from code
      this.localization.getResourceString('Change_Tracking_Comment_PlaceHolder').then(resourceStringValue => {
        this.placeholderTextforComment = resourceStringValue;
      });
    });
    this.localization.getResourceString('Change_Tracking_Comment_PlaceHolder').then(resourceStringValue => {
      this.placeholderTextforComment = resourceStringValue;
    });
    this.resourceStringTitle = "Change_Tracking_Comment_Dialog_Title";    
  }

  open(): Promise<string> {
    const self = this;
    return new Promise<string>((resolve, reject) => {
      self.openPromise = resolve;

      this.dialog.open().then(result => {
        if (result.id == "save" && !self.commentForm.invalid) {
          const comment = self.commentForm.get('comment').value;
          resolve(comment);
        }
      }).finally(() => {
        this.commentForm.reset();
        
        if (this.openPromise) {
          this.openPromise(null);
          this.openPromise = null;
        }

        self.dialog.close();
      });
    });
  }
}
