import { FormattingService } from 'root/services';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({name: 'formatDuration'})
export class FormatDurationPipe implements PipeTransform {

  constructor(private formattingService: FormattingService) {}

  transform(start: Date | string, stop: Date | string ) {

    const startDate = start instanceof Date ? start : new Date(start as string);
    const endDate = stop instanceof Date ? stop : new Date(stop as string);

    return this.formattingService.formatDuration(startDate, endDate);
  }

}
