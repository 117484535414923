<ng-template #dialogTemplate let-name>

  <div class="{{modalClassName}} {{customCssClass}}" *ngIf="active" [style.zIndex]="zIndex" [id]="id">
    <div class="modal-background"></div>
    <div class="modal-card" [class.fullheight]="fullheight">
      <header class="modal-card-head" [class.modal-card-head--small]="useSmallHeader" *ngIf="title || titleResourceStringKey || rawTitle">

        <p *ngIf="title" class="modal-card-title" [class.modal-card-title--orange]="orangeTitle">
          <mibp-resource-string [key]="title"></mibp-resource-string>
        </p>
        <p *ngIf="titleResourceStringKey" class="modal-card-title" [class.modal-card-title--orange]="orangeTitle">
          <mibp-resource-string [key]="titleResourceStringKey" *ngIf="titleResourceStringKey"></mibp-resource-string>
        </p>
        <p *ngIf="rawTitle" class="modal-card-title">
          <span>{{rawTitle}}</span>
        </p>
        @if (subTitleResourceStringKey) {
          <p class="subtitle"><mibp-resource-string [key]="subTitleResourceStringKey"></mibp-resource-string></p>
        }
        @if (!hideCloseButton) {
          <mibp-button *ngIf="!useSmallHeader" aria-label="close" class="modal-close" (click)="close()"></mibp-button>
          <a *ngIf="useSmallHeader" aria-label="close" class="small-modal-close" (click)="close()"><span class="material-icon">close</span></a>
        }
      </header>
      <section class="modal-card-body body" [class.no-scroll]="disableScroll" [style.minHeight]="minHeight ? minHeight + 'px' : null">
        <div class="my-body" *ngIf="rawHtml" [innerHtml]="rawHtml"></div>
        <div class="simplebody" *ngIf="resourceStringKey || rawText">
          <p>
            <mibp-resource-string [key]="resourceStringKey" *ngIf="resourceStringKey"
              [isHtml]="resourceStringKeyIsHtml"></mibp-resource-string>


            <span *ngIf="rawText">{{ rawText }}</span>
          </p>
          <mibp-button *ngIf="!hideCloseButton && (!title && !titleResourceStringKey)" aria-label="close"
            class="modal-close" (click)="close()"></mibp-button>
        </div>
        <div *ngIf="showWarningText" class="warningText">
          <span translate="no" class="material-icon">warning</span>
          <mibp-resource-string [key]="warningResourceStringKey" [isHtml]="resourceStringKeyIsHtml">
          </mibp-resource-string>
        </div>
        <ng-content></ng-content>
      </section>
      <footer class="modal-card-foot hide-print" [class.modal-card-foot--spread-buttons]="speadButtonsEvenly" *ngIf="dialogButtons" [class.simpledialog]="!!resourceStringKey">


        <span class="btnSpan" *ngFor="let btn of dialogButtons; trackBy: trackButton">

        <div *ngIf="btn.alignRight" [class.modal-card-foot--spread-buttons-right]="btn.alignRight">
          <mibp-button [fallbackText]="btn.textOverride" [automatedTestId]="btn.automatedTestId"
            [minWidth]="btn.minWidth ? btn.minWidth : 120" [resourceStringKey]="btn.resourceKey"
            [resourceStringMacros]="btn.resourceMacros" (click)="dialogButtonClick(btn)" [disabled]="btn.disabled"
            [style]="btn.style" [color]="btn.color">{{btn.textOverride ? btn.textOverride : ''}}</mibp-button>
        </div>
        <div *ngIf="!btn.alignRight" [class.modal-card-foot--spread-buttons-left]="!btn.alignRight">
          <mibp-button [fallbackText]="btn.textOverride" [automatedTestId]="btn.automatedTestId"
            [minWidth]="btn.minWidth ? btn.minWidth : 120" [resourceStringKey]="btn.resourceKey"
            [resourceStringMacros]="btn.resourceMacros" (click)="dialogButtonClick(btn)" [disabled]="btn.disabled"
            [style]="btn.style" [color]="btn.color"></mibp-button>
        </div>
        </span>
      </footer>
    </div>
  </div>

  <div *ngIf="active && false">
    <div class="modal-fader dialog" style="display: block;"></div>
    <div class="modal-container" style="display: block;">
      <div class="modal-window dialog" [style.marginTop]="marginTop" [class.hide-close-button]="hideCloseButton">
        <mibp-button *ngIf="!hideCloseButton" class="modal-close" (click)="close()"></mibp-button>
        <div class="modal-content">
          <div style="max-width: 100%;">
            <div class="body lightBoxDialog" [style.width]="dialogWidth" [style.maxWidth]="dialogWidth ? 'none' : null">
              <!-- <ng-content></ng-content> -->
              <mibp-resource-string [key]="resourceStringKey" *ngIf="resourceStringKey"></mibp-resource-string>
            </div>
            <div class="buttons" *ngIf="dialogButtons">
              <span style="margin: 2px" *ngFor="let btn of dialogButtons">
                <mibp-button [automatedTestId]="btn.automatedTestId" [minWidth]="btn.minWidth ? btn.minWidth : 120" [resourceStringKey]="btn.resourceKey"
                  [resourceStringMacros]="btn.resourceMacros" (click)="dialogButtonClick(btn)" [disabled]="btn.disabled"
                  [style]="btn.style" [color]="btn.color"></mibp-button>
              </span>
            </div>
            <div [attr.class]="showButtonsClass" *ngIf="!dialogButtons">
              <div class="buttons">
                <span style="margin: 2px">
                  <mibp-button [automatedTestId]="'button-dialog-ok'" resourceStringKey="OK" [color]="btn.Enum.Colors.Primary" #btn></mibp-button>
                </span>
                <span style="margin: 2px">
                  <mibp-button [automatedTestId]="'button-dialog-cancel'"  resourceStringKey="Cancel" [color]="btn.Enum.Colors.Secondary" (click)="close()" #btn>
                  </mibp-button>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>
