import { Component,  Input, ViewEncapsulation, Output, EventEmitter, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'mibp-datetimepicker',
  templateUrl: './datetimepicker.component.html',
  styleUrls: ['./datetimepicker.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => DateTimepickerComponent),
    }
  ]
})
export class DateTimepickerComponent implements ControlValueAccessor {


  dateValue: string = null;
  @Input() placeholder: string;
  @Input() displayBlock = false;
  @Input() hideClearIcon = true;
  // TODO: Rename event to non-native event
  // eslint-disable-next-line @angular-eslint/no-output-native
  @Output() change = new EventEmitter<Date>();
  minDateValue: Date = null;
  maxDateValue: Date = null;
  @Input() isDisabled = false;
  public stylingName = "datepicker";
  onChange: (value: any) => void = () => {};
  onTouch: () => void = () => {};

  clear(): void {
    this.onChange(null);
    this.change.emit(null);
    this.onTouch();
  }

  @Input()
  set minDate(min: Date | string) {
    if (typeof min === 'string' || min instanceof Date) {
      this.minDateValue = new Date(min);
    } else if (typeof min === 'object') {
      this.minDateValue = min;
    } else {
      this.minDateValue = null;
    }
  }

  @Input()
  set maxDate(max: Date | string) {
    if (typeof max === 'string' || max instanceof Date) {
      this.maxDateValue = new Date(max);
    } else if (typeof max === 'object') {
      this.maxDateValue = max;
    } else {
      this.maxDateValue = null;
    }
  }

  @Input()
  public set date(value: Date | string) {

    if (value) {
      this.dateValue = new Date(value).toISOString().slice(0, 16);
    } else {
      this.dateValue = null;
    }
  }



  valuechange(value) {
    const offsetTime = new Date(Date.now()).getTimezoneOffset();
    const utcDateTime=value!=null && value!=''? new Date(new Date(value).getTime() - offsetTime*60*1000):null;
    this.onChange(utcDateTime);
    this.change.emit(value);
    this.onTouch();
  }
  writeValue(obj: any): void {
    this.date = obj;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }


}
